import React from 'react';
import { IconButton } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {
  AssetTable,
  IColumn,
  IRow,
} from '../../shared/AssetTable/AssetTable/AssetTable';
import { IBreadcrumbHandlerProps } from '../../../utils/addBreadcrumbHandler';
import { IChargingRecordViewModel } from '../../../types/chargingRecords';
import { getSharedChargingSessionsData } from '../../CollectionCards/ChargingSessions/helpers';
import { getTypeIcon } from '../../../utils/getTypeIcon';
import {
  IFieldNames,
  mapFieldsForTable,
} from '../../../utils/mapFieldsForTable';
import useSharedStyles from '../../shared/useSharedStyles';

interface IOwnProps {
  chargingSessions: IChargingRecordViewModel[];
  title?: string;
  addBreadcrumbHandler: (props: IBreadcrumbHandlerProps) => void;
}

export const ChargingSessions: React.FC<IOwnProps> = ({
  chargingSessions,
  addBreadcrumbHandler,
  title,
}) => {
  const sharedClasses = useSharedStyles();
  const fieldNames: IFieldNames = {
    public: {
      name: 'Session Type',
      width: '20px',
      noPadding: true,
      align: 'right',
    },
    created_at: {
      name: 'Creation Date',
      width: '165px',
    },
    total_time: {
      name: 'Duration',
    },
    total_energy: {
      name: 'Consumption',
    },
    total_price: {
      name: 'Price (incl. VAT)',
    },
    location_city: {
      name: 'City',
    },
    actions: {
      name: '',
      width: '36px',
    },
  };

  const getColumns = (): IColumn[] => mapFieldsForTable(fieldNames, []);

  const getRows = (): IRow[] => {
    if (!chargingSessions || !chargingSessions.length) {
      return [];
    }

    return Object.values(chargingSessions).map((cs) => {
      return {
        data: {
          public: {
            content: getTypeIcon({
              type: cs.type,
              color: '#878C96',
              fontSize: 14,
              marginBottom: 2,
            }),
          },
          ...getSharedChargingSessionsData(cs, {
            textOverflow: sharedClasses.textOverflow,
          }),
          location_city: {
            content: cs.city ? (
              <span data-testid='city-data'>{cs.city}</span>
            ) : (
              <span data-testid='city-data'>-</span>
            ),
          },
          actions: {
            content: (
              <IconButton
                color='primary'
                data-testid='charging-session-icon'
                onClick={() =>
                  addBreadcrumbHandler({
                    component: 'ChargingSessionDetails',
                    friendlyText: 'Charging Session Details',
                    id: cs.id,
                  })
                }
              >
                <ChevronRightIcon fontSize='large' />
              </IconButton>
            ),
          },
        },
        id: cs.id,
      };
    });
  };

  return (
    <div>
      <AssetTable
        data-testid='asset-table'
        bulkActions={[]}
        columns={getColumns()}
        rows={getRows()}
        sortRequestHandler={() => true}
        noDataTitle='No charging sessions available'
        noDataSubTitle={
          title ? title : 'User account has no access to any charging sessions.'
        }
      />
    </div>
  );
};

export default ChargingSessions;
