import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  outerWrapper: {
    position: 'relative',
  },
  informationRow: {
    padding: 16,
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexFlow: 'row nowrap',
    overflowX: 'scroll',
    position: 'relative',
    '&::after': {
      content: '"xxx"',
      color: '#fff',
      width: 50,
      height: '100%',
      background: '#fff',
      display: 'inline-block',
    },
  },
  rightFade: {
    right: 0,
    width: 50,
    position: 'absolute',
    background:
      'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',
    height: 56,
    top: 0,
    zIndex: 100,
  },
});
