import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    terminateSubscriptionButton: {
      color: theme.colorAlertPrimary,
      marginTop: '12px',
      display: 'flex',
      justifyContent: 'center',
    },
  })
);

export default useStyles;
