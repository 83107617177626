import {
  IHomeChargingStationModel,
  IOrganizationModel,
} from '../../../types/user';

/* eslint-disable @typescript-eslint/naming-convention */
import { ErrorObject } from 'serialize-error';
import { IAccounts } from '../../../types/organization';

export interface GetOrganizationRequest {
  organizationId: string | undefined;
  taskSid: string;
}

export interface OrganizationStateModel {
  loading: boolean;
  data: IOrganizationModel | null;
  error: ErrorObject | null;
  request: { organizationId: string | undefined } | null;
  stations: IHomeChargingStationModel | null;
  accounts: IAccounts | null;
}

export interface OrganizationState {
  [id: string]: OrganizationStateModel;
}

const state: OrganizationState = {};

export type State = typeof state;
export default state;
