import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';
import useStyles from './useStyles';

export type OwnProps = {
  open: boolean;
  details: string;
  onClose: () => void;
};
export const SubscriptionDetailsErrorDialog: React.FC<OwnProps> = ({
  open,
  details,
  onClose,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t, terms } = useTypedTranslation();
  return (
    <Dialog
      data-testid='subscription-termination-error-dialog'
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      open={open}
      onClose={onClose}
      PaperProps={{
        classes: {
          root: classes.errorDialog,
        },
      }}
    >
      <div>
        <DialogTitle>
          {onClose ? (
            <IconButton
              aria-label='close'
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
          <Typography variant='h2'>
            {t(terms.subscription_termination.error_dialog_heading)}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant='inherit'>{details}</Typography>
        </DialogContent>
      </div>
    </Dialog>
  );
};
