import { IOwnProps as IInputFieldProps } from '../../shared/Forms/InputField';
import { IOwnProps as IControlledSelect } from '../../shared/Forms/SelectField/ControlledSelect';
import { countryInformationMapper } from '../../../utils/currencies';
import { ISubscriberModel } from '../../../types/subscriber';
import { IUpdateBillingAddress } from '../../../services/billingAddress';

type SelectField = Omit<IControlledSelect, 'control'>;
type TranslationFunc = (key: string) => string;
type DefaultValues = Omit<IChangeBillingFields, 'zip'>;

export interface IChangeBillingFields {
  zip: string;
  city: string;
  country_code: string;
  address_line_1?: string;
  address_line_2?: string;
  state?: string;
}

export interface ITranslatedFields {
  textFields: { [key: string]: IInputFieldProps };
  selectFields: { [key: string]: SelectField };
}

export const DEFAULT_VALUES: DefaultValues = {
  city: '',
  country_code: '',
  address_line_1: '',
  address_line_2: '',
  state: '',
};

const countryCodeOptions = Object.keys(countryInformationMapper).map((key) => ({
  key,
  value: key,
  content: key,
}));

export const getTranslatedTextFields = (
  translate: TranslationFunc
): ITranslatedFields['textFields'] => ({
  addressLine1: {
    id: 'address_line_1',
    name: 'address_line_1',
    label: translate('address_line_1'),
  },

  addressLine2: {
    id: 'address_line_2',
    name: 'address_line_2',
    label: translate('address_line_2'),
  },

  zip: {
    id: 'zip',
    name: 'zip',
    label: translate('zip'),
    isRequired: true,
  },

  city: {
    id: 'city',
    name: 'city',
    label: translate('city'),
    isRequired: true,
  },

  state: {
    id: 'state',
    name: 'state',
    label: translate('state'),
  },
});

const getTranslatedSelectFields = (
  translate: TranslationFunc
): ITranslatedFields['selectFields'] => {
  return {
    country: {
      labelText: translate('country_code'),
      label: translate('country_code'),
      name: 'country_code',
      labelId: 'country',
      options: countryCodeOptions,
      isRequired: true,
    },
  };
};

/**
 * Creates all fields for the form to change the billing address with translations
 */
export const getTranslatedFields = (
  translate: TranslationFunc
): ITranslatedFields => {
  const getPrefixedString = (field: string) => translate(`fields.${field}`);

  return {
    textFields: getTranslatedTextFields(getPrefixedString),
    selectFields: getTranslatedSelectFields(getPrefixedString),
  };
};

export const getDefaultValues = (
  subscriberAddress: ISubscriberModel['address']
): Partial<IUpdateBillingAddress> => {
  if (!subscriberAddress) {
    return { ...DEFAULT_VALUES };
  }

  return {
    zip: subscriberAddress.zip,
    city: subscriberAddress.city || DEFAULT_VALUES.city,
    country_code: subscriberAddress.country_code || DEFAULT_VALUES.country_code,
    address_line_1:
      subscriberAddress.address_line_1 || DEFAULT_VALUES.address_line_1,
    address_line_2:
      subscriberAddress.address_line_2 || DEFAULT_VALUES.address_line_2,
    state: subscriberAddress.state || DEFAULT_VALUES.state,
  };
};
