import React from 'react';
import {
  AssetTable,
  IColumn,
  IRow,
  ISortState,
} from '../../shared/AssetTable/AssetTable/AssetTable';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { ElliTooltip } from '../../shared/ElliTooltip/ElliTooltip';
import { IBreadcrumbHandlerProps } from '../../../utils/addBreadcrumbHandler';
import { IInvoice } from '../../../types/invoice';
import IconButton from '@material-ui/core/IconButton';
import { formatIsoDates } from '../../../utils/dates';
import useSharedStyles from '../../shared/useSharedStyles';
import {
  IFieldNames,
  mapFieldsForTable,
} from '../../../utils/mapFieldsForTable';

export const parseAmount = (amount: number, currency?: string): string => {
  if (currency) {
    return `${amount} ${currency}`;
  }
  return `${amount}`;
};

interface IOwnProps {
  invoices: IInvoice[];
  sortState: ISortState;
  sortRequestHandler: (columnkey: string) => void;
  addBreadcrumbHandler: (props: IBreadcrumbHandlerProps) => void;
}

export const InvoiceTable: React.FC<IOwnProps> = ({
  invoices,
  sortState,
  sortRequestHandler,
  addBreadcrumbHandler,
}) => {
  const fieldNames: IFieldNames = {
    invoice_number: {
      name: 'Invoice Number',
    },
    creation_date: {
      name: 'Creation Date',
    },
    amount: {
      name: 'Amount',
    },
    credit_notes_amount: {
      name: 'Credit Notes',
    },
    actions: {
      name: '',
      width: '36px',
    },
  };

  const getColumns = (): IColumn[] => {
    const sortableColumns = [
      'invoice_number',
      'creation_date',
      'amount',
      'credit_notes_amount',
    ];

    return mapFieldsForTable(fieldNames, sortableColumns);
  };

  const getRows = (): IRow[] => {
    const sharedClasses = useSharedStyles();
    if (!invoices || !invoices.length) {
      return [];
    }

    return Object.values(invoices).map((invoice) => {
      return {
        data: {
          invoice_number: {
            content: invoice.invoice_number ? (
              <ElliTooltip title={invoice.invoice_number}>
                <span
                  className={sharedClasses.textOverflow}
                  data-testid='data-invoice-number'
                >
                  {invoice.invoice_number}
                </span>
              </ElliTooltip>
            ) : (
              <span data-testid='data-invoice-number'>-</span>
            ),
          },
          creation_date: {
            content: invoice.created_at ? (
              <ElliTooltip title={formatIsoDates(invoice.created_at)}>
                <span
                  className={sharedClasses.textOverflow}
                  data-testid='data-created-at'
                >
                  {formatIsoDates(invoice.created_at)}
                </span>
              </ElliTooltip>
            ) : (
              <span data-testid='data-created-at'>-</span>
            ),
          },
          amount: {
            content: invoice.amount ? (
              <ElliTooltip
                title={parseAmount(invoice.amount, invoice.currency)}
              >
                <span
                  className={sharedClasses.textOverflow}
                  data-testid='data-invoice-amount'
                >
                  {parseAmount(invoice.amount, invoice.currency)}
                </span>
              </ElliTooltip>
            ) : (
              <span data-testid='data-invoice-amount'>-</span>
            ),
          },
          credit_notes_amount: {
            content: (
              <span data-testid='data-invoice-credit-notes-amount'>
                {invoice.credit_notes?.length || ''}
              </span>
            ),
          },
          actions: {
            content: (
              <IconButton
                data-testid='invoice-link'
                onClick={() =>
                  addBreadcrumbHandler({
                    component: 'InvoiceDetails',
                    friendlyText: 'Invoice',
                    id: invoice.id,
                  })
                }
                color='primary'
              >
                <ChevronRightIcon fontSize='large' />
              </IconButton>
            ),
          },
        },
        id: invoice.id,
      };
    });
  };

  return (
    <div data-testid='invoice-table'>
      <AssetTable
        bulkActions={[]}
        columns={getColumns()}
        rows={getRows()}
        sortingState={sortState}
        sortRequestHandler={sortRequestHandler}
        noDataTitle='No invoices available'
        noDataSubTitle='Account does not yet have any invoices.'
      />
    </div>
  );
};

export default InvoiceTable;
