import { ActionInterface } from '../actions';
import { State } from '..';
import update from 'immutability-helper';

export default (
  state: State,
  action: ActionInterface.GetChargingCardSuccess
): State => {
  const { data } = action;
  const currentChargingCardState = state[action.taskSid];
  const updatedChargingCardState = update(currentChargingCardState, {
    data: {
      $set: data,
    },
    loading: {
      $set: false,
    },
  });

  return {
    ...state,
    [action.taskSid]: updatedChargingCardState,
  };
};
