import ActiveSessions from './activeSessions/sagas';
import Cardorders from './cardorders/sagas';
import ChargingCards from './chargingCards/sagas';
import ChargingSessions from './chargingSessions/sagas';
import ChargingStation from './chargingStations/sagas';
import HomeStations from './homeStations/sagas';
import Invoices from './invoices/sagas';
import Organization from './organization/sagas';
import Other from './other/sagas';
import Subscriber from './subscriber/sagas';
import Subscription from './subscription/sagas';
import User from './user/sagas';
import UserSearch from './userSearch/sagas';
import Tariffs from './tariffs/sagas';
import { all } from 'redux-saga/effects';

function* rootSaga() {
  yield all([
    Other(),
    User(),
    Subscriber(),
    Subscription(),
    Cardorders(),
    UserSearch(),
    ChargingCards(),
    ChargingSessions(),
    ChargingStation(),
    Organization(),
    HomeStations(),
    ActiveSessions(),
    Invoices(),
    Tariffs(),
  ]);
}

export default rootSaga;
