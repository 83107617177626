import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import {
  IBreadcrumbHandlerProps,
  addBreadcrumbHandler,
} from '../../../../utils/addBreadcrumbHandler';
import {
  IHomeChargingAddress,
  IHomeChargingStation,
  IOrganizationModel,
  IUserModel,
} from '../../../../types/user';

import { ActionInterface } from '../../../../store/state/breadcrumbs/actions';
import { BreadcrumbState } from '../../../../store/state/breadcrumbs';
import { ElliTooltip } from '../../../shared/ElliTooltip/ElliTooltip';
import { IChargingCardState } from '../../../../store/state/chargingCards';
import { IChargingRecordViewModel } from '../../../../types/chargingRecords';
import { ILocationModel } from '../../../../types/chargingsession';
import { IRFIDCardModel } from '../../../../types/subscriber';
import { OrganizationService } from '../../../../services/organizations';
import { UserState } from '../../../../store/state/user';
import { getStationDisplayName } from '../../../CollectionCards/ChargingSessions/helpers';
import { getTypeIcon } from '../../../../utils/getTypeIcon';
import { parseHomeChargingAddress } from '../../../../utils/parseAddress';
import { useCurrentTask } from '../../../../custom-hooks/useCurrentTask';
import useSharedStyles from '../../../shared/useSharedStyles';
import useStyles from './useStyles';

interface IOwnProps {
  chargingCardState: IChargingCardState;
  breadcrumbState: BreadcrumbState;
  crumbId?: string;
  addBreadcrumb: ({
    taskSid,
    data,
  }: Pick<ActionInterface.AddBreadcrumb, 'taskSid' | 'data'>) => void;
  chargingSession: IChargingRecordViewModel;
  userState: UserState;
  currentStation: IHomeChargingStation | null;
  currentLocation: ILocationModel | null;
}

export const ChargingLocation: React.FC<IOwnProps> = ({
  chargingCardState,
  addBreadcrumb,
  breadcrumbState,
  crumbId,
  chargingSession,
  userState,
  currentStation,
  currentLocation,
}) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const taskSid = useCurrentTask();
  const taskBreadcrumbState = breadcrumbState && breadcrumbState[taskSid];
  const taskUserState = userState && userState[taskSid];
  const [chargingCard, setChargingCard] = useState<IRFIDCardModel | null>(null);
  const [currentUser, setCurrentUser] = useState<IUserModel | null>(null);
  const [
    currentOrganization,
    setCurrentOrganization,
  ] = useState<IOrganizationModel | null>(null);

  const breadcrumbHandler = (props: IBreadcrumbHandlerProps): void => {
    addBreadcrumbHandler({
      ...props,
      addBreadcrumb,
      taskSid,
      crumbId,
      taskBreadcrumbState,
    });
  };

  useEffect(() => {
    if (
      chargingSession.type === 'public' ||
      chargingSession.type === 'business'
    ) {
      const getOrganization = async (organizationId: string) => {
        const orgResponse = await OrganizationService.getOrganization({
          organizationId,
        });

        setCurrentOrganization(orgResponse);
      };

      if (
        currentStation &&
        currentStation.owner &&
        currentStation.owner.type === 'organization'
      ) {
        getOrganization(currentStation.owner.iam_id);
      }
    }
  }, [currentStation]);

  useEffect(() => {
    if (taskUserState.data) {
      setCurrentUser(taskUserState.data);
    }
  }, [taskUserState]);

  useEffect(() => {
    if (chargingCardState && chargingSession) {
      const chargingCards = chargingCardState[taskSid].data;
      if (chargingCards) {
        const currentCard = chargingCards.find(
          (cc) => cc.id === chargingSession.card_id
        );

        if (currentCard) {
          setChargingCard(currentCard);
        }
      }
    }
  }, [chargingSession]);

  const viewModel = {
    operator: '-',
    address: '-',
    authType: '-',
    cardId: '-',
    stationId: '-',
  };

  if (chargingSession.type === 'private' && currentUser) {
    if (currentUser.first_name) {
      viewModel.operator = `${currentUser.first_name} ${currentUser.last_name} (user)`;
    } else {
      viewModel.operator = `${currentUser.email} (user)`;
    }
  } else if (
    (chargingSession.type === 'public' ||
      chargingSession.type === 'business') &&
    currentOrganization &&
    currentOrganization.name
  ) {
    viewModel.operator = currentOrganization.name;
  } else if (chargingSession.location_operator_name) {
    viewModel.operator = chargingSession.location_operator_name;
  } else if (
    currentLocation &&
    currentLocation.operator &&
    currentLocation.operator.name
  ) {
    viewModel.operator = currentLocation.operator.name;
  }

  if (chargingSession.location_address) {
    viewModel.address = chargingSession.location_address;
  } else if (currentStation && currentStation.location) {
    viewModel.address = parseHomeChargingAddress(
      currentStation.location.address
    );
  } else if (currentLocation && currentLocation.address) {
    viewModel.address = parseHomeChargingAddress(
      currentLocation.address as IHomeChargingAddress
    );
  }

  if (chargingSession.authentication_method) {
    viewModel.authType = chargingSession.authentication_method;
  }

  if (chargingCard && chargingCard.number) {
    viewModel.cardId = chargingCard.number;
  } else if (chargingSession.rfid_card_serial_number) {
    viewModel.cardId = chargingSession.rfid_card_serial_number;
  } else if (chargingSession.card_id) {
    viewModel.cardId = chargingSession.card_id;
  }

  viewModel.stationId = getStationDisplayName(chargingSession);

  return (
    <>
      <Grid item xs={12} className={classes.chargingLocationTitle}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h4'>Charging location</Typography>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h6'>
            {chargingSession.type === 'private' ? (
              <span data-testid='station-owner-title'>Station Owner</span>
            ) : (
              <span data-testid='station-operator-title'>Station Operator</span>
            )}
          </Typography>
          <div className={sharedClasses.cardDetailData}>
            {viewModel.operator !== '-' ? (
              <ElliTooltip title={viewModel.operator}>
                <span
                  data-testid='operator-data'
                  className={`${classes.itemValue}`}
                >
                  {viewModel.operator}
                </span>
              </ElliTooltip>
            ) : (
              <span data-testid='operator-data'>-</span>
            )}
          </div>
        </div>
      </Grid>
      <Grid item xs={8}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h6'>Address</Typography>
          <div className={sharedClasses.cardDetailData}>
            <Typography data-testid='data-address' variant='body2'>
              {viewModel.address}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={4}>
        <Typography variant='h6'>Authentication Type</Typography>
        <div className={sharedClasses.cardDetailData}>
          <Typography data-testid='data-authType' variant='body2'>
            {viewModel.authType}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={4}>
        <Typography variant='h6'>Charging Card Id</Typography>
        <div className={sharedClasses.cardDetailData}>
          {viewModel.cardId !== '-' ? (
            <ElliTooltip title={viewModel.cardId}>
              <span
                className={classes.clickableText}
                data-testid='data-charging-card-id'
                onClick={() =>
                  breadcrumbHandler({
                    component: 'ChargingCardDetails',
                    friendlyText: 'Charging Card',
                    id: chargingSession.card_id,
                  })
                }
              >
                {viewModel.cardId}
              </span>
            </ElliTooltip>
          ) : (
            <span data-testid='data-charging-card-id'>-</span>
          )}
        </div>
      </Grid>
      <Grid item xs={4}>
        <Typography variant='h6'>Station Id</Typography>
        <div className={classes.addressElement}>
          {viewModel.stationId !== '-' ? (
            <span
              className={classes.clickableText}
              data-testid='station-id-link'
              onClick={() =>
                breadcrumbHandler({
                  component: 'ChargingStationDetails',
                  friendlyText: 'Charging Station',
                  id: chargingSession.station_id,
                  config: {
                    station_id: chargingSession.station_id,
                    location_evse_id: chargingSession.location_evse_id,
                  },
                })
              }
            >
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {getTypeIcon({ type: chargingSession.type, fontSize: 16 })}
                <ElliTooltip title={viewModel.stationId}>
                  <span
                    data-testid='data-charging-station-id'
                    className={classes.itemValue}
                  >
                    {viewModel.stationId}
                  </span>
                </ElliTooltip>
              </span>
            </span>
          ) : (
            <span data-testid='data-charging-station-id'>-</span>
          )}
        </div>
      </Grid>
    </>
  );
};

export default ChargingLocation;
