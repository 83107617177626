import ApiService from './api';

export interface ITerminateSubscriptionResponse {
  connection_layer_customer_id?: string;
  connection_layer_customer_id_type?: string;
  connection_layer_subscriber_id?: string;
  connection_layer_subscriber_id_type?: string;
  connection_layer_subscription_id?: string;
  iam_id: string;
  subscription_id: string;
  tariff_code: string;
  vehicle_identification_number?: string;
}

const terminateSubscription = async (
  subscriptionId: string,
  clearVin: boolean
): Promise<ITerminateSubscriptionResponse> => {
  const apiService = new ApiService(
    `/mgmt/customer/v1/subscriptions/${subscriptionId}/terminate`,
    {
      method: 'POST',
      data: {
        clear_vehicle_identification_number: clearVin,
      },
    }
  );
  const response = await apiService.query();
  return response.data;
};

export const TerminateSubscriptionService = {
  terminateSubscription,
};
