import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    typeWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    indicator: {
      borderRadius: '5px',
      height: 8,
      width: 8,
    },

    statusPulse: {
      boxShadow: '0 0 0 0 rgba(255, 140, 0, 1)',
      animation: 'pulse-orange 2s infinite',
    },

    '@keyframes pulse-orange': {
      '0%': {
        transform: 'scale(0.95)',
        boxShadow: '0 0 0 0 rgba(255, 140, 0, 0.7)',
      },
      '70%': {
        transform: 'scale(1)',
        boxShadow: '0 0 0 5px rgba(255, 140, 0, 0)',
      },
      '100%': {
        transform: 'scale(0.95)',
        boxShadow: '0 0 0 0 rgba(255, 140, 0, 0)',
      },
    },
  })
);

export default useStyles;
