import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tagBox: {
      height: 16,
      paddingLeft: 3,
      paddingRight: 8,
      backgroundColor: theme.colorAlertPrimary,
      color: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center-',
      borderRadius: 200,
      fontWeight: 900,
      fontSize: 9,
      lineHeight: 1.33,
      boxShadow: '0 4px 12px 0 rgba(240, 0, 102, 0.2)',
      cursor: 'pointer',
      minWidth: '95px',
    },
    icon: {
      fontSize: 12,
      marginRight: 2,
    },
    dialog: {
      padding: 32,
      width: 410,
    },
    textWrapper: {
      display: 'flex',
      flexFlow: 'column nowrap',
      marginBottom: 20,
      '&:last-child': {
        marginBottom: 0,
      },
    },
    title: {
      fontWeight: 900,
      fontSize: 12,
      marginBottom: 6,
      lineHeight: 1.33,
      display: 'block',
    },
    section: {
      marginBottom: 24,
      '&:last-child': {
        marginBottom: 0,
      },
    },
  })
);

export default useStyles;
