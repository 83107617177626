import { TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';

import voucherIcon from '../../../../../assets/image/voucher.svg';
import creditCardIcon from '../../../../../assets/image/creditCard.svg';
import MultilineButton from '../../../../shared/Buttons/MultilineButton';
import { useTypedTranslation } from '../../../../../custom-hooks/useTypedTranslation';
import { countryInformationMapper } from '../../../../../utils/currencies';
import SelectField from '../../../../shared/Forms/SelectField';
import useFormStyles from '../../../../shared/Forms/useStyles';
import SelectOptionWithIcon from '../../../../shared/Forms/SelectField/SelectOptionWithIcon';
import useStyles from './useStyles';

interface IOwnProps {
  handleSubmit: (lineItemData: TLineItemFormData, countryCode: string) => void;
}

const countryCodeOptions = Object.keys(countryInformationMapper).map((key) => ({
  key,
  value: key,
  content: key,
}));

export interface ILineItem {
  type: TLineItemType;
  reimbursementType: TLineItemReimbursementType;
  amount: number;
  externalCdrId?: string;
  incurredAt?: string;
}

export type TLineItemType = keyof typeof LineItemTypes;
export type TLineItemReimbursementType = keyof typeof LineItemReimbursementTypes;
export type TLineItemFormData = Omit<ILineItem, 'id'>;

export const LineItemReimbursementTypes = {
  CREDIT_CARD: { text: 'Credit Card', icon: <img src={creditCardIcon} /> },
  VOUCHER: { text: 'Voucher', icon: <img src={voucherIcon} /> },
};

export enum LineItemTypes {
  CHARGING_SESSION = 'Charging Session',
  MONTHLY_FEE = 'Monthly Fee',
  SIGN_UP_FEE = 'Signup Fee',
}

const lineItemTypeOptions = Object.entries(LineItemTypes).map(
  ([key, text]) => ({
    key,
    value: key,
    content: text,
  })
);

const getLineItemReimbursementTypeOptions = (
  disabledKeys: TLineItemReimbursementType[] = []
) =>
  Object.entries(LineItemReimbursementTypes).map(([key, content]) => ({
    key,
    value: key,
    content: <SelectOptionWithIcon icon={content.icon} text={content.text} />,
    isDisabled: disabledKeys.includes(key as TLineItemReimbursementType),
  }));

const incurredAtLineItemTypes: TLineItemType[] = ['MONTHLY_FEE', 'SIGN_UP_FEE'];

const NewLineItem: React.FC<IOwnProps> = ({ handleSubmit }) => {
  const { t, terms } = useTypedTranslation();
  const classes = useStyles();
  const formClasses = useFormStyles();
  const [countryCode, setCountryCode] = useState<string>('');
  const [lineItemType, setLineItemType] = useState<TLineItemType | string>('');
  const [lineItemReimbursementType, setLineItemReimbursementType] = useState<
    TLineItemReimbursementType | ''
  >('');
  const [amount, setAmount] = useState<string>('');
  const [externalCdrId, setExternalCdrId] = useState<string>('');
  const [incurredAt, setIncurredAt] = useState<string>('');
  const currency = countryInformationMapper[countryCode]?.currency;
  const currencyLabel = currency ? ` in ${currency}` : '';

  const resetForm = () => {
    setLineItemType('');
    setLineItemReimbursementType('');
    setAmount('');
    setExternalCdrId('');
    setIncurredAt('');
  };

  const isExternalCdrIdRequired = lineItemType === 'CHARGING_SESSION';
  const isDateOfPositionRequired = incurredAtLineItemTypes.includes(
    lineItemType as TLineItemType
  );
  const isSubmitDisabled =
    !lineItemType ||
    !countryCode ||
    !lineItemReimbursementType ||
    (isExternalCdrIdRequired && !externalCdrId) ||
    (isDateOfPositionRequired && !incurredAt) ||
    isNaN(parseFloat(amount));

  const handleEntrySubmit = () => {
    handleSubmit(
      {
        type: lineItemType as TLineItemType,
        reimbursementType: lineItemReimbursementType as TLineItemReimbursementType,
        amount: parseFloat(amount),
        externalCdrId,
        incurredAt,
      },
      countryCode
    );
    resetForm();
  };

  const handleCountryCode = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCountryCode(event.target.value as string);
  };

  const handleLineItemType = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newLineItemType = event.target.value as TLineItemType;

    if (newLineItemType !== 'CHARGING_SESSION') {
      setLineItemReimbursementType('CREDIT_CARD');
    }

    setLineItemType(newLineItemType);
  };

  const handleLineItemReimbursementType = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setLineItemReimbursementType(
      event.target.value as TLineItemReimbursementType
    );
  };

  const handleAmount = (event: React.ChangeEvent<{ value: unknown }>) => {
    const cleanedUpAmount = (event.target.value as string)
      .replace(',', '.')
      .replace(' ', '');
    setAmount(cleanedUpAmount);
  };

  const handleDateOfPosition = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setIncurredAt(event.target.value as string);
  };

  const handleExternalCdrId = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setExternalCdrId(event.target.value as string);
  };

  const lineItemReimbursementOptions =
    lineItemType === 'CHARGING_SESSION'
      ? getLineItemReimbursementTypeOptions()
      : getLineItemReimbursementTypeOptions(['VOUCHER']);

  return (
    <>
      <SelectField
        name={'country-code'}
        labelText={t(terms.refunds.form.fields.country_code)}
        value={countryCode}
        handleChange={handleCountryCode}
        options={countryCodeOptions}
        isRequired
      />

      <Typography variant='h4' className={classes.headline}>
        {t(terms.refunds.form.line_item_headline)}
      </Typography>

      <SelectField
        name={'line-item-type'}
        labelText={t(terms.refunds.form.fields.type)}
        value={lineItemType}
        handleChange={handleLineItemType}
        options={lineItemTypeOptions}
        isRequired
      />

      <SelectField
        name={'line-item-reimbursement-type'}
        labelText={t(terms.refunds.form.fields.reimbursement_type)}
        value={lineItemReimbursementType}
        handleChange={handleLineItemReimbursementType}
        options={lineItemReimbursementOptions}
        isRequired
      />

      <TextField
        id='amount-input'
        label={t(terms.refunds.form.fields.amount) + currencyLabel}
        variant='outlined'
        value={amount}
        onChange={handleAmount}
        className={formClasses.formField}
        error={isNaN(Number(amount))}
        placeholder='0.00'
        fullWidth
        required
      />

      {isExternalCdrIdRequired && (
        <TextField
          id='ext-cdr-id-input'
          label={t(terms.refunds.form.fields.external_cdr_id)}
          variant='outlined'
          value={externalCdrId}
          onChange={handleExternalCdrId}
          className={formClasses.formField}
          placeholder='123a4567-b89c-12d3-e456-424242424242'
          fullWidth
          required
        />
      )}

      {isDateOfPositionRequired && (
        <TextField
          id='incurred-at'
          label={t(terms.refunds.form.fields.incurred_at)}
          variant='outlined'
          value={incurredAt}
          type='date'
          onChange={handleDateOfPosition}
          className={formClasses.formField}
          fullWidth
          required
        />
      )}

      <div className={classes.centered}>
        <MultilineButton
          mainText={t(terms.refunds.form.add_item)}
          handleClick={handleEntrySubmit}
          isDisabled={isSubmitDisabled}
          actionType='secondary'
        />
      </div>
    </>
  );
};

export default NewLineItem;
