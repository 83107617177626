import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    componentWrapper: {
      position: 'relative',
      minHeight: 180,
      '&:hover': {
        '& $cardOverlay': {
          transform: 'scale(1)',
        },
      },
    },
    componentWrapperSingle: {
      minHeight: 150,
    },
    chargerWrapper: {
      display: 'flex',
      alignItems: 'center',
      flexFlow: 'column nowrap',
      justifyContent: 'flex-start',
    },
    chargerIcon: {
      marginBottom: 12,
      position: 'relative',
    },
    chargerStateIcon: {
      position: 'absolute',
      left: 20,
    },
    elviCharger: {
      top: 45,
    },
    evBoxCharger: {
      top: 37,
    },
    chargerDetails: {
      display: 'flex',
      alignItems: 'center',
      flexFlow: 'column nowrap',
      justifyContent: 'flex-start',
    },
    totalEnergy: {
      fontSize: 20,
      fontWeight: 900,
      textAlign: 'center',
      lineHeight: 1.2,
    },
    energyText: {
      fontSize: 12,
      lineHeight: 1.33,
      textAlign: 'center',
      marginBottom: 8,
    },
    stationId: {
      fontSize: 12,
      lineHeight: 1.33,
      color: '#878c96',
      textAlign: 'center',
    },
    cardOverlay: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      position: 'absolute',
      top: '0',
      left: '0',
      height: '100%',
      width: '100%',
      background: 'rgb(255 255 255 / 77%)',
      paddingBottom: 20,
      transform: 'scale(0)',
    },
    cardOverlaySingle: {
      justifyContent: 'center',
    },
    iconButton: {
      padding: 6,
      fontSize: 20,
    },
    detailButton: {
      border: '2px solid #6633cc',
      background: 'white',
      fontSize: 16,
      fontWeight: 900,
      color: '#6633cc',
      lineHeight: 1.5,
      height: 40,
      width: 100,
      cursor: 'pointer',
      outline: 'none',
    },
    stationDotsWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 12,
    },
    dotCurrent: {
      display: 'block',
      height: 6,
      width: 6,
      backgroundColor: '#bfc7d3',
      margin: '4px 3px',
      borderRadius: 100,
    },
    dot: {
      display: 'block',
      height: 6,
      width: 6,
      backgroundColor: '#edeef0',
      margin: '4px 3px',
      borderRadius: 100,
    },
  })
);

export default useStyles;
