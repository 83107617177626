import React from 'react';
import Button from '@material-ui/core/Button';
import { useTheme } from '@material-ui/core';
import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';
import useStyles from './useStyles';

export type TerminateSubscriptionType = 'active' | 'upcoming';
export type OwnProps = {
  type: TerminateSubscriptionType;
  onClick: () => void;
};

export const TerminateSubscriptionButton: React.FC<OwnProps> = ({
  type,
  onClick,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t, terms } = useTypedTranslation();
  const terminationTranslation = {
    active: t(terms.subscription_information.terminate_subscription),
    upcoming: t(terms.subscription_information.terminate_upcoming_subscription),
  }[type];

  return (
    <div className={classes.terminateSubscriptionButton}>
      <Button className={classes.terminateSubscriptionButton} onClick={onClick}>
        {terminationTranslation}
      </Button>
    </div>
  );
};
