import React, { useEffect, useState } from 'react';
import TableFilter, {
  IFilterOption,
  IFilters,
} from '../../../shared/Filters/TableFilter';

import { Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import FilterTag from '../../../shared/Tags/FilterTag';
import { IHomeStation } from '../../../../types/user';
import { IRFIDCardModel } from '../../../../types/subscriber';
import { getFilters } from './filters';
import update from 'immutability-helper';
import useStyles from './useStyles';
interface IOwnProps {
  chargingCards: IRFIDCardModel[];
  selectedFilters: IFilterOption[];
  defaultFilters?: string[];
  privateStationsIds: string[];
  homeStations: IHomeStation[];
  setSelectedFilters: React.Dispatch<React.SetStateAction<IFilterOption[]>>;
}

export const ChargingSessionsFilter: React.FC<IOwnProps> = ({
  chargingCards,
  selectedFilters,
  privateStationsIds,
  homeStations,
  defaultFilters,
  setSelectedFilters,
}) => {
  const classes = useStyles();
  const [filterDrawerState, setFilterDrawerState] = useState<boolean>(false);
  const [filterState, setFilterState] = useState<IFilters>({
    filters: getFilters({ defaultFilters }),
  });

  useEffect(() => {
    const filters = getFilters({
      defaultFilters,
      chargingCards,
      privateStationsIds,
      homeStations,
    });

    setFilterState({
      filters,
    });
  }, [chargingCards, homeStations, defaultFilters]);

  useEffect(() => {
    const selected = filterState.filters.reduce(
      (accum: IFilterOption[], current) => {
        current.options.forEach((opt) => {
          if (opt.checked) {
            accum.push(opt);
          }
        });
        return accum;
      },
      []
    );

    setSelectedFilters(selected);
  }, [filterState]);

  const handleFilterChange = (option: IFilterOption): void => {
    const filterIndex = filterState.filters.findIndex(
      (stateFilter) => stateFilter.filterName === option.filterName
    );
    const optionIndex = filterState.filters[filterIndex].options.findIndex(
      (stateOption) => stateOption.name === option.name
    );

    const newState = update(filterState, {
      filters: {
        [filterIndex]: {
          options: {
            [optionIndex]: { $toggle: ['checked'] },
          },
        },
      },
    });

    setFilterState(newState);
  };

  const toggleFiltersDrawer = (open: boolean) => (): void | undefined => {
    setFilterDrawerState(open);
  };

  const clearAllFilters = (): void => {
    const newState = filterState.filters.map((stateFilter) => {
      const stateOptions = stateFilter.options.map((stateOption) => {
        stateOption.checked = false;
        return stateOption;
      });

      stateFilter.options = stateOptions;

      return stateFilter;
    });

    setFilterState({ filters: newState });
  };

  return (
    <>
      <div className={classes.filterWrapper} data-testid='filter-wrapper'>
        <Button
          className={classes.filterButton}
          endIcon={<FilterListIcon />}
          color='primary'
          onClick={toggleFiltersDrawer(true)}
          data-testid='open-filters-button'
        >
          Filters
        </Button>
        <div className={classes.filterTags}>
          {selectedFilters.map((tag: IFilterOption, index) => (
            <FilterTag key={index} tag={tag} clearFilter={handleFilterChange} />
          ))}
        </div>
        {selectedFilters.length > 0 && (
          <div className={classes.filterClear}>
            <Button
              className={classes.filterClearButton}
              color='primary'
              onClick={clearAllFilters}
              data-testid='clear-all-filters'
            >
              Clear All Filters
            </Button>
          </div>
        )}
      </div>
      <TableFilter
        filterDrawerState={filterDrawerState}
        toggleFiltersDrawer={toggleFiltersDrawer}
        handleFilterChange={handleFilterChange}
        filterState={filterState}
        setFilterState={setFilterState}
        selectedFilters={selectedFilters}
        clearAll={clearAllFilters}
      />
    </>
  );
};

export default ChargingSessionsFilter;
