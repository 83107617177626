/* eslint-disable @typescript-eslint/naming-convention */
import { ErrorObject } from 'serialize-error';
import { IUserModel, IUserSearchModel } from '../../../types/user';

export interface GetSelectedUserRequest {
  userId: string;
  taskSid: string;
}
export interface GetUserSearchRequest {
  email: string;
  taskSid: string;
}

export interface UserSearchStateModel {
  loading: boolean;
  data: IUserSearchModel[];
  error: ErrorObject | null;
  request: { query: string } | null;
  selectedUser: IUserModel | null;
}

export interface UserSearchState {
  [id: string]: UserSearchStateModel;
}

const state: UserSearchState = {};

export type State = typeof state;
export default state;
