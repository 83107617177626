import update from 'immutability-helper';
import { State, OrganizationState } from '..';
import { ActionInterface } from '../actions';

export default (
  state: State,
  action: ActionInterface.ClearOrganization
): State => {
  const updatedState: OrganizationState = update(state, {
    $unset: [action.taskSid],
  });

  return {
    ...updatedState,
  };
};
