import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useStyles from './useStyles';

type TConnectionState = 'connected' | 'disconnected';

interface IOwnProps {
  status: string;
}

export const ConnectionStatus: React.FC<IOwnProps> = ({ status }) => {
  const classes = useStyles();
  const theme = useTheme();
  const backgroundColorStatusMapping = {
    connected: theme.colorAccent,
    disconnected: theme.colorAlertPrimary,
  };
  const backgroundColor =
    backgroundColorStatusMapping[status as TConnectionState];
  return (
    <div
      data-testid='status-indicator'
      className={`${classes.indicator}`}
      style={{ backgroundColor }}
    />
  );
};
