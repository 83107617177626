/* eslint-disable @typescript-eslint/naming-convention */
import { ErrorObject } from 'serialize-error';

interface IDesignTemplate {
  template_id: string;
  url_1200_front?: string;
  url_1200_back?: string;
  url_800_front?: string;
  url_800_back?: string;
  url_400_front?: string;
  url_400_back?: string;
}

export interface IRFIDCard {
  brand_name: string;
  brand_id: string;
  design_templates: IDesignTemplate[];
}

export interface IRFIDCardModel {
  loading: boolean;
  data: IRFIDCard[];
  error: ErrorObject | null;
}

export interface IOtherState {
  rfidCards: IRFIDCardModel;
}

const state: IOtherState = {
  rfidCards: {
    loading: false,
    data: [],
    error: null,
  },
};

export type State = typeof state;
export default state;
