import { ActionType, ActionInterface } from '../actions';
import initialState, { State } from '../';
import getUserRequest from './getUserRequest';
import getUserSuccess from './getUserSuccess';
import getUserError from './getUserError';
import verifyUser from './verifyUser';
import clearUser from './clearUser';

const reducer = (state: State = initialState, action: any) => {
  switch (action.type) {
    case ActionType.GetUserRequest:
      return getUserRequest(state, action as ActionInterface.GetUserRequest);
    case ActionType.GetUserSuccess:
      return getUserSuccess(state, action as ActionInterface.GetUserSuccess);
    case ActionType.GetUserError:
      return getUserError(state, action as ActionInterface.GetUserError);
    case ActionType.VerifyUser:
      return verifyUser(state, action as ActionInterface.VerifyUser);
    case ActionType.ClearUser:
      return clearUser(state, action as ActionInterface.ClearUser);
    default:
      return state;
  }
};

export default reducer;
