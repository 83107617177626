import ApiService from './api';
import { IChargingRecordResponse } from '../types/chargingRecords';

interface IChargingRecordsProps {
  organization_id?: string;
  user_id?: string;
  limit?: number;
  offset?: number;
}

const getChargingRecords = async ({
  organization_id,
  user_id,
}: IChargingRecordsProps): Promise<IChargingRecordResponse> => {
  const data: IChargingRecordsProps = {
    limit: 100,
  };

  if (organization_id) {
    data.organization_id = organization_id;
  }

  if (user_id) {
    data.user_id = user_id;
  }

  const apiService = new ApiService(`/mgmt/csms/v1/chargingrecords`, {
    data,
  });

  const firstResponse = await apiService.query();
  const responseData = firstResponse.data;
  const totalCount = responseData.total_count;

  for (let i = 100; i <= totalCount; i = i + 100) {
    const newApiService = new ApiService(`/mgmt/csms/v1/chargingrecords`, {
      data: {
        ...data,
        offset: i,
      },
    });
    const newResponse = await newApiService.query();
    responseData.charging_records.push(...newResponse.data.charging_records);
  }

  return responseData;
};

export const ChargingRecordService = {
  getChargingRecords,
};
