import { ActionCreator, ActionInterface, ActionType } from './actions';
import {
  ActionCreator as BreadcrumbsActionCreator,
  ActionInterface as BreadcrumbsActionInterface,
} from '../breadcrumbs/actions';
import {
  ActionCreator as CardOrdersActionCreator,
  ActionInterface as CardOrdersActionInterface,
} from '../cardorders/actions';
import {
  ActionCreator as ChargingSessionsActionCreator,
  ActionInterface as ChargingSessionsActionInterface,
} from '../chargingSessions/actions';
import {
  ActionCreator as SubscriberActionCreator,
  ActionInterface as SubscriberActionInterface,
} from '../subscriber/actions';
import {
  ActionCreator as SubscriptionActionCreator,
  ActionInterface as SubscriptionActionInterface,
} from '../subscription/actions';
import {
  ActionCreator as UserActionCreator,
  ActionInterface as UserActionInterface,
} from '../user/actions';
import {
  ActionCreator as OrganizationActionCreator,
  ActionInterface as OrganizationActionInterface,
} from '../organization/actions';
import {
  ActionCreator as HomeStationsActionCreator,
  ActionInterface as HomeStationsActionInterface,
} from '../homeStations/actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import { IRFIDCard } from '.';
import { getRFIDCards } from '../../../services/rfidCards';

export function* otherSaga() {
  function* fetchRFIDCards(_action: ActionInterface.GetRFIDCardsRequest) {
    try {
      const rfidCards: IRFIDCard[] = yield call(getRFIDCards);

      yield put<ActionInterface.GetRFIDCardsSuccess>(
        ActionCreator.GetRFIDCardsSuccess({
          data: rfidCards,
        })
      );
    } catch (error) {
      yield put<ActionInterface.GetRFIDCardsError>(
        ActionCreator.GetRFIDCardsError({
          error,
        })
      );
    }
  }

  function* clearAll(action: ActionInterface.ClearAll) {
    try {
      yield put<UserActionInterface.ClearUser>(
        UserActionCreator.ClearUser({
          taskSid: action.taskSid,
        })
      );

      yield put<SubscriptionActionInterface.ClearSubscription>(
        SubscriptionActionCreator.ClearSubscription({
          taskSid: action.taskSid,
        })
      );

      yield put<SubscriberActionInterface.ClearSubscriber>(
        SubscriberActionCreator.ClearSubscriber({
          taskSid: action.taskSid,
        })
      );

      yield put<CardOrdersActionInterface.ClearCardOrders>(
        CardOrdersActionCreator.ClearCardOrders({
          taskSid: action.taskSid,
        })
      );

      yield put<BreadcrumbsActionInterface.ClearBreadcrumbs>(
        BreadcrumbsActionCreator.ClearBreadcrumbs({
          taskSid: action.taskSid,
        })
      );

      yield put<ChargingSessionsActionInterface.ClearChargingSessions>(
        ChargingSessionsActionCreator.ClearChargingSessions({
          taskSid: action.taskSid,
        })
      );

      yield put<OrganizationActionInterface.ClearOrganization>(
        OrganizationActionCreator.ClearOrganization({
          taskSid: action.taskSid,
        })
      );

      yield put<HomeStationsActionInterface.ClearHomeStations>(
        HomeStationsActionCreator.ClearHomeStations({
          taskSid: action.taskSid,
        })
      );
    } catch (error) {
      throw new Error(error);
    }
  }

  yield all([
    takeEvery(ActionType.GetRFIDCardsRequest, fetchRFIDCards),
    takeEvery(ActionType.ClearAll, clearAll),
  ]);
}

export default otherSaga;
