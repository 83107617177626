import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  Step,
  StepLabel,
  Stepper,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { ITerminateSubscriptionResponse } from '../../../../services/subscriptions';
import Typography from '@material-ui/core/Typography';
import { MKitDataPage } from '../MKitDataPage';
import { WeChargeDataPage } from '../WeChargeDataPage/WeChargeDataPage';
import { StepDescription } from '../StepDescription';
import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';
import { Trans } from 'react-i18next';
import useStyles from './useStyles';

export type OwnProps = {
  open: boolean;
  onClose: () => void;
  terminateSubscriptionResult: ITerminateSubscriptionResponse;
  tenant: string;
  twoStep: boolean;
};
export const TerminateSubscriptionSummaryDialog: React.FC<OwnProps> = ({
  open,
  onClose,
  terminateSubscriptionResult,
  tenant,
  twoStep,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { t, terms } = useTypedTranslation();

  const [step, setStep] = useState(0);
  let finished = false;

  return (
    <Dialog
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      open={open}
      onClose={onClose}
      PaperProps={{
        classes: {
          root: classes.dialog,
        },
      }}
    >
      <DialogTitle>
        <Typography
          display='block'
          align='center'
          color='textPrimary'
          variant='h2'
          paragraph
        >
          {t(terms.subscription_termination.result_dialog_heading_almost_there)}
        </Typography>
        <Typography
          display='block'
          align='center'
          color='textPrimary'
          variant='h4'
          paragraph
        >
          <Trans
            t={t}
            i18nKey={terms.subscription_termination.result_dialog_subheading}
          />
        </Typography>
      </DialogTitle>
      {twoStep ? (
        <Grid container direction='row' alignItems='center' justify='center'>
          <Grid item xs={8}>
            <Stepper
              className={classes.stepper}
              data-testid='terminate-subscription-result-stepper'
              activeStep={step}
            >
              <Step completed={step === 1}>
                <StepLabel />
              </Step>
              <Step completed={finished}>
                <StepLabel />
              </Step>
            </Stepper>
          </Grid>
        </Grid>
      ) : null}
      <StepDescription step={twoStep ? step : undefined} />
      <DialogContent>
        {step === 0 ? (
          <MKitDataPage
            terminationResponse={terminateSubscriptionResult}
            tenant={tenant}
          />
        ) : (
          <WeChargeDataPage />
        )}
      </DialogContent>

      <Grid
        container
        direction='row'
        spacing={0}
        className={classes.warningNote}
      >
        <Grid item xs={1}>
          <Icon>info</Icon>
        </Grid>
        <Grid item xs={11}>
          <Typography paragraph variant='caption'>
            {t(terms.subscription_termination.result_dialog_warning_note)}
          </Typography>
        </Grid>
      </Grid>
      {step === 0 && twoStep ? (
        <Grid container direction='row' alignItems='center' justify='center'>
          <Grid item xs={6}>
            <Button
              variant='outlined'
              className={classes.stepperButton}
              onClick={() => setStep(1)}
            >
              {t(terms.subscription_termination.result_dialog_button_next)}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid container direction='row' alignItems='baseline' justify='center'>
          {twoStep ? (
            <Grid item xs={6}>
              <Button
                className={classes.stepperButton}
                onClick={() => setStep(0)}
              >
                {t(terms.subscription_termination.result_dialog_button_back)}
              </Button>
            </Grid>
          ) : null}
          <Grid item xs={6}>
            <Button
              variant='outlined'
              className={classes.stepperButton}
              onClick={() => {
                finished = true;
                setStep(0);
                onClose();
              }}
            >
              {t(terms.subscription_termination.result_dialog_button_done)}
            </Button>
          </Grid>
        </Grid>
      )}
    </Dialog>
  );
};
