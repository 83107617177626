import update from 'immutability-helper';
import { State } from '../';
import { ActionInterface } from '../actions';

export default (
  state: State,
  action: ActionInterface.GetSubscriberSuccess
): State => {
  const { data } = action;
  const currentSubscriber = state[action.taskSid];

  const updatedSubscriber = update(currentSubscriber, {
    data: {
      $set: data,
    },
    loading: {
      $set: false,
    },
  });

  return {
    ...state,
    [action.taskSid]: updatedSubscriber,
  };
};
