import { ActionType, ActionInterface } from '../actions';
import initialState, { State } from '../';
import getSubscriberRequest from './getSubscriberRequest';
import getSubscriberSuccess from './getSubscriberSuccess';
import getSubscriberError from './getSubscriberError';
import clearSubscriber from './clearSubscriber';
import getSubscriberTempRequest from './getSubscriberTempRequest';
import getSubscriberTempSuccess from './getSubscriberTempSuccess';
import clearSubscriberTemp from './clearSubscriberTemp';
import setSubscriberTemp from './setSubscriberTemp';
import updateBillingAddressRequest from './updateSubscriberRequest';
import updateBillingAddressError from './updateSubscriberError';
import { updateBillingAddressSuccess } from './updateSubscriberSuccess';

const reducer = (state: State = initialState, action: any) => {
  switch (action.type) {
    case ActionType.GetSubscriberRequest:
      return getSubscriberRequest(
        state,
        action as ActionInterface.GetSubscriberRequest
      );
    case ActionType.GetSubscriberSuccess:
      return getSubscriberSuccess(
        state,
        action as ActionInterface.GetSubscriberSuccess
      );
    case ActionType.GetSubscriberError:
      return getSubscriberError(
        state,
        action as ActionInterface.GetSubscriberError
      );
    case ActionType.ClearSubscriber:
      return clearSubscriber(state, action as ActionInterface.ClearSubscriber);
    case ActionType.GetSubscriberTempRequest:
      return getSubscriberTempRequest(
        state,
        action as ActionInterface.GetSubscriberRequest
      );
    case ActionType.GetSubscriberTempSuccess:
      return getSubscriberTempSuccess(
        state,
        action as ActionInterface.GetSubscriberTempSuccess
      );
    case ActionType.ClearSubscriberTemp:
      return clearSubscriberTemp(
        state,
        action as ActionInterface.ClearSubscriberTemp
      );
    case ActionType.SetSubscriberTemp:
      return setSubscriberTemp(
        state,
        action as ActionInterface.SetSubscriberTemp
      );
    case ActionType.UpdateBillingAddressRequest:
      return updateBillingAddressRequest(
        state,
        action as ActionInterface.UpdateBillingAddressRequest
      );
    case ActionType.UpdateBillingAddressSuccess:
      return updateBillingAddressSuccess(
        state,
        action as ActionInterface.UpdateBillingAddressSuccess
      );
    case ActionType.UpdateBillingAddressError:
      return updateBillingAddressError(
        state,
        action as ActionInterface.UpdateBillingAddressError
      );
    default:
      return state;
  }
};

export default reducer;
