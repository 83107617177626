import { ActionType, ActionInterface } from '../actions';
import initialState, { State } from '..';
import getCardOrderRequest from './getCardOrderRequest';
import getCardOrderSuccess from './getCardOrderSuccess';
import getCardOrderError from './getCardOrderError';
import sortCardOrders from './sortCardOrders';
import clearCardOrders from './clearCardOrders';

const reducer = (state: State = initialState, action: any) => {
  switch (action.type) {
    case ActionType.GetCardOrderRequest:
      return getCardOrderRequest(
        state,
        action as ActionInterface.GetCardOrderRequest
      );
    case ActionType.GetCardOrderSuccess:
      return getCardOrderSuccess(
        state,
        action as ActionInterface.GetCardOrderSuccess
      );
    case ActionType.GetCardOrderError:
      return getCardOrderError(
        state,
        action as ActionInterface.GetCardOrderError
      );
    case ActionType.SortCardOrders:
      return sortCardOrders(state, action as ActionInterface.SortCardOrders);
    case ActionType.ClearCardOrders:
      return clearCardOrders(state, action as ActionInterface.ClearCardOrders);
    default:
      return state;
  }
};

export default reducer;
