import { config } from './config';

// your account sid
const accountSid = config.twilioAccountSid;
// set to /plugins.json for local dev
// set to /plugins.local.build.json for testing your build
// set to "" for the default live plugin loader
const pluginServiceUrl = '/plugins.json';
interface IPluginService {
  enabled: boolean;
  url: string;
}
interface ISso {
  accountSid: string;
}
export interface IAppConfig {
  pluginService: IPluginService;
  sso: ISso;
  logLevel: string;
}

// https://www.twilio.com/docs/flex/developer/ui/configuration
export const appConfig: IAppConfig = {
  pluginService: {
    enabled: true,
    url: pluginServiceUrl,
  },
  sso: {
    accountSid: accountSid,
  },
  logLevel: 'warn',
};
