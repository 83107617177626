import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Trans } from 'react-i18next';
import { ITerminateSubscriptionResponse } from '../../../services/subscriptions';
import { TerminationResponse } from './TerminationResponse/TerminationResponse';
import { useTypedTranslation } from '../../../custom-hooks/useTypedTranslation';

export type OwnProps = {
  terminationResponse: ITerminateSubscriptionResponse;
  tenant: string;
};

export const MKitDataPage: React.FC<OwnProps> = ({
  terminationResponse,
  tenant,
}) => {
  const { t, terms } = useTypedTranslation();
  return (
    <React.Fragment>
      <Typography color='textPrimary' paragraph variant='inherit'>
        <Trans
          t={t}
          i18nKey={terms.subscription_termination.create_mkit_ticket_prompt}
          components={{
            a: <a href='https://sc3.vwgroup.com/sc3/index.do?lang=en' />,
          }}
        />
      </Typography>
      <TerminationResponse
        terminationResponse={terminationResponse}
        tenant={tenant}
      />
    </React.Fragment>
  );
};
