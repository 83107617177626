/* eslint-disable @typescript-eslint/naming-convention */
import { ErrorObject } from 'serialize-error';
import { ISubscriberModel } from '../../../types/subscriber';

export interface GetSubscriberRequest {
  userId: string;
  taskSid: string;
}

export interface SubscriberStateModel {
  loading: boolean;
  data: ISubscriberModel | null;
  searchData: ISubscriberModel | null;
  error: ErrorObject | null;
  request: { userId: string; action: string } | null;
}

export interface SubscriberState {
  [id: string]: SubscriberStateModel;
}

const state: SubscriberState = {};

export type State = typeof state;
export default state;
