import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  headline: {
    marginBottom: 16,
    fontSize: 16,
    fontWeight: 900,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  chargingCardLink: {
    cursor: 'pointer',
    textDecoration: 'underline',

    '&:hover': {
      color: '#6633cc',
    },
  },
  cardDetail: {
    marginBottom: '24px',
  },
  cardDetailData: {
    marginTop: '10px',
    padding: '0 20px 0 0',
    display: 'flex',
    alignItems: 'center',
  },
  outerItemWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    flexFlow: 'row wrap',
  },
  itemWrapper: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: 24,
  },
  marginRight: {
    marginRight: 36,
  },
  itemTitle: {
    fontSize: 12,
    color: '#878c96',
    marginBottom: 10,
    lineHeight: 1.33,
    position: 'relative',
  },
  itemValue: {
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
  },
  itemLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
});

export default useStyles;
