import update from 'immutability-helper';
import { State } from '..';
import { ActionInterface } from '../actions';

export default (
  state: State,
  action: ActionInterface.ClearSelectedUser
): State => {
  const { taskSid } = action;
  const currentUser = state[taskSid];

  const updatedUser = update(currentUser, {
    selectedUser: {
      $set: null,
    },
    data: {
      $set: [],
    },
    loading: {
      $set: false,
    },
    error: {
      $set: null,
    },
    request: {
      $set: null,
    },
  });

  return {
    ...state,
    [action.taskSid]: updatedUser,
  };
};
