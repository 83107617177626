/* eslint-disable @typescript-eslint/naming-convention */
import { ErrorObject } from 'serialize-error';
import { ISubscriptionModel } from '../../../types/subscriber';

export interface GetSubscriptionRequest {
  subscriberId: string;
  taskSid: string;
}

export interface SubscriptionStateModel {
  loading: boolean;
  data: ISubscriptionModel[] | null;
  error: ErrorObject | null;
  request: { subscriberId: string } | null;
}

export interface SubscriptionState {
  [id: string]: SubscriptionStateModel;
}

const state: SubscriptionState = {};

export type State = typeof state;
export default state;
