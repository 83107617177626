import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    orderRfidCardButton: {
      '&:hover': {
        background: 'none',
      },
    },
    chargingCardWrapper: {
      display: 'flex',
      flexDirection: 'column',
      '> *:not(:last-child)': {
        marginBottom: '16px',
      },
    },
    chargingCardInnerWrapper: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'center',
      justifyContent: 'center',
    },
    panelAction: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      marginTop: '16px',
    },
  })
);

export default useStyles;
