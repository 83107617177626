import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import Exclaim from '../../../../../assets/image/exclaim.svg';
import useStyles from './useStyles';

interface ICircleProps {
  circleState: 'happy' | 'sad' | 'neutral';
  happyText?: string;
  sadText?: string;
  linePosition?: 'top' | 'bottom' | 'center';
}

export const Circle: React.FC<ICircleProps> = ({
  circleState,
  happyText,
  sadText,
  linePosition,
}) => {
  const classes = useStyles();

  const circleClass =
    circleState === 'happy'
      ? classes.circleHappy
      : circleState === 'sad'
      ? classes.circleSad
      : classes.circleNeutral;

  return (
    <div className={classes.circleComponentWrapper}>
      <div className={`${classes.circle} ${circleClass}`}>
        {circleState === 'happy' ? (
          <CheckIcon
            style={{
              fontSize: 32,
              color: '#00ce4e',
            }}
          />
        ) : circleState === 'sad' ? (
          <img src={Exclaim} alt='exclaim' />
        ) : (
          ''
        )}
      </div>
      <div className={classes.circleText}>
        {happyText ? happyText : sadText}
      </div>
      {linePosition === 'top' ? (
        <div className={classes.circleDirectionDown}>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 82 82'>
            <g
              style={{
                transform: 'scale(1, -1)',
                transformOrigin: 'center',
              }}
            >
              <path
                d='M.976 1.952A.976.976 0 11.976 0c14.566 0 23.31 1.75 29.766 6.92C38.314 12.98 41.976 23.719 41.976 41c0 16.545 3.756 26.823 11.121 32.547 6.167 4.792 14.561 6.5 27.927 6.5a.976.976 0 010 1.953c-13.765 0-22.525-1.783-29.125-6.911C43.987 68.94 40.024 58.092 40.024 41c0-16.746-3.474-26.93-10.502-32.556C23.51 3.63 15.131 1.952.976 1.952z'
                fill={
                  circleState === 'happy'
                    ? '#00ce4e'
                    : circleState === 'sad'
                    ? '#f00066'
                    : '#cad3e0'
                }
                fillRule='nonzero'
              />
            </g>
          </svg>
        </div>
      ) : linePosition === 'bottom' ? (
        <div className={classes.circleDirectionUp}>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 82 82'>
            <path
              d='M.976 1.952A.976.976 0 11.976 0c14.566 0 23.31 1.75 29.766 6.92C38.314 12.98 41.976 23.719 41.976 41c0 16.545 3.756 26.823 11.121 32.547 6.167 4.792 14.561 6.5 27.927 6.5a.976.976 0 010 1.953c-13.765 0-22.525-1.783-29.125-6.911C43.987 68.94 40.024 58.092 40.024 41c0-16.746-3.474-26.93-10.502-32.556C23.51 3.63 15.131 1.952.976 1.952z'
              fill={
                circleState === 'happy'
                  ? '#00ce4e'
                  : circleState === 'sad'
                  ? '#f00066'
                  : '#cad3e0'
              }
              fillRule='nonzero'
            />
          </svg>
        </div>
      ) : linePosition === 'center' ? (
        <div className={classes.circleDirectionStraight}>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 82 2'>
            <rect
              stroke={
                circleState === 'happy'
                  ? '#00ce4e'
                  : circleState === 'sad'
                  ? '#f00066'
                  : '#cad3e0'
              }
              strokeWidth='2'
              x='1'
              y='1'
              width='80'
              height='1'
              rx='.5'
              fill='none'
              fillRule='evenodd'
            />
          </svg>
        </div>
      ) : null}
    </div>
  );
};
