import Msal from 'msal';
import { config } from './config';

export const msalConfig: Msal.Configuration = {
  auth: {
    clientId: config.aadClientId,
    authority: config.aadClientAuthority,
    redirectUri: config.aadRedirectUrl,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const scopes = ['openid', 'email', 'user.read'];
export const state = window.location.search;
