/* eslint-disable @typescript-eslint/naming-convention */
import { ErrorObject } from 'serialize-error';
import { IInvoice } from '../../../types/invoice';

export interface InvoiceStateModel {
  loading: boolean;
  data: IInvoice[] | null;
  error: ErrorObject | null;
  request: {
    subscriberId?: string;
    invoiceId?: string;
  } | null;
}

export interface InvoiceState {
  [id: string]: InvoiceStateModel;
}

const state: InvoiceState = {};

export type State = typeof state;
export default state;
