import React, { useEffect, useState } from 'react';
import ReceiptIcon from '@material-ui/icons/Receipt';

import connector, { IPropsFromState } from '../../Connector/Connector';
import { IInvoice } from '../../../types/invoice';
import { InvoiceService } from '../../../services/invoices';
import { useCurrentTask } from '../../../custom-hooks/useCurrentTask';
import Details from './Details';
import useStyles from './useStyles';
import {
  addBreadcrumbHandler,
  IBreadcrumbHandlerProps,
} from '../../../utils/addBreadcrumbHandler';
import PdfViewer from '../../shared/PdfViewer';

type TOwnProps = IPropsFromState & {
  crumbId?: string;
  resourceId?: string;
  hideRefunds?: boolean;
};

export const InvoiceDetails: React.FC<TOwnProps> = ({
  addBreadcrumb,
  breadcrumbState,
  invoicesState,
  resourceId,
  crumbId,
  hideRefunds,
}) => {
  const classes = useStyles();
  const taskSid = useCurrentTask();
  const [currentInvoice, setCurrentInvoice] = useState<IInvoice | null>(null);
  const [invoicePdf, setInvoicePdf] = useState<Blob | null>(null);
  const taskBreadcrumbState = breadcrumbState && breadcrumbState[taskSid];
  const taskInvoices = invoicesState && invoicesState[taskSid];

  const breadcrumbHandler = (props: IBreadcrumbHandlerProps): void =>
    addBreadcrumbHandler({
      ...props,
      addBreadcrumb,
      crumbId,
      taskBreadcrumbState,
      id: currentInvoice?.id,
      taskSid,
    });

  useEffect(() => {
    const invoices = taskInvoices && taskInvoices.data;
    if (!invoices) {
      return;
    }

    const current = invoices.find((invoice) => invoice.id === resourceId);

    if (current) {
      setCurrentInvoice(current);
    }
  }, [taskInvoices, resourceId]);

  useEffect(() => {
    const getPDF = async () => {
      const { data } = await InvoiceService.getInvoicePdf(
        (currentInvoice as IInvoice).id
      );
      setInvoicePdf(data);
    };

    if (currentInvoice?.id) {
      getPDF().catch(() => {
        setInvoicePdf(null);
      });
    }
  }, [currentInvoice]);

  return (
    <div
      className={classes.cardElement}
      data-testid='invoice-details-component'
    >
      {currentInvoice && (
        <>
          <div className={classes.cardHeaderWrapper}>
            <ReceiptIcon className={classes.headerLogo} />
            <h1 className={classes.headerTitle}>
              Invoice {currentInvoice.invoice_number}
            </h1>
          </div>
          <Details
            currentInvoice={currentInvoice}
            addBreadcrumbHandler={breadcrumbHandler}
            hideRefunds={hideRefunds}
          />
        </>
      )}
      {invoicePdf && <PdfViewer file={invoicePdf} />}
    </div>
  );
};

export default connector(InvoiceDetails);
