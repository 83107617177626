import { ActionInterface } from '../actions';
import { IMember } from '../../../../types/organization';
import { ISortState } from '../../../../components/shared/AssetTable/AssetTable/AssetTable';
import { State } from '..';
import { lexicographical } from '../../../../utils/comparisonFunctions';
import update from 'immutability-helper';

interface ISortFunctionProps {
  a: IMember;
  b: IMember;
}

interface ISortFunctions {
  [string: string]: (props: ISortFunctionProps) => number;
}

export const sortFunctions: ISortFunctions = {
  email: ({ a, b }) => {
    return lexicographical(a.email, b.email);
  },
  first_name: ({ a, b }) => {
    return lexicographical(a.first_name || '', b.first_name || '');
  },
  last_name: ({ a, b }) => {
    return lexicographical(a.last_name || '', b.last_name || '');
  },
  roles: ({ a, b }) => {
    const first = a.roles ? a.roles.join('') : '-';
    const second = b.roles ? b.roles.join('') : '-';
    return lexicographical(first, second);
  },
};

export const generateSortFunction = (sortState: ISortState) => (
  a: IMember,
  b: IMember
): number => {
  const sortKey = sortState.sortByColumnKey as AdminOrderSortKey;
  if (sortKey) {
    const sortFunction = sortFunctions[sortKey];
    const ascendingSortResult = sortFunction({ a, b });

    return sortState.sortDesc ? ascendingSortResult * -1 : ascendingSortResult;
  } else {
    return 0;
  }
};

type AdminOrderSortKey = keyof typeof sortFunctions;

export default (
  state: State,
  action: ActionInterface.SortOrganizationStations
): State => {
  const { sortState, taskSid } = action;
  const currentOrganizationState = state[taskSid];

  if (currentOrganizationState && currentOrganizationState.accounts) {
    const sortedAccounts: IMember[] = currentOrganizationState.accounts.members;
    sortedAccounts.sort(generateSortFunction(sortState));

    const updatedOrganization = update(currentOrganizationState, {
      loading: {
        $set: false,
      },
      accounts: {
        members: {
          $set: sortedAccounts,
        },
      },
    });

    return {
      ...state,
      [action.taskSid]: updatedOrganization,
    };
  }

  return {
    ...state,
  };
};
