import {
  IRFIDCardModel,
  ISubscriptionModel,
} from '../../../../types/subscriber';

import { ActionInterface } from '../actions';
import { IChargingRecordViewModel } from '../../../../types/chargingRecords';
import { ISortState } from '../../../../components/shared/AssetTable/AssetTable/AssetTable';
import { ITariffModel } from '../../../../types/tariff';
import { State } from '..';
import { lexicographical } from '../../../../utils/comparisonFunctions';
import update from 'immutability-helper';

interface ISortFunctionProps {
  a: IRFIDCardModel;
  b: IRFIDCardModel;
  sessions?: IChargingRecordViewModel[];
  subscriptions?: ISubscriptionModel[];
  tariffs?: ITariffModel[];
}

interface ISortFunctions {
  [string: string]: (props: ISortFunctionProps) => number;
}

export const getTariff = (
  subscriptionId?: string,
  subscriptions: ISubscriptionModel[] = [],
  tariffs: ITariffModel[] = []
): string => {
  if (tariffs.length < 1 || subscriptions.length < 1) {
    return '';
  }

  const subscription = subscriptions.find((item) => subscriptionId === item.id);

  if (subscription) {
    const tariff = tariffs.find((item) => item.id === subscription.tariff_id);

    if (tariff) {
      return tariff.code;
    }
  }

  return '';
};

export const getLastTimeUsedAndEvseId = (
  id?: string,
  sessions: IChargingRecordViewModel[] = [],
  isDate = false
): string => {
  if (sessions.length < 1) {
    return '';
  }

  const cardSessions = sessions.filter((item) => id === item.card_id);

  if (cardSessions && cardSessions.length > 0) {
    const { start_date_time, station_id } = [
      ...cardSessions,
    ].sort((a, b): number =>
      new Date(b.start_date_time) > new Date(a.start_date_time) ? 1 : -1
    )[0];

    if (isDate) {
      return start_date_time;
    }

    return station_id;
  }

  return '';
};

export const sortFunctions: ISortFunctions = {
  number: ({ a, b }) => {
    return lexicographical(a.number, b.number);
  },
  status: ({ a, b }) => {
    return lexicographical(a.status, b.status);
  },
  subscription: ({ a, b, subscriptions, tariffs }) => {
    const cardA = getTariff(a.subscription_id, subscriptions, tariffs);
    const cardB = getTariff(b.subscription_id, subscriptions, tariffs);
    return lexicographical(cardA, cardB);
  },
  label: ({ a, b }) => {
    return lexicographical(a.label, b.label);
  },
  last_time_used: ({ a, b, sessions }) => {
    const timeA = getLastTimeUsedAndEvseId(a.id, sessions, true);
    const timeB = getLastTimeUsedAndEvseId(b.id, sessions, true);
    const lastTimeUsedA = timeA ? new Date(timeA) : '';
    const lastTimeUsedB = timeB ? new Date(timeB) : '';
    return lexicographical(lastTimeUsedA, lastTimeUsedB);
  },
  last_station_used: ({ a, b, sessions }) => {
    const evseA = getLastTimeUsedAndEvseId(a.id, sessions);
    const evseB = getLastTimeUsedAndEvseId(b.id, sessions);
    return lexicographical(evseA, evseB);
  },
};

export const generateSortFunction = (
  sortState: ISortState,
  sessions: IChargingRecordViewModel[],
  subscriptions: ISubscriptionModel[],
  tariffs: ITariffModel[]
) => (a: IRFIDCardModel, b: IRFIDCardModel): number => {
  const sortKey = sortState.sortByColumnKey as AdminOrderSortKey;
  if (sortKey) {
    const sortFunction = sortFunctions[sortKey];
    const ascendingSortResult = sortFunction({
      a,
      b,
      sessions,
      subscriptions,
      tariffs,
    });

    return sortState.sortDesc ? ascendingSortResult * -1 : ascendingSortResult;
  } else {
    return 0;
  }
};

type AdminOrderSortKey = keyof typeof sortFunctions;

export default (
  state: State,
  action: ActionInterface.SortChargingCards
): State => {
  const { sortState, taskSid, sessions, subscriptions, tariffs } = action;
  const currentChargingCardsState = state[taskSid];

  if (currentChargingCardsState && currentChargingCardsState.data) {
    const sortedChargingCards: IRFIDCardModel[] = currentChargingCardsState.data.slice();
    sortedChargingCards.sort(
      generateSortFunction(sortState, sessions, subscriptions, tariffs)
    );

    const updatedChargingCards = update(currentChargingCardsState, {
      data: {
        $set: sortedChargingCards,
      },
      loading: {
        $set: false,
      },
    });

    return {
      ...state,
      [action.taskSid]: updatedChargingCards,
    };
  }

  return {
    ...state,
  };
};
