import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      padding: 64,
      maxWidth: 690,
    },
    closeButton: {
      position: 'absolute',
      right: 6,
      top: 6,
      padding: 10,
    },
  })
);

export default useStyles;
