import axios from 'axios';
import ApiService from './api';

export const getRFIDCards = async () => {
  try {
    const response = await axios.get(
      'https://storage.googleapis.com/rfid-cards/rfid-cards-config.json'
    );

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const pairRFIDCardAndSubscription = async (
  subscriptionId: string,
  rfidCardID: string
) => {
  const apiService = new ApiService(
    `/mgmt/customer/v1/subscriptions/${subscriptionId}/pairrfidcard`,
    {
      method: 'POST',
      data: {
        rfid_card_number: rfidCardID,
      },
    }
  );

  const { data } = await apiService.query();
  return data;
};

export const rfidCardAction = async (
  action: 'activate' | 'deactivate' | 'archive',
  rfidCardId: string
) => {
  const apiService = new ApiService(
    `/mgmt/customer/v1/rfidcards/${rfidCardId}/${action}`,
    {
      method: 'POST',
    }
  );

  await apiService.query();
};
