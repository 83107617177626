import { ActionInterface } from '../actions';
import { State } from '..';
import update from 'immutability-helper';

export default (
  state: State,
  action: ActionInterface.GetOrganizationSuccess
): State => {
  const { data, stations, accounts } = action;
  const currentOrganization = state[action.taskSid];
  const updatedOrganization = update(currentOrganization, {
    data: {
      $set: data,
    },
    loading: {
      $set: false,
    },
    stations: {
      $set: stations,
    },
    accounts: {
      $set: accounts,
    },
  });

  return {
    ...state,
    [action.taskSid]: updatedOrganization,
  };
};
