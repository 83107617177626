import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  actions: { textAlign: 'center' },
  button: { margin: '0 32px' },
  outerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: -16,

    '&> *': {
      margin: 16,
    },
  },
});

export default useStyles;
