import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { ChargeRecord } from '../../../../../view-models/ChargeRecordModel';
import ChargingSessions from '../Table/Table';
import DoubleArrow from '../../../../../assets/image/doubleArrow.svg';
import { IActiveSession } from '../../../../../types/activeSessions';
import { IBreadcrumbHandlerProps } from '../../../../../utils/addBreadcrumbHandler';
import { IChargingRecordViewModel } from '../../../../../types/chargingRecords';
import { IRFIDCardModel } from '../../../../../types/subscriber';
import { Pagination } from '../../../../shared/Pagination/Pagination';
import Typography from '@material-ui/core/Typography';
import { paginateArray } from '../../../../../utils/paginateArray';
import useStyles from './useStyles';

interface IOwnProps {
  chargingRecords: IChargingRecordViewModel[];
  activeSessions: IActiveSession[];
  stationRefreshing: boolean;
  chargingCards: IRFIDCardModel[];
  breadcrumbHandler: (props: IBreadcrumbHandlerProps) => void;
}

export const RecentSessions: React.FC<IOwnProps> = ({
  chargingRecords,
  activeSessions,
  stationRefreshing,
  chargingCards,
  breadcrumbHandler,
}) => {
  const classes = useStyles();

  const [showMore, setShowMore] = useState<boolean>(false);
  const [combinedRecords, setCombinedRecords] = useState<
    IChargingRecordViewModel[]
  >([]);
  const [activeSessionRecords, setActiveSessionRecords] = useState<
    IChargingRecordViewModel[]
  >([]);

  const [itemsPerPage, setItemsPerPage] = useState<number>(4);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pagesLength, setPagesLength] = useState<number>(0);
  const [paginatedSessions, setPaginatedSessions] = useState<
    IChargingRecordViewModel[][]
  >([[]]);

  const paginateSessions = (sessions: IChargingRecordViewModel[]): void => {
    const ps = paginateArray(sessions, itemsPerPage);
    setPaginatedSessions(ps);
    setPagesLength(ps.length);
  };

  useEffect(() => {
    setCombinedRecords(paginatedSessions[currentPage - 1]);
  }, [paginatedSessions, currentPage]);

  useEffect(() => {
    const activeSessionsViewModel = activeSessions.map((session) => {
      session.type = 'active';
      return new ChargeRecord(session);
    });

    setActiveSessionRecords(activeSessionsViewModel);
  }, [activeSessions]);

  useEffect(() => {
    const mergedRecords = [...activeSessionRecords, ...chargingRecords];

    if (mergedRecords.length > 3) {
      setShowMore(true);
    }

    mergedRecords.sort((a, b) => {
      const first = new Date(a.creation_date);
      const second = new Date(b.creation_date);
      return first > second ? -1 : first < second ? 1 : 0;
    });

    setCurrentPage(1);
    paginateSessions(mergedRecords);
  }, [activeSessionRecords, chargingRecords]);

  return (
    <div
      className={`${showMore && classes.componentWrapper}`}
      data-testid='charging-station-recent-sessions-private'
    >
      <Typography
        data-testid='charging-sessions-table-header'
        classes={{
          h2: classes.chargingSessionsHeader,
        }}
        variant='h2'
      >
        Recent Charging Sessions at this Station
      </Typography>
      <div className={`${stationRefreshing ? classes.stationRefreshing : ''}`}>
        <ChargingSessions
          chargingCards={chargingCards}
          addBreadcrumbHandler={breadcrumbHandler}
          chargingSessions={combinedRecords}
          title='User did not charge at this station yet.'
        />
        <Pagination
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pagesLength={pagesLength}
          itemSelectText='Records per page'
          showSelect={false}
          small={true}
        />
      </div>
      {showMore && (
        <div className={classes.showMoreLink}>
          <Button
            data-testid='charging-session-button'
            color='primary'
            endIcon={<img src={DoubleArrow} alt='arrow' />}
            className={classes.showMoreButton}
            onClick={() =>
              breadcrumbHandler({
                component: 'ChargingSessionsCollection',
                friendlyText: 'Charging Sessions',
                large: true,
              })
            }
          >
            Show all sessions
          </Button>
        </div>
      )}
    </div>
  );
};

export default RecentSessions;
