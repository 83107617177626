import { Grid, Typography } from '@material-ui/core';
import React, { useRef, useState } from 'react';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Transition } from 'react-transition-group';

import useSharedStyles from '../../shared/useSharedStyles';
import useStyles from './useStyles';

type ITransitionStyles = 'entering' | 'entered' | 'exiting' | 'exited';

type TProps = {
  title: string;
  Icon: JSX.Element;
  startCollapse?: boolean;
};

export const ActionCardElement: React.FC<TProps> = ({
  title,
  children,
  Icon,
  startCollapse = false,
}) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const [expand, setExpand] = useState<boolean>(!startCollapse);
  const cardElementRef = useRef<HTMLDivElement>(null);

  const defaultStyle = {
    transition: `max-height .3s ease-in-out`,
    height: 'auto',
    maxHeight: '600px',
    overflow: 'hidden',
  };

  const transitionStyles = {
    entering: {
      maxHeight: '600px',
    },
    entered: {
      maxHeight: '600px',
    },
    exiting: { maxHeight: '0' },
    exited: { maxHeight: '0' },
  };

  const IconElement = <span className={classes.iconElement}>{Icon}</span>;

  return (
    <div ref={cardElementRef} className={sharedClasses.cardElement}>
      <Grid item xs={12}>
        <Grid container alignItems='center' justify='space-between'>
          <div className={classes.titleWithExpand}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {IconElement}
              <Typography
                className={sharedClasses.cardTitle}
                variant='h4'
                data-testid='page-heading-subscriptions'
              >
                {title}
              </Typography>
            </div>
            <ExpandLessIcon
              data-testid='expand-icon'
              color='primary'
              className={`
                ${classes.expandIcon} 
                ${expand ? classes.expandIconOpen : classes.expandIconClosed}`}
              onClick={() => setExpand(!expand)}
            />
          </div>
        </Grid>
      </Grid>
      <Transition in={expand} timeout={500}>
        {(state: ITransitionStyles) => (
          <Grid
            data-testid='child-wrapper'
            item
            xs={12}
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            <div className={classes.contentWrapper}>{children}</div>
          </Grid>
        )}
      </Transition>
    </div>
  );
};

export default ActionCardElement;
