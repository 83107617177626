import React from 'react';

import { formatIsoDates } from '../../../../utils/dates';
import { parseAmount } from '../../../CollectionCards/Invoices/InvoiceTable';
import useStyles from './useStyles';
import { IInvoice } from '../../../../types/invoice';
import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';
import { isCCSupervisor } from '../../../../utils/isRole';
import { IBreadcrumbHandlerProps } from '../../../../utils/addBreadcrumbHandler';
import InformationItems from '../../../shared/InformationItems';
import { IInformationItem } from '../../../shared/InformationItems/InformationItem';

export interface IOwnProps {
  currentInvoice: IInvoice;
  addBreadcrumbHandler: (props: IBreadcrumbHandlerProps) => void;
  hideRefunds?: boolean;
}

/**
 * Generates information for invoice to be displayed
 * @param currentInvoice handled invoice
 * @param addBreadcrumbHandler callback for click handler
 * @param hideRefunds should refund link be displayed?
 */
const Details: React.FC<IOwnProps> = ({
  currentInvoice,
  addBreadcrumbHandler,
  hideRefunds,
}) => {
  const classes = useStyles();
  const { getScopedTranslation } = useTypedTranslation('refunds');

  const getShowRefundsHeadline = () => ({
    id: 'show-credit-notes',
    headline: getScopedTranslation('show_headline'),
    content: (
      <span
        className={classes.itemLink}
        onClick={() =>
          addBreadcrumbHandler({
            component: 'CreditNotesCollection',
            friendlyText: getScopedTranslation('show_headline'),
            large: true,
          })
        }
      >
        {getScopedTranslation('show_link')}
      </span>
    ),
  });

  const getAddRefundHeadline = () => ({
    id: 'add-refund',
    headline: getScopedTranslation('add_headline'),
    content: (
      <span
        className={classes.itemLink}
        data-testid='data-invoice-add-refund'
        onClick={() =>
          addBreadcrumbHandler({
            component: 'AddRefund',
            friendlyText: getScopedTranslation('add_link'),
            withSidebar: true,
          })
        }
      >
        {getScopedTranslation('add_link')}
      </span>
    ),
  });

  const getInformationItems = () => {
    const detailItems: IInformationItem[] = [
      {
        id: 'creation-date',
        headline: getScopedTranslation('overview.fields.creation_date'),
        content: formatIsoDates(currentInvoice.created_at),
      },
      {
        id: 'total',
        headline: 'Total',
        content:
          typeof currentInvoice.amount === 'number'
            ? parseAmount(currentInvoice.amount, currentInvoice.currency)
            : '-',
      },
    ];

    if (!hideRefunds) {
      detailItems.push(getShowRefundsHeadline());
    }
    if (!hideRefunds && isCCSupervisor()) {
      detailItems.push(getAddRefundHeadline());
    }

    return detailItems;
  };

  return <InformationItems items={getInformationItems()} title='Details' />;
};

export default Details;
