import { useTranslation } from 'react-i18next';
import { get } from 'lodash';

import { terms } from '../utils/translation/i18n_terms';

export const useTypedTranslation = (scope?: string) => {
  const orig = useTranslation();

  const getScopedTranslation = (path: string) =>
    orig.t(get(terms, `${scope}.${path}`));

  return { ...orig, terms, getScopedTranslation };
};
