export const terms = {
  action_cards: {
    general: {
      show_all: 'action-cards.general.show-all',
      show_less: 'action-cards.general.show-less',
      show_more: 'action-cards.general.show-more',
    },
    invoices: {
      headline: 'action-cards.invoices.headline',
      invoice_id: 'action-cards.invoices.invoice-id',
      creation_date: 'action-cards.invoices.creation-date',
      total_amount: 'action-cards.invoices.total-amount',
      details: 'action-cards.invoices.details',
    },
    rfid_cards: {
      headline: 'action-cards.rfid-cards.headline',
      order: {
        title: 'action-cards.rfid-cards.order.title',
      },
    },
    sessions: {
      headline: 'action-cards.sessions.headline',
    },
  },
  card_details: {
    actions: {
      activate: {
        bullets: 'card-details.actions.activate.bullets',
        bullets_subscription:
          'card-details.actions.activate.bullets-subscription',
        menu: 'card-details.actions.activate.menu',
        title: 'card-details.actions.activate.title',
      },
      archive: {
        bullets: 'card-details.actions.archive.bullets',
        menu: 'card-details.actions.archive.menu',
        title: 'card-details.actions.archive.title',
      },
      deactivate: {
        bullets: 'card-details.actions.deactivate.bullets',
        menu: 'card-details.actions.deactivate.menu',
        title: 'card-details.actions.deactivate.title',
      },
    },
  },
  customer_verification: {
    user_search: {
      continue_button: 'customer-verification.user-search.continue-button',
      loading: 'customer-verification.user-search.loading',
      match_label_customer_number:
        'customer-verification.user-search.match-label-customer-number',
      match_label_rfid_card_number:
        'customer-verification.user-search.match-label-rfid-card-number',
      no_users: 'customer-verification.user-search.no-users',
      search_label: 'customer-verification.user-search.search-label',
      subtitle: 'customer-verification.user-search.subtitle',
      title: 'customer-verification.user-search.title',
    },
    verify_questions: {
      back_button: 'customer-verification.verify-questions.back-button',
      subtitle: 'customer-verification.verify-questions.subtitle',
      title: 'customer-verification.verify-questions.title',
      verify_button: 'customer-verification.verify-questions.verify-button',
    },
  },
  general: {
    are_you_sure: 'general.are-you-sure',
    cancel: 'general.cancel',
    close: 'general.close',
    confirm: 'general.confirm',
  },
  order_card_dialog: {
    additional_information: 'order-card-dialog.additional-information',
    fields: {
      address_line_1: 'order-card-dialog.fields.address-line-1',
      address_line_2: 'order-card-dialog.fields.address-line-2',
      city: 'order-card-dialog.fields.city',
      country: 'order-card-dialog.fields.country',
      name: 'order-card-dialog.fields.name',
      other_reason: 'order-card-dialog.fields.other-reason',
      reason: 'order-card-dialog.fields.reason',
      reason_options: {
        defective: 'order-card-dialog.fields.reason-options.defective',
        lost: 'order-card-dialog.fields.reason-options.lost',
        not_arrived: 'order-card-dialog.fields.reason-options.not-arrived',
        other: 'order-card-dialog.fields.reason-options.other',
      },
      state: 'order-card-dialog.fields.state',
      zip: 'order-card-dialog.fields.zip',
    },
    headline: 'order-card-dialog.headline',
    no_subscriber: {
      headline: 'order-card-dialog.no-subscriber.headline',
      sub_headline: 'order-card-dialog.no-subscriber.sub-headline',
    },
    request_failed: 'order-card-dialog.request-failed',
    shipping_address: 'order-card-dialog.shipping-address',
    sub_headline: 'order-card-dialog.sub-headline',
    submit: 'order-card-dialog.submit',
    success: {
      headline: 'order-card-dialog.success.headline',
    },
  },
  overview: {
    select_customer: 'overview.select-customer',
    verified: 'overview.verified',
  },
  pair_rfid_card_and_subscription: {
    headline: 'pair-rfid-card-and-subscription.headline',
    rfid_card_input: 'pair-rfid-card-and-subscription.rfid-card-input',
    sub_headline: 'pair-rfid-card-and-subscription.sub-headline',
    submit: {
      main_text: 'pair-rfid-card-and-subscription.submit.main-text',
      small_text: 'pair-rfid-card-and-subscription.submit.small-text',
    },
    warnings: {
      no_active_subscription:
        'pair-rfid-card-and-subscription.warnings.no-active-subscription',
    },
  },
  refunds: {
    add_headline: 'refunds.add-headline',
    add_link: 'refunds.add-link',
    credit_note: {
      headline: 'refunds.credit-note.headline',
    },
    form: {
      add_item: 'refunds.form.add-item',
      error: 'refunds.form.error',
      fields: {
        amount: 'refunds.form.fields.amount',
        country_code: 'refunds.form.fields.country-code',
        external_cdr_id: 'refunds.form.fields.external-cdr-id',
        incurred_at: 'refunds.form.fields.incurred-at',
        reimbursement_type: 'refunds.form.fields.reimbursement-type',
        type: 'refunds.form.fields.type',
      },
      headline: 'refunds.form.headline',
      line_item_headline: 'refunds.form.line-item-headline',
      line_items_headline: 'refunds.form.line-items-headline',
      short_version: {
        external_cdr_id: 'refunds.form.short-version.external-cdr-id',
        incurred_at: 'refunds.form.short-version.incurred-at',
      },
      submit: 'refunds.form.submit',
      success: 'refunds.form.success',
      total_amount: 'refunds.form.total-amount',
    },
    overview: {
      fields: {
        amount: 'refunds.overview.fields.amount',
        creation_date: 'refunds.overview.fields.creation-date',
        credit_note_number: 'refunds.overview.fields.credit-note-number',
        current_state: 'refunds.overview.fields.current-state',
      },
      headline: 'refunds.overview.headline',
    },
    show_headline: 'refunds.show-headline',
    show_link: 'refunds.show-link',
  },
  subscription_information: {
    address: 'subscription-information.address',
    box_title: 'subscription-information.box-title',
    preliminary_end: 'subscription-information.preliminary-end',
    signup_date: 'subscription-information.signup-date',
    status: 'subscription-information.status',
    tariff_name: 'subscription-information.tariff-name',
    terminate_subscription: 'subscription-information.terminate-subscription',
    terminate_upcoming_subscription:
      'subscription-information.terminate-upcoming-subscription',
    terminate_upcoming_subscription_success: {
      headline:
        'subscription-information.terminate-upcoming-subscription-success.headline',
      sub_headline:
        'subscription-information.terminate-upcoming-subscription-success.sub-headline',
    },
    terminate_upcoming_subscription_error: {
      headline:
        'subscription-information.terminate-upcoming-subscription-error.headline',
      sub_headline:
        'subscription-information.terminate-upcoming-subscription-error.sub-headline',
    },
  },
  subscription_termination: {
    confirmation: 'subscription-termination.confirmation',
    vin_checkbox: 'subscription-termination.vin-checkbox',
    vin_tenant_warning: 'subscription-termination.vin-tenant-warning',
    terminate_subscription: 'subscription-termination.terminate-subscription',
    create_mkit_ticket_prompt:
      'subscription-termination.create-mkit-ticket-prompt',
    result_dialog_step_1_title:
      'subscription-termination.result-dialog-step-1-title',
    result_dialog_step_2_title:
      'subscription-termination.result-dialog-step-2-title',
    result_dialog_title: 'subscription-termination.result-dialog-title',
    result_dialog_title_details:
      'subscription-termination.result-dialog-title-details',
    result_dialog_subtitle: 'subscription-termination.result-dialog-subtitle',
    result_dialog_headers_personal_data:
      'subscription-termination.result-dialog-headers-personal-data',
    result_dialog_headers_bcid:
      'subscription-termination.result-dialog-headers-bcid',
    result_dialog_headers_contact:
      'subscription-termination.result-dialog-headers-contact',
    result_dialog_headers_reported_by:
      'subscription-termination.result-dialog-headers-reported-by',
    result_dialog_content_reported_by:
      'subscription-termination.result-dialog-content-reported-by',
    result_dialog_headers_call_code:
      'subscription-termination.result-dialog-headers-call-code',
    result_dialog_headers_categorization:
      'subscription-termination.result-dialog-headers-categorization',
    result_dialog_headers_reported_ci:
      'subscription-termination.result-dialog-headers-reported-ci',
    result_dialog_headers_affected_ci:
      'subscription-termination.result-dialog-headers-affected-ci',
    result_dialog_headers_assignment:
      'subscription-termination.result-dialog-headers-assignment',
    result_dialog_headers_assignment_group:
      'subscription-termination.result-dialog-headers-assignment-group',
    result_dialog_heading_almost_there:
      'subscription-termination.result-dialog-heading-almost-there',
    result_dialog_subheading:
      'subscription-termination.result-dialog-subheading',
    result_dialog_warning_note:
      'subscription-termination.result-dialog-warning-note',
    result_dialog_button_next:
      'subscription-termination.result-dialog-button-next',
    result_dialog_button_back:
      'subscription-termination.result-dialog-button-back',
    result_dialog_button_done:
      'subscription-termination.result-dialog-button-done',
    error_dialog_heading: 'subscription-termination.error-dialog-heading',
    subscription_status_info_terminated:
      'subscription-termination.subscription-status-info-terminated',
    subscription_status_info_details:
      'subscription-termination.subscription-status-info-details',
  },
  public_charging: {
    box_title: 'public-charging.box-title',
    tariff_name: 'public-charging.tariff-name',
    status: 'public-charging.status',
    signup_date: 'public-charging.signup-date',
    preliminary_end: 'public-charging.preliminary-end',
    billing_address: 'public-charging.billing-address',
    linked_charging_card: 'public-charging.linked-charging-card',
    upcoming_tariff: 'public-charging.upcoming-tariff',
  },

  update_billing_address: {
    failure: 'update-billing-address.failure',
    fields: {
      address_line_1: 'update-billing-address.fields.address-line-1',
      address_line_2: 'update-billing-address.fields.address-line-2',
      city: 'update-billing-address.fields.city',
      country_code: 'update-billing-address.fields.country-code',
      name: 'update-billing-address.fields.name',
      state: 'update-billing-address.fields.state',
      zip: 'update-billing-address.fields.zip',
    },
    headline: 'update-billing-address.headline',

    request_failed: 'update-billing-address.request-failed',
    sub_headline: 'update-billing-address.sub-headline',
    submit: 'update-billing-address.submit',
    success: {
      headline: 'update-billing-address.success.headline',
      sub_headline: 'update-billing-address.success.sub-headline',
    },
  },
  user_profile: {
    box_title: 'user-profile.box-title',
    customer_account_created: 'user-profile.customer-account-created',
    customer_last_login: 'user-profile.customer-last-login',
    ecosystem: 'user-profile.ecosystem',
    email: 'user-profile.email',
    name: 'user-profile.name',
    signup_country: 'user-profile.signup-country',
  },
};

export type Terms = typeof terms;
