import update from 'immutability-helper';
import { State } from '../';
import { ActionInterface } from '../actions';
import { SubscriptionState } from '../';

export default (
  state: State,
  action: ActionInterface.ClearSubscription
): State => {
  const updatedState: SubscriptionState = update(state, {
    $unset: [action.taskSid],
  });

  return {
    ...updatedState,
  };
};
