import React from 'react';

import useStyles from './useStyles';

export interface IOwnProps {
  file: Blob;
}

export const PdfViewer: React.FC<IOwnProps> = ({ file }) => {
  const classes = useStyles();

  return (
    <iframe
      className={classes.pdfWrapper}
      src={URL.createObjectURL(file)}
      data-testid='pdf-viewer'
    />
  );
};

export default React.memo(PdfViewer);
