import React from 'react';

import useStyles from './useStyles';
import InformationItem, { IInformationItem } from './InformationItem';

interface IOwnProps {
  items: IInformationItem[];
  title?: string;
}

const InformationItems: React.FC<IOwnProps> = ({ items, title }) => {
  const classes = useStyles();
  return (
    <>
      {title && <h2 className={classes.headline}>{title}</h2>}
      <div className={classes.outerItemWrapper} role='list'>
        {items.map((item) => (
          <InformationItem item={item} key={item.id} />
        ))}
      </div>
    </>
  );
};

export default InformationItems;
