import { ICardOrderModel } from '../../../../types/subscriber';

import { ActionInterface } from '../actions';
import { ISortState } from '../../../../components/shared/AssetTable/AssetTable/AssetTable';
import { State } from '..';
import { lexicographical } from '../../../../utils/comparisonFunctions';
import { parseAddress } from '../../../../utils/parseAddress';
import update from 'immutability-helper';

interface ISortFunctionProps {
  a: ICardOrderModel;
  b: ICardOrderModel;
}
interface ISortFunctions {
  [string: string]: (props: ISortFunctionProps) => number;
}

const COMPARABLE = '-';

export const sortFunctions: ISortFunctions = {
  order_item: () => {
    return lexicographical('RFID Card', 'RFID Card');
  },
  item_id: ({ a, b }) => {
    return lexicographical(a.rfid_card.number, b.rfid_card.number);
  },
  status: ({ a, b }) => {
    return lexicographical(a.status, b.status);
  },
  order_date: ({ a, b }) => {
    const orderDateA = new Date(a.created_at);
    const orderDateB = new Date(b.created_at);
    return lexicographical(orderDateA, orderDateB);
  },
  shipping_address: ({ a, b }) => {
    const addressA = parseAddress(a.shipping_address);
    const addressB = parseAddress(b.shipping_address);

    return lexicographical(addressA, addressB);
  },
  orderer: ({ a, b }) => {
    return lexicographical(
      a.orderer?.last_name?.toLowerCase() || COMPARABLE,
      b.orderer?.last_name?.toLowerCase() || COMPARABLE
    );
  },
  reason: ({ a, b }) => {
    return lexicographical(
      a.reason.toLowerCase() || COMPARABLE,
      b.reason.toLowerCase() || COMPARABLE
    );
  },
};

export const generateSortFunction = (sortState: ISortState) => (
  a: ICardOrderModel,
  b: ICardOrderModel
) => {
  const sortKey = sortState.sortByColumnKey as AdminOrderSortKey;
  if (sortKey) {
    const sortFunction = sortFunctions[sortKey];
    const ascendingSortResult = sortFunction({ a, b });

    return sortState.sortDesc ? ascendingSortResult * -1 : ascendingSortResult;
  } else {
    return 0;
  }
};

type AdminOrderSortKey = keyof typeof sortFunctions;

export default (
  state: State,
  action: ActionInterface.SortCardOrders
): State => {
  const { sortState, taskSid } = action;
  const currentCardOrders = state[taskSid];

  if (currentCardOrders && currentCardOrders.data) {
    const newCardOrders: ICardOrderModel[] = currentCardOrders.data.slice();
    newCardOrders.sort(generateSortFunction(sortState));

    const updatedCardOrders = update(currentCardOrders, {
      data: {
        $set: newCardOrders,
      },
      loading: {
        $set: false,
      },
    });

    return {
      ...state,
      [action.taskSid]: updatedCardOrders,
    };
  }

  return {
    ...state,
  };
};
