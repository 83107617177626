import { groupBy } from 'lodash';

import { IRfidCardOrderFields } from '../components/shared/Dialogs/OrderRfidCard/fields';
import ApiService from './api';
import { ICardOrderModel, ICardOrderRequestModel } from '../types/subscriber';
import { UsersService } from './users';

interface IShippingAddressRequest {
  city: string;
  country_code: string;
  zip: string;
  name?: string;
  address_line_1?: string;
  address_line_2?: string;
  state?: string;
}

interface IOrderRfidCardRequestBody {
  shipping_address: IShippingAddressRequest;
  reason: string;
  subscription_id?: string;
}

const orderRfidCard = async (
  subscriberId: string,
  formData: IRfidCardOrderFields,
  subscriptionId?: string
): Promise<string> => {
  const requestBody: IOrderRfidCardRequestBody = {
    shipping_address: {
      city: formData.city,
      country_code: formData.country,
      zip: formData.zip,
      name: formData.name,
      state: formData.state,
      address_line_1: formData.address_line_1,
      address_line_2: formData.address_line_2,
    },
    subscription_id: subscriptionId,
    reason: formData.other_reason || formData.reason,
  };

  const apiService = new ApiService(
    `/mgmt/customer/v1/subscribers/${subscriberId}/orderrfidcard`,
    {
      method: 'POST',
      data: requestBody,
    }
  );

  const { data } = await apiService.query();
  return data;
};

const enrichOrdersWithOrderers = async (
  rfidCardOrders: ICardOrderRequestModel[]
): Promise<ICardOrderModel[]> => {
  const rfidCardOrdersWithCreator = { ...rfidCardOrders };
  const groupedOrdersByCreator = groupBy(
    rfidCardOrdersWithCreator,
    'ordered_by'
  );
  const orderOrderersIds = Object.keys(groupedOrdersByCreator).filter(
    (key) => key !== 'undefined'
  );

  await Promise.all(
    orderOrderersIds.map(async (userId) => {
      try {
        const user = await UsersService.getById({ userId });
        groupedOrdersByCreator[userId] = groupedOrdersByCreator[
          userId
        ].map((order) => ({ ...order, orderer: user }));
      } catch (e) {
        // do nothing
      }
    })
  );

  return Object.values(groupedOrdersByCreator).flat();
};

export const RfidCardOrdersService = {
  enrichOrdersWithOrderers,
  orderRfidCard,
};
