import { IOtherState, State } from '..';

import { ActionInterface } from '../actions';
import update from 'immutability-helper';

export default (
  state: State,
  _action: ActionInterface.GetRFIDCardsRequest
): State => {
  const updatedState: IOtherState = update(state, {
    rfidCards: {
      error: {
        $set: null,
      },
      loading: {
        $set: true,
      },
    },
  });

  return {
    ...updatedState,
  };
};
