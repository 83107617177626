import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Icon, Typography } from '@material-ui/core';

import useSharedStyles from '../../../shared/useSharedStyles';
import { StatusIndicator } from '../../../CardsUI/StatusIndicator/StatusIndicator';
import { RfidCardOrderStatus } from '../../../../types/subscriber';
import useStyles from './useStyles';

export interface IOwnProps {
  status?: string;
  onInfoClick?: () => void;
  display?: boolean;
}

export const TOOLTIP_NAME = 'subscription-info';

const Status: React.FC<IOwnProps> = ({ status, onInfoClick, display }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const sharedClasses = useSharedStyles();
  return (
    <div className={sharedClasses.cardDetail}>
      <Typography data-testid='subscription-status-detail-title' variant='h6'>
        Status
      </Typography>
      <div className={sharedClasses.cardDetailData}>
        {status ? (
          <div className={classes.statusWrapper}>
            <StatusIndicator
              status={status as RfidCardOrderStatus}
              className={classes.activationStatus}
            />
            <Typography
              className={classes.statusText}
              data-testid='subscription-status'
              variant='body2'
            >
              {status}
            </Typography>
            {display ? (
              <Icon
                data-testid='subscription-status-info'
                className={classes.subscriptionStatusInfo}
                onClick={onInfoClick}
              >
                info
              </Icon>
            ) : null}
          </div>
        ) : (
          '-'
        )}
      </div>
    </div>
  );
};

export default Status;
