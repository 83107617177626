import { ActionCreator, ActionInterface, ActionType } from './actions';
import {
  ActionCreator as ActiveSessionsActionCreator,
  ActionInterface as ActiveSessionsActionInterface,
} from '../activeSessions/actions';
import {
  ActionCreator as HomeStationsActionCreator,
  ActionInterface as HomeStationsActionInterface,
} from '../homeStations/actions';
import {
  ActionCreator as OrganizationActionCreator,
  ActionInterface as OrganizationActionInterface,
} from '../organization/actions';
import {
  ActionCreator as SubscriberActionCreator,
  ActionInterface as SubscriberActionInterface,
} from '../subscriber/actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import { IUserModel } from '../../../types/user';
import { UsersService } from '../../../services/users';

export function* userSaga() {
  function* getUser(action: ActionInterface.GetUserRequest) {
    try {
      const user: IUserModel = yield call(UsersService.getById, {
        userId: action.userId,
      });

      yield put<ActionInterface.GetUserSuccess>(
        ActionCreator.GetUserSuccess({
          data: user,
          taskSid: action.taskSid,
        })
      );

      yield put<OrganizationActionInterface.GetOrganizationRequest>(
        OrganizationActionCreator.GetOrganizationRequest({
          organizationId: user.organisation_id || '',
          taskSid: action.taskSid,
        })
      );

      yield put<HomeStationsActionInterface.GetHomeStationsRequest>(
        HomeStationsActionCreator.GetHomeStationsRequest({
          user_id: user.id,
          taskSid: action.taskSid,
        })
      );

      yield put<ActiveSessionsActionInterface.GetActiveSessionsRequest>(
        ActiveSessionsActionCreator.GetActiveSessionsRequest({
          userId: user.id,
          taskSid: action.taskSid,
        })
      );

      yield put<SubscriberActionInterface.GetSubscriberRequest>(
        SubscriberActionCreator.GetSubscriberRequest({
          userId: action.userId,
          organizationId: user.organisation_id || '',
          taskSid: action.taskSid,
        })
      );
    } catch (error) {
      yield put<ActionInterface.GetUserError>(
        ActionCreator.GetUserError({
          error,
          taskSid: action.taskSid,
        })
      );
    }
  }

  yield all([takeEvery(ActionType.GetUserRequest, getUser)]);
}

export default userSaga;
