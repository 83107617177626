import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    titleElement: {
      marginBottom: '16px',
      fontSize: '20px',
      fontWeight: 900,
    },
    userVerification: {
      position: 'absolute',
      right: '16px',
      top: '16px',
    },
    contentWrapper: {
      position: 'relative',
    },
    detailWrapper: {
      padding: '0 16px 16px',
    },
    tooltipWrapper: {
      position: 'absolute',
      right: 0,
      top: 0,
      transform: 'translateX(100%)',
    },
    ecosystemElement: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: '10px',
    },
    ecosystemView: {
      marginLeft: '8px',
    },
    emailTrim: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    headingWithIcon: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: 1, // !important,
    },
    infoIcon: {
      marginLeft: '2px !important',
      cursor: 'pointer',
      fontSize: '12px !important',
    },
  })
);

export default useStyles;
