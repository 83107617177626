import React, { useEffect, useState } from 'react';
import Divider from '@material-ui/core/Divider';
import { useTheme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import CreditCardIcon from '@material-ui/icons/CreditCard';

import {
  addBreadcrumbHandler,
  IBreadcrumbHandlerProps,
} from '../../../../utils/addBreadcrumbHandler';
import {
  IRFIDCardModel,
  ISubscriptionModel,
} from '../../../../types/subscriber';
import connector, { IPropsFromState } from '../../../Connector/Connector';
import { DialogChargingCard } from '../../../ChargingCards/DialogChargingCard';
import { IChargingCardDialog } from '../../../OverviewCards/PublicCharging';
import { ITariffConditionModel, ITariffModel } from '../../../../types/tariff';
import { TariffService } from '../../../../services/tariffs';
import { formatIsoDates } from '../../../../utils/dates';
import { parseAddress } from '../../../../utils/parseAddress';
import useSharedStyles from '../../../shared/useSharedStyles';
import { useCurrentTask } from '../../../../custom-hooks/useCurrentTask';
import Status from '../Status/Status';
import SignupDate from '../SignupDate/SignupDate';
import Tooltip from '../../../ui/Tooltip';
import Tariff from '../Tariff/Tariff';
import { IActionItem } from '../../../shared/ActionDropdownContainer';
import PairRfidAndSubscription from '../../../shared/Dialogs/PairRfidAndSubscription';
import { TerminateSubscriptionWarningDialog } from '../TerminateSubscriptionWarningDialog/TerminateSubscriptionWarningDialog';
import { TerminateSubscriptionSummaryDialog } from '../TerminateSubscriptionSummaryDialog/TerminateSubscriptionSummaryDialog';
import { dealershipName } from '../../../OverviewCards/UserProfile';
import {
  ITerminateSubscriptionResponse,
  TerminateSubscriptionService,
} from '../../../../services/subscriptions';
import { SubscriptionDetailsErrorDialog } from '../SubscriptionDetailsErrorDialog/SubscriptionDetailsErrorDialog';
import { getSubscriptionStatus } from '../../../CardsUI/StatusIndicator/StatusIndicator';
import {
  getSubscriptionTerminationResult,
  storeSubscriptionTerminationResult,
} from '../subscriptionTerminationResultsStorage';
import { StatusInformationDialog } from '../StatusInformationDialog/StatusInformationDialog';
import {
  TerminateSubscriptionButton,
  TerminateSubscriptionType,
} from '../TerminateSubscriptionButton/TerminateSubscriptionButton';
import useStyles from './useStyles';
import { isCCSupervisor } from '../../../../utils/isRole';
import { TerminateUpcomingSubscriptionDialog } from '../TerminateUpcomingSubscriptionDialog';
import { SubscriptionDetailsHeader } from '../SubscriptionDetailsHeader/SubscriptionDetailsHeader';
import { BasicInformationItem } from '../../../shared/InformationItems/BasicInformationItem';
import { TextLink } from '../../../shared/InformationItems/TextLink';
import { CardSubheadline } from '../../../shared/InformationItems/CardSubheadline';

const checkIfSingle = (str: number): string => (str === 1 ? '' : 's');

const hasUpcomingSubscription = (
  subscriptions: ISubscriptionModel[] | null
) => {
  if (subscriptions === null) {
    return false;
  }
  return subscriptions.some((sub) => sub.status === 'upcoming');
};

type TOwnProps = IPropsFromState & {
  resourceId?: string;
  crumbId?: string;
};

export const SubscriptionDetails: React.FC<TOwnProps> = ({
  subscriberState,
  subscriptionState,
  resourceId,
  chargingCardState,
  addBreadcrumb,
  breadcrumbState,
  crumbId,
  tariffsState,
  getSubscriptions,
  terminateSubscription,
  removeBreadcrumb,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const sharedClasses = useSharedStyles();
  const taskSid = useCurrentTask();
  const taskBreadcrumbState = breadcrumbState && breadcrumbState[taskSid];
  const chargingCards = chargingCardState && chargingCardState[taskSid];
  const subscriber = subscriberState && subscriberState[taskSid];

  const [
    tariffBaseConditions,
    setTariffBaseConditions,
  ] = useState<ITariffConditionModel | null>(null);

  const [tariff, setTariff] = useState<ITariffModel | null>(null);
  const [actionItems, setActionItems] = useState<IActionItem[] | null>(null);

  const [subscription, setSubscription] = useState<ISubscriptionModel | null>(
    null
  );

  const [
    subscriptionChargingCard,
    setSubscriptionChargingCard,
  ] = useState<IRFIDCardModel | null>(null);

  const [dialogData, setDialogData] = useState<IChargingCardDialog | null>(
    null
  );

  const [
    terminateUpcomingSubDialogIsOpen,
    setTerminateUpcomingSubDialogIsOpen,
  ] = useState(false);
  const [
    terminateSubscriptionWarningIsOpen,
    setTerminateSubscriptionWarningIsOpen,
  ] = useState<boolean>(false);
  const [
    terminateSubscriptionSummaryIsOpen,
    setTerminateSubscriptionSummaryIsOpen,
  ] = useState<boolean>(false);
  const [statusInfoIsOpen, setStatusInfoIsOpen] = useState<boolean>(false);

  const [error, setError] = useState(false);
  const [
    blockTerminationWithUpcomingSubscription,
    setBlockTerminationWithUpcomingSubscription,
  ] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const [
    terminationResult,
    setTerminationResult,
  ] = useState<ITerminateSubscriptionResponse | null>(null);

  const getTenant = () => {
    const subscriberData = subscriber.data;
    return subscriberData !== null
      ? dealershipName[subscriberData.tenant_id]
      : null;
  };
  const tenant = getTenant();

  const isWeChargeTenant = (tn: string | null): boolean => {
    const weChargeTenantNames = ['WeCharge', 'Volkswagen'];
    return tn !== null && weChargeTenantNames.includes(tn);
  };

  const [clearVin, setClearVin] = useState(!isWeChargeTenant(tenant));

  const updateActionItems = () => {
    setActionItems([
      {
        icon: <CreditCardIcon className={classes.actionItemIcon} />,
        friendlyText: 'Pair with RFID Card',
        component: <PairRfidAndSubscription />,
      },
    ]);
  };

  useEffect(() => {
    if (subscription && chargingCards && chargingCards.data) {
      const currentCard = chargingCards.data.find(
        (cc) => cc.subscription_id === subscription.id
      );
      setSubscriptionChargingCard(currentCard || null);

      if (currentCard) {
        setDialogData({
          rfidUid: currentCard.id,
          number: currentCard.number,
          tag: currentCard.tag,
        });
      }
    }
  }, [subscription, chargingCards]);

  const updateTariff = (tariffId: string) => {
    const tariffs = tariffsState[taskSid].data || [];
    const updatedTariff = tariffs.find(({ id }) => id === tariffId) || null;
    setTariff(updatedTariff);
  };

  useEffect(() => {
    if (subscription) {
      const getTariffBaseConditions = async () => {
        try {
          const trf = await TariffService.getByConditionId(
            subscription.tariff_id,
            subscription.base_condition_id
          );
          setTariffBaseConditions(trf);
        } catch (err) {
          setTariffBaseConditions(null);
        }
      };

      getTariffBaseConditions();
      updateTariff(subscription.tariff_id);
      updateActionItems();
    }
  }, [subscription]);

  useEffect(() => {
    if (subscriptionState && resourceId) {
      if (subscriptionState[taskSid].data) {
        const currentSubscription = (subscriptionState[taskSid]
          .data as ISubscriptionModel[]).find((cc) => cc.id === resourceId);

        if (currentSubscription) {
          setSubscription(currentSubscription);
        }
      }
    }
  }, [resourceId, subscriptionState]);

  useEffect(() => {
    const hasUpcomingSub = hasUpcomingSubscription(
      subscriptionState[taskSid].data
    );
    if (subscription && subscription.status === 'active' && hasUpcomingSub) {
      setBlockTerminationWithUpcomingSubscription(true);
    }
  });

  const breadcrumbHandler = (props: IBreadcrumbHandlerProps): void =>
    addBreadcrumbHandler({
      ...props,
      addBreadcrumb,
      taskSid,
      crumbId,
      taskBreadcrumbState,
    });

  if (!subscription || tenant === null) {
    return null;
  }

  const linkToChargingCardDetails = () => {
    breadcrumbHandler({
      component: 'ChargingCardDetails',
      friendlyText: 'Charging Card Details',
      id: subscriptionChargingCard?.id,
    });
  };

  const storedSubscriptionTerminationInfo = getSubscriptionTerminationResult(
    subscription.id
  );

  const doTerminateSubscription = async (
    sub: ISubscriptionModel,
    cv: boolean
  ) => {
    try {
      const terminateSubscriptionResult = await TerminateSubscriptionService.terminateSubscription(
        sub?.id,
        cv
      );
      setTerminationResult(terminateSubscriptionResult);
      storeSubscriptionTerminationResult(terminateSubscriptionResult);

      setTerminateSubscriptionWarningIsOpen(false);
      setTerminateSubscriptionSummaryIsOpen(true);
    } catch (e) {
      if (e instanceof Error) {
        setError(true);
        setErrorMsg(e.message);
      }
    }
  };

  const handleCloseUpcomingSubscriptionDialog = () => {
    setTerminateUpcomingSubDialogIsOpen(false);
    removeBreadcrumb({ taskSid, position: 0 });
  };

  const onTerminateUpcomingSubscription = () => {
    if (!subscriber.data) {
      return;
    }
    terminateSubscription({
      taskSid: taskSid,
      subscriptionId: subscription.id,
      subscriberId: subscriber.data.id,
    });
    setTerminateUpcomingSubDialogIsOpen(true);
  };

  const reloadSubscriptions = () => {
    const subscriberId = subscriber.data?.id;
    if (!subscriberId) {
      return;
    }
    getSubscriptions({
      subscriberId: subscriberId,
      taskSid,
    });
  };

  const renderTerminationButton = () => {
    if (isCCSupervisor()) {
      return {
        active: (
          <>
            <Divider />
            <TerminateSubscriptionButton
              type='active'
              onClick={() => {
                setTerminateSubscriptionWarningIsOpen(true);
              }}
            />
          </>
        ),
        upcoming: (
          <>
            <Divider />
            <TerminateSubscriptionButton
              type='upcoming'
              onClick={() => {
                onTerminateUpcomingSubscription();
              }}
            />
          </>
        ),
      }[subscription.status as TerminateSubscriptionType];
    }

    return null;
  };
  const getTariffInformationItems = () => [
    {
      title: 'Description',
      content: tariffBaseConditions?.description || '-',
    },
    {
      title: 'Monthly Fee',
      content:
        tariffBaseConditions &&
        typeof tariffBaseConditions.subscription_monthly_fee !== undefined
          ? `${tariffBaseConditions.subscription_monthly_fee} ${tariffBaseConditions.currency}`
          : '-',
    },
    {
      title: 'Duration',
      content:
        tariffBaseConditions &&
        typeof tariffBaseConditions.duration_months !== 'undefined'
          ? `${tariffBaseConditions.duration_months} month${checkIfSingle(
              tariffBaseConditions.duration_months
            )}`
          : '-',
    },
    {
      title: 'Notice Period',
      content:
        tariffBaseConditions &&
        typeof tariffBaseConditions.notice_period_days !== 'undefined'
          ? `${tariffBaseConditions.notice_period_days} day${checkIfSingle(
              tariffBaseConditions.notice_period_days
            )}`
          : '-',
    },
    {
      title: 'Signup Fee',
      content:
        tariffBaseConditions &&
        typeof tariffBaseConditions.sign_up_fee !== 'undefined'
          ? `${tariffBaseConditions.sign_up_fee} ${tariffBaseConditions.currency}`
          : '-',
    },
  ];

  const getVoucherDetailsItems = () => [
    {
      title: 'Total Free Charge Credit',
      content: `${subscription.voucher_details?.total_credit.toFixed(2)} ${
        subscription.voucher_details?.currency
      }`,
    },
    {
      title: 'Remaining Free Charge Credit',
      content: `${subscription.voucher_details?.remaining_credit.toFixed(2)} ${
        subscription.voucher_details?.currency
      }`,
    },
    {
      title: 'Free Charge Credit Last Used',
      content: subscription.voucher_details?.last_used_at
        ? formatIsoDates(subscription.voucher_details.last_used_at)
        : '-',
    },
  ];

  return (
    <div
      className={sharedClasses.cardElement}
      data-testid='subscription-details-component'
    >
      <SubscriptionDetailsHeader
        tariffBaseCondtionName={tariffBaseConditions?.name}
        actionItems={actionItems}
      />
      <Grid
        container
        justify='space-between'
        alignItems='flex-start'
        direction='row'
      >
        <Grid item xs={12}>
          <CardSubheadline title='Details' />
        </Grid>
        <Grid item xs={4}>
          <SignupDate
            startDate={subscription.start_date}
            subscriptionId={subscription.id}
          />
        </Grid>
        <Grid item xs={4}>
          <BasicInformationItem
            title='Preliminary End'
            content={formatIsoDates(subscription.end_date)}
          />
        </Grid>
        <Grid item xs={4}>
          <Status
            status={getSubscriptionStatus(subscription)}
            display={storedSubscriptionTerminationInfo !== null}
            onInfoClick={() => setStatusInfoIsOpen(true)}
          />
        </Grid>
        <Grid item xs={4}>
          <div className={sharedClasses.cardDetail}>
            <Typography
              className={classes.headingWithIcon}
              data-testid='subscription-linked-charging-cards-title'
              variant='h6'
            >
              Linked Charging Card
              {dialogData && (
                <Tooltip tooltipName='more-info'>
                  <DialogChargingCard
                    rfidUid={dialogData.rfidUid}
                    cardNumber={dialogData.number}
                    tag={dialogData.tag}
                  />
                </Tooltip>
              )}
            </Typography>
            <div className={sharedClasses.cardDetailData}>
              {subscriptionChargingCard ? (
                <TextLink
                  testId='linked-charging-card'
                  linkText={subscriptionChargingCard.number || '-'}
                  linkTo={linkToChargingCardDetails}
                />
              ) : (
                '-'
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <BasicInformationItem
            title='Automatic Renewal'
            content={
              subscription.automatic_renewal ? 'Activated' : 'Deactivated'
            }
          />
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={12}>
          <BasicInformationItem
            title='Billing Address'
            content={
              (subscriber?.data && parseAddress(subscriber.data.address)) || '-'
            }
          />
        </Grid>

        <Grid item xs={12}>
          <CardSubheadline title='Tariff Information' />
        </Grid>
        <Grid item xs={4}>
          <Tariff tariff={tariff}>
            <Typography variant='body2'>
              {tariffBaseConditions?.name || '-'}
            </Typography>
          </Tariff>
        </Grid>
        {getTariffInformationItems().map((item, index) => (
          <Grid item xs={item.title === 'description' ? 8 : 4} key={index}>
            <BasicInformationItem title={item.title} content={item.content} />
          </Grid>
        ))}

        {subscription.voucher_details &&
          getVoucherDetailsItems().map((item, index) => {
            return (
              <Grid item xs={4} key={index}>
                <BasicInformationItem
                  title={item.title}
                  content={item.content}
                />
              </Grid>
            );
          })}
      </Grid>
      <div className={classes.terminateSubscription}>
        {renderTerminationButton()}
      </div>

      <TerminateUpcomingSubscriptionDialog
        hasError={!!subscriptionState[taskSid].error}
        isOpen={terminateUpcomingSubDialogIsOpen}
        onClose={handleCloseUpcomingSubscriptionDialog}
      />

      <SubscriptionDetailsErrorDialog
        open={error}
        details={errorMsg}
        onClose={() => {
          setError(false);
          setErrorMsg('');
          setTerminateSubscriptionWarningIsOpen(false);
          setTerminateSubscriptionSummaryIsOpen(false);
        }}
      />
      <StatusInformationDialog
        open={statusInfoIsOpen}
        onClose={() => {
          setStatusInfoIsOpen(false);
        }}
        terminateSubscriptionResult={storedSubscriptionTerminationInfo}
      />

      <TerminateSubscriptionWarningDialog
        open={terminateSubscriptionWarningIsOpen}
        onClose={() => {
          setTerminateSubscriptionWarningIsOpen(false);
          setClearVin(!isWeChargeTenant(tenant));
        }}
        doTerminate={() => doTerminateSubscription(subscription, clearVin)}
        clearVin={clearVin}
        setClearVin={setClearVin}
        showTenantWarning={isWeChargeTenant(tenant)}
        hasUpcomingSubscription={blockTerminationWithUpcomingSubscription}
      />
      {terminationResult && (
        <TerminateSubscriptionSummaryDialog
          open={terminateSubscriptionSummaryIsOpen}
          onClose={() => {
            reloadSubscriptions();
            setTerminateSubscriptionSummaryIsOpen(false);
          }}
          terminateSubscriptionResult={terminationResult}
          tenant={tenant}
          twoStep={isWeChargeTenant(tenant)}
        />
      )}
    </div>
  );
};

export default connector(SubscriptionDetails);
