import React from 'react';
import { Grid } from '@material-ui/core';

import Invoice from '../index';
import CardElement from '../../../shared/CardElement/CardElement';
import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';
import useStyles from './useStyles';
import AddRefundForm from './AddRefundForm';

export interface IOwnProps {
  invoiceId?: string;
}

const AddRefund: React.FC<IOwnProps> = ({ invoiceId }) => {
  const { t, terms } = useTypedTranslation();
  const title = t(terms.refunds.form.headline);
  const classes = useStyles();

  if (!invoiceId) {
    return null;
  }

  return (
    <Grid container spacing={0}>
      <Grid item xs={5} classes={{ root: classes.formCard }}>
        <CardElement title={title} isCollapsable={false}>
          <AddRefundForm invoiceId={invoiceId} />
        </CardElement>
      </Grid>
      <Grid item xs={7}>
        <Invoice resourceId={invoiceId} hideRefunds />
      </Grid>
    </Grid>
  );
};

export default AddRefund;
