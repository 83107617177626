import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    cardElement: {
      backgroundColor: '#ffffff',
      boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.08)',
      borderRadius: '4px',
      marginBottom: '12px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexFlow: 'row wrap',
    },
    cardTitle: {
      fontSize: '20px',
      lineHeight: 'normal',
    },
    headerStyle: {
      padding: '16px',
    },
    titleWithExpand: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
    },
    nextIcon: {
      fontSize: '24px',
      color: '#6633cc',
      cursor: 'pointer',
    },
    expandIcon: {
      transition: 'transform 0.3s cubic-bezier(0.87, 0, 0.13, 1) !important',
      marginRight: '10px',
      fontSize: '24px',
      color: '#6633cc',
      cursor: 'pointer',
    },

    expandIconOpen: {
      transform: 'rotate(0)',
    },
    expandIconClosed: {
      transform: 'rotate(180deg)',
    },
    actionWrapper: {
      position: 'absolute',
      right: '-10px',
    },
    titleWrapper: {
      position: 'relative',
    },
    actionButton: {
      padding: '10px !important',
    },
  })
);

export default useStyles;
