import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    infoIcon: {
      marginLeft: '2px !important',
      cursor: 'pointer',
      fontSize: '12px !important',
      top: '-1px !important',
    },
    warningIcon: {
      marginRight: '4px !important',
      cursor: 'pointer',
      fontSize: '18px !important',
      color: '#ff8c00',
    },
    addressElement: {
      marginTop: '10px',
    },
  })
);

export default useStyles;
