/* eslint-disable @typescript-eslint/naming-convention */
import { ErrorObject } from 'serialize-error';

import { ITariffModel } from '../../../types/tariff';

export interface TariffsStateModel {
  loading: boolean;
  data: ITariffModel[] | null;
  error: ErrorObject | null;
}

export interface TariffsState {
  [id: string]: TariffsStateModel;
}

const state: TariffsState = {};

export type State = typeof state;
export default state;
