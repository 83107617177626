import { ActionType, ActionInterface } from '../actions';
import initialState, { State } from '..';
import getSubscriptionRequest from './getSubscriptionRequest';
import getSubscriptionSuccess from './getSubscriptionSuccess';
import getSubscriptionError from './getSubscriptionError';
import clearSubscription from './clearSubscription';
import sortSubscriptions from './sortSubscriptions';
import terminateSubscriptionRequest from './terminateSubscriptionRequest';
import terminateSubscriptionSuccess from './terminateSubscriptionSuccess';
import terminateSubscriptionError from './terminateSubscriptionError';

const reducer = (state: State = initialState, action: any) => {
  switch (action.type) {
    case ActionType.GetSubscriptionRequest:
      return getSubscriptionRequest(
        state,
        action as ActionInterface.GetSubscriptionRequest
      );
    case ActionType.GetSubscriptionSuccess:
      return getSubscriptionSuccess(
        state,
        action as ActionInterface.GetSubscriptionSuccess
      );
    case ActionType.GetSubscriptionError:
      return getSubscriptionError(
        state,
        action as ActionInterface.GetSubscriptionError
      );
    case ActionType.ClearSubscription:
      return clearSubscription(
        state,
        action as ActionInterface.ClearSubscription
      );
    case ActionType.SortSubscriptions:
      return sortSubscriptions(
        state,
        action as ActionInterface.SortSubscriptions
      );
    case ActionType.TerminateSubscriptionRequest:
      return terminateSubscriptionRequest(
        state,
        action as ActionInterface.TerminateSubscriptionRequest
      );
    case ActionType.TerminateSubscriptionSuccess:
      return terminateSubscriptionSuccess(
        state,
        action as ActionInterface.TerminateSubscriptionSuccess
      );
    case ActionType.TerminateSubscriptionError:
      return terminateSubscriptionError(
        state,
        action as ActionInterface.TerminateSubscriptionError
      );
    default:
      return state;
  }
};

export default reducer;
