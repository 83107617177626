import { ActionInterface } from '../actions';
import { State } from '..';
import update from 'immutability-helper';

export default (
  state: State,
  action: ActionInterface.GetChargingStationSuccess
): State => {
  const { data, evse_id } = action;
  const currentChargingStationState = state[action.taskSid];
  const updatedData = Object.assign({}, currentChargingStationState.data, {
    [evse_id]: data,
  });

  const updatedChargingStationState = update(currentChargingStationState, {
    data: {
      $set: updatedData,
    },
    loading: {
      $set: false,
    },
  });

  return {
    ...state,
    [action.taskSid]: updatedChargingStationState,
  };
};
