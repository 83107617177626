import { ISortState } from '../components/shared/AssetTable/AssetTable/AssetTable';

export const sortRequestHandler = (
  columnkey: string | null,
  sortState: ISortState,
  setSortState: React.Dispatch<React.SetStateAction<ISortState>>
): void => {
  if (columnkey === sortState.sortByColumnKey) {
    setSortState((prevState) => {
      return {
        ...prevState,
        sortDesc: !prevState.sortDesc,
      };
    });
  } else {
    setSortState((prevState) => {
      return {
        ...prevState,
        sortByColumnKey: columnkey,
        sortDesc: true,
      };
    });
  }
};
