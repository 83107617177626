import React from 'react';
import Typography from '@material-ui/core/Typography';
import { IStationViewModel } from '../../../../../view-models/StationModel';
import useStyles from './useStyles';

interface IOwnProps {
  chargingStation: IStationViewModel;
  stationRefreshing: boolean;
}

export const General: React.FC<IOwnProps> = ({
  chargingStation,
  stationRefreshing,
}) => {
  const classes = useStyles();

  return (
    <div
      className={classes.generalWrapper}
      data-testid='charging-station-general-public'
    >
      <div
        style={{ width: 200 }}
        className={`${classes.profileElement} ${
          stationRefreshing ? classes.stationRefreshing : ''
        }`}
      >
        <Typography data-testid='tarif f-name-title' variant='h6'>
          Station owner
        </Typography>
        <div className={classes.dataTypography}>
          <Typography data-testid='station-operator' variant='body2'>
            {chargingStation.operator}
          </Typography>
        </div>
      </div>
      <div
        style={{ marginLeft: 50 }}
        className={`${classes.profileElement} ${
          stationRefreshing ? classes.stationRefreshing : ''
        }`}
      >
        <Typography variant='h6'>Authentication Modes</Typography>
        <div className={classes.dataTypography}>
          <Typography data-testid='authentication-modes' variant='body2'>
            Unknown
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default General;
