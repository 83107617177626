import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardElement: {
      backgroundColor: '#ffffff',
      boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.08)',
      borderRadius: 4,
      padding: 16,
      marginBottom: 12,
      position: 'relative',
    },
    cardHeaderWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginBottom: 32,
    },
    headerLogo: {
      fontSize: 24,
      marginRight: 16,
      color: theme.colorPrimary,
    },
    headerTitle: {
      fontSize: 20,
      fontWeight: 900,
    },
  })
);

export default useStyles;
