import connector, { IPropsFromState } from '../Connector/Connector';

import HomeChargingUI from '../OverviewCards/HomeCharging';
import OrganizationUI from '../OverviewCards/Organization';
import React from 'react';
import SubscriptionsUI from '../OverviewCards/PublicCharging';
import UserUI from '../OverviewCards/UserProfile';
import useSharedStyles from '../shared/useSharedStyles';
import { useCurrentTask } from '../../custom-hooks/useCurrentTask';

export const StaticPanel: React.FC<IPropsFromState> = ({
  subscriptionState,
  homeStationsState,
}) => {
  const taskSid = useCurrentTask();
  const sharedClasses = useSharedStyles();

  const subscription = subscriptionState && subscriptionState[taskSid];
  const hasSubscription = Boolean(
    subscription && subscription.data && subscription.data.length >= 1
  );

  const homeCharging = homeStationsState && homeStationsState[taskSid];
  const hasHomeCharging = Boolean(homeCharging && homeCharging.data);

  return (
    <div className={sharedClasses.staticWrapper}>
      <UserUI />
      <SubscriptionsUI />
      <OrganizationUI startCollapsed={hasSubscription || hasHomeCharging} />
      <HomeChargingUI startCollapsed={hasSubscription} />
    </div>
  );
};

export default connector(StaticPanel);
