import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    detailPanel: {
      zIndex: 2,
      transition: 'transform 0.4s ease',

      '&:last-child': {
        paddingRight: 'calc(100% - 744px)',
      },
    },
    detailPanelLarge: {
      zIndex: 2,
      transition: 'transform 0.4s ease',

      '&:last-child': {
        paddingRight: 'calc(100% - 992px)',
      },
    },
  })
);

export default useStyles;
