import differenceInSeconds from 'date-fns/differenceInSeconds';
import format from 'date-fns/format';
import { parseSecondsDigital } from './parseSeconds';

export const formatIsoDates = (date: string): string => {
  try {
    return format(new Date(date), `dd.MM.yyyy HH:mm:ss`);
  } catch (_error) {
    return date;
  }
};

export const formatDateNoTime = (date: string): string => {
  try {
    return format(new Date(date), `dd.MM.yyyy`);
  } catch (_error) {
    return date;
  }
};

export const formatDates = (date: string | number | Date) => {
  return format(new Date(date), `dd-MM-yyyy`);
};

export const formatTimeSince = (date: string) => {
  const result = differenceInSeconds(new Date(), new Date(date));
  return parseSecondsDigital(result);
};
