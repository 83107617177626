import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useStyles from './useStyles';

export type TChargingState =
  | 'available'
  | 'occupied'
  | 'preparing'
  | 'finishing'
  | 'reserved'
  | 'unavailable'
  | 'faulted'
  | 'unreported'
  | 'disconnected';

interface IOwnProps {
  status: TChargingState;
}

export const ChargingState: React.FC<IOwnProps> = ({ status }) => {
  const classes = useStyles();
  const theme = useTheme();

  const chargingStateMap = {
    available: { color: theme.colorAccent, pulse: false },
    occupied: { color: theme.colorAlertSecondary, pulse: true },
    preparing: { color: theme.colorAlertSecondary, pulse: true },
    finishing: { color: theme.colorAlertSecondary, pulse: true },
    reserved: { color: theme.colorAlertSecondary, pulse: false },
    unavailable: { color: theme.surfaceTertiary, pulse: false },
    faulted: { color: theme.colorAlertPrimary, pulse: false },
    unreported: { color: theme.surfaceTertiary, pulse: false },
    disconnected: { color: theme.colorAlertPrimary, pulse: false },
  };

  if (!chargingStateMap[status]) {
    return null;
  }

  const backgroundColor = chargingStateMap[status].color;

  return (
    <div
      data-testid='status-indicator'
      className={`${classes.indicator} ${chargingStateMap[status].pulse &&
        classes.statusPulse}`}
      style={{ backgroundColor }}
    />
  );
};
