import update from 'immutability-helper';
import { State, SubscriberStateModel } from '..';
import { ActionInterface } from '../actions';

export default (
  state: State,
  action: ActionInterface.GetSubscriberRequest
): State => {
  const subscriber = state[action.taskSid] || {
    loading: true,
    data: null,
    error: null,
    request: null,
  };

  const updatedSubscriber: SubscriberStateModel = update(subscriber, {
    error: {
      $set: null,
    },
    loading: {
      $set: true,
    },
    request: {
      $set: { action: action.type, userId: action.userId },
    },
  });

  return {
    ...state,
    [action.taskSid]: updatedSubscriber,
  };
};
