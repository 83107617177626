import { makeStyles, createStyles, Theme } from '@material-ui/core';

const lightBorder = '1px solid #f5f6f8';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    noBorder: {
      borderBottom: '0 !important',
    },
    checkboxCell: {
      padding: '0px 0px 0px 6.9px',
      zIndex: 2, // Make sure the hover state circle is visible
    },
    noPadding: {
      padding: '0px',
      borderBottom: lightBorder,
    },
    table: {
      background: theme.surfacePrimary,
    },
    tableCell: {
      fontSize: theme.typography.body1.fontSize,
      padding: '14px 12px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      borderBottom: lightBorder,
    },
    tableRow: {
      '& td': {
        fontSize: theme.typography.body1.fontSize,
        overflow: 'hidden',
        paddingBottom: '6.5px',
        paddingTop: '6.5px',
        whiteSpace: 'nowrap',
        maxWidth: 150,
        textOverflow: 'ellipsis',
        display: 'table-cell',
      },
      '&:focus-within': {
        backgroundColor: theme.surfaceSecondary,
      },
      '&:hover': {
        backgroundColor: theme.surfaceSecondary,
        '& .clickableCell': {
          textDecoration: 'underline',
          '&:hover': {
            cursor: 'pointer',
            color: theme.colorPrimary,
          },
        },
      },
      height: '56px',
    },
    tableRowAnySelected: {
      cursor: 'pointer',
    },
    tableRowClickable: {
      '&:hover': {
        cursor: 'pointer',
      },
      height: 'default',
    },
    tableRowSelected: {
      '& td': {
        color: theme.textSecondary,
      },
      backgroundColor: theme.surfaceSecondary,
    },
    noContentRow: {
      borderBottom: 0,
    },
    noDataWrapper: {
      marginBottom: 16,
      marginTop: 16,
    },
    noDataTitle: {
      fontSize: 16,
      fontWeight: 900,
      color: theme.textSecondary,
      marginBottom: 12,
      marginTop: 20,
    },
    noDataSubTitle: {
      color: theme.textSecondary,
      fontSize: 14,
    },
  });
});

export default useStyles;
