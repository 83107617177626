import React from 'react';
import connector, { IPropsFromState } from '../../Connector/Connector';
import defaultRFIDCard from '../../../assets/image/defaultRFIDCard.jpg';
import useStyles from './useStyles';

type TProps = IPropsFromState & {
  brandId: number;
  templateId: number;
  back?: boolean;
};

export const CardImage: React.FC<TProps> = ({
  otherState,
  brandId,
  templateId,
  back,
}) => {
  const classes = useStyles();
  const rfidCards = otherState.rfidCards.data;

  const findDesignTemplate = (bid: number, tid: number): string | undefined => {
    const brandCard = rfidCards.find((c) => +c.brand_id === +bid);
    if (brandCard) {
      const brandTemplate = brandCard.design_templates.find(
        (dt) => +dt.template_id === +tid
      );
      if (brandTemplate) {
        return back ? brandTemplate.url_400_back : brandTemplate.url_400_front;
      }
    }

    return defaultRFIDCard;
  };

  return (
    <img
      data-testid='card-image'
      className={classes.cardImage}
      src={findDesignTemplate(brandId, templateId)}
      alt='rfid card'
    />
  );
};

export default connector(CardImage);
