/* eslint-disable @typescript-eslint/naming-convention */
import { ICardOrderModel } from '../../../types/subscriber';
import { ISortState } from '../../../components/shared/AssetTable/AssetTable/AssetTable';
import { actionCreator } from '../../type-utils';

export const ActionType = {
  GetCardOrderRequest: 'card-order/get-card-order-request',
  GetCardOrderSuccess: 'card-order/get-card-order-success',
  GetCardOrderError: 'card-order/get-card-order-error',
  SortCardOrders: 'card-order/sort-card-orders',
  ClearCardOrders: 'card-order/clear-card-orders',
};

export declare namespace ActionInterface {
  export interface GetCardOrderRequest {
    type: string;
    subscriberId: string;
    taskSid: string;
  }

  export interface GetCardOrderSuccess {
    type: string;
    data: ICardOrderModel[];
    taskSid: string;
  }

  export interface GetCardOrderError {
    type: string;
    error: Error;
    taskSid: string;
  }

  export interface SortCardOrders {
    type: string;
    taskSid: string;
    sortState: ISortState;
  }

  export interface ClearCardOrders {
    type: string;
    taskSid: string;
  }
}

export const ActionCreator = {
  GetCardOrderRequest: actionCreator<ActionInterface.GetCardOrderRequest>(
    ActionType.GetCardOrderRequest
  ),
  GetCardOrderSuccess: actionCreator<ActionInterface.GetCardOrderSuccess>(
    ActionType.GetCardOrderSuccess
  ),
  GetCardOrderError: actionCreator<ActionInterface.GetCardOrderError>(
    ActionType.GetCardOrderError
  ),
  SortCardOrders: actionCreator<ActionInterface.SortCardOrders>(
    ActionType.SortCardOrders
  ),
  ClearCardOrders: actionCreator<ActionInterface.ClearCardOrders>(
    ActionType.ClearCardOrders
  ),
};
