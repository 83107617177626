import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    cardWrapper: {
      position: 'relative',
      display: 'flex',
      flexFlow: 'column nowrap',
      '&:hover': {
        cardOverlay: {
          transform: 'scale(1)',
        },
      },
    },
    cardBackgroundGradient: {
      width: '100%',
      position: 'absolute',
      borderRadius: '8px',
      height: '50%',
      bottom: '0',
      borderTopLeftRadius: '0',
      borderTopRightRadius: '0',
      backgroundImage:
        'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 06), rgba(0, 0, 0, 08))',
    },
    cardNumber: {
      position: 'absolute',
      bottom: '12px',
      right: '10px',
      color: 'white',
      fontSize: '12px',
      fontWeight: 900,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
    },
    statusWrapper: {
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      top: '8px',
      right: '8px',
      boxShadow: '0 0 4px 0px rgba(0, 0, 0, 02)',
      background: 'rgba(0, 0, 0, 01)',
      borderRadius: '40px',
      padding: '1px 2px',
    },

    activationStatus: {
      display: 'inline-block',
      marginRight: '4px',
      verticalAlign: 'baseline',
    },
    statusText: {
      fontSize: '12px !important',
      color: 'white !important',
      fontWeight: 900, //!important'
      lineHeight: '12px !important',
      textTransform: 'capitalize', // !important',
      textShadow: '0 0 4px rgba(0, 0, 0, 1)',
    },
    cardLabelWrapper: {
      borderRadius: '15px',
      backgroundColor: 'rgba(0, 0, 0, 075)',
      position: 'absolute',
      right: '10px',
      bottom: '37px',
      padding: '2px 8px',
      maxWidth: '90%',
    },
    cardLabelText: {
      fontSize: '12px',
      lineJeight: '12px',
      color: '#878c96',
      textOverflow: 'ellipsis',
      display: 'block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    cardOverlay: {
      position: 'absolute',
      bottom: '0',
      left: '0',
      right: '0',
      backgroundColor: 'rgba(0, 0, 0, 063)',
      overflow: 'hidden',
      width: '100%',
      height: '100%',
      zIndex: 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '8px',
      transform: 'scale(0)',
    },
    cardDetailsButton: {
      color: 'rgb(104, 53, 204) !important',
      backgroundColor: '#f5f6f8 !important',
      padding: '6px 38px !important',
      fontSize: '16px !important',
      border: '2px solid rgb(104, 53, 204) !important',

      '&:hover': {
        backgroundColor: 'darken(#f5f6f8, 9) !important',
      },
    },
    cardCaptionWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '10px',
      fontSize: '10px',
      color: '#c4c4c4',
      fontWeight: 'bold',
    },
    contactlessIcon: {
      fontSize: '16px !important',
      marginRight: '3px',
    },
    infoIcon: {
      marginLeft: '2px !important',
      cursor: 'pointer',
      fontSize: '12px !important',
      color: '#fff !important',
      marginRight: '4px !important',
    },
  })
);

export default useStyles;
