import update from 'immutability-helper';
import { State } from '../';
import { ActionInterface } from '../actions';
import { UserState } from '../';

export default (state: State, action: ActionInterface.ClearUser): State => {
  const updatedState: UserState = update(state, {
    $unset: [action.taskSid],
  });

  return {
    ...updatedState,
  };
};
