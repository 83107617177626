import { Dialog } from '@material-ui/core';
import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { ITerminateSubscriptionResponse } from '../../../../services/subscriptions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { MKitIds } from '../MKitIDs/MKitIDs';
import Typography from '@material-ui/core/Typography';
import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';
import useStyles from './useStyles';

export type OwnProps = {
  open: boolean;
  onClose: () => void;
  terminateSubscriptionResult: ITerminateSubscriptionResponse | null;
};
export const StatusInformationDialog: React.FC<OwnProps> = ({
  open,
  onClose,
  terminateSubscriptionResult,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t, terms } = useTypedTranslation();

  return (
    <Dialog
      open={open && terminateSubscriptionResult !== null}
      onClose={onClose}
      PaperProps={{
        classes: {
          root: classes.dialog,
        },
      }}
    >
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      {terminateSubscriptionResult !== null ? (
        <div>
          <Typography variant='h3' paragraph>
            {t(
              terms.subscription_termination.subscription_status_info_terminated
            )}
          </Typography>
          <Typography variant='inherit'>
            {t(terms.subscription_termination.subscription_status_info_details)}
          </Typography>
          <div className={classes.dataBoxGrey}>
            <MKitIds terminationResponse={terminateSubscriptionResult} />
          </div>
        </div>
      ) : null}
    </Dialog>
  );
};
