import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    list: {
      width: 325,
      position: 'relative',
    },
    closeButtonWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: 12,
      boxSizing: 'border-box',
      paddingLeft: 40,
    },
    topDrawer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 30,
      paddingLeft: 40,
    },
    clearAllButton: {
      fontWeight: 'normal',
      marginRight: 18,
    },
    filterSection: {
      paddingTop: 15,
      paddingBottom: 15,
    },
    mainFilterHeading: {
      fontSize: 24,
    },
    filterTitle: {
      fontSize: 16,
      marginBottom: 12,
      fontWeight: 900,
    },
    filterOptions: {},
    filterOption: {
      display: 'flex',
      flexFlow: 'column nowrap',
      padding: '15px 0 13px 40px',
    },
    checkBox: {},
    checkboxLabel: {
      fontWeight: 'normal',
      maxWidth: 238,
      display: 'block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    tooltipOverflow: {
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: 238,
    },
  })
);

export default useStyles;
