import React from 'react';
import { IStationViewModel } from '../../../../view-models/StationModel';
import Typography from '@material-ui/core/Typography';
import useStyles from './useStyles';
import useChargeStationStyles from '../useStyles';

interface IOwnProps {
  chargingStation: IStationViewModel;
}

export const Location: React.FC<IOwnProps> = ({ chargingStation }) => {
  const classes = useStyles();
  const { profileElement: profileElementClass } = useChargeStationStyles();

  const Public = () => {
    return (
      <div className={classes.itemWrapper}>
        <span className={classes.itemTitle}>Address</span>
        <span className={classes.itemValue}>{chargingStation.address}</span>
      </div>
    );
  };

  const PrivateBusiness = () => {
    return (
      <>
        <div className={classes.itemWrapper}>
          <span className={classes.itemTitle}>Name</span>
          <span className={classes.itemValue}>
            {chargingStation.locationName}
          </span>
        </div>
        <div className={classes.itemWrapper}>
          <span className={classes.itemTitle}>Description</span>
          <span className={classes.itemValue}>
            {chargingStation.locationDescription}
          </span>
        </div>
        <Public />
      </>
    );
  };

  return (
    <div
      data-testid='charging-station-location'
      className={classes.locationWrapper}
    >
      <div className={profileElementClass}>
        <Typography
          data-testid='charging-sessions-table-header'
          classes={{
            h2: classes.chargingSessionsHeader,
          }}
          variant='h2'
        >
          Location
        </Typography>
      </div>
      <div className={classes.outerItemWrapper}>
        {chargingStation.type === 'public' ? <Public /> : <PrivateBusiness />}
      </div>
    </div>
  );
};

export default Location;
