export const parseURLparameters = (url: string) => {
  const obj: any = {};
  url.slice(1).replace(/([^=&]+)=([^&]*)/g, (_m, key: string, value): any => {
    obj[decodeURIComponent(key)] = decodeURIComponent(value);
  });

  return obj;
};

export const checkCrm = (urlParams: any) => {
  return (
    (urlParams &&
      urlParams.integration &&
      urlParams.integration === 'salesforce-contact-center') ||
    urlParams.integration === 'salesforce-crm'
  );
};

export const checkVerifiedCustomer = (urlParams: any) => {
  return Boolean(
    urlParams.customer_verified &&
      urlParams.customer_verified === 'true' &&
      urlParams.email
  );
};
