import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import connector, { IPropsFromState } from '../../Connector/Connector';

import { DealershipLogo } from '../../DealershipLogo/DealershipLogo';
import { IUserModel } from '../../../types/user';
import VerificationQuestionsList from '../../VerificationQuestions/VerificationQuestionsList';
import { useCurrentTask } from '../../../custom-hooks/useCurrentTask';
import useStyles from './useStyles';

const getNameOrEmail = (user: IUserModel | null): string | null => {
  if (!user) return null;
  return user.first_name && user.last_name
    ? `${user.first_name} ${user.last_name}`
    : user.email;
};

type TOwnProps = IPropsFromState & {
  setStep: (x: number) => void;
  setProgress: (x: number) => void;
};

export const VerifyQuestions: React.FC<TOwnProps> = ({
  userSearchState,
  setStep,
  setProgress,
}) => {
  const classes = useStyles();
  const taskSid = useCurrentTask();

  const userSearch = userSearchState && userSearchState[taskSid];
  const user = userSearch.selectedUser;

  React.useEffect(() => {
    setProgress(50);
  }, []);

  return (
    <>
      <Grid item={true}>
        <div
          data-testid='verify-questions-screen'
          className={classes.dialogTitle}
        >
          <Typography variant='h1'>Verify Customer</Typography>
          <div className={classes.dialogInitials}>
            <Typography variant='h1' color='primary'>
              {getNameOrEmail(user)} (
            </Typography>
            <div>
              {user && (
                <DealershipLogo
                  id={user && user.tenant_id}
                  width='30px'
                  height='30px'
                />
              )}
            </div>
            <Typography variant='h1' color='primary'>
              )
            </Typography>
          </div>
        </div>
        <div className={classes.subTitle}>
          <Typography variant='h3'>
            Validate all information displayed below.
          </Typography>
        </div>
      </Grid>
      <Grid item={true}>
        <VerificationQuestionsList setProgress={setProgress} />
      </Grid>
      <Grid item={true}>
        <div className={classes.backContainer}>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => setStep(1)}
            classes={{
              root: classes.buttonStyling,
            }}
          >
            Back
          </Button>
        </div>
      </Grid>
    </>
  );
};

export default connector(VerifyQuestions);
