import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    indicator: {
      borderRadius: '5px',
      height: 8,
      width: 8,
    },
    statusPulse: {
      boxShadow: '0 0 0 0 rgba(255, 140, 0, 1)',
      animation: '$pulse-orange 2s infinite',
      backgroundColor: theme.colorAlertSecondary,
    },
    '@keyframes pulse-orange': {
      '0%': {
        transform: 'scale(095)',
        boxShadow: '0 0 0 0 rgba(255, 140, 0, 07)',
      },
      '70%': {
        transform: 'scale(1)',
        boxShadow: '0 0 0 5px rgba(255, 140, 0, 0)',
      },
      '100%': {
        transform: 'scale(095)',
        boxShadow: '0 0 0 0 rgba(255, 140, 0, 0)',
      },
    },
  })
);

export default useStyles;
