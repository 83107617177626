import ApiService from './api';
import { IUserModel, IUserSearchModel } from '../types/user';

interface IRequestParams {
  userId: string;
}

interface IFindUserParams {
  query: string;
}

const list = async (): Promise<IUserModel[]> => {
  const apiService = new ApiService('/mgmt/iam/users/');
  const response = await apiService.query();
  return response.data;
};

const getById = async ({ userId }: IRequestParams): Promise<IUserModel> => {
  const apiService = new ApiService(`/mgmt/iam/users/${userId}`);
  const response = await apiService.query();
  return response.data;
};

const findUser = async ({
  query,
}: IFindUserParams): Promise<IUserSearchModel[]> => {
  const apiService = new ApiService(`/mgmt/customer/users/search`, {
    data: { query },
  });
  const response = await apiService.query();
  return response.data;
};

export const UsersService = { list, getById, findUser };
