import { makeStyles, createStyles, Theme } from '@material-ui/core';

const transition = 'all .125s linear';
const insideStuckHeader = '.stickyHeader &';
const headerHeight = 48;

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    bulkActionButton: {
      fill: theme.colorPrimary,
    },
    bulkActionContainer: {
      display: 'inline-block',
      marginRight: '.5em',
      width: 'min-content',
    },
    deselect: {
      color: theme.colorPrimary,
      cursor: 'pointer',
      fontSize: theme.typography.h4.fontSize,
      fontWeight: 'bold',
      lineHeight: '24px',
      marginRight: '1em',
      verticalAlign: 'middle',
    },
    iconButtonLabel: {
      height: '24px',
      width: '24px',
    },
    lastHeaderCell: {
      '&:after': {
        [insideStuckHeader]: {
          width: `calc(100% + ${theme.portalPadding}) !important`,
        },
        content: '""',
        width: '100%',
      },
      '&:before': {
        [insideStuckHeader]: {
          right: `-${theme.portalPadding}`,
          width: theme.portalPadding,
        },
        backgroundColor: theme.surfacePrimary,
        content: '""',
        height: `${headerHeight - 2}px`,
        position: 'absolute',
        right: 0,
        top: 0,
        transition,
        width: 0,
      },
    },
    tableHead: {
      '& th': {
        backgroundColor: theme.surfacePrimary,
        borderBottom: `1px solid ${theme.surfaceTertiary}`,
        fontWeight: 'normal',
        paddingBottom: '0px',
        paddingTop: '0px',
        position: 'sticky',
        top: `-${theme.portalPadding}`,
        zIndex: 1,
      },
      '& tr': {
        height: headerHeight,
      },
      '&.stickyHeader th': {
        // when header is stuck, replace the border bottom by pseudo-elements simulating a drop-shadow
        // cannot use real box-shadow as this leaks to the sides
        '&:after': {
          background:
            'linear-gradient(to bottom, rgba(0, 0, 0, 0.06), rgba(0,0,0,0))',
          content: '""',
          height: '8px',
          left: 0,
          position: 'absolute',
          top: `${headerHeight - 2}px`,
          transition,
          width: '100%',
        },
        borderBottom: 'none',
      },
      color: theme.typography.h6.color,
      fontSize: theme.typography.h6.fontSize,
    },
  });
});

export default useStyles;
