import React, { useEffect, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useForm, UseFormOptions } from 'react-hook-form';
import { FormHelperText, Grid, GridSize, Typography } from '@material-ui/core';

import { useStyles } from './useStyles';
import DialogHeadline from '../../../shared/Dialogs/DialogHeadline';
import MultilineButton from '../../../shared/Buttons/MultilineButton';
import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';
import { ISubscriberModel } from '../../../../types/subscriber';
import { validationSchema } from '../validationSchema';
import ControlledSelect, {
  IOwnProps as IControlledSelect,
} from '../../../shared/Forms/SelectField/ControlledSelect';
import {
  getTranslatedFields,
  getDefaultValues,
  IChangeBillingFields,
} from '../fields';
import InputField, {
  IOwnProps as IInputFieldProps,
} from '../../../shared/Forms/InputField';
import { Dispatcher } from '../../../Connector/dispatchers';
import { ActionInterface } from '../../../../store/state/subscriber/actions';

type SubmitState = 'submitted' | 'failed' | 'open';

const formOptions: UseFormOptions<IChangeBillingFields> = {
  resolver: yupResolver(validationSchema) as Resolver<IChangeBillingFields>,
  mode: 'onChange',
};

export interface IOwnProps {
  subscriberAddress: ISubscriberModel['address'];
  subscriberId: ISubscriberModel['id'];
  submitError: boolean;
  taskSid: string;
  updateBillingAddress: Dispatcher<ActionInterface.UpdateBillingAddressRequest>;
  onSuccess: () => void;
}

const ChangeBillingForm: React.FC<IOwnProps> = ({
  subscriberAddress,
  subscriberId,
  submitError,
  taskSid,
  onSuccess,
  updateBillingAddress,
}) => {
  const classes = useStyles();
  const { getScopedTranslation } = useTypedTranslation(
    'update_billing_address'
  );
  const [submitState, setSubmitState] = useState<SubmitState>('open');
  const subscriberAddressRef = useRef(subscriberAddress);
  const { textFields, selectFields } = getTranslatedFields(
    getScopedTranslation
  );

  useEffect(() => {
    submitError && setSubmitState('failed');
  }, [submitError]);

  useEffect(() => {
    if (subscriberAddress !== subscriberAddressRef.current) {
      onSuccess();
    }
  }, [subscriberAddress]);

  const { register, control, handleSubmit, errors, formState } = useForm<
    IChangeBillingFields
  >({ ...formOptions, defaultValues: getDefaultValues(subscriberAddress) });

  const isDisabledSubmit = !formState.isValid || formState.isSubmitting;
  const hasError = (field: keyof IChangeBillingFields) => !!errors[field];

  const onSubmit = (data: IChangeBillingFields) => {
    updateBillingAddress({
      subscriberAddress: data,
      subscriberId,
      taskSid,
    });

    setSubmitState('submitted');
  };

  const createTextInputField = (
    data: IInputFieldProps,
    size: GridSize = 12
  ) => (
    <Grid item xs={size}>
      <InputField
        {...data}
        key={data.id}
        hasError={hasError(data.name as keyof IChangeBillingFields)}
        inputRef={register}
      />
    </Grid>
  );

  const createControlledSelectField = (
    selectData: Omit<IControlledSelect, 'control'>,
    size: GridSize = 12
  ) => (
    <Grid item xs={size}>
      <ControlledSelect
        {...selectData}
        control={control}
        hasError={!!errors[selectData.name as keyof IChangeBillingFields]}
      />
    </Grid>
  );

  const getShippingAddressFields = () => (
    <>
      <Grid item xs>
        <Typography variant={'h4'}>
          {getScopedTranslation('shipping_address')}
        </Typography>
      </Grid>

      {createTextInputField(textFields.addressLine1)}
      {createTextInputField(textFields.addressLine2)}

      <Grid container item spacing={2}>
        <Grid item xs>
          {createTextInputField(textFields.zip)}
        </Grid>
        <Grid item xs={8}>
          {createTextInputField(textFields.city)}
        </Grid>
      </Grid>

      <Grid container item spacing={2}>
        {createTextInputField(textFields.state, 6)}
        {createControlledSelectField(selectFields.country, 6)}
      </Grid>
    </>
  );

  return (
    <div className={classes.outerWrapper}>
      <div>
        <DialogHeadline
          headline={getScopedTranslation('headline')}
          subHeadline={getScopedTranslation('sub_headline')}
        />
      </div>

      <form
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Grid container spacing={2}>
          {getShippingAddressFields()}

          <Grid item xs={12} className={classes.submitWrapper}>
            <MultilineButton
              mainText={getScopedTranslation('submit')}
              isDisabled={isDisabledSubmit}
            />
            {submitState === 'failed' && (
              <FormHelperText className={classes.errorMessage}>
                {getScopedTranslation('failure')}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default ChangeBillingForm;
