import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dataBoxGrey: {
      backgroundColor: theme.palette.grey['200'],
      padding: '1em',
    },
  })
);

export default useStyles;
