import React from 'react';
import Typography from '@material-ui/core/Typography';

interface IOwnProps {
  headline: string;
  subHeadline?: string;
}

const DialogHeadline: React.FC<IOwnProps> = ({ headline, subHeadline }) => {
  return (
    <>
      <Typography variant='h1' align='center' gutterBottom>
        {headline}
      </Typography>
      {subHeadline && (
        <Typography variant='subtitle1' align='center' gutterBottom>
          {subHeadline}
        </Typography>
      )}
    </>
  );
};

export default DialogHeadline;
