import update from 'immutability-helper';
import { omit } from 'lodash';
import { State, SubscriptionStateModel } from '..';
import { ActionInterface } from '../actions';

export default (
  state: State,
  action: ActionInterface.TerminateSubscriptionRequest
): State => {
  const subscription = state[action.taskSid] || {
    loading: true,
    data: null,
    error: null,
    request: null,
  };

  const terminatedSubscription: SubscriptionStateModel = update(subscription, {
    error: {
      $set: null,
    },
    loading: {
      $set: true,
    },
    request: {
      $set: omit(action, ['type', 'taskSid', 'subscriptionId']),
    },
  });

  return {
    ...state,
    [action.taskSid]: terminatedSubscription,
  };
};
