import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import {
  ISubscriptionModel,
  RfidCardOrderStatus,
  RfidCardStatus,
} from '../../../types/subscriber';
import useStyles from './useStyles';

export const getSubscriptionStatus = (
  subscription: ISubscriptionModel
): SubscriptionStatus =>
  subscription.inactivation_reason === 'revoked'
    ? 'cancelled'
    : (subscription.status as SubscriptionStatus);

export type SubscriptionStatus =
  | RfidCardStatus
  | RfidCardOrderStatus
  | 'cancelled'
  | 'upcoming';

interface IOwnProps {
  className?: string;
  status: SubscriptionStatus;
}

export const StatusIndicator: React.FC<IOwnProps> = ({ status, className }) => {
  const classes = useStyles();
  const theme = useTheme();
  const backgroundColorStatusMapping: {
    [K in IOwnProps['status']]: string;
  } = {
    active: theme.colorAccent,
    approved: theme.colorPrimary,
    archived: '#cad3e0',
    cancelled: theme.colorAlertSecondary,
    completed: theme.colorAccent,
    upcoming: theme.colorPrimaryDark,
    inactive: '#cad3e0',
    ordered: theme.colorAccent,
    pending: theme.materialFrameColor,
    printed: theme.colorAlertSecondary,
    readytopair: theme.colorAccent,
    rejected: theme.colorAlertPrimary,
    shipped: theme.colorAccent,
  };
  const backgroundColor = backgroundColorStatusMapping[status];
  return (
    <div
      data-testid='status-indicator'
      className={`${classes.indicator} ${className} ${backgroundColor ===
        'radiating' && classes.statusPulse}`}
      style={{ backgroundColor }}
    />
  );
};
