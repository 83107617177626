import React from 'react';
import { Typography } from '@material-ui/core';
import ActionDropdownContainer, {
  IActionItem,
} from '../../../shared/ActionDropdownContainer';
import SubscriptionLogo from '../../../../assets/image/subscription.svg';
import useStyles from './useStyles';

interface IProps {
  tariffBaseCondtionName: string | undefined;
  actionItems: IActionItem[] | null;
}

export const SubscriptionDetailsHeader: React.FC<IProps> = ({
  tariffBaseCondtionName,
  actionItems,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.chargingCardDetailsHeader}>
      <div className={classes.titleWrapper}>
        <div className={classes.logoContainer}>
          <img src={SubscriptionLogo} alt='subscription logo' />
        </div>
        <Typography
          variant='h1'
          classes={{
            root: classes.panelTitle,
          }}
        >
          Subscription -{' '}
          <span data-testid='tariff-label'>
            &quot;{tariffBaseCondtionName || '-'}
            &quot;
          </span>
        </Typography>
      </div>
      {actionItems && <ActionDropdownContainer actionItems={actionItems} />}
    </div>
  );
};
