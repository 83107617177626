import { ActionCreator, ActionInterface, ActionType } from './actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import { ChargeRecord } from '../../../view-models/ChargeRecordModel';
import { ChargingRecordService } from '../../../services/chargingRecords';
import { IChargingRecordResponse } from '../../../types/chargingRecords';
import { IChargingSessionModel } from '../../../types/chargingsession';
import { SubscribersService } from '../../../services/subscribers';

export function* chargingsessionsSaga() {
  function* getChargingSessions(
    action: ActionInterface.GetChargingSessionsRequest
  ) {
    try {
      let publicChargeRecords: IChargingSessionModel[] = [];

      if (action.subscriber_id) {
        try {
          publicChargeRecords = yield call(
            SubscribersService.getChargeRecords,
            action.subscriber_id
          );
        } catch (error) {
          // Do nothing on error (means no records returned)
        }
      }

      publicChargeRecords.forEach((record: IChargingSessionModel) => {
        record.type = 'public';
      });

      let homeChargeRecords: IChargingRecordResponse = {
        charging_records: [],
        limit: 0,
        offset: 0,
        total_count: 0,
      };

      if (action.user_id) {
        homeChargeRecords = yield call(
          ChargingRecordService.getChargingRecords,
          {
            user_id: action.user_id,
          }
        );

        homeChargeRecords.charging_records.forEach((record) => {
          record.type = 'private';
        });
      }

      let organizationChargeRecords: IChargingRecordResponse = {
        charging_records: [],
        limit: 0,
        offset: 0,
        total_count: 0,
      };

      if (action.organization_id) {
        organizationChargeRecords = yield call(
          ChargingRecordService.getChargingRecords,
          {
            organization_id: action.organization_id,
          }
        );

        organizationChargeRecords.charging_records.forEach((record) => {
          record.type = 'business';
        });
      }

      const combinedData = [
        ...publicChargeRecords,
        ...homeChargeRecords.charging_records,
        ...organizationChargeRecords.charging_records,
      ].map((record) => {
        return new ChargeRecord(record);
      });

      yield put<ActionInterface.GetChargingSessionsSuccess>(
        ActionCreator.GetChargingSessionsSuccess({
          data: combinedData,
          private: homeChargeRecords.charging_records,
          public: publicChargeRecords,
          organization: organizationChargeRecords.charging_records,
          taskSid: action.taskSid,
        })
      );
    } catch (error) {
      yield put<ActionInterface.GetChargingSessionsError>(
        ActionCreator.GetChargingSessionsError({
          error,
          taskSid: action.taskSid,
        })
      );
    }
  }

  yield all([
    takeEvery(ActionType.GetChargingSessionsRequest, getChargingSessions),
  ]);
}

export default chargingsessionsSaga;
