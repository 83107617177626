import React, { ChangeEvent } from 'react';

import SelectControl from './SelectControl';
import SelectComponent from './SelectComponent';
import useStyles from '../useStyles';

export interface ISelectOption {
  key: string;
  value: string;
  content: React.ReactNode;
  isDisabled?: boolean;
}

export interface IOwnProps {
  labelText: string;
  name: string;
  value?: string;
  handleChange: (e: ChangeEvent<{ value: unknown }>) => void;
  options: ISelectOption[];
  isRequired?: boolean;
}

const SelectField: React.FC<IOwnProps> = ({
  labelText,
  name,
  value = '',
  handleChange,
  options,
  isRequired = false,
}) => {
  const labelId = `${name}-select-label`;
  const formClasses = useStyles();

  return (
    <div className={formClasses.formField}>
      <SelectControl labelText={labelText} name={name} isRequired={isRequired}>
        <SelectComponent
          value={value}
          handleChange={handleChange}
          options={options}
          labelId={labelId}
          labelText={labelText}
          name={name}
        />
      </SelectControl>
    </div>
  );
};

export default SelectField;
