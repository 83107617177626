import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    dialogTitle: {
      marginBottom: 20,
      textAlign: 'center',
    },
    subTitle: {
      textAlign: 'center',
    },
    buttonContainer: {
      marginTop: 26,
    },
    buttonStyling: {
      padding: '5px 20px',
      minWidth: 170,
    },
  })
);

export default useStyles;
