import { ActionInterface } from '../actions';
import { State } from '..';
import serializeError from 'serialize-error';
import update from 'immutability-helper';

export default (
  state: State,
  action: ActionInterface.GetInvoicesError
): State => {
  const { error, taskSid } = action;

  const currentInvoicesState = state[taskSid] || {
    loading: true,
    data: null,
    error: null,
    request: null,
  };

  const updatedInvoicesState = update(currentInvoicesState, {
    error: {
      $set: serializeError(error),
    },
    loading: {
      $set: false,
    },
    data: {
      $set: null,
    },
  });

  return {
    ...state,
    [action.taskSid]: updatedInvoicesState,
  };
};
