import 'regenerator-runtime/runtime';
import 'smoothscroll-polyfill';
import './index.css';
import './utils/translation/i18n';

import * as Flex from '@twilio/flex-ui';
import * as React from 'react';

import { checkCrm, parseURLparameters } from './utils/parseURLparameters';

import App from './components/App';
import AuthService from './services/auth';
import CRMPanelContainer from './components/CRMPanel/CRMPanel';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { appConfig } from './appConfig';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { config } from './config';
import logo from './assets/image/logo-white.png';
import store from './store';

const mountNode = document.getElementById('root') as Element;

async function renderApp(
  flex: typeof Flex,
  manager: Flex.Manager
): Promise<void> {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: config.launchDarklyClientId,
  });

  const auth = new AuthService();
  try {
    await auth.fetchIdToken();
  } catch (error) {
    // Authentication failed, will retry on first api call
  }

  const urlParams = parseURLparameters(window.location.search);
  const isCrm = checkCrm(urlParams);

  if (isCrm) {
    flex.AgentDesktopView.defaultProps.splitterOptions = {
      initialFirstPanelSize: '0%',
      minimumFirstPanelSize: '0%',
      minimumSecondPanelSize: '100%',
    };

    flex.RootContainer.Content.remove('header');
    flex.MainContainer.Content.remove('sidenav');
  } else {
    flex.AgentDesktopView.defaultProps.splitterOptions = {
      initialFirstPanelSize: '300px',
      minimumFirstPanelSize: '0%',
    };

    flex.MainHeader.defaultProps.logoUrl = logo;
  }

  flex.CRMContainer.Content.replace(<CRMPanelContainer key='custom-crm' />);

  ReactDOM.render(
    <Provider store={store}>
      <LDProvider>
        <App manager={manager} />
      </LDProvider>
    </Provider>,
    mountNode
  );
}

function handleError(error: any): void {
  console.error('Failed to initialize Flex', error);
}

window.onload = (): void => {
  const predefinedConfig = (appConfig as Flex.Config) || {};

  const configuration = {
    ...predefinedConfig,
  };

  Flex.progress(mountNode)
    .provideLoginInfo(configuration, mountNode)
    .then(() => Flex.Manager.create(configuration, store))
    .then((manager) => renderApp(Flex, manager))
    .catch((error) => handleError(error));
};
