import { ActionCreator, ActionInterface, ActionType } from './actions';
import {
  IHomeChargingStationModel,
  IOrganizationModel,
} from '../../../types/user';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import { HomeChargingService } from '../../../services/stations';
import { IAccounts } from '../../../types/organization';
import { OrganizationService } from '../../../services/organizations';

export function* organizationSaga() {
  function* getOrganization(action: ActionInterface.GetOrganizationRequest) {
    try {
      if (action.organizationId) {
        const organization: IOrganizationModel = yield call(
          OrganizationService.getOrganization,
          {
            organizationId: action.organizationId,
          }
        );

        const stations: IHomeChargingStationModel = yield call(
          HomeChargingService.getStations,
          {
            organizationId: action.organizationId,
          }
        );

        let accounts: IAccounts = {
          members: [],
        };

        try {
          accounts = yield call(OrganizationService.getAccounts, {
            organizationId: action.organizationId,
          });
        } catch (error) {
          // Do nothing on error
        }

        yield put<ActionInterface.GetOrganizationSuccess>(
          ActionCreator.GetOrganizationSuccess({
            data: organization,
            stations,
            accounts,
            taskSid: action.taskSid,
          })
        );
      } else {
        throw new Error('no organisation id');
      }
    } catch (error) {
      yield put<ActionInterface.GetOrganizationError>(
        ActionCreator.GetOrganizationError({
          error,
          taskSid: action.taskSid,
        })
      );
    }
  }

  yield all([takeEvery(ActionType.GetOrganizationRequest, getOrganization)]);
}

export default organizationSaga;
