/* eslint-disable @typescript-eslint/naming-convention */
import { ErrorObject } from 'serialize-error';
import { ICardOrderModel } from '../../../types/subscriber';

export interface GetCardOrderRequest {
  subscriberId: string;
  taskSid: string;
}

export interface CardOrderStateModel {
  loading: boolean;
  data: ICardOrderModel[] | null;
  error: ErrorObject | null;
  request: { subscriberId: string } | null;
}

export interface CardOrderState {
  [id: string]: CardOrderStateModel;
}

const state: CardOrderState = {};

export type State = typeof state;
export default state;
