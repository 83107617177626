import { IOtherState, State } from '..';

import { ActionInterface } from '../actions';
import serializeError from 'serialize-error';
import update from 'immutability-helper';

export default (
  state: State,
  action: ActionInterface.GetRFIDCardsError
): State => {
  const { error } = action;

  const updatedState: IOtherState = update(state, {
    rfidCards: {
      error: {
        $set: serializeError(error),
      },
      loading: {
        $set: false,
      },
      data: {
        $set: [],
      },
    },
  });

  return {
    ...updatedState,
  };
};
