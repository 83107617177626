import ApiService from './api';
import { AxiosResponse } from 'axios';
import { IInvoice } from '../types/invoice';

const getSubscriberInvoices = async (
  subscriberId: string
): Promise<IInvoice[]> => {
  const apiService = new ApiService(
    `/mgmt/customer/v1/subscribers/${subscriberId}/invoices`
  );
  const response = await apiService.query();
  return response.data;
};

const getInvoiceById = async (invoiceId: string): Promise<IInvoice[]> => {
  const apiService = new ApiService(`/mgmt/customer/v1/invoices/${invoiceId}`);
  const response = await apiService.query();
  return response.data;
};

const getInvoicePdf = async (
  invoiceId: string
): Promise<AxiosResponse<Blob>> => {
  const apiService = new ApiService(
    `/mgmt/customer/v1/invoices/${invoiceId}/pdf`,
    {
      responseType: 'blob',
    }
  );
  return await apiService.query();
};

export const InvoiceService = {
  getInvoiceById,
  getSubscriberInvoices,
  getInvoicePdf,
};
