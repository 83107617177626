import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    circleComponentWrapper: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'center',
      justifyContent: 'flex-start',
      maxWidth: 110,
      width: '100%',
      position: 'relative',
      height: '100%',
    },
    circle: {
      height: 55,
      width: 55,
      border: `2px solid #00ce4e`,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 10,
    },
    circleText: {
      textAlign: 'center',
      fontWeight: 900,
    },
    circleHappy: {
      borderColor: '#00ce4e',
    },
    circleSad: {
      borderColor: '#f00066',
    },
    circleNeutral: {
      borderColor: '#cad3e0',
    },
    circleDirectionDown: {
      height: 82,
      width: 82,
      position: 'absolute',
      right: -56,
      top: -54,
    },
    circleDirectionUp: {
      height: 82,
      width: 82,
      position: 'absolute',
      right: -56,
      top: 27,
    },
    circleDirectionStraight: {
      width: 82,
      position: 'absolute',
      right: -56,
      top: 15,
    },
    circleDirectionImage: {
      height: '100%',
      width: '100%',
    },
  })
);

export default useStyles;
