import React, { useState } from 'react';
import {
  BulkAction,
  IBulkActionWithCallback,
  IBulkActionWithMenu,
  IColumn,
  ISortState,
} from '../AssetTable/AssetTable';
import { IconButton, TableCell, TableHead, TableRow } from '@material-ui/core';

import { AssetTableHeadCell } from '../AssetTableHeadCell/AssetTableHeadCell';
import { ElliTooltip } from '../../ElliTooltip/ElliTooltip';
import useStyles from './useStyles';

function instanceOfBulkActionWithCallback(
  object: BulkAction
): object is IBulkActionWithCallback {
  return 'callback' in object;
}

function instanceOfBulkActionWithMenu(
  object: BulkAction
): object is IBulkActionWithMenu {
  return !instanceOfBulkActionWithCallback(object);
}

interface IOwnHeadProps {
  bulkActions: BulkAction[];
  columns: IColumn[];
  isStuck: boolean;
  nRows: number;
  selected: string[];
  sortingState: ISortState;
  condensed: boolean;
  sortRequestHandler: (property: string) => void;
  toggleSelectAll: () => void;
}

const getMenuElement = (
  action: BulkAction,
  selected: string[],
  openMenuKey: string | null,
  closeBulkMenu: () => void
) => {
  if (instanceOfBulkActionWithMenu(action) && action.key === openMenuKey) {
    return action.menu(selected, closeBulkMenu);
  } else {
    return null;
  }
};

const getClickHandler = (
  action: BulkAction,
  selected: string[],
  setOpenMenuKey: (newValue: string) => void
) => {
  if (instanceOfBulkActionWithCallback(action)) {
    return () => action.callback(selected);
  } else {
    return () => setOpenMenuKey(action.key);
  }
};

export const AssetTableHead: React.FC<IOwnHeadProps> = ({
  bulkActions,
  columns,
  isStuck,
  selected,
  sortingState,
  condensed,
  sortRequestHandler,
  toggleSelectAll,
}) => {
  const classes = useStyles();
  const [openMenuKey, setOpenMenuKey] = useState<string | null>(null);

  const setOpenMenuKeyLambda = () => (newValue: string) =>
    setOpenMenuKey(newValue);

  const closeBulkMenu = () => setOpenMenuKey(null);

  const bulkActionsSection = bulkActions.map((action) => {
    const clickHandler = getClickHandler(
      action,
      selected,
      setOpenMenuKeyLambda()
    );

    return (
      <div key={`${action.title}-div`} className={classes.bulkActionContainer}>
        <ElliTooltip title={action.title} alwaysShowOnHover={true}>
          <IconButton
            key={action.title}
            id={action.title}
            onClick={clickHandler}
            size={'small'}
            className={classes.bulkActionButton}
            classes={{
              label: classes.iconButtonLabel,
            }}
          >
            {action.icon}
          </IconButton>
        </ElliTooltip>
        {getMenuElement(action, selected, openMenuKey, closeBulkMenu)}
      </div>
    );
  });

  return (
    <TableHead
      className={`${classes.tableHead} ${isStuck ? 'stickyHeader' : ''}`}
    >
      <TableRow
        style={{
          height: condensed ? '40px' : '48px',
        }}
      >
        {selected.length === 0 ? (
          columns.map((column, index) => (
            <AssetTableHeadCell
              key={column.key}
              column={column}
              sortingState={sortingState}
              sortRequestHandler={sortRequestHandler}
              lastHeaderCellClass={
                index === columns.length - 1 ? classes.lastHeaderCell : ''
              }
            />
          ))
        ) : (
          <TableCell
            colSpan={columns.length}
            style={{ paddingBottom: '8.5px', paddingTop: '8.5px' }}
            className={classes.lastHeaderCell}
          >
            <span onClick={toggleSelectAll} className={classes.deselect}>
              Deselect #
            </span>
            {bulkActionsSection}
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};
