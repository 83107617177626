import update from 'immutability-helper';
import { State, BreadcrumbState } from '..';
import { ActionInterface } from '../actions';

export default (
  state: State,
  action: ActionInterface.ClearBreadcrumbs
): State => {
  const updatedState: BreadcrumbState = update(state, {
    $unset: [action.taskSid],
  });

  return {
    ...updatedState,
  };
};
