import HomeCharger, {
  CHARGER_MAP,
  TValidChargers,
} from '../ActionCards/HomeCharger';
import React, { useEffect, useState } from 'react';
import connector, { IPropsFromState } from '../Connector/Connector';

import ActionCardElement from '../shared/ActionCardElement/ActionCardElement';
import ActiveSession from '../ActionCards/ActiveSession';
import ChargingCards from '../ChargingCards/ChargingCards';
import ChargingCardsImage from '../../assets/image/chargingCards.svg';
import ChargingStationIcon from '../../assets/image/charging_station.svg';
import { IHomeStation } from '../../types/user';
import { IInvoice } from '../../types/invoice';
import Invoice from '../ActionCards/Invoice';
import OfflineBoltOutlinedIcon from '@material-ui/icons/OfflineBoltOutlined';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { lexicographical } from '../../utils/comparisonFunctions';
import useSharedStyles from '../shared/useSharedStyles';
import { useCurrentTask } from '../../custom-hooks/useCurrentTask';
import { useTypedTranslation } from '../../custom-hooks/useTypedTranslation';

export type TOwnProps = IPropsFromState;

const sortByCreation = (a: IInvoice, b: IInvoice): number =>
  lexicographical(
    new Date(a.created_at).getTime(),
    new Date(b.created_at).getTime()
  );

export const ActionPanel: React.FC<TOwnProps> = ({
  activeSessionsState,
  userState,
  homeStationsState,
  invoicesState,
}) => {
  const taskSid = useCurrentTask();
  const sharedClasses = useSharedStyles();
  const activeSessions = activeSessionsState && activeSessionsState[taskSid];
  const taskHomeStations = homeStationsState && homeStationsState[taskSid];
  const user = userState && userState[taskSid];
  const taskInvoices = invoicesState && invoicesState[taskSid];

  const [userId, setUserId] = useState<string>('');
  const [homeStations, setHomeStations] = useState<IHomeStation[]>([]);
  const [homeStationTitle, setHomeStationTitle] = useState<string>('');
  const [latestInvoice, setLatestInvoice] = useState<IInvoice | null>(null);
  const { getScopedTranslation } = useTypedTranslation('action_cards');

  const hasSessions = Boolean(
    activeSessions &&
      Array.isArray(activeSessions.data) &&
      activeSessions.data.length
  );

  const hasHomeStations = Boolean(homeStations && homeStations.length);
  const hasInvoices = Boolean(latestInvoice);

  useEffect(() => {
    if (!taskInvoices?.data) {
      return;
    }

    const invoices = [...taskInvoices.data];
    invoices.sort(sortByCreation);
    setLatestInvoice(invoices[invoices.length - 1]);
  }, [taskInvoices]);

  useEffect(() => {
    if (user.data) {
      setUserId(user.data.id);
    }
  }, [user]);

  useEffect(() => {
    if (taskHomeStations && taskHomeStations.stations) {
      const filtered = taskHomeStations.stations.filter(
        (hs) => hs.model && Object.keys(CHARGER_MAP).includes(hs.model)
      );

      setHomeStations(filtered);

      if (filtered.length) {
        setHomeStationTitle(CHARGER_MAP[filtered[0].model as TValidChargers]);
      }
    }
  }, [taskHomeStations]);

  return (
    <div className={sharedClasses.actionWrapper}>
      <ActionCardElement
        title={getScopedTranslation('rfid_cards.headline')}
        Icon={<img src={ChargingCardsImage} alt='charging cards' />}
      >
        <ChargingCards />
      </ActionCardElement>

      {hasHomeStations && (
        <ActionCardElement
          title={homeStationTitle}
          Icon={<img src={ChargingStationIcon} alt='Charging Station' />}
        >
          <HomeCharger
            homeStations={homeStations}
            setHomeStationTitle={setHomeStationTitle}
          />
        </ActionCardElement>
      )}

      {hasInvoices && (
        <ActionCardElement
          title={getScopedTranslation('invoices.headline')}
          Icon={
            <ReceiptIcon
              style={{
                color: 'rgb(102, 51, 204)',
              }}
            />
          }
        >
          <Invoice invoice={latestInvoice} />
        </ActionCardElement>
      )}

      {hasSessions &&
        activeSessions.data.map((session, index) => (
          <ActionCardElement
            key={session.id}
            startCollapse={index > 0}
            title={getScopedTranslation('sessions.headline')}
            Icon={
              <OfflineBoltOutlinedIcon
                style={{
                  color: 'rgb(102, 51, 204)',
                }}
              />
            }
          >
            <ActiveSession
              session={session}
              loading={activeSessions.loading}
              userId={userId}
            />
          </ActionCardElement>
        ))}
    </div>
  );
};

export default connector(ActionPanel);
