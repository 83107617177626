import ApiService from './api';
import { ITariffModel, ITariffConditionModel } from '../types/tariff';

const getById = async (tariffId: string): Promise<ITariffModel> => {
  const apiService = new ApiService(`/mgmt/customer/tariffs/${tariffId}`);
  const response = await apiService.query();
  return response.data;
};

const getByConditionId = async (
  tariffId: string,
  baseConditionId: string
): Promise<ITariffConditionModel> => {
  const apiService = new ApiService(
    `/mgmt/customer/v1/tariffs/${tariffId}/baseconditions/${baseConditionId}`
  );
  const response = await apiService.query();
  return response.data;
};

export const TariffService = {
  getById,
  getByConditionId,
};
