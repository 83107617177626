import * as activeSessions from './activeSessions/actions';
import * as breadcrumbs from './breadcrumbs/actions';
import * as cardorders from './cardorders/actions';
import * as chargingCards from './chargingCards/actions';
import * as chargingSessions from './chargingSessions/actions';
import * as chargingStations from './chargingStations/actions';
import * as homeStations from './homeStations/actions';
import * as invoices from './invoices/actions';
import * as organization from './organization/actions';
import * as other from './other/actions';
import * as subscriber from './subscriber/actions';
import * as subscription from './subscription/actions';
import * as ui from './ui/actions';
import * as user from './user/actions';
import * as userSearch from './userSearch/actions';
import * as tariffs from './tariffs/actions';

export const ActionCreator = {
  other: other.ActionCreator,
  user: user.ActionCreator,
  subscriber: subscriber.ActionCreator,
  subscription: subscription.ActionCreator,
  cardorders: cardorders.ActionCreator,
  userSearch: userSearch.ActionCreator,
  breadcrumbs: breadcrumbs.ActionCreator,
  ui: ui.ActionCreator,
  chargingCards: chargingCards.ActionCreator,
  chargingSessions: chargingSessions.ActionCreator,
  chargingStations: chargingStations.ActionCreator,
  organization: organization.ActionCreator,
  homeStations: homeStations.ActionCreator,
  activeSessions: activeSessions.ActionCreator,
  invoices: invoices.ActionCreator,
  tariffs: tariffs.ActionCreator,
};

export const ActionType = {
  other: other.ActionType,
  user: user.ActionType,
  subscriber: subscriber.ActionType,
  subscription: subscription.ActionType,
  cardorders: cardorders.ActionType,
  userSearch: userSearch.ActionType,
  breadcrumbs: breadcrumbs.ActionType,
  ui: ui.ActionType,
  chargingCards: chargingCards.ActionType,
  chargingSessions: chargingSessions.ActionType,
  chargingStations: chargingStations.ActionType,
  organization: organization.ActionType,
  homeStations: homeStations.ActionType,
  activeSessions: activeSessions.ActionType,
  invoices: invoices.ActionType,
};
