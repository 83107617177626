import { all, call, put, takeEvery } from 'redux-saga/effects';
import { ActionCreator, ActionInterface, ActionType } from './actions';
import { SubscribersService } from '../../../services/subscribers';
import { ITariffModel } from '../../../types/tariff';

export function* tariffsSaga() {
  function* getTariffs(action: ActionInterface.GetTariffsRequest) {
    try {
      const tariffs: ITariffModel[] = yield call(SubscribersService.getTariffs);

      yield put<ActionInterface.GetTariffsSuccess>(
        ActionCreator.GetTariffsSuccess({
          data: tariffs,
          taskSid: action.taskSid,
        })
      );
    } catch (error) {
      yield put<ActionInterface.GetTariffsError>(
        ActionCreator.GetTariffsError({
          error,
          taskSid: action.taskSid,
        })
      );
    }
  }

  yield all([takeEvery(ActionType.GetTariffsRequest, getTariffs)]);
}

export default tariffsSaga;
