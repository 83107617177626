import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chargingCardDetailsHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '16px',
    },
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    titleIcon: {
      color: theme.colorPrimary,
      fontSize: '24px',
      marginRight: '16px',
    },
    panelTitle: { fontSize: '20px' },
  })
);

export default useStyles;
