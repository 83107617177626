import { IFieldNames } from '../../../utils/mapFieldsForTable';

export const fieldNames: IFieldNames = {
  public: {
    name: 'Session Type',
    width: '20px',
    noPadding: true,
    align: 'right',
  },
  start_date_time: {
    name: 'Session Start',
    width: '165px',
  },
  total_time: {
    name: 'Duration',
  },
  total_energy: {
    name: 'Consumption',
    width: '120px',
  },
  total_price: {
    name: 'Price (incl. VAT)',
    width: '125px',
  },
  location_evse_id: {
    name: 'Station',
  },
  card_used: {
    name: 'Card Used',
  },
  actions: {
    name: '',
    width: '36px',
  },
};
