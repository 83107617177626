import { IAddressModel } from '../types/subscriber';
import ApiService from './api';

export type IUpdateBillingAddress = Omit<
  IAddressModel,
  'id' | 'created_at' | 'updated_at' | 'connection_layer_id'
>;

export type UpdateBillingAddress = {
  subscriberId: string;
  subscriberAddress: IUpdateBillingAddress;
};

const updateBillingAddress = async ({
  subscriberId,
  subscriberAddress,
}: UpdateBillingAddress): Promise<IUpdateBillingAddress> => {
  const requestBody: IUpdateBillingAddress = {
    address_line_1: subscriberAddress.address_line_1,
    address_line_2: subscriberAddress.address_line_2,
    city: subscriberAddress.city,
    country_code: subscriberAddress.country_code,
    zip: subscriberAddress.zip,
    state: subscriberAddress.state,
  };

  const apiService = new ApiService(
    `/mgmt/customer/v1/subscribers/${subscriberId}/address`,
    {
      method: 'PUT',
      data: requestBody,
    }
  );
  const { data } = await apiService.query();
  return data;
};

export const BillingAddressService = {
  updateBillingAddress,
};
