import React, { useState } from 'react';

import OrderSuccessIcon from '../../../../assets/image/cardsuccess.svg';
import { useCurrentTask } from '../../../../custom-hooks/useCurrentTask';
import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';
import connector, { IPropsFromState } from '../../../Connector/Connector';
import DialogHeadline from '../DialogHeadline';
import SuccessMessage from '../SuccessMessage';
import OrderRfidCardForm from './OrderRfidCardForm';

type OwnProps = IPropsFromState;

const OrderRfidCard: React.FC<OwnProps> = ({ subscriberState, userState }) => {
  const taskSid = useCurrentTask();
  const subscriber = subscriberState[taskSid] && subscriberState[taskSid].data;
  const user = userState[taskSid] && userState[taskSid].data;
  const { getScopedTranslation } = useTypedTranslation('order_card_dialog');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const handleSuccess = () => {
    setIsSuccess(true);
  };

  if (isSuccess) {
    return (
      <SuccessMessage
        headline={getScopedTranslation('success.headline')}
        iconAltText={getScopedTranslation('success.headline')}
        icon={OrderSuccessIcon}
      />
    );
  }

  if (!subscriber) {
    return (
      <DialogHeadline
        headline={getScopedTranslation('no_subscriber.headline')}
        subHeadline={getScopedTranslation('no_subscriber.sub_headline')}
      />
    );
  }

  return (
    <OrderRfidCardForm
      subscriber={subscriber}
      user={user}
      onSuccess={handleSuccess}
    />
  );
};

export default connector(OrderRfidCard);
