import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    flipSwirl: {
      transform: 'scaleY(-1)',
    },
    chargingReadinessWrapper: {
      overflow: 'hidden',
      width: 550,
    },
    closeIcon: {},
    headerWrapper: {
      marginBottom: 48,
      textAlign: 'center',
    },
    dialogTitle: {
      fontSize: 28,
      marginBottom: 16,
      fontWeight: 900,
    },
    dialogSubtitle: {
      fontSize: 16,
      fontWeight: 'normal',
    },
    gridContainer: {
      margin: 0,
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridTemplateRows: '160px 180px 124px',
      gap: '1px 1px',
      gridTemplateAreas: `"area1 area2 area4 ." ". area3 area4 area5" ". . area4 ."`,
      height: 360,
    },
    area1: {
      gridArea: 'area1',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    area2: {
      gridArea: 'area2',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    area3: {
      gridArea: 'area3',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    area4: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: '0.75fr 160px 144px 0.75fr',
      gap: '1px 1px',
      gridTemplateAreas: `"." "area41" "area42" "."`,
      gridArea: 'area4',
    },
    area41: {
      gridArea: 'area41',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    area42: {
      gridArea: 'area42',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    area5: {
      gridArea: 'area5',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);

export default useStyles;
