import { all, call, put, takeEvery } from 'redux-saga/effects';
import { ActionCreator, ActionInterface, ActionType } from './actions';
import { ISubscriptionModel } from '../../../types/subscriber';
import { SubscribersService } from '../../../services/subscribers';
import { TerminateSubscriptionService } from '../../../services/subscriptions';

export function* getSubscription(
  action: ActionInterface.GetSubscriptionRequest
) {
  try {
    const subscription: ISubscriptionModel[] = yield call(
      SubscribersService.getSubscriptions,
      {
        subscriberId: action.subscriberId,
      }
    );

    yield put<ActionInterface.GetSubscriptionSuccess>(
      ActionCreator.GetSubscriptionSuccess({
        data: subscription,
        taskSid: action.taskSid,
      })
    );
  } catch (error) {
    yield put<ActionInterface.GetSubscriptionError>(
      ActionCreator.GetSubscriptionError({
        error,
        taskSid: action.taskSid,
      })
    );
  }
}

export function* terminateSubscription(
  action: ActionInterface.TerminateSubscriptionRequest
) {
  try {
    yield call(
      TerminateSubscriptionService.terminateSubscription,
      action.subscriptionId,
      true
    );
    yield put<ActionInterface.TerminateSubscriptionSuccess>(
      ActionCreator.TerminateSubscriptionSuccess({
        taskSid: action.taskSid,
      })
    );
  } catch (error) {
    yield put<ActionInterface.TerminateSubscriptionError>(
      ActionCreator.TerminateSubscriptionError({
        error,
        taskSid: action.taskSid,
      })
    );
  }
}

export function* subscriptionSaga() {
  yield all([
    takeEvery(ActionType.GetSubscriptionRequest, getSubscription),
    takeEvery(ActionType.TerminateSubscriptionRequest, terminateSubscription),
  ]);
}

export default subscriptionSaga;
