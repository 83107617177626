/* eslint-disable @typescript-eslint/naming-convention */
import { IActiveSession } from '../../../types/activeSessions';
import { actionCreator } from '../../type-utils';

export const ActionType = {
  GetActiveSessionsRequest: 'active-sessions/get-active-sessions-request',
  GetActiveSessionsSuccess: 'active-sessions/get-active-sessions-success',
  GetActiveSessionsError: 'active-sessions/get-active-sessions-error',
};

export declare namespace ActionInterface {
  export interface GetActiveSessionsRequest {
    type: string;
    userId: string;
    taskSid: string;
  }

  export interface GetActiveSessionsSuccess {
    type: string;
    data: IActiveSession[];
    taskSid: string;
  }

  export interface GetActiveSessionsError {
    type: string;
    error: Error;
    taskSid: string;
  }
}

export const ActionCreator = {
  GetActiveSessionsRequest: actionCreator<
    ActionInterface.GetActiveSessionsRequest
  >(ActionType.GetActiveSessionsRequest),
  GetActiveSessionsSuccess: actionCreator<
    ActionInterface.GetActiveSessionsSuccess
  >(ActionType.GetActiveSessionsSuccess),
  GetActiveSessionsError: actionCreator<ActionInterface.GetActiveSessionsError>(
    ActionType.GetActiveSessionsError
  ),
};
