import React from 'react';
import { TableCell, TableSortLabel } from '@material-ui/core';
import { IColumn, ISortState } from '../AssetTable/AssetTable';
import publicPrivateIcon from '../../../../assets/image/publicprivate.svg';
import useStyles from './useStyles';

interface IOwnHeadCellProps {
  column: IColumn;
  sortingState: ISortState;
  sortRequestHandler: (property: string) => void;
  lastHeaderCellClass: string;
}

export const AssetTableHeadCell: React.FC<IOwnHeadCellProps> = ({
  column,
  sortingState,
  sortRequestHandler,
  lastHeaderCellClass,
}) => {
  const classes = useStyles();
  const createSortHandler = (columnkey: string) => () => {
    sortRequestHandler(columnkey);
  };

  const active =
    column.isSortable && sortingState.sortByColumnKey === column.key;

  if (column.isStatus) {
    return (
      <TableCell
        align='center'
        padding='none'
        className={`${
          classes.statusCell
        } ${lastHeaderCellClass} ${column.noBorder && classes.noBorder}`}
      >
        <div
          data-testid='table-header-sort-button'
          className={`${classes.indicator} ${
            active
              ? sortingState.sortDesc
                ? 'inactivefirst'
                : 'activefirst'
              : ''
          }`}
          onClick={createSortHandler(column.key)}
        />
      </TableCell>
    );
  }

  if (column.header === 'Session Type') {
    return (
      <TableCell
        data-testid='pp-icon'
        classes={{
          root: classes.tableCellRoot,
        }}
        style={column.width ? { width: column.width } : {}}
        className={`${lastHeaderCellClass} ${column.noBorder &&
          classes.noBorder}`}
      >
        <img src={publicPrivateIcon} />
      </TableCell>
    );
  }

  return (
    <TableCell
      data-testid='table-header-cell'
      classes={{
        root: classes.tableCellRoot,
      }}
      style={column.width ? { width: column.width } : {}}
      className={`${lastHeaderCellClass} ${
        classes.headerCell
      } ${column.noBorder && classes.noBorder}`}
    >
      {column.isSortable ? (
        <TableSortLabel
          data-testid='table-header-sort-button'
          active={active}
          direction={sortingState.sortDesc ? 'desc' : 'asc'}
          onClick={createSortHandler(column.key)}
        >
          {column.header}
        </TableSortLabel>
      ) : (
        column.header
      )}
    </TableCell>
  );
};
