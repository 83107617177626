import { IHomeChargingStation, IHomeConnector, IHomeEvse } from '../types/user';

import { IActiveSession } from '../types/activeSessions';
import { IChargingRecordViewModel } from '../types/chargingRecords';

export interface IConnectorDetails {
  type?: 'IEC_62196_T2_COMBO' | 'IEC_62196_T2' | 'CHADEMO';
  power?: number;
  powerType?: string;
}

interface IGetFromStationProps {
  currentStation: IHomeChargingStation;
  connectorId: number;
}

const getConnectorFromStation = ({
  currentStation,
  connectorId,
}: IGetFromStationProps): IConnectorDetails | undefined => {
  const connectors: IHomeConnector[] = currentStation.evses.reduce(
    (accum: IHomeConnector[], current: IHomeEvse) => {
      accum.push(...current.connectors);
      return accum;
    },
    []
  );

  const currentConnector = connectors.find(
    (connector) => connector.id === connectorId
  );

  if (currentConnector) {
    return {
      type: currentConnector.type as
        | 'IEC_62196_T2_COMBO'
        | 'IEC_62196_T2'
        | 'CHADEMO'
        | undefined,
      power: currentConnector.max_electric_power_kw,
      powerType: currentConnector.power_type,
    };
  }

  return;
};

interface IGetConnectorDetailsProps {
  currentStation: IHomeChargingStation | null;
  currentSession: IChargingRecordViewModel | IActiveSession | null;
}

export const getConnectorDetails = ({
  currentStation,
  currentSession,
}: IGetConnectorDetailsProps): IConnectorDetails | undefined => {
  if (!currentSession) {
    return;
  }

  const connectorId = currentSession.connector_id;

  if (!connectorId) {
    return;
  }

  if (currentStation) {
    return getConnectorFromStation({
      currentStation,
      connectorId,
    });
  }

  return;
};
