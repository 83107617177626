import { ActionInterface } from '../actions';
import { IChargingRecordViewModel } from '../../../../types/chargingRecords';
import { IRFIDCardModel } from '../../../../types/subscriber';
import { ISortState } from '../../../../components/shared/AssetTable/AssetTable/AssetTable';
import { State } from '..';
import { lexicographical } from '../../../../utils/comparisonFunctions';
import update from 'immutability-helper';

interface ISortFunctionProps {
  a: IChargingRecordViewModel;
  b: IChargingRecordViewModel;
  chargingCards?: IRFIDCardModel[];
}

interface ISortFunctions {
  [string: string]: (props: ISortFunctionProps) => number;
}

export const getCardNumber = (
  id?: string,
  chargingCards: IRFIDCardModel[] = []
): string => {
  if (id) {
    const card = chargingCards.find((cc) => cc.id === id);
    if (card) {
      return card.number;
    }
  }
  return '';
};

export const sortFunctions: ISortFunctions = {
  start_date_time: ({ a, b }) => {
    const createdAtA = new Date(a.start_date_time);
    const createdAtB = new Date(b.start_date_time);
    return lexicographical(createdAtA, createdAtB);
  },
  total_time: ({ a, b }) => {
    return lexicographical(a.duration, b.duration);
  },
  total_energy: ({ a, b }) => {
    return lexicographical(a.consumption, b.consumption);
  },
  total_price: ({ a, b }) => {
    return lexicographical(a.price || 0, b.price || 0);
  },
  location_evse_id: ({ a, b }) => {
    return lexicographical(a.station_id, b.station_id);
  },
  card_used: ({ a, b, chargingCards }) => {
    const cardA = getCardNumber(a.card_id, chargingCards);
    const cardB = getCardNumber(b.card_id, chargingCards);
    return lexicographical(cardA, cardB);
  },
};

export const generateSortFunction = (
  sortState: ISortState,
  chargingCards: IRFIDCardModel[]
) => (a: IChargingRecordViewModel, b: IChargingRecordViewModel): number => {
  const sortKey = sortState.sortByColumnKey as AdminOrderSortKey;
  if (sortKey) {
    const sortFunction = sortFunctions[sortKey];
    const ascendingSortResult = sortFunction({ a, b, chargingCards });

    return sortState.sortDesc ? ascendingSortResult * -1 : ascendingSortResult;
  } else {
    return 0;
  }
};

type AdminOrderSortKey = keyof typeof sortFunctions;

export default (
  state: State,
  action: ActionInterface.SortChargingSessions
): State => {
  const { sortState, taskSid, chargingCards } = action;
  const currentChargingSessionsState = state[taskSid];

  if (currentChargingSessionsState && currentChargingSessionsState.data) {
    const sortedChargingSessions: IChargingRecordViewModel[] = currentChargingSessionsState.data.slice();
    sortedChargingSessions.sort(generateSortFunction(sortState, chargingCards));

    const updatedChargingSessions = update(currentChargingSessionsState, {
      data: {
        $set: sortedChargingSessions,
      },
      loading: {
        $set: false,
      },
    });

    return {
      ...state,
      [action.taskSid]: updatedChargingSessions,
    };
  }

  return {
    ...state,
  };
};
