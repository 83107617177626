import React, { useEffect, useRef, useState } from 'react';
import Divider from '@material-ui/core/Divider';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import RefreshIcon from '@material-ui/icons/Refresh';
import TextField from '@material-ui/core/TextField';
import {
  isCCManager as hasCCManagerRole,
  isExpired,
  isLoggedIn,
} from '../../utils/isRole';

import AuthService from '../../services/auth';
import ElliLogo from '../../assets/image/elli-logo.svg';
import LoginIcon from '../../assets/image/login.svg';
import VerifyCustomer from '../VerifyCustomer/VerifyCustomer';
import WelcomeImage from '../../assets/image/welcome.svg';
import { config } from '../../config';
import decodeToken from '../../utils/decodeToken';
import useCRMStyles from '../CRMPanel/useStyles';
import useStyles from './useStyles';

const InvalidRole = ({ uid, email }: { uid: string; email: string }) => {
  const classes = useStyles();
  const userIdRef = useRef<HTMLInputElement>(null);

  const copyText = (ref: React.RefObject<HTMLInputElement>): void => {
    (ref.current as HTMLInputElement).select();
    document.execCommand('copy');
  };

  const sendToEmail = () => {
    const mailtoString = `
      mailto:request-cc-access@elli.eco?Subject=Grant access permissions for user ${email} to Contact Center App ${config.env}&body=Grant the following user permissions to access the Contact Center app:
      %0A%0A
      User Email: ${email}%0A
      IAM id: ${uid}%0A
      Env: ${config.env}%0A
    `;
    window.open(mailtoString);
  };

  return (
    <div
      data-testid='invalid-role-component'
      className={classes.invalidRoleContextBox}
    >
      <h4 className={classes.insufficentPermissionsText}>
        <p className={classes.topText}>
          Your account does not have sufficient permissions.
        </p>
        <p className={classes.bottomText}>
          Request access through{' '}
          <span className={classes.mailtoLink} onClick={() => sendToEmail()}>
            request-cc-access@elli.eco
          </span>{' '}
          by referencing your user account id from below.
        </p>
      </h4>
      <Divider className={classes.divider} />

      <div className={classes.userDetailsWrapper}>
        <div className={classes.accountEmail}>
          <h2 className={classes.emailTitle}>User account email</h2>
          <p className={classes.emailText}>{email}</p>
        </div>

        {uid && (
          <div className={classes.textWrapper}>
            <span className={classes.title}>User account id</span>
            <TextField
              InputProps={{
                classes: {
                  input: classes.textBox,
                  root: classes.inputRoot,
                  focused: classes.inputFocused,
                },
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={() => copyText(userIdRef)}
                      className={classes.copyIcon}
                      data-testid='id-copy-button'
                      disableFocusRipple
                      disableRipple
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                ref: userIdRef,
                readOnly: true,
                'data-testid': 'user-id',
              }}
              defaultValue={uid}
              variant='outlined'
              size='small'
            />
          </div>
        )}
      </div>
    </div>
  );
};

const LoggedOut = ({ login }: { login: () => Promise<void> }): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <div data-testid='logged-out-warning' className={classes.loggedOutBox}>
        <h4 className={classes.invalidTitle}>
          You’ve been logged out. To continue log in again.
        </h4>
      </div>
      <button
        data-testid='login-button'
        onClick={() => login()}
        className={classes.loginButton}
      >
        <img src={LoginIcon} alt='log in' className={classes.buttonIcon} />
        <span>Log into Account</span>
      </button>
    </>
  );
};

const TokenExpired = ({
  login,
}: {
  login: () => Promise<void>;
}): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <div data-testid='token-expired-warning' className={classes.loggedOutBox}>
        <h4 className={classes.invalidTitle}>
          Your session expired, please login again.
        </h4>
      </div>
      <button
        data-testid='login-button'
        onClick={() => login()}
        className={classes.loginButton}
      >
        <RefreshIcon className={classes.buttonIcon} />
        <span>Retry Login</span>
      </button>
    </>
  );
};

const LogoComponent = (): JSX.Element => {
  const sharedCrmClasses = useCRMStyles();
  return (
    <div className={sharedCrmClasses.logoContainer}>
      <img src={ElliLogo} alt='elli logo' />
    </div>
  );
};

const WelcomeImageComponent = (): JSX.Element => {
  const sharedCrmClasses = useCRMStyles();
  return (
    <div className={sharedCrmClasses.welcomeImageContainer}>
      <img src={WelcomeImage} alt='Welcome' />
    </div>
  );
};

interface IOwnProps {
  verifyDisplayState: boolean;
  emailFromURL: string;
  width?: number | undefined;
  setDataLoading: (x: boolean) => void;
}

const WelcomePanel: React.FC<IOwnProps> = ({
  verifyDisplayState,
  emailFromURL,
  width,
  setDataLoading,
}) => {
  const classes = useStyles();
  const [isCCManager, setIsCCManager] = useState<boolean>(hasCCManagerRole());
  const [loggedIn, setLoggedIn] = useState<boolean>(isLoggedIn());
  const [tokenExpired, setTokenExpired] = useState<boolean>(isExpired());
  const [uid, setUid] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  useEffect(() => {
    const authService = new AuthService();
    if (authService.bearerToken && authService.idToken) {
      try {
        const decodedBearerToken = decodeToken(authService.bearerToken);
        const decodedIdToken = decodeToken(authService.idToken);
        setUid(decodedBearerToken.uid || '');
        setEmail(decodedIdToken.preferred_username || '');
      } catch (error) {
        // catch possible decode error
      }
    }
  });

  const logout = () => {
    AuthService.logout();
    setLoggedIn(false);
  };

  const login = async () => {
    const auth = new AuthService();
    try {
      await auth.fetchIdToken();
      const isManager = hasCCManagerRole();
      setIsCCManager(isManager);
      setLoggedIn(true);
      setTokenExpired(false);
    } catch (error) {
      setLoggedIn(false);
    }
  };

  if (loggedIn) {
    if (tokenExpired) {
      return (
        <div className={classes.welcomePanel}>
          <LogoComponent />
          <WelcomeImageComponent />
          <TokenExpired login={login} />
        </div>
      );
    } else if (isCCManager) {
      return (
        <div className={classes.welcomePanel}>
          <LogoComponent />
          <WelcomeImageComponent />
          <VerifyCustomer
            setDataLoading={setDataLoading}
            emailFromURL={emailFromURL}
            startOpen={verifyDisplayState}
            width={width}
            fullSizeButton={true}
          />
          <span
            data-testid='logout-button'
            className={classes.logoutLink}
            onClick={() => logout()}
          >
            Logout
          </span>
        </div>
      );
    } else {
      return (
        <div className={classes.invalidRoleWelcomePanel}>
          <LogoComponent />
          <WelcomeImageComponent />
          <InvalidRole uid={uid} email={email} />
        </div>
      );
    }
  }

  return (
    <div className={classes.welcomePanel}>
      <LogoComponent />
      <WelcomeImageComponent />
      <LoggedOut login={login} />
    </div>
  );
};

export default WelcomePanel;
