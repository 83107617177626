import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    cardImage: {
      width: '100%',
      borderRadius: '8px',
    },
  })
);

export default useStyles;
