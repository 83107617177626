import React, { useEffect, useMemo, useState } from 'react';

import connector, { IPropsFromState } from '../../Connector/Connector';
import { useCurrentTask } from '../../../custom-hooks/useCurrentTask';
import CardElement from '../../shared/CardElement/CardElement';
import { useTypedTranslation } from '../../../custom-hooks/useTypedTranslation';
import Details from './Details';
import useStyles from './useStyles';
import PdfViewer from '../../shared/PdfViewer';
import { RefundService } from '../../../services/refunds';

export type TOwnProps = IPropsFromState & {
  creditNoteId?: string;
};

const CreditNote: React.FC<TOwnProps> = ({ creditNoteId, invoicesState }) => {
  const classes = useStyles();
  const taskSid = useCurrentTask();
  const { getScopedTranslation } = useTypedTranslation('refunds');
  const [creditNotePdf, setCreditNotePdf] = useState<Blob | null>(null);

  useEffect(() => {
    if (!creditNoteId) {
      setCreditNotePdf(null);
      return;
    }

    const getPdf = async (): Promise<void> => {
      try {
        const { data } = await RefundService.getPdfForCreditNote(creditNoteId);
        setCreditNotePdf(data);
      } catch (e) {
        setCreditNotePdf(null);
      }
    };

    getPdf();
  }, [creditNoteId]);

  const creditNote = useMemo(() => {
    const { data } = invoicesState[taskSid];
    if (!data) {
      return undefined;
    }

    return data
      .flatMap(({ credit_notes }) => credit_notes || [])
      .find(({ id }) => id === creditNoteId);
  }, [creditNoteId, invoicesState, taskSid]);

  return (
    <CardElement
      title={getScopedTranslation('creditNote.headline')}
      isCollapsable={false}
    >
      <div className={classes.contentWrapper}>
        <Details creditNote={creditNote} />
        {creditNotePdf && <PdfViewer file={creditNotePdf} />}
      </div>
    </CardElement>
  );
};

export default connector(CreditNote);
