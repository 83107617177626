import React from 'react';

import useStyles from './useStyles';

export interface IInformationItem {
  headline: React.ReactNode | string;
  content: React.ReactNode | string | number;
  id: string;
}

export interface IOwnProps {
  item: IInformationItem;
  hasMarginRight?: boolean;
}

/**
 * Simple container for information used e.g on detail cards
 * @param headline information headline text
 * @param content element that represents the actual information
 */
const InformationItem: React.FC<IOwnProps> = ({
  item,
  hasMarginRight = true,
}) => {
  const classes = useStyles();
  const { headline, content, id } = item;

  return (
    <div
      className={`${classes.itemWrapper} ${
        hasMarginRight ? classes.marginRight : ''
      }`}
    >
      <span className={classes.itemTitle}>{headline}</span>
      <span className={classes.itemValue} data-testid={id}>
        {content}
      </span>
    </div>
  );
};

export default InformationItem;
