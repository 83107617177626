import React from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { useStyles } from './useStyles';

export enum MessageTypes {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export interface IMessage {
  type: MessageTypes;
  text: string;
}

export interface IOwnProps {
  message: IMessage;
}

const Message: React.FC<IOwnProps> = ({ message }) => {
  const classes = useStyles();
  const getAdditionalClass = () => {
    if (message.type === MessageTypes.ERROR) {
      return classes.error;
    }

    return classes.success;
  };

  const getIcon = () => {
    if (message.type === MessageTypes.ERROR) {
      return <ErrorOutlineIcon className={classes.icon} />;
    }
    return null;
  };

  return (
    <p
      className={`${classes.message} ${getAdditionalClass()}`}
      data-testid={`status-${MessageTypes[message.type].toLowerCase()}-message`}
    >
      {getIcon()} {message.text}
    </p>
  );
};

export default Message;
