import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 16,
    },
    headline: {
      lineHeight: 'normal',
      marginBottom: 16,
    },
    lineItemTable: {
      width: '100%',
      tableLayout: 'auto',
      marginBottom: 16,

      '& thead td': {
        paddingBottom: 8,
      },

      '& td:first-child': {
        paddingLeft: 0,
      },

      '& td': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        verticalAlign: 'middle',
        padding: 4,
        fontSize: theme.typography.body2.fontSize,
      },

      '& tr': {
        '&:nth-last-of-type(1) td': {
          paddingTop: 16,
        },

        '&:nth-last-of-type(2) td': {
          paddingBottom: 16,
        },
      },
    },
    amount: {
      width: 90,
      textAlign: 'right',
    },
    deleteColumn: {
      width: 20,
      justifyContent: 'flex-end',
    },
    deleteButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    deleteIcon: {
      color: theme.colorPrimary,
      fontSize: 20,
    },
    iconColumn: {
      width: 24,
    },
    divider: {
      marginTop: 16,
      marginBottom: 16,
    },
    centered: {
      display: 'flex',
      justifyContent: 'center',
    },
    asRows: {
      flexDirection: 'column',
      alignItems: 'center',

      '& > :not(:last-child)': {
        marginBottom: 16,
      },
    },
    loadingSpinner: {
      margin: 4,
    },
    totalAmountRow: {
      // theme.palette.divider color without transparency (white background) due to chrome bug for colspan borders
      borderTop: '1px solid #e0e0e0',
    },
  })
);

export default useStyles;
