import update from 'immutability-helper';
import { State } from '..';
import { ActionInterface } from '../actions';
import { CardOrderState } from '..';

export default (
  state: State,
  action: ActionInterface.ClearCardOrders
): State => {
  const updatedState: CardOrderState = update(state, {
    $unset: [action.taskSid],
  });

  return {
    ...updatedState,
  };
};
