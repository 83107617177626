import { ActionInterface } from '../actions';
import { IHomeStation } from '../../../../types/user';
import { ISortState } from '../../../../components/shared/AssetTable/AssetTable/AssetTable';
import { State } from '..';
import { sortFunctions } from '../../homeStations/reducers/sortHomeStations';
import update from 'immutability-helper';

export const generateSortFunction = (sortState: ISortState) => (
  a: IHomeStation,
  b: IHomeStation
): number => {
  const sortKey = sortState.sortByColumnKey as AdminOrderSortKey;
  if (sortKey) {
    const sortFunction = sortFunctions[sortKey];
    const ascendingSortResult = sortFunction({ a, b });

    return sortState.sortDesc ? ascendingSortResult * -1 : ascendingSortResult;
  } else {
    return 0;
  }
};

type AdminOrderSortKey = keyof typeof sortFunctions;

export default (
  state: State,
  action: ActionInterface.SortOrganizationStations
): State => {
  const { sortState, taskSid } = action;
  const currentOrganizationState = state[taskSid];

  if (currentOrganizationState && currentOrganizationState.stations) {
    const sortedStations: IHomeStation[] =
      currentOrganizationState.stations.stations;
    sortedStations.sort(generateSortFunction(sortState));

    const updatedOrganization = update(currentOrganizationState, {
      stations: {
        $set: currentOrganizationState.stations,
      },
      loading: {
        $set: false,
      },
    });

    return {
      ...state,
      [action.taskSid]: updatedOrganization,
    };
  }

  return {
    ...state,
  };
};
