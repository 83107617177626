import React from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import MenuItem from '@material-ui/core/MenuItem';
import { Select } from '@material-ui/core';

import SelectControl from './SelectControl';
import { ISelectOption } from './index';
import useStyles from './useStyles';

export interface IOwnProps {
  labelText: string;
  name: string;
  control: UseFormMethods['control'];
  labelId: string;
  label: string;
  options: ISelectOption[];
  isRequired?: boolean;
  hasError?: boolean;
}

/**
 * Wraps select field with react-hook-form controller
 */
const ControlledSelect: React.FC<IOwnProps> = ({
  labelText,
  name,
  control,
  labelId,
  label,
  options,
  isRequired = false,
  hasError = false,
}) => {
  const classes = useStyles();
  const customSelect = (
    <Select
      labelId={labelId}
      label={label}
      data-testid={`${name}-select`}
      classes={{
        icon: classes.selectIcon,
      }}
    >
      {options.map(({ key, value: option, content: text }) => (
        <MenuItem key={key} value={option}>
          {text}
        </MenuItem>
      ))}
    </Select>
  );

  return (
    <SelectControl
      labelText={labelText}
      name={name}
      isRequired={isRequired}
      hasError={hasError}
    >
      <Controller as={customSelect} name={name} control={control} />
    </SelectControl>
  );
};

export default ControlledSelect;
