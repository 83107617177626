import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    locationWrapper: {
      marginTop: 32,
    },
    chargingSessionsHeader: {
      fontSize: 16,
      fontWeight: 900,
      lineHeight: 1.38,
      marginBottom: 7,
    },
    outerItemWrapper: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      flexFlow: 'row wrap',
    },
    itemWrapper: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      marginBottom: 24,
      width: 200,
      flex: '0 0 33%',
      '&:nth-child(2)': {
        flex: '0 0 66%',
      },
      '&:last-child': {
        flex: '0 0 100%',
        marginBottom: 0,
      },
    },
    itemTitle: {
      fontSize: 12,
      color: '#878c96',
      marginBottom: 10,
      lineHeight: 1.33,
    },
    itemValue: {
      fontSize: 14,
      display: 'flex',
      alignItems: 'center',
      maxWidth: '100%',
    },
  })
);

export default useStyles;
