import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    profileElement: {
      marginBottom: 30,
    },
    chargingSessionsHeader: {
      fontSize: 16,
      fontWeight: 900,
      lineHeight: 1.38,
      marginBottom: 7,
      display: 'flex',
      alignItems: 'center',
    },
    refreshIcon: {
      color: theme.colorPrimary,
      cursor: 'pointer',
      fontSize: 20,
      marginLeft: 4,
    },
    stationRefreshing: {
      opacity: 0.5,
    },
    circleProgress: {
      color: theme.colorAccent,
      marginLeft: 6,
    },
  })
);

export default useStyles;
