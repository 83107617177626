import update from 'immutability-helper';
import { State } from '../';
import { IAddressModel, ISubscriberModel } from '../../../../types/subscriber';
import { ActionInterface } from '../actions';

export const updateBillingAddressSuccess = (
  state: State,
  action: ActionInterface.UpdateBillingAddressSuccess
): State => {
  const { subscriberAddress, taskSid } = action;
  const currentSubscriber = state[taskSid];

  const createUpdatedAddress = (
    currentAddress: ISubscriberModel['address']
  ) => {
    return {
      ...(currentAddress as IAddressModel),
      ...subscriberAddress,
    };
  };

  const updatedSubscriber = update(currentSubscriber, {
    data: {
      address: {
        $apply: createUpdatedAddress,
      },
    },
  });

  return {
    ...state,
    [action.taskSid]: updatedSubscriber,
  };
};
