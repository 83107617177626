/* eslint-disable @typescript-eslint/naming-convention */
import JwtDecode from 'jwt-decode';

interface IClaims {
  roles: string[];
}

interface IJwtDecoded {
  name: string;
  uid: string;
  claims: IClaims;
  aud: string;
  exp: number;
  iat: number;
  iss: string;
  sub: string;
  preferred_username?: string;
}

export default function(token: string) {
  try {
    return JwtDecode<IJwtDecoded>(token);
  } catch (error) {
    throw new Error('Invalid token provided');
  }
}
