import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import useStyles from './useStyles';

export const WeChargeDataPage: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const commonText = (
    <React.Fragment>
      <Typography paragraph variant='inherit'>
        <strong>Personal data:</strong> VIN, Email, address of customer.
      </Typography>
      <Typography>Contact</Typography>
      <Typography paragraph variant='inherit'>
        <strong>Reported by:</strong> User/Agent who is creating the ticket.
        <br />
        <strong>Call Code:</strong> E-Mail
      </Typography>
      <Typography>Categorization</Typography>
      <Typography paragraph variant='inherit'>
        <strong>Reported CI:</strong> CIC_WECONNECT_CHARGING
      </Typography>
      <Typography>Assignment</Typography>
      <Typography paragraph variant='inherit'>
        <strong>Assignment Group:</strong> MOS We Charge Support VW
      </Typography>
    </React.Fragment>
  );
  return (
    <React.Fragment>
      <Typography color='textPrimary' paragraph variant='inherit'>
        Therefore, please create a ticket within the{' '}
        <a href='https://sc3.vwgroup.com/sc3/index.do?lang=en'>
          Service Center 3 (SC3)
        </a>{' '}
        with the following mandatory information:
      </Typography>
      <div className={classes.dataBoxGrey}>
        <h3>
          <strong>REVOCATION:</strong>
        </h3>
        <Typography paragraph variant='inherit'>
          <strong>Title:</strong> Revocation: Unlock VW VIN again for customer
          (within 14 days period)
        </Typography>
        <Typography paragraph variant='inherit'>
          <strong>Description:</strong> Revocation: Unlock VW VIN again for
          customer (within 14 days period). Approved by Elli.
        </Typography>
        {commonText}

        <h3>
          <strong>TARIFF UPGRADES:</strong>
        </h3>
        <Typography paragraph variant='inherit'>
          <strong>Title:</strong> Extraordinary Cancellation: Unlock VW VIN
          again for customer for tariff upgrade.
        </Typography>
        <Typography paragraph variant='inherit'>
          <strong>Description:</strong> Extraordinary Cancellation: Unlock VW
          VIN again for customer for tariff upgrade. Approved by Elli.
        </Typography>
        {commonText}
      </div>
    </React.Fragment>
  );
};
