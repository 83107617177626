import { ErrorObject } from 'serialize-error';
import { IRFIDCardModel } from '../../../types/subscriber';

export interface IChargingCardModel {
  loading: boolean;
  data: IRFIDCardModel[] | null;
  error: ErrorObject | null;
  request: { subscriberId: string } | null;
  currentCard: IRFIDCardModel | null;
}

export interface IChargingCardState {
  [id: string]: IChargingCardModel;
}

const state: IChargingCardState = {};

export type State = typeof state;
export default state;
