import update from 'immutability-helper';
import { State } from '..';
import { ActionInterface } from '../actions';
import serializeError from 'serialize-error';

export default (
  state: State,
  action: ActionInterface.GetUserSearchError
): State => {
  const { error, taskSid } = action;
  const currentUser = state[taskSid];

  const updatedUser = update(currentUser, {
    error: {
      $set: serializeError(error),
    },
    loading: {
      $set: false,
    },
  });

  return {
    ...state,
    [action.taskSid]: updatedUser,
  };
};
