import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';

import { useCurrentTask } from '../../../custom-hooks/useCurrentTask';
import { useTypedTranslation } from '../../../custom-hooks/useTypedTranslation';
import { IUserModel } from '../../../types/user';
import { formatDateNoTime } from '../../../utils/dates';
import { getCountry } from '../../../utils/getCountryFromCode';
import connector, { IPropsFromState } from '../../Connector/Connector';
import { DealershipLogo } from '../../DealershipLogo/DealershipLogo';
import CardElement from '../../shared/CardElement/CardElement';
import InformationItem from '../../shared/InformationItems/InformationItem';
import UserVerified from '../../shared/UserVerified/UserVerified';
import Tooltip from '../../ui/Tooltip';
import { UserIds } from './UserIds';
import useStyles from './useStyles';

interface IDealershipName {
  [id: string]: string;
}

export const dealershipName: IDealershipName = {
  Ee4qUdsuePsfg3wj1NuL: 'Volkswagen',
  FUt6cGL7Muvu9aVRZDLL: 'Elli',
  BMJ7MQCdw1ChOg5tFmEJ: 'Elli',
  LgWQZjLJPb7lNaIwnPKQ: 'Seat',
  JMJn0x1539PRL3Biefz3: 'Seat',
  eSMMKx84NriRMEpCapIu: 'WeCharge',
  pkEp7dW3rzepQWc40Tg0: 'WeCharge',
  ursWWtYQQ0QQahA0DFNv: 'Volkswagen',
  '6vAHOeO0oWltnhNKD1c2': 'Skoda',
  waZpfLWcTLPVMDg3WlwP: 'Skoda',
};

type TProps = IPropsFromState & {
  crumbId?: string;
};

const getFullName = (data: IUserModel): string => {
  if (data.first_name && data.last_name) {
    return `${data.first_name} ${data.last_name}`;
  }

  return '-';
};

export const UserUI: React.FC<TProps> = ({ userState, subscriberState }) => {
  const taskSid = useCurrentTask();
  const { t, terms } = useTypedTranslation();
  const classes = useStyles();
  const user = userState && userState[taskSid];
  const subscriber = subscriberState && subscriberState[taskSid];

  const [userId, setUserId] = useState<string>('');
  const [subscriberId, setSubscriberId] = useState<string>('');
  const [customerNumber, setCustomerNumber] = useState<string>('');

  useEffect(() => {
    if (user && user.data) {
      setUserId(user.data.id);
    }

    if (subscriber && subscriber.data) {
      setSubscriberId(subscriber.data.id);
      setCustomerNumber(subscriber.data.customer_number || '-');
    }
  }, [user, subscriber]);

  const getItems = (userData: IUserModel) => {
    return [
      {
        id: 'customer-name',
        headline: t(terms.user_profile.name),
        content: getFullName(userData),
      },
      {
        id: 'customer-email-title',
        headline: (
          <>
            {t(terms.user_profile.email)}
            <div className={classes.tooltipWrapper}>
              <Tooltip tooltipName='more-info'>
                <UserIds
                  userId={userId}
                  subscriberId={subscriberId}
                  customerNumber={customerNumber}
                />
              </Tooltip>
            </div>
          </>
        ),
        content: userData.email || '-',
      },
      {
        id: 'customer-account-creation',
        headline: t(terms.user_profile.customer_account_created),
        content: (
          <Typography data-testid='customer-account-creation'>
            {userData.created_at ? formatDateNoTime(userData.created_at) : '-'}
          </Typography>
        ),
      },
      {
        id: 'customer-last-login',
        content: userData.last_login
          ? formatDateNoTime(userData.last_login)
          : '-',
        headline: t(terms.user_profile.customer_last_login),
      },

      {
        id: 'customer-ecosystem',
        headline: t(terms.user_profile.ecosystem),
        content: (
          <>
            {userData.tenant_id && (
              <DealershipLogo
                id={userData.tenant_id}
                width='20px'
                height='20px'
              />
            )}
            <p className={classes.ecosystemView}>
              {userData.tenant_id ? dealershipName[userData.tenant_id] : '-'}
            </p>
          </>
        ),
      },
      {
        id: 'customer-country',
        headline: t(terms.user_profile.signup_country),
        content: userData.country ? getCountry(userData.country) : '-',
      },
    ];
  };

  return (
    <div className={classes.contentWrapper} data-testid='user-ui-component'>
      <UserVerified />
      <CardElement title={t(terms.user_profile.box_title)}>
        {user.data && (
          <Grid container className={classes.detailWrapper}>
            {getItems(user.data).map((item) => (
              <Grid item xs={6} key={item.id}>
                <InformationItem item={item} />
              </Grid>
            ))}
          </Grid>
        )}
      </CardElement>
    </div>
  );
};

export default connector(UserUI);
