import React, { useState } from 'react';
import { Resolver, useForm, UseFormOptions } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Divider,
  FormHelperText,
  Grid,
  GridSize,
  Typography,
} from '@material-ui/core';

import connector, { IPropsFromState } from '../../../Connector/Connector';
import useStyles from './useStyles';
import DialogHeadline from '../DialogHeadline';
import MultilineButton from '../../Buttons/MultilineButton';
import InputField, {
  IOwnProps as IInputFieldProps,
} from '../../Forms/InputField';
import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';
import ControlledSelect, {
  IOwnProps as IControlledSelect,
} from '../../Forms/SelectField/ControlledSelect';
import { validationSchema } from './validationSchema';
import {
  getDefaultValues,
  getTranslatedFields,
  IRfidCardOrderFields,
} from './fields';
import { RfidCardOrdersService } from '../../../../services/rfidCardOrders';
import { useCurrentTask } from '../../../../custom-hooks/useCurrentTask';
import { ISubscriberModel } from '../../../../types/subscriber';
import { IUserModel } from '../../../../types/user';

type SubmitState = 'submitted' | 'failed' | 'open';

const formOptions: UseFormOptions<IRfidCardOrderFields> = {
  resolver: yupResolver(validationSchema) as Resolver<IRfidCardOrderFields>,
  mode: 'onChange',
};

export interface IOwnProps extends IPropsFromState {
  subscriber: ISubscriberModel;
  user: IUserModel | null;
  onSuccess(): void;
}

/**
 * Creates a form to order a new RFID card for user
 */
const OrderRfidCardForm: React.FC<IOwnProps> = ({
  subscriber,
  user,
  onSuccess,
  subscriptionState,
  getRFIDCardOrderRequest,
}) => {
  const taskSid = useCurrentTask();
  const classes = useStyles();
  const { getScopedTranslation } = useTypedTranslation('order_card_dialog');
  const [submitState, setSubmitState] = useState<SubmitState>('open');
  const { textFields, selectFields } = getTranslatedFields(
    getScopedTranslation
  );

  const { register, watch, control, handleSubmit, errors, formState } = useForm<
    IRfidCardOrderFields
  >({ ...formOptions, defaultValues: getDefaultValues(subscriber, user) });

  const isCustomReasonFieldRequired = watch('reason') === 'other';
  const isDisabledSubmit =
    !Object.keys(formState.touched).length ||
    !formState.isValid ||
    formState.isSubmitting;

  const hasError = (field: keyof IRfidCardOrderFields) => !!errors[field];

  const onSubmit = async (data: IRfidCardOrderFields) => {
    const subscriptions = subscriptionState[taskSid].data || [];
    const activeSubscription = subscriptions.find(
      ({ status }) => status === 'active'
    );

    try {
      await RfidCardOrdersService.orderRfidCard(
        subscriber.id,
        data,
        activeSubscription?.id
      );
      setSubmitState('submitted');
      getRFIDCardOrderRequest({ subscriberId: subscriber.id, taskSid });
      onSuccess();
    } catch (e) {
      setSubmitState('failed');
    }
  };

  const createTextInputField = (
    data: IInputFieldProps,
    size: GridSize = 12
  ) => (
    <Grid item xs={size}>
      <InputField
        {...data}
        key={data.id}
        hasError={hasError(data.name as keyof IRfidCardOrderFields)}
        inputRef={register}
      />
    </Grid>
  );

  const createControlledSelectField = (
    selectData: Omit<IControlledSelect, 'control'>,
    size: GridSize = 12
  ) => (
    <Grid item xs={size}>
      <ControlledSelect
        {...selectData}
        control={control}
        hasError={!!errors[selectData.name as keyof IRfidCardOrderFields]}
      />
    </Grid>
  );

  const getAdditionalInformationFields = () => (
    <>
      <Grid item xs={12}>
        <Typography variant={'h4'}>
          {getScopedTranslation('additional_information')}
        </Typography>
      </Grid>
      {createControlledSelectField(selectFields.reason)}
      {isCustomReasonFieldRequired &&
        createTextInputField(textFields.otherReason)}
    </>
  );

  const getShippingAddressFields = () => (
    <>
      <Grid item xs>
        <Typography variant={'h4'}>
          {getScopedTranslation('shipping_address')}
        </Typography>
      </Grid>

      {createTextInputField(textFields.name)}
      {createTextInputField(textFields.addressLine1)}
      {createTextInputField(textFields.addressLine2)}

      <Grid container item spacing={2}>
        <Grid item xs>
          {createTextInputField(textFields.zip)}
        </Grid>
        <Grid item xs={8}>
          {createTextInputField(textFields.city)}
        </Grid>
      </Grid>

      <Grid container item spacing={2}>
        {createTextInputField(textFields.state, 6)}
        {createControlledSelectField(selectFields.country, 6)}
      </Grid>
    </>
  );

  return (
    <div className={classes.outerWrapper}>
      <div>
        <DialogHeadline
          headline={getScopedTranslation('headline')}
          subHeadline={getScopedTranslation('sub_headline')}
        />
      </div>

      <form
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Grid container spacing={2}>
          {getShippingAddressFields()}

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {getAdditionalInformationFields()}

          <Grid item xs={12} className={classes.submitWrapper}>
            <MultilineButton
              mainText={getScopedTranslation('submit')}
              isDisabled={isDisabledSubmit}
            />
            {submitState === 'failed' && (
              <FormHelperText className={classes.errorMessage}>
                {getScopedTranslation('request_failed')}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default connector(OrderRfidCardForm);
