import { IChargingCardModel, State } from '..';

import { ActionInterface } from '../actions';
import update from 'immutability-helper';

export default (
  state: State,
  action: ActionInterface.SetCurrentCard
): State => {
  const { card, taskSid } = action;

  const chargingCardState = state[taskSid] || {
    loading: false,
    data: null,
    error: null,
    request: null,
    currentCard: null,
  };

  const updatedChargingCardState: IChargingCardModel = update(
    chargingCardState,
    {
      currentCard: {
        $set: card,
      },
    }
  );

  return {
    ...state,
    [taskSid]: updatedChargingCardState,
  };
};
