import {
  ICardOrderModel,
  IRFIDCardModel,
  ISubscriberModel,
  ISubscriptionModel,
  ITariffModel,
} from '../types/subscriber';

import ApiService from './api';
import { IChargingSessionModel } from './../types/chargingsession';

interface ISubscriberRequestParams {
  userId: string;
}

interface ISubscriptionRequestParams {
  subscriberId: string;
}

interface ICardOrderRequestParams {
  subscriberId: string;
}

const list = async (): Promise<any> => {
  const apiService = new ApiService('/mgmt/customer/subscribers/iamid/');
  const response = await apiService.query();
  return response.data;
};

const getById = async ({
  userId,
}: ISubscriberRequestParams): Promise<ISubscriberModel> => {
  const apiService = new ApiService(
    `/mgmt/customer/subscribers/iamid/${userId}`
  );
  const response = await apiService.query();
  return response.data;
};

const getChargeRecords = async (
  subscriber_id: string
): Promise<IChargingSessionModel[]> => {
  const apiService = new ApiService(
    `/mgmt/customer/subscribers/${subscriber_id}/chargerecords`,
    {
      data: {
        limit: 50,
      },
    }
  );
  const response = await apiService.query();
  let data = response.data;

  const totalCount = response.headers['x-total-count'];

  for (let i = 50; i <= totalCount; i = i + 50) {
    const newApiService = new ApiService(
      `/mgmt/customer/subscribers/${subscriber_id}/chargerecords`,
      {
        data: {
          limit: 50,
          offset: i,
        },
      }
    );
    const newResponse = await newApiService.query();
    data = [...data, ...newResponse.data];
  }

  return data;
};

interface IRFIDCardOrderRequest {
  subscriberId: string;
}

const getRFIDCards = async ({
  subscriberId,
}: IRFIDCardOrderRequest): Promise<IRFIDCardModel[]> => {
  const apiService = new ApiService(
    `/mgmt/customer/subscribers/${subscriberId}/rfidcards`
  );
  const response = await apiService.query();
  return response.data;
};

const getRFIDCardOrders = async ({
  subscriberId,
}: ICardOrderRequestParams): Promise<ICardOrderModel> => {
  const apiService = new ApiService(
    `/mgmt/customer/subscribers/${subscriberId}/rfidcardorders`
  );
  const response = await apiService.query();
  return response.data;
};

const getSubscriptions = async ({
  subscriberId,
}: ISubscriptionRequestParams): Promise<ISubscriptionModel> => {
  const apiService = new ApiService(
    `/mgmt/customer/subscribers/${subscriberId}/subscriptions`
  );
  const response = await apiService.query();
  return response.data;
};

const getTariffs = async (): Promise<ITariffModel[]> => {
  const apiService = new ApiService(`/mgmt/customer/tariffs`);
  const response = await apiService.query();
  return response.data;
};

export const SubscribersService = {
  list,
  getById,
  getChargeRecords,
  getRFIDCards,
  getRFIDCardOrders,
  getSubscriptions,
  getTariffs,
};
