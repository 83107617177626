import React from 'react';
import MaterialPagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import useStyles from './useStyles';

interface IOwnProps {
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  pagesLength: number;
  small: boolean;
}

export const Navigation: React.FC<IOwnProps> = ({
  currentPage,
  setCurrentPage,
  pagesLength,
  small,
}) => {
  const classes = useStyles();

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const styleProps = {
    style: {},
  };

  if (small) {
    styleProps.style = {
      margin: '0 2px',
      padding: '0 4px',
      fontSize: 14,
    };
  }

  return (
    <MaterialPagination
      classes={{
        root: classes.paginationRoot,
      }}
      data-testid='material-pagination'
      renderItem={(item) => (
        <PaginationItem
          {...item}
          {...styleProps}
          data-testid='pagination-item'
          classes={{
            root: classes.itemRoot,
            selected: classes.itemSelected,
            page: classes.itemPage,
            ellipsis: classes.itemEllipsis,
            icon: classes.itemIcon,
          }}
        />
      )}
      count={pagesLength}
      page={currentPage}
      onChange={handleChange}
    />
  );
};

export default Navigation;
