import { ITerminateSubscriptionResponse } from '../../../../services/subscriptions';
import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useStyles from './useStyles';

export type OwnProps = {
  terminationResponse: ITerminateSubscriptionResponse;
};

export const MKitIds: React.FC<OwnProps> = ({ terminationResponse }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <div>
      <Typography variant='inherit'>
        IAM ID: {terminationResponse.iam_id}
      </Typography>
      <br />
      <Typography variant='inherit'>
        Tariff Code: {terminationResponse.tariff_code}
      </Typography>
      <br />
      <Typography variant='inherit'>
        VIN: {terminationResponse.vehicle_identification_number}
      </Typography>
      <br />
      <div>
        <Typography variant='inherit'>MKIT IDs:</Typography>
        <div className={classes.mkitIds}>
          <Typography variant='inherit'>
            subscription_id:{' '}
            {terminationResponse.connection_layer_subscription_id}
          </Typography>
          <br />
          <Typography variant='inherit'>
            mkit_subscriber_id:{' '}
            {terminationResponse.connection_layer_subscriber_id}
          </Typography>
          <br />
          <Typography variant='inherit'>
            mkit_subscriber_id_type:{' '}
            {terminationResponse.connection_layer_subscriber_id_type}
          </Typography>
          <br />
          <Typography variant='inherit'>
            mkit_customer_id: {terminationResponse.connection_layer_customer_id}
          </Typography>
          <br />
          <Typography variant='inherit'>
            mkit_customer_id_type:{' '}
            {terminationResponse.connection_layer_customer_id_type}
          </Typography>
        </div>
      </div>
    </div>
  );
};
