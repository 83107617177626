import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    rootContainer: {
      width: '473px',
      maxWidth: 473,
    },
  })
);

export default useStyles;
