import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    chargingCardDetailsHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '16px',
    },

    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },

    titleIcon: {
      color: '#6633CB !important',
      fontSize: '24px !important',
      marginRight: '16px !important',
    },

    panelTitle: {
      fontSize: '20px !important',
    },

    chargingSessionsTableWrapper: {},
  })
);

export default useStyles;
