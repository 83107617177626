import React from 'react';
import useStyles from './useStyles';

export interface IOwnProps {
  icon: JSX.Element;
  text: string;
}

const SelectOptionWithIcon: React.FC<IOwnProps> = ({ icon, text }) => {
  const classes = useStyles();
  return (
    <div className={classes.option}>
      <span className={classes.iconWrapper}>{icon}</span>
      <p>{text}</p>
    </div>
  );
};

export default SelectOptionWithIcon;
