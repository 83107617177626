import decodeToken from './decodeToken';

const CC_MANAGER = 'cc.manager';
const CC_SUPERVISOR = 'cc.supervisor';

export const isLoggedIn = (): boolean => !!sessionStorage.getItem('bearer');

const hasRole = (role: string): boolean => {
  const token = sessionStorage.getItem('bearer');

  if (!token) {
    return false;
  }

  try {
    const decodedToken = decodeToken(token);
    return decodedToken.claims.roles.includes(role);
  } catch (error) {
    return false;
  }
};

export const isCCSupervisor = (): boolean => hasRole(CC_SUPERVISOR);
export const isCCManager = (): boolean => hasRole(CC_MANAGER);

export const isExpired = (): boolean => {
  const token = sessionStorage.getItem('bearer');

  if (token) {
    try {
      const decodedToken = decodeToken(token);
      const expiry = decodedToken.exp;

      return expiry * 1000 < Date.now();
    } catch (error) {
      return false;
    }
  }

  return false;
};
