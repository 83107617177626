import { ActionCreator, ActionInterface, ActionType } from './actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import { ILocationModel } from '../../../types/chargingsession';
import { LocationService } from '../../../services/locations';

export function* chargingStationSaga() {
  function* getChargingStation(
    action: ActionInterface.GetChargingStationRequest
  ) {
    try {
      const chargingStation: ILocationModel[] = yield call(
        LocationService.getById,
        action.evse_id
      );

      yield put<ActionInterface.GetChargingStationSuccess>(
        ActionCreator.GetChargingStationSuccess({
          data: chargingStation[0] || null,
          taskSid: action.taskSid,
          evse_id: action.evse_id,
        })
      );
    } catch (error) {
      yield put<ActionInterface.GetChargingStationError>(
        ActionCreator.GetChargingStationError({
          error,
          taskSid: action.taskSid,
        })
      );
    }
  }

  function* getNearbyLocations(
    action: ActionInterface.GetNearbyLocationsRequest
  ) {
    try {
      const nearbyLocations: ILocationModel[] = yield call(
        LocationService.getNearbyLocations,
        action.location
      );

      yield put<ActionInterface.GetNearbyLocationsSuccess>(
        ActionCreator.GetNearbyLocationsSuccess({
          nearbyLocations: nearbyLocations,
          evse_id: action.evse_id,
          taskSid: action.taskSid,
        })
      );
    } catch (error) {
      yield put<ActionInterface.GetChargingStationError>(
        ActionCreator.GetChargingStationError({
          error,
          taskSid: action.taskSid,
        })
      );
    }
  }

  yield all([
    takeEvery(ActionType.GetChargingStationRequest, getChargingStation),
    takeEvery(ActionType.GetNearbyLocationsRequest, getNearbyLocations),
  ]);
}

export default chargingStationSaga;
