import update from 'immutability-helper';
import { State } from '../';
import { ActionInterface } from '../actions';

export default (
  state: State,
  action: ActionInterface.ClearSubscriber
): State => {
  const { taskSid } = action;
  const currentUser = state[taskSid];

  const updatedState = update(currentUser, {
    searchData: {
      $set: null,
    },
  });

  return {
    ...state,
    [action.taskSid]: updatedState,
  };
};
