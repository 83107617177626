import {
  AssetTable,
  IColumn,
  IRow,
} from '../../shared/AssetTable/AssetTable/AssetTable';
import {
  addBreadcrumbHandler,
  IBreadcrumbHandlerProps,
} from '../../../utils/addBreadcrumbHandler';
import { IRFIDCardModel, ISubscriptionModel } from '../../../types/subscriber';
import { IconButton, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import connector, { IPropsFromState } from '../../Connector/Connector';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { IChargingCardModel } from '../../../store/state/chargingCards';
import {
  getSubscriptionStatus,
  StatusIndicator,
} from '../../CardsUI/StatusIndicator/StatusIndicator';
import { TariffService } from '../../../services/tariffs';
import { formatIsoDates } from '../../../utils/dates';
import {
  IFieldNames,
  mapFieldsForTable,
} from '../../../utils/mapFieldsForTable';
import useSharedStyles from '../../shared/useSharedStyles';
import { sortRequestHandler as sortStateHandler } from '../../../utils/sortRequestHander';

import subscriptionsImage from '../../../assets/image/subscriptions.svg';
import { useCurrentTask } from '../../../custom-hooks/useCurrentTask';
import useStyles from './useStyles';

type TOwnProps = IPropsFromState & {
  crumbId?: string;
};

export interface ISortState {
  sortByColumnKey: string | null;
  sortDesc: boolean;
}

type ISubscriptionsCollectionRow = ISubscriptionModel & {
  tariff_name: string;
  linked_charging_card: string;
};

const fieldNames: IFieldNames = {
  tariff_name: {
    name: 'Tariff Name',
    width: '165px',
  },
  status: {
    name: 'Status',
    width: '100px',
  },
  start_date: {
    name: 'Start Date',
  },
  preliminary_end: {
    name: 'Preliminary End',
  },
  linked_charging_card: {
    name: 'Linked Charging Card',
  },
  actions: {
    name: '',
    width: '36px',
  },
};

const addClickableLink = (data: string): string => {
  return data === '-' ? '' : 'clickableCell';
};

const notEmptyArray = (value: IChargingCardModel): boolean | null => {
  return value && value.data && value.data.length > 0;
};

const getTariff = async (tariff_id: string, base_condition_id: string) => {
  try {
    const tariff = await TariffService.getByConditionId(
      tariff_id,
      base_condition_id
    );
    return tariff;
  } catch (error) {
    return null;
  }
};

const createDataForTable = (
  subscriptions: ISubscriptionModel[],
  chargingCards: IChargingCardModel
): Promise<ISubscriptionsCollectionRow[]> => {
  return Promise.all(
    subscriptions.map(async (cs) => {
      const row: ISubscriptionsCollectionRow = {
        ...cs,
        tariff_name: '-',
        linked_charging_card: '-',
      };

      const tariff = await getTariff(cs.tariff_id, cs.base_condition_id);

      if (tariff) {
        row.tariff_name = tariff.name;
      }

      if (notEmptyArray(chargingCards)) {
        const linkedCard = (chargingCards.data as IRFIDCardModel[]).find(
          (item) => cs.id === item.subscription_id
        );

        if (linkedCard) {
          row.linked_charging_card = linkedCard.number;
        }
      }

      return row;
    })
  );
};

const getColumns = (): IColumn[] => {
  const sortableColumns: string[] = [
    'tariff_name',
    'status',
    'start_date',
    'preliminary_end',
    'linked_charging_card',
  ];

  return mapFieldsForTable(fieldNames, sortableColumns);
};

export const SubscriptionsCollection: React.FC<TOwnProps> = ({
  chargingCardState,
  addBreadcrumb,
  breadcrumbState,
  subscriptionState,
  sortSubscriptions,
  crumbId,
}) => {
  const taskSid = useCurrentTask();
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const chargingCards = chargingCardState && chargingCardState[taskSid];
  const subscriptions = subscriptionState && subscriptionState[taskSid];
  const taskBreadcrumbState = breadcrumbState && breadcrumbState[taskSid];

  const [rows, setRows] = useState<IRow[]>([]);

  const [sortState, setSortState] = useState<ISortState>({
    sortByColumnKey: 'start_date',
    sortDesc: false,
  });

  const sortRequestHandler = (columnkey: string | null): void => {
    sortStateHandler(columnkey, sortState, setSortState);
  };

  const breadcrumbHandler = (props: IBreadcrumbHandlerProps): void =>
    addBreadcrumbHandler({
      ...props,
      addBreadcrumb,
      taskSid,
      crumbId,
      taskBreadcrumbState,
    });

  const getCardNumber = (id: string | undefined): string | JSX.Element => {
    if (
      chargingCards &&
      chargingCards.data &&
      chargingCards.data.length > 0 &&
      id
    ) {
      const card = chargingCards.data.find((cc) => cc.subscription_id === id);
      if (card) {
        return (
          <span
            data-testid='linked-charging-card'
            className={addClickableLink(card.id)}
            onClick={() =>
              breadcrumbHandler({
                component: 'ChargingCardDetails',
                friendlyText: 'Charging Card',
                id: card.id,
              })
            }
          >
            {card.number}
          </span>
        );
      }
    }
    return <span data-testid='linked-charging-card'>-</span>;
  };

  const getRows = async (): Promise<void> => {
    if (
      !(subscriptions && subscriptions.data && subscriptions.data.length > 0)
    ) {
      return;
    }

    const dataForTable: ISubscriptionsCollectionRow[] = await createDataForTable(
      subscriptions.data,
      chargingCards
    );

    const tableRows = dataForTable.map((cs: ISubscriptionsCollectionRow) => {
      return {
        data: {
          tariff_name: {
            content: cs.tariff_name,
          },
          status: {
            content: (
              <div className={classes.statusWrapper}>
                <StatusIndicator
                  status={getSubscriptionStatus(cs)}
                  className={classes.activationStatus}
                />
                <Typography className={classes.statusText} variant='body2'>
                  {getSubscriptionStatus(cs)}
                </Typography>
              </div>
            ),
          },
          start_date: {
            content: cs.start_date ? formatIsoDates(cs.start_date) : '-',
          },
          preliminary_end: {
            content: cs.end_date ? formatIsoDates(cs.end_date) : '-',
          },
          linked_charging_card: {
            content: getCardNumber(cs.id),
          },
          actions: {
            content: (
              <IconButton
                onClick={() =>
                  breadcrumbHandler({
                    component: 'SubscriptionDetails',
                    friendlyText: 'Subscription',
                    id: cs.id,
                  })
                }
                color='primary'
              >
                <ChevronRightIcon fontSize='large' />
              </IconButton>
            ),
          },
        },
        id: cs.id,
      };
    });

    setRows(tableRows);
  };

  useEffect(() => {
    getRows();
  }, [subscriptionState]);

  useEffect(() => {
    sortSubscriptions({
      taskSid,
      sortState,
      chargingCards: chargingCards.data as IRFIDCardModel[],
      tariffs: [],
    });
  }, [sortState, taskSid, sortSubscriptions]);

  return (
    <div
      className={sharedClasses.cardElement}
      data-testid='subsriptions-collection-ui-component'
    >
      <div className={sharedClasses.sectionWrap}>
        <div className={classes.logoContainer}>
          <img src={subscriptionsImage} alt='subscriptions' />
        </div>
        <Typography classes={{ root: sharedClasses.sectionTitle }} variant='h2'>
          Subscriptions Collection
        </Typography>
      </div>
      <section>
        <AssetTable
          bulkActions={[]}
          columns={getColumns()}
          rows={rows}
          sortingState={sortState}
          sortRequestHandler={sortRequestHandler}
          noDataTitle='No subscriptions for this account'
          noDataSubTitle='User has not yet signed up for a subscription.'
        />
      </section>
    </div>
  );
};

export default connector(SubscriptionsCollection);
