import AccountsCollection from '../components/CollectionCards/Accounts';
import ActiveSessionDetails from '../components/DetailCards/ActiveSession';
import { BreadcrumbModel } from '../store/state/breadcrumbs';
import CardsUI from '../components/CardsUI/CardsUI';
import ChargingCardDetails from '../components/DetailCards/ChargingCard';
import ChargingCardsCollection from '../components/CollectionCards/ChargingCards';
import ChargingSessionDetails from '../components/DetailCards/ChargeRecord';
import ChargingSessionsCollection from '../components/CollectionCards/ChargingSessions';
import ChargingStationDetails from '../components/DetailCards/ChargingStation';
import ChargingStationsCollection from '../components/CollectionCards/ChargingStations';
import ContractsUI from '../components/ContractsUI/ContractsUI';
import InvoiceCollection from '../components/CollectionCards/Invoices';
import InvoiceDetails from '../components/DetailCards/Invoice';
import OrdersCollection from '../components/CollectionCards/Orders';
import React from 'react';
import SubscriptionDetails from '../components/DetailCards/Subscription/SubscriptionDetails/SubscriptionDetails';
import SubscriptionsCollection from '../components/CollectionCards/Subscriptions';
import SubscriptionsUI from '../components/OverviewCards/PublicCharging';
import UserUI from '../components/OverviewCards/UserProfile';
import AddRefund from '../components/DetailCards/Invoice/Refunds';
import CreditNotesCollection from '../components/CollectionCards/CreditNotes';
import CreditNote from '../components/DetailCards/CreditNote';

export const getDetailComponent = (
  crumb: BreadcrumbModel
): JSX.Element | null => {
  switch (crumb.component) {
    case 'UserUI':
      return <UserUI crumbId={crumb.id} />;
    case 'SubscriptionsUI':
      return <SubscriptionsUI crumbId={crumb.id} />;
    case 'ContractsUI':
      return <ContractsUI crumbId={crumb.id} />;
    case 'CardsUI':
      return <CardsUI crumbId={crumb.id} />;
    case 'ChargingCardDetails':
      return (
        <ChargingCardDetails
          resourceId={crumb.componentId}
          crumbId={crumb.id}
        />
      );
    case 'ChargingCardsCollection':
      return <ChargingCardsCollection crumbId={crumb.id} />;
    case 'ChargingSessionDetails':
      return (
        <ChargingSessionDetails
          resourceId={crumb.componentId}
          crumbId={crumb.id}
        />
      );
    case 'ChargingSessionsCollection':
      return (
        <ChargingSessionsCollection
          crumbId={crumb.id}
          defaultFilters={crumb.defaultFilters}
        />
      );
    case 'ChargingStationDetails':
      return (
        <ChargingStationDetails
          resourceId={crumb.componentId}
          crumbId={crumb.id}
          stationId={crumb.station_id}
          evseId={crumb.location_evse_id}
        />
      );
    case 'SubscriptionsCollection':
      return <SubscriptionsCollection crumbId={crumb.id} />;
    case 'SubscriptionDetails':
      return (
        <SubscriptionDetails
          resourceId={crumb.componentId}
          crumbId={crumb.id}
        />
      );
    case 'OrdersCollection':
      return <OrdersCollection crumbId={crumb.id} />;
    case 'ChargingStationsCollection':
      return (
        <ChargingStationsCollection crumbId={crumb.id} isOrg={crumb.org} />
      );
    case 'ActiveSessionDetails':
      return (
        <ActiveSessionDetails
          crumbId={crumb.id}
          resourceId={crumb.componentId}
        />
      );
    case 'AccountsCollection':
      return <AccountsCollection crumbId={crumb.id} />;
    case 'InvoiceCollection':
      return <InvoiceCollection crumbId={crumb.id} />;
    case 'InvoiceDetails':
      return (
        <InvoiceDetails crumbId={crumb.id} resourceId={crumb.componentId} />
      );
    case 'AddRefund':
      return <AddRefund invoiceId={crumb.componentId} />;
    case 'CreditNotesCollection':
      return (
        <CreditNotesCollection
          crumbId={crumb.id}
          invoiceId={crumb.componentId}
        />
      );
    case 'CreditNote':
      return <CreditNote creditNoteId={crumb.componentId} />;
    default:
      return null;
  }
};
