/* eslint-disable @typescript-eslint/naming-convention */
import { ErrorObject } from 'serialize-error';
import { IActiveSession } from '../../../types/activeSessions';

export interface ActiveSessionsStateModel {
  loading: boolean;
  data: IActiveSession[];
  error: ErrorObject | null;
  request: { userId: string } | null;
}

export interface ActiveSessionsState {
  [id: string]: ActiveSessionsStateModel;
}

const state: ActiveSessionsState = {};

export type State = typeof state;
export default state;
