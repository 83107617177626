import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    filterWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginLeft: '16px',
      marginBottom: '6px',
      minHeight: '32px',
    },

    filterButton: {
      fontWeight: 'bold',
    },

    filterClear: {
      marginLeft: '8px',
    },

    filterClearButton: {
      fontSize: '14px !important',
      fontWeight: 'normal',
    },

    filterTags: {
      marginLeft: '27px',
      display: 'flex',
    },
  })
);

export default useStyles;
