import { ActionType, ActionInterface } from '../actions';
import initialState, { State } from '..';
import getTariffsRequest from './getTariffsRequest';
import getTariffsSuccess from './getTariffsSuccess';
import getTariffsError from './getTariffsError';

const reducer = (state: State = initialState, action: any) => {
  switch (action.type) {
    case ActionType.GetTariffsRequest:
      return getTariffsRequest(
        state,
        action as ActionInterface.GetTariffsRequest
      );
    case ActionType.GetTariffsSuccess:
      return getTariffsSuccess(
        state,
        action as ActionInterface.GetTariffsSuccess
      );
    case ActionType.GetTariffsError:
      return getTariffsError(state, action as ActionInterface.GetTariffsError);
    default:
      return state;
  }
};

export default reducer;
