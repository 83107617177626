import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import AuthService from './auth';
import ErrorService from './error';
import { config as appConfig } from '../config';

interface IQueryOptions {
  method?: 'GET' | 'PUT' | 'PATCH' | 'POST' | 'DELETE';
  data?: any;
  headers?: any;
  responseType?:
    | 'arraybuffer'
    | 'blob'
    | 'document'
    | 'json'
    | 'text'
    | 'stream';
}

export default class ApiService {
  url: string;
  options: IQueryOptions;

  constructor(url: string, options: IQueryOptions = {}) {
    this.url = url;
    this.options = options;
  }

  /**
   * Handles REST-API queries
   * @throws axios error
   */
  async query({ refresh } = { refresh: false }): Promise<AxiosResponse> {
    const authService = new AuthService();
    try {
      const { method = 'GET', data = null } = this.options;
      let queryString = '';

      if (method === 'GET' && data) {
        queryString = `?${this.getQueryString(data)}`;
      }

      const bearer = authService.bearerToken;

      // if no bearer then get one
      const headers = this.options.headers || {};

      Object.assign(headers, {
        ...(bearer ? { Authorization: `Bearer ${bearer}` } : null),
      });

      const config: AxiosRequestConfig = {
        url: appConfig.elliBaseUrl + this.url + queryString,
        method,
        headers,
        data,
      };

      if (this.options.responseType) {
        config.responseType = this.options.responseType;
      }

      return await axios.request(config);
    } catch (error) {
      if (!refresh && error.response && error.response.data.errors) {
        const errorService = new ErrorService(error.response.data.errors);
        if (errorService.shouldTokenRefresh()) {
          await authService.fetchIdToken();
          return this.query({ refresh: true });
        }
      }

      throw error;
    }
  }

  getQueryString(data: Record<string, any>): string {
    const params = new URLSearchParams(data);
    return params.toString();
  }
}
