import { ActionInterface } from '../actions';
import { State } from '..';
import update from 'immutability-helper';

export default (
  state: State,
  action: ActionInterface.GetActiveSessionsSuccess
): State => {
  const { data } = action;
  const currentState = state[action.taskSid];
  const updatedState = update(currentState, {
    data: {
      $set: data,
    },
    loading: {
      $set: false,
    },
  });

  return {
    ...state,
    [action.taskSid]: updatedState,
  };
};
