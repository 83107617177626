import { IChargingCardModel, State } from '..';

import { ActionInterface } from '../actions';
import { omit } from 'lodash';
import update from 'immutability-helper';

export default (
  state: State,
  action: ActionInterface.GetChargingCardRequest
): State => {
  const chargingCardState = state[action.taskSid] || {
    loading: true,
    data: null,
    error: null,
    request: null,
  };

  const updatedChargingCardState: IChargingCardModel = update(
    chargingCardState,
    {
      error: {
        $set: null,
      },
      loading: {
        $set: true,
      },
      request: {
        $set: omit(action, ['type', 'taskSid']),
      },
    }
  );

  return {
    ...state,
    [action.taskSid]: updatedChargingCardState,
  };
};
