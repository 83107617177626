import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { Button, Divider, Tab, Tabs, Typography } from '@material-ui/core';
import ArchiveIcon from '@material-ui/icons/Archive';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ActivateIcon from '@material-ui/icons/CheckCircle';
import DeactivateIcon from '@material-ui/icons/Block';
import {
  IBreadcrumbHandlerProps,
  addBreadcrumbHandler,
} from '../../../utils/addBreadcrumbHandler';
import {
  ICardOrderModel,
  IRFIDCardModel,
  isActivatableCard,
  isArchivableCard,
  isDeactivatableCard,
  ISubscriptionModel,
} from '../../../types/subscriber';

import connector, { IPropsFromState } from '../../Connector/Connector';
import ChargingSessions from './ChargingSessions';
import DoubleArrow from '../../../assets/image/doubleArrow.svg';
import General from './General/General';
import { IChargingRecordViewModel } from '../../../types/chargingRecords';
import { ITariffConditionModel } from '../../../types/tariff';
import OrderDetails from './OrderDetails';
import ConfirmationDialog from '../../shared/Dialogs/Confirmation';
import { Pagination } from '../../shared/Pagination/Pagination';
import PublicCharging from './PublicCharging/PublicCharging';
import { TariffService } from '../../../services/tariffs';
import { paginateArray } from '../../../utils/paginateArray';
import useSharedStyles from '../../shared/useSharedStyles';
import { useCurrentTask } from '../../../custom-hooks/useCurrentTask';
import ActionDropdownContainer, {
  IActionItem,
} from '../../shared/ActionDropdownContainer';
import SubscriptionLogo from '../../../assets/image/subscription.svg';
import PairRfidAndSubscription from '../../shared/Dialogs/PairRfidAndSubscription';
import { rfidCardAction } from '../../../services/rfidCards';
import { useTypedTranslation } from '../../../custom-hooks/useTypedTranslation';
import useStyles from './useStyles';

interface ITabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<ITabPanelProps> = (props: ITabPanelProps) => {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={classes.tabPanelRoot}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

type TOwnProps = IPropsFromState & {
  resourceId?: string;
  crumbId?: string;
};

export const ChargingCardDetails: React.FC<TOwnProps> = ({
  chargingCardState,
  cardOrdersState,
  subscriptionState,
  chargingSessionsState,
  breadcrumbState,
  addBreadcrumb,
  resourceId,
  crumbId,
  getChargingCardRequest,
  subscriberState,
}) => {
  const { t, terms } = useTypedTranslation();
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const taskSid = useCurrentTask();
  const taskBreadcrumbState = breadcrumbState && breadcrumbState[taskSid];
  const subscriber = subscriberState && subscriberState[taskSid];
  const taskChargingSessions =
    chargingSessionsState && chargingSessionsState[taskSid];

  const [actionItems, setActionItems] = useState<IActionItem[] | null>(null);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [chargingCard, setChargingCard] = useState<IRFIDCardModel | null>(null);
  const [cardOrder, setCardOrder] = useState<ICardOrderModel | null>(null);
  const [subscription, setSubscription] = useState<ISubscriptionModel | null>(
    null
  );
  const [
    subscriptionTariff,
    setSubscriptionTariff,
  ] = useState<ITariffConditionModel | null>(null);

  const [chargingSessions, setChargingSessions] = useState<
    IChargingRecordViewModel[]
  >([]);

  const [itemsPerPage, setItemsPerPage] = useState<number>(4);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pagesLength, setPagesLength] = useState<number>(0);
  const [paginatedSessions, setPaginatedSessions] = useState<
    IChargingRecordViewModel[][]
  >([[]]);

  const paginateSessions = (sessions: IChargingRecordViewModel[]): void => {
    const ps = paginateArray(sessions, itemsPerPage);
    setPaginatedSessions(ps);
    setPagesLength(ps.length);
  };

  const rfidCardActionAndUpdate = async (
    action: Parameters<typeof rfidCardAction>[0]
  ) => {
    if (chargingCard && subscriber.data) {
      await rfidCardAction(action, chargingCard.id);
      getChargingCardRequest({
        taskSid,
        subscriberId: subscriber.data.id,
      });
    }
  };

  const rfidCardActionPopupContent = (
    action: keyof typeof terms.card_details.actions,
    key = 'bullets'
  ) => {
    const bullets = t(
      get(terms, `card_details.actions.${action}.${key}`)
    ).split('###');
    return (
      <>
        <ul className={classes.confirmationPopupList}>
          {bullets.map((bullet) => (
            <li key={bullet}>{bullet}</li>
          ))}
        </ul>
        {t(terms.general.are_you_sure)}
      </>
    );
  };

  useEffect(() => {
    setChargingSessions(paginatedSessions[currentPage - 1]);
  }, [paginatedSessions, currentPage]);

  useEffect(() => {
    if (chargingCardState && resourceId) {
      if (chargingCardState[taskSid].data) {
        const currentCard = (chargingCardState[taskSid]
          .data as IRFIDCardModel[]).find((cc) => cc.id === resourceId);

        if (currentCard) {
          setChargingCard(currentCard);
        }
      }
    }
  }, [resourceId, chargingCardState]);

  useEffect(() => {
    if (chargingCard && cardOrdersState) {
      const cardOrders = cardOrdersState[taskSid].data;
      const currentCardOrder =
        cardOrders &&
        cardOrders.find((co) => co.rfid_card.id === chargingCard.id);
      if (currentCardOrder) {
        setCardOrder(currentCardOrder);
      } else {
        setCardOrder(null);
      }
    }
  }, [chargingCard]);

  useEffect(() => {
    if (chargingCard && subscriptionState) {
      const subscriptions = subscriptionState[taskSid].data;
      const currentSubscription =
        subscriptions &&
        subscriptions.find((sub) => sub.id === chargingCard.subscription_id);
      if (currentSubscription) {
        setSubscription(currentSubscription);
      } else {
        setSubscription(null);
        setSubscriptionTariff(null);
      }
    }
  }, [chargingCard]);

  useEffect(() => {
    if (subscription) {
      const getTariff = async () => {
        try {
          const trf = await TariffService.getByConditionId(
            subscription.tariff_id,
            subscription.base_condition_id
          );
          setSubscriptionTariff(trf);
        } catch (error) {
          setSubscriptionTariff(null);
        }
      };

      getTariff();
    }
  }, [subscription]);

  useEffect(() => {
    if (chargingCard) {
      const cs = taskChargingSessions.data;
      const currentChargingSessions =
        cs &&
        cs.filter(
          (session: IChargingRecordViewModel) =>
            session.card_id === chargingCard.id
        );
      if (currentChargingSessions) {
        currentChargingSessions.sort((a, b) => {
          const first = new Date(a.creation_date);
          const second = new Date(b.creation_date);
          return first > second ? -1 : first < second ? 1 : 0;
        });

        paginateSessions(currentChargingSessions);
      }
    }
  }, [chargingCard]);

  useEffect(() => {
    const items = [
      {
        icon: <img src={SubscriptionLogo} alt='subscription logo' />,
        friendlyText: 'Pair with subscription',
        component: (
          <PairRfidAndSubscription rfidCardId={chargingCard?.number} />
        ),
      },
      {
        icon: <ActivateIcon className={classes.actionIcon} />,
        friendlyText: t(terms.card_details.actions.activate.menu),
        component: (
          <ConfirmationDialog
            confirmAction={async () => rfidCardActionAndUpdate('activate')}
            headline={t(terms.card_details.actions.activate.title)}
          >
            {subscription
              ? rfidCardActionPopupContent('activate', 'bullets_subscription')
              : rfidCardActionPopupContent('activate')}
          </ConfirmationDialog>
        ),
        disabled: !chargingCard || !isActivatableCard(chargingCard),
      },
      {
        icon: <DeactivateIcon className={classes.actionIcon} />,
        friendlyText: t(terms.card_details.actions.deactivate.menu),
        component: (
          <ConfirmationDialog
            confirmAction={async () => rfidCardActionAndUpdate('deactivate')}
            headline={t(terms.card_details.actions.deactivate.title)}
          >
            {rfidCardActionPopupContent('deactivate')}
          </ConfirmationDialog>
        ),
        disabled: !chargingCard || !isDeactivatableCard(chargingCard),
      },
      {
        icon: <ArchiveIcon className={classes.actionIcon} />,
        friendlyText: t(terms.card_details.actions.archive.menu),
        component: (
          <ConfirmationDialog
            confirmAction={async () => rfidCardActionAndUpdate('archive')}
            headline={t(terms.card_details.actions.archive.title)}
          >
            {rfidCardActionPopupContent('archive')}
          </ConfirmationDialog>
        ),
        disabled: !chargingCard || !isArchivableCard(chargingCard),
      },
    ];
    setActionItems(items);
  }, [chargingCard, t, terms, classes]);

  const handleChange = (
    _event: React.ChangeEvent<Record<string, any>>,
    newValue: number
  ) => {
    setCurrentTab(newValue);
  };

  const breadcrumbHandler = (props: IBreadcrumbHandlerProps): void =>
    addBreadcrumbHandler({
      ...props,
      addBreadcrumb,
      taskSid,
      crumbId,
      taskBreadcrumbState,
    });

  return (
    chargingCard && (
      <div
        className={sharedClasses.cardElement}
        data-testid='charging-card-details-component'
      >
        <div className={classes.chargingCardDetailsHeader}>
          <div className={classes.titleWrapper}>
            <CreditCardIcon className={classes.titleIcon} />
            <Typography
              variant='h1'
              classes={{
                root: classes.panelTitle,
              }}
            >
              Charging Card -{' '}
              {chargingCard.label ? (
                <span data-testid='heading-label'>
                  &quot;{chargingCard.label}&quot;
                </span>
              ) : (
                <span data-testid='heading-id'>{chargingCard.number}</span>
              )}
            </Typography>
          </div>
          {actionItems && <ActionDropdownContainer actionItems={actionItems} />}
        </div>
        <div>
          <Tabs
            classes={{
              root: classes.tabWrapperRoot,
            }}
            value={currentTab}
            onChange={handleChange}
            aria-label='simple tabs example'
            indicatorColor='primary'
            textColor='primary'
            centered
          >
            <Tab
              disableRipple={true}
              classes={{
                root: classes.tabButtonRoot,
                textColorPrimary: classes.tabText,
                selected: classes.selectedText,
              }}
              label='General'
              data-testid='general-tab'
            />
            <Tab
              disableRipple={true}
              classes={{
                root: classes.tabButtonRoot,
                textColorPrimary: classes.tabText,
                selected: classes.selectedText,
              }}
              label='Public Charging'
              data-testid='public-charging-tab'
            />
            {cardOrder && (
              <Tab
                disableRipple={true}
                classes={{
                  root: classes.tabButtonRoot,
                  textColorPrimary: classes.tabText,
                  selected: classes.selectedText,
                }}
                label='Order Details'
                data-testid='order-details-tab'
              />
            )}
          </Tabs>
          <Divider className={classes.divider} />
          <TabPanel value={currentTab} index={0}>
            <General chargingCard={chargingCard} />
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <PublicCharging
              chargingCard={chargingCard}
              tariff={subscriptionTariff}
              addBreadcrumbHandler={breadcrumbHandler}
              subscriptionId={subscription ? subscription.id : undefined}
              subscriptionStatus={
                subscription ? subscription.status : undefined
              }
            />
          </TabPanel>
          {cardOrder && (
            <TabPanel value={currentTab} index={2}>
              <OrderDetails cardOrder={cardOrder} />
            </TabPanel>
          )}
        </div>
        <div>
          <Typography
            data-testid='charging-sessions-table-header'
            classes={{
              h2: classes.chargingSessionsHeader,
            }}
            variant='h2'
          >
            Recent Charging Sessions
          </Typography>
          <ChargingSessions
            addBreadcrumbHandler={breadcrumbHandler}
            chargingSessions={chargingSessions}
            title='This card has not been used yet for charging.'
          />
          <Pagination
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pagesLength={pagesLength}
            itemSelectText='Records per page'
            showSelect={false}
            small={true}
          />
          {paginatedSessions.length > 1 && (
            <div className={classes.showMoreLink}>
              <Button
                color='primary'
                endIcon={<img src={DoubleArrow} alt='arrow' />}
                className={classes.showMoreButton}
                onClick={() =>
                  breadcrumbHandler({
                    component: 'ChargingSessionsCollection',
                    friendlyText: 'Charging Sessions Collection',
                    large: true,
                    defaultFilters: [chargingCard.number],
                  })
                }
              >
                Show all sessions
              </Button>
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default connector(ChargingCardDetails);
