/* eslint-disable @typescript-eslint/naming-convention */
import { actionCreator } from '../../type-utils';
import { ITariffModel } from '../../../types/tariff';

export const ActionType = {
  GetTariffsRequest: 'tariffs/get-tariffs-request',
  GetTariffsSuccess: 'tariffs/get-tariffs-success',
  GetTariffsError: 'tariffs/get-tariffs-error',
};

export declare namespace ActionInterface {
  export interface GetTariffsRequest {
    type: string;
    taskSid: string;
  }

  export interface GetTariffsSuccess {
    type: string;
    data: ITariffModel[];
    taskSid: string;
  }

  export interface GetTariffsError {
    type: string;
    error: Error;
    taskSid: string;
  }
}

export const ActionCreator = {
  GetTariffsRequest: actionCreator<ActionInterface.GetTariffsRequest>(
    ActionType.GetTariffsRequest
  ),
  GetTariffsSuccess: actionCreator<ActionInterface.GetTariffsSuccess>(
    ActionType.GetTariffsSuccess
  ),
  GetTariffsError: actionCreator<ActionInterface.GetTariffsError>(
    ActionType.GetTariffsError
  ),
};
