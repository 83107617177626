import { all, call, put, takeEvery } from 'redux-saga/effects';

import { InvoiceService } from '../../../services/invoices';
import { IInvoice } from '../../../types/invoice';
import { ActionCreator, ActionInterface, ActionType } from './actions';

export function* invoiceSaga() {
  function* getInvoices(action: ActionInterface.GetInvoicesRequest) {
    try {
      const invoices: IInvoice[] = yield call(
        InvoiceService.getSubscriberInvoices,
        action.subscriberId
      );

      yield put<ActionInterface.GetInvoicesSuccess>(
        ActionCreator.GetInvoicesSuccess({
          data: invoices,
          taskSid: action.taskSid,
        })
      );

      yield put<ActionInterface.SortInvoices>(
        ActionCreator.SortInvoices({
          taskSid: action.taskSid,
          sortState: {
            sortByColumnKey: 'creation_date',
            sortDesc: true,
          },
        })
      );
    } catch (error) {
      yield put<ActionInterface.GetInvoicesError>(
        ActionCreator.GetInvoicesError({
          error,
          taskSid: action.taskSid,
        })
      );
    }
  }

  yield all([takeEvery(ActionType.GetInvoicesRequest, getInvoices)]);
}

export default invoiceSaga;
