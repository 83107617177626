import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    actionItemIcon: {
      height: '100%',
      width: 'auto',
    },
    chargingCardDetailsHeader: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '16px',
    },
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    panelTitle: {
      fontSize: '20px !important',
    },
    addressElement: {
      marginTop: '10px',
      height: '24px',
    },
    logoContainer: {
      marginRight: '15px',
    },
    chargingCardLink: {
      cursor: 'pointer',
      textDecoration: 'underline',
      '&:hover': {
        color: '#6633cc',
      },
    },
    tariffInfoTitle: {
      marginTop: '8px !important',
    },
    headingWithIcon: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: 1, // !important,
    },
    terminateSubscription: {
      marginTop: '24px',
    },
  })
);

export default useStyles;
