import { ActionType, ActionInterface } from '../actions';
import initialState, { State } from '..';
import getUserSearchRequest from './getUserSearchRequest';
import getUserSearchSuccess from './getUserSearchSuccess';
import getUserSearchError from './getUserSearchError';
import setSelectedUser from './setSelectedUser';
import clearSelectedUser from './clearSelectedUser';

const reducer = (state: State = initialState, action: any) => {
  switch (action.type) {
    case ActionType.GetUserSearchRequest:
      return getUserSearchRequest(
        state,
        action as ActionInterface.GetUserSearchRequest
      );
    case ActionType.GetUserSearchSuccess:
      return getUserSearchSuccess(
        state,
        action as ActionInterface.GetUserSearchSuccess
      );
    case ActionType.GetUserSearchError:
      return getUserSearchError(
        state,
        action as ActionInterface.GetUserSearchError
      );
    case ActionType.SetSelectedUser:
      return setSelectedUser(state, action as ActionInterface.SetSelectedUser);
    case ActionType.ClearSelectedUser:
      return clearSelectedUser(
        state,
        action as ActionInterface.ClearSelectedUser
      );

    default:
      return state;
  }
};

export default reducer;
