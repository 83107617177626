import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    itemValue: {
      fontSize: 14,
      display: 'flex',
      alignItems: 'center',
      maxWidth: '100%',
    },
    itemWrapper: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      marginBottom: 24,
      width: 200,
      flex: '0 0 33%',
    },
    stationRefreshing: {
      opacity: 0.5,
    },
    outerItemWrapper: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      flexFlow: 'row wrap',
    },
    itemTitleWithIcon: {
      fontSize: 12,
      color: '#878c96',
      marginBottom: 10,
      lineHeight: 1.33,
      display: 'flex',
      alignItems: 'center',
      '&:first-child': {
        marginRight: 3,
      },
    },
    infoIcon: {
      marginLeft: 2,
      cursor: 'pointer',
      fontSize: 12,
    },
    capitalize: {
      textTransform: 'capitalize',
    },
    tooltipOverflow: {
      width: '90%',
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingRight: '20px',
    },
    ownerIconItem: {
      display: 'flex',
      alignItems: 'center',
    },
  })
);

export default useStyles;
