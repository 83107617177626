import React, { useEffect, useState } from 'react';
import {
  IBreadcrumbHandlerProps,
  addBreadcrumbHandler,
} from '../../../utils/addBreadcrumbHandler';
import connector, { IPropsFromState } from '../../Connector/Connector';

import ChargingSessions from './ChargingSessions';
import ChargingSessionsIcon from '../../../assets/image/chargingSessions.svg';
import { IChargingRecordViewModel } from '../../../types/chargingRecords';
import { IFilterOption } from '../../shared/Filters/TableFilter';
import { IHomeStation } from '../../../types/user';
import { IRFIDCardModel } from '../../../types/subscriber';
import { ISortState } from '../../shared/AssetTable/AssetTable/AssetTable';
import { Pagination } from '../../shared/Pagination/Pagination';
import { Typography } from '@material-ui/core';
import { paginateArray } from '../../../utils/paginateArray';
import useSharedStyles from '../../shared/useSharedStyles';
import { sortRequestHandler as sortStateHandler } from '../../../utils/sortRequestHander';
import { useCurrentTask } from '../../../custom-hooks/useCurrentTask';
import useStyles from './useStyles';

type TOwnProps = IPropsFromState & {
  crumbId?: string;
  defaultFilters?: string[];
};

export const ChargingSessionsCollection: React.FC<TOwnProps> = ({
  chargingSessionsState,
  chargingCardState,
  breadcrumbState,
  crumbId,
  defaultFilters,
  homeStationsState,
  addBreadcrumb,
  sortChargingSessions,
}) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const taskSid = useCurrentTask();
  const taskBreadcrumbState = breadcrumbState && breadcrumbState[taskSid];
  const taskHomeStations = homeStationsState && homeStationsState[taskSid];

  const [privateStationsIds, setPrivateStationsIds] = useState<string[]>([]);
  const [homeStations, setHomeStations] = useState<IHomeStation[]>([]);

  const [chargingSessions, setChargingSessions] = useState<
    IChargingRecordViewModel[]
  >([]);
  const [chargingCards, setChargingCards] = useState<IRFIDCardModel[]>([]);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pagesLength, setPagesLength] = useState<number>(0);
  const [paginatedSessions, setPaginatedSessions] = useState<
    IChargingRecordViewModel[][]
  >([[]]);
  const [selectedFilters, setSelectedFilters] = useState<IFilterOption[]>([]);

  const [sortState, setSortState] = useState<ISortState>({
    sortByColumnKey: 'start_date_time',
    sortDesc: true,
  });

  const sortRequestHandler = (columnkey: string | null): void => {
    sortStateHandler(columnkey, sortState, setSortState);
  };

  const breadcrumbHandler = (props: IBreadcrumbHandlerProps): void =>
    addBreadcrumbHandler({
      ...props,
      addBreadcrumb,
      taskSid,
      crumbId,
      taskBreadcrumbState,
    });

  const paginateSessions = (sessions: IChargingRecordViewModel[]): void => {
    const ps = paginateArray(sessions, itemsPerPage);
    setPaginatedSessions(ps);
    setPagesLength(ps.length);
  };

  const getFilteredSessions = (
    cs: IChargingRecordViewModel[]
  ): IChargingRecordViewModel[] => {
    const filteredSessions: IChargingRecordViewModel[] = cs.reduce(
      (accum: IChargingRecordViewModel[], current) => {
        const pass = selectedFilters.some((sf) => {
          return sf.filterFunc(current);
        });

        if (pass || selectedFilters.length === 0) {
          accum.push(current);
        }

        return accum;
      },
      []
    );

    return filteredSessions;
  };

  const getUniqueStations = (cs: IChargingRecordViewModel[]) => {
    const allPrivateStationsIds = cs
      .filter((session) => session.type === 'private')
      .map((session) => session.station_id);

    const uniqueStations = [...new Set(allPrivateStationsIds)];
    setPrivateStationsIds(uniqueStations);
  };

  useEffect(() => {
    if (taskHomeStations.stations) {
      setHomeStations(taskHomeStations.stations);
    }
  }, [taskHomeStations]);

  useEffect(() => {
    setChargingSessions(paginatedSessions[currentPage - 1]);
  }, [paginatedSessions, currentPage]);

  useEffect(() => {
    const cs = chargingSessionsState[taskSid].data;
    if (cs) {
      getUniqueStations(cs);
      setCurrentPage(1);
      const sessions = getFilteredSessions(cs);
      paginateSessions(sessions);
    }
  }, [chargingSessionsState, itemsPerPage, selectedFilters]);

  useEffect(() => {
    if (chargingCardState) {
      setChargingCards(chargingCardState[taskSid].data || []);
    }
  }, [chargingCardState]);

  useEffect(() => {
    sortChargingSessions({ taskSid, sortState, chargingCards });
  }, [sortState, taskSid, sortChargingSessions]);

  return (
    <div
      className={sharedClasses.cardElement}
      data-testid='charging-sessions-collection'
    >
      <div className={classes.chargingCardDetailsHeader}>
        <div className={classes.titleWrapper}>
          <img
            src={ChargingSessionsIcon}
            alt='Charging Sessions'
            className={classes.titleIcon}
          />
          <Typography
            variant='h1'
            classes={{
              root: classes.panelTitle,
            }}
          >
            Charging Records
          </Typography>
        </div>
      </div>
      <div className={classes.chargingSessionsTableWrapper}>
        <ChargingSessions
          privateStationsIds={privateStationsIds}
          homeStations={homeStations}
          chargingSessions={chargingSessions}
          chargingCards={chargingCards}
          sortState={sortState}
          sortRequestHandler={sortRequestHandler}
          addBreadcrumbHandler={breadcrumbHandler}
          defaultFilters={defaultFilters}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
      </div>
      <Pagination
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pagesLength={pagesLength}
        itemSelectText='Records per page'
      />
    </div>
  );
};

export default connector(ChargingSessionsCollection);
