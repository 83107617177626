import React, { useState } from 'react';

import { useCurrentTask } from '../../../custom-hooks/useCurrentTask';
import { useTypedTranslation } from '../../../custom-hooks/useTypedTranslation';
import { ISubscriberModel } from '../../../types/subscriber';
import ContactDetailsIcon from '../../../assets/image/contactDetails.svg';
import connector, { IPropsFromState } from '../../Connector/Connector';
import SuccessMessage from '../../shared/Dialogs/SuccessMessage';
import ChangeBillingForm from './ChangeBillingForm/ChangeBillingForm';

const ChangeBillingModal: React.FC<IPropsFromState> = ({
  subscriberState,
  updateBillingAddress,
}) => {
  const taskSid = useCurrentTask();
  const { getScopedTranslation } = useTypedTranslation(
    'update_billing_address'
  );
  const { data, error } = subscriberState[taskSid];
  const { address, id } = data as ISubscriberModel;
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const handleSuccess = () => setIsSuccess(true);

  if (isSuccess) {
    return (
      <SuccessMessage
        headline={getScopedTranslation('success.headline')}
        subHeadline={getScopedTranslation('success.sub_headline')}
        icon={ContactDetailsIcon}
        iconAltText={getScopedTranslation('success.headline')}
      />
    );
  }

  return (
    <ChangeBillingForm
      subscriberAddress={address}
      subscriberId={id}
      onSuccess={handleSuccess}
      submitError={!!error}
      taskSid={taskSid}
      updateBillingAddress={updateBillingAddress}
    />
  );
};

export default connector(ChangeBillingModal);
