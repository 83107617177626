import { ActionCreator, ActionInterface, ActionType } from './actions';
import {
  IHomeChargingStation,
  IHomeChargingStationModel,
} from '../../../types/user';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import { HomeChargingService } from '../../../services/stations';
import { lexicographical } from '../../../utils/comparisonFunctions';

export function* homestationsSaga() {
  function* getHomeStations(action: ActionInterface.GetHomeStationsRequest) {
    try {
      const homeStations: IHomeChargingStationModel = yield call(
        HomeChargingService.getStations,
        { userId: action.user_id }
      );

      if (
        homeStations &&
        homeStations.stations &&
        homeStations.stations.length >= 1
      ) {
        const sortedArray = [...homeStations.stations].sort((a, b) => {
          if (!a.last_contact && !b.last_contact) {
            return 0;
          }

          if (!a.last_contact) {
            return 1;
          }

          if (!b.last_contact) {
            return -1;
          }

          const date1 = new Date(a.last_contact);
          const date2 = new Date(b.last_contact);
          return lexicographical(date1, date2) * -1;
        });

        const station: IHomeChargingStation = yield call(
          HomeChargingService.getStationById,
          sortedArray[0].id
        );

        yield put<ActionInterface.GetHomeStationsSuccess>(
          ActionCreator.GetHomeStationsSuccess({
            data: station,
            stations: homeStations.stations,
            taskSid: action.taskSid,
          })
        );
      } else {
        throw new Error('No stations');
      }
    } catch (error) {
      yield put<ActionInterface.GetHomeStationsError>(
        ActionCreator.GetHomeStationsError({
          error,
          taskSid: action.taskSid,
        })
      );
    }
  }

  yield all([takeEvery(ActionType.GetHomeStationsRequest, getHomeStations)]);
}

export default homestationsSaga;
