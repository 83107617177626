import { AppDispatch } from '../../../../store';
import { ActionCreator } from '../../../../store/state/actions';
import { ActionInterface } from '../../../../store/state/tariffs/actions';
import { Dispatcher } from '../index';

interface IDispatchers {
  getTariffsRequest: Dispatcher<ActionInterface.GetTariffsRequest>;
}

export const createDispatchers = (dispatch: AppDispatch): IDispatchers => ({
  getTariffsRequest: (payload) =>
    dispatch(ActionCreator.tariffs.GetTariffsRequest(payload)),
});
