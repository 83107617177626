import { connect } from 'react-redux';

import { State } from '../../store/state';
import { getDispatchers } from './dispatchers';

export interface IPropsFromState
  extends ReturnType<typeof mapDispatchToProps>,
    ReturnType<typeof mapStateToProps> {}

const mapStateToProps = (state: State) => {
  return {
    urlQuery: state.flex.router.location
      ? state.flex.router.location.search
      : '',
    userState: state.app.user,
    subscriberState: state.app.subscriber,
    subscriptionState: state.app.subscription,
    cardOrdersState: state.app.cardorders,
    chargingCardState: state.app.chargingCards,
    userSearchState: state.app.userSearch,
    breadcrumbState: state.app.breadcrumbs,
    chargingSessionsState: state.app.chargingSessions,
    uiState: state.app.ui,
    otherState: state.app.other,
    chargingStationState: state.app.chargingStations,
    organizationState: state.app.organization,
    homeStationsState: state.app.homeStations,
    activeSessionsState: state.app.activeSessions,
    invoicesState: state.app.invoices,
    tariffsState: state.app.tariffs,
  };
};

const mapDispatchToProps = getDispatchers;

export { mapStateToProps, mapDispatchToProps };
export default connect(mapStateToProps, mapDispatchToProps);
