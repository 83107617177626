/* eslint-disable @typescript-eslint/naming-convention */
import { actionCreator } from '../../type-utils';
import { IUserModel, IUserSearchModel } from '../../../types/user';

export const ActionType = {
  GetUserSearchRequest: 'user-search/get-user-search-request',
  GetUserSearchSuccess: 'user-search/get-user-search-success',
  GetUserSearchError: 'user-search/get-user-search-error',
  SetSelectedUser: 'user-search/set-selected-user',
  ClearSelectedUser: 'user-search/clear-selected-user',
};

export declare namespace ActionInterface {
  export interface GetUserSearchRequest {
    type: string;
    query: string;
    taskSid: string;
  }

  export interface GetUserSearchSuccess {
    type: string;
    data: IUserSearchModel[];
    taskSid: string;
  }

  export interface GetUserSearchError {
    type: string;
    error: Error;
    taskSid: string;
  }

  export interface SetSelectedUser {
    type: string;
    taskSid: string;
    user: IUserModel;
  }

  export interface ClearSelectedUser {
    type: string;
    taskSid: string;
  }
}

export const ActionCreator = {
  GetUserSearchRequest: actionCreator<ActionInterface.GetUserSearchRequest>(
    ActionType.GetUserSearchRequest
  ),
  GetUserSearchSuccess: actionCreator<ActionInterface.GetUserSearchSuccess>(
    ActionType.GetUserSearchSuccess
  ),
  GetUserSearchError: actionCreator<ActionInterface.GetUserSearchError>(
    ActionType.GetUserSearchError
  ),
  SetSelectedUser: actionCreator<ActionInterface.SetSelectedUser>(
    ActionType.SetSelectedUser
  ),
  ClearSelectedUser: actionCreator<ActionInterface.ClearSelectedUser>(
    ActionType.ClearSelectedUser
  ),
};
