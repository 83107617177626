import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    detailWrapper: {
      marginBottom: ' 8px',
      padding: '0 16px 8px',
    },
    emailTrim: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    divider: {
      width: '100%',
      backgroundColor: '#f5f6f8 !important',
    },
    informationRow: {
      padding: '16px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingTop: 0,
      flexFlow: 'row wrap',
    },
  })
);

export default useStyles;
