import { IRFIDCard } from '.';
/* eslint-disable @typescript-eslint/naming-convention */
import { actionCreator } from '../../type-utils';
export const ActionType = {
  GetRFIDCardsRequest: 'other/get-rfidcards-request',
  GetRFIDCardsSuccess: 'other/get-rfidcards-success',
  GetRFIDCardsError: 'other/get-rfidcards-error',
  ClearAll: 'other/clear-all',
};

export declare namespace ActionInterface {
  export interface GetRFIDCardsRequest {
    type: string;
  }

  export interface GetRFIDCardsSuccess {
    type: string;
    data: IRFIDCard[];
  }

  export interface GetRFIDCardsError {
    type: string;
    error: Error;
  }

  export interface ClearAll {
    type: string;
    taskSid: string;
  }
}

export const ActionCreator = {
  GetRFIDCardsRequest: actionCreator<ActionInterface.GetRFIDCardsRequest>(
    ActionType.GetRFIDCardsRequest
  ),
  GetRFIDCardsSuccess: actionCreator<ActionInterface.GetRFIDCardsSuccess>(
    ActionType.GetRFIDCardsSuccess
  ),
  GetRFIDCardsError: actionCreator<ActionInterface.GetRFIDCardsError>(
    ActionType.GetRFIDCardsError
  ),
  ClearAll: actionCreator<ActionInterface.ClearAll>(ActionType.ClearAll),
};
