import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  iconWrapper: {
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    lineHeight: 0,
  },
  option: {
    position: 'relative',
    paddingLeft: 30,
  },
});

export default useStyles;
