/* eslint-disable no-constant-condition */
import { Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { IStationViewModel } from '../../../../view-models/StationModel';
import { General as PrivateBusiness } from './PrivateBusiness/PrivateBusiness';
import { General as Public } from './Public/Public';

interface IOwnProps {
  chargingStation: IStationViewModel;
  stationRefreshing: boolean;
}

export const General: React.FC<IOwnProps> = ({
  chargingStation,
  stationRefreshing,
}) => {
  const [stationType, setStationType] = useState<string>('');

  useEffect(() => {
    if (chargingStation.type === 'public') {
      setStationType('public');
    } else if (chargingStation.type === 'private_business') {
      setStationType('private_business');
    }
  }, [chargingStation]);

  return (
    <div data-testid='charging-station-general'>
      <Grid
        container
        justify='space-between'
        alignItems='center'
        direction='row'
      >
        <Grid item xs={12}>
          <div style={{ marginBottom: 24 }}>
            <Typography data-testid='tariff-name-title' variant='h4'>
              General
            </Typography>
          </div>
        </Grid>
      </Grid>
      {stationType === 'public' ? (
        <Public
          chargingStation={chargingStation}
          stationRefreshing={stationRefreshing}
        />
      ) : (
        <PrivateBusiness
          chargingStation={chargingStation}
          stationRefreshing={stationRefreshing}
        />
      )}
    </div>
  );
};

export default General;
