import React from 'react';
import { format } from 'date-fns';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { IColumn, IRow } from '../../shared/AssetTable/AssetTable/AssetTable';
import { ICreditNote } from '../../../types/creditNote';
import {
  addBreadcrumbHandler,
  TAddBreadCrumbFunc,
} from '../../../utils/addBreadcrumbHandler';
import { BreadcrumbStateModel } from '../../../store/state/breadcrumbs';

interface IFieldName {
  name: string;
  key: keyof ICreditNote | 'action';
  isSortableBy: boolean;
}

export const fieldNames: IFieldName[] = [
  {
    name: 'Credit Note Number',
    key: 'credit_note_number',
    isSortableBy: true,
  },
  {
    name: 'Creation Date',
    key: 'created_at',
    isSortableBy: true,
  },
  {
    name: 'Amount',
    key: 'amount',
    isSortableBy: true,
  },
  {
    name: 'Current Status',
    key: 'status',
    isSortableBy: true,
  },
  {
    name: '',
    key: 'action',
    isSortableBy: false,
  },
];

export const getColumns = (): IColumn[] =>
  fieldNames.map(({ name, key, isSortableBy }) => ({
    header: name,
    isSortable: isSortableBy,
    key,
  }));

export const getRows = (
  creditNotes: ICreditNote[],
  taskSid: string,
  addBreadcrumb: TAddBreadCrumbFunc,
  taskBreadcrumbState: BreadcrumbStateModel,
  crumbId?: string
): IRow[] =>
  creditNotes.map((creditNote) => ({
    id: creditNote.id,
    data: {
      credit_note_number: {
        content: creditNote.credit_note_number || '-',
      },
      created_at: {
        content: format(new Date(creditNote.created_at), 'dd.MM.YYY HH:mm'),
      },
      amount: {
        content: `${creditNote.amount.toFixed(2)} ${creditNote.currency}`,
      },
      status: {
        content: creditNote.status,
      },
      action: {
        content: (
          <IconButton
            data-testid='invoice-link'
            onClick={() =>
              addBreadcrumbHandler({
                component: 'CreditNote',
                friendlyText: 'Credit Note',
                id: creditNote.id,
                taskSid,
                taskBreadcrumbState,
                addBreadcrumb,
                crumbId,
              })
            }
            color='primary'
          >
            <ChevronRightIcon fontSize='large' />
          </IconButton>
        ),
      },
    },
  }));
