import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Connectors as ConnectorsTable } from '../Connectors';
import { IStationViewModel } from '../../../../view-models/StationModel';
import RefreshIcon from '@material-ui/icons/Refresh';
import useStyles from './useStyles';

interface IOwnProps {
  chargingStation: IStationViewModel;
  refreshStation: () => void;
  stationRefreshing: boolean;
}

export const Connectors: React.FC<IOwnProps> = ({
  chargingStation,
  stationRefreshing,
  refreshStation,
}) => {
  const classes = useStyles();

  const refresh = () => {
    refreshStation();
  };

  return (
    <div
      className={classes.profileElement}
      data-testid='charging-station-connectors'
    >
      <div
        data-testid='charging-sessions-table-header'
        className={classes.chargingSessionsHeader}
      >
        <span>Connectors</span>
        {stationRefreshing ? (
          <CircularProgress
            size={16}
            classes={{
              root: classes.circleProgress,
            }}
          />
        ) : (
          <RefreshIcon
            data-testid='button-refresh'
            onClick={() => refresh()}
            className={classes.refreshIcon}
          />
        )}
      </div>
      <div className={`${stationRefreshing ? classes.stationRefreshing : ''}`}>
        <ConnectorsTable
          chargingSpots={chargingStation.connectors}
          type={chargingStation.type}
        />
      </div>
    </div>
  );
};

export default Connectors;
