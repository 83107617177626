import {
  Checkbox,
  CircularProgress,
  Dialog,
  FormControlLabel,
} from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';
import useStyles from './useStyles';
import Message, { IMessage, MessageTypes } from '../../../shared/Message';

const blockTerminationWithUpcomingSubMessage: IMessage = {
  type: MessageTypes.ERROR,
  text:
    'This subscription can not be terminated as it has an upcoming subscription attached to it. Please terminate the upcoming subscription first to revoke this active subscription.',
};

export type OwnProps = {
  open: boolean;
  onClose: () => void;
  doTerminate: () => Promise<void>;
  clearVin: boolean;
  setClearVin: (val: boolean) => void;
  showTenantWarning: boolean;
  hasUpcomingSubscription: boolean;
};

export const TerminateSubscriptionWarningDialog: React.FC<OwnProps> = ({
  open,
  onClose,
  doTerminate,
  clearVin,
  setClearVin,
  showTenantWarning,
  hasUpcomingSubscription,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t, terms } = useTypedTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const onConfirm = async () => {
    setIsLoading(true);
    try {
      await doTerminate();
    } catch (err) {
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const terminateButton = useRef<HTMLButtonElement>(null);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        classes: {
          root: classes.dialog,
        },
      }}
    >
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      <Typography paragraph variant='h1' color='inherit' align='center'>
        {t(terms.subscription_termination.terminate_subscription)}
      </Typography>
      <br />
      <Typography paragraph variant='subtitle1' align='center'>
        <Trans i18nKey={terms.subscription_termination.confirmation} />
      </Typography>
      <br />
      <div className={classes.terminationConfirmationVinCheckbox}>
        <FormControlLabel
          control={
            <Checkbox
              data-testid='subscription-details-clear-vin'
              checked={clearVin}
              onChange={() => {
                setClearVin(!clearVin);
              }}
              color='primary'
            />
          }
          label={t(terms.subscription_termination.vin_checkbox)}
        />
      </div>
      {showTenantWarning ? (
        <Typography paragraph variant='caption' align='center'>
          {t(terms.subscription_termination.vin_tenant_warning)}
        </Typography>
      ) : (
        ''
      )}
      <br />
      <div className={classes.terminationConfirmationButton}>
        {!isLoading ? (
          <Button
            ref={terminateButton}
            variant='outlined'
            className={classes.terminateButton}
            onClick={onConfirm}
            disabled={isLoading || hasUpcomingSubscription}
          >
            {t(terms.subscription_termination.terminate_subscription)}
          </Button>
        ) : (
          <CircularProgress
            color='primary'
            variant='indeterminate'
            size='32px'
          />
        )}
        {hasUpcomingSubscription && (
          <Message message={blockTerminationWithUpcomingSubMessage} />
        )}
      </div>
    </Dialog>
  );
};
