import update from 'immutability-helper';
import { omit } from 'lodash';
import { State } from '../';
import { ActionInterface } from '../actions';
import { UserStateModel } from '../';

export default (
  state: State,
  action: ActionInterface.GetUserRequest
): State => {
  const user = state[action.taskSid] || {
    loading: true,
    data: null,
    error: null,
    request: null,
  };

  const updatedUser: UserStateModel = update(user, {
    error: {
      $set: null,
    },
    loading: {
      $set: true,
    },
    request: {
      $set: omit(action, ['type', 'taskSid']),
    },
  });

  return {
    ...state,
    [action.taskSid]: updatedUser,
  };
};
