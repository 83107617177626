/* eslint-disable @typescript-eslint/naming-convention */
import { ErrorObject } from 'serialize-error';
import { IUserModel } from '../../../types/user';

export interface GetUserRequest {
  userId: string;
  taskSid: string;
}

export interface UserStateModel {
  verified: boolean;
  loading: boolean;
  data: IUserModel | null;
  error: ErrorObject | null;
  request: { userId: string } | null;
}

export interface UserState {
  [id: string]: UserStateModel;
}

const state: UserState = {};

export type State = typeof state;
export default state;
