import { ITerminateSubscriptionResponse } from '../../../../services/subscriptions';
import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';
import { Trans } from 'react-i18next';
import { MKitIds } from '../MKitIDs/MKitIDs';
import useStyles from './useStyles';

export type OwnProps = {
  terminationResponse: ITerminateSubscriptionResponse;
  tenant: string;
};

export const TerminationResponse: React.FC<OwnProps> = ({
  terminationResponse,
  tenant,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t, terms } = useTypedTranslation();
  return (
    <div className={classes.dataBoxGrey}>
      <Typography paragraph variant='inherit'>
        <strong>
          {t(terms.subscription_termination.result_dialog_title)}:
        </strong>{' '}
        {tenant} -{' '}
        {t(terms.subscription_termination.result_dialog_title_details)}
      </Typography>
      <Typography paragraph variant='inherit'>
        <Trans
          t={t}
          i18nKey={terms.subscription_termination.result_dialog_subtitle}
        />
      </Typography>
      <Typography paragraph variant='inherit'>
        <strong>
          {t(
            terms.subscription_termination.result_dialog_headers_personal_data
          )}
        </strong>
        <MKitIds terminationResponse={terminationResponse} />
      </Typography>
      <Typography paragraph variant='inherit'>
        <strong>
          {t(terms.subscription_termination.result_dialog_headers_bcid)}:
        </strong>{' '}
        {tenant}
      </Typography>
      <Typography>
        {t(terms.subscription_termination.result_dialog_headers_contact)}
      </Typography>
      <Typography paragraph variant='inherit'>
        <strong>
          {t(terms.subscription_termination.result_dialog_headers_reported_by)}:
        </strong>{' '}
        {t(terms.subscription_termination.result_dialog_content_reported_by)}
        <br />
        <strong>
          {t(terms.subscription_termination.result_dialog_headers_call_code)}:
        </strong>{' '}
        E-Mail
      </Typography>
      <Typography>
        {t(terms.subscription_termination.result_dialog_headers_categorization)}
      </Typography>
      <Typography variant='inherit'>
        <strong>
          {t(terms.subscription_termination.result_dialog_headers_reported_ci)}:
        </strong>{' '}
        MOS WE CHARGE (P-AWS EU-WEST-1)
      </Typography>
      <Typography paragraph variant='inherit'>
        <strong>
          {t(terms.subscription_termination.result_dialog_headers_affected_ci)}:
        </strong>{' '}
        SBA (P)
      </Typography>
      <Typography>
        {t(terms.subscription_termination.result_dialog_headers_assignment)}
      </Typography>
      <Typography paragraph variant='inherit'>
        <strong>
          {t(
            terms.subscription_termination
              .result_dialog_headers_assignment_group
          )}
          :
        </strong>{' '}
        SBA SUPPORT VW GROUP
      </Typography>
    </div>
  );
};
