import React from 'react';
import { format } from 'date-fns';

import { ICreditNote } from '../../../types/creditNote';
import { useTypedTranslation } from '../../../custom-hooks/useTypedTranslation';
import { IInformationItem } from '../../shared/InformationItems/InformationItem';
import InformationItems from '../../shared/InformationItems';

interface IOwnProps {
  creditNote?: ICreditNote;
}

const Details: React.FC<IOwnProps> = ({ creditNote }) => {
  const { getScopedTranslation } = useTypedTranslation(
    'refunds.overview.fields'
  );

  const getItems = (currentCreditNote: ICreditNote): IInformationItem[] => [
    {
      id: 'credit_note_number',
      headline: getScopedTranslation('credit_note_number'),
      content: currentCreditNote.credit_note_number,
    },
    {
      id: 'created_at',
      headline: getScopedTranslation('creation_date'),
      content: format(
        new Date(currentCreditNote.created_at),
        'dd.MM.YYY HH:mm'
      ),
    },
    {
      id: 'amount',
      headline: getScopedTranslation('amount'),
      content: `${currentCreditNote.amount} ${currentCreditNote.currency}`,
    },
    {
      id: 'status',
      headline: getScopedTranslation('current_state'),
      content: currentCreditNote.status,
    },
  ];

  return creditNote ? (
    <InformationItems items={getItems(creditNote)} title='Details' />
  ) : null;
};

export default Details;
