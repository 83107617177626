import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    headingWithIcon: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: 1, // !important,
    },
  })
);

export default useStyles;
