import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    session_dialog: {
      padding: 32,
      width: 410,
    },
    textWrapper: {
      display: 'flex',
      flexFlow: 'column nowrap',
      marginBottom: 20,
      '&:last-child': {
        marginBottom: 0,
      },
    },
    title: {
      fontWeight: 900,
      fontSize: 12,
      marginBottom: 6,
      display: 'block',
    },
    section: {
      marginBottom: 24,
      '&:last-child': {
        marginBottom: 0,
      },
    },
  })
);

export default useStyles;
