import React from 'react';
import Check from '@material-ui/icons/Check';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import connector, { IPropsFromState } from '../../Connector/Connector';
import { useCurrentTask } from '../../../custom-hooks/useCurrentTask';
import useStyles from './useStyles';

type TProps = IPropsFromState;

export const UserVerified: React.FC<TProps> = ({
  subscriberState,
  setSelectedUser,
  userState,
  setSubscriberTemp,
  openVerificationFlow,
  setVerificationStep,
}) => {
  const taskSid = useCurrentTask();
  const classes = useStyles();

  const user = userState && userState[taskSid];
  const subscriber = subscriberState && subscriberState[taskSid];
  const classList = user.verified
    ? `${classes.verifiedBox} ${classes.verified}`
    : `${classes.verifiedBox} ${classes.unverified}`;

  const continueVerification = () => {
    if (user.verified) {
      return;
    }
    if (subscriber && subscriber.data) {
      setSubscriberTemp({ taskSid: taskSid, subscriber: subscriber.data });
    }
    if (user && user.data) {
      setSelectedUser({ taskSid: taskSid, user: user.data });
    }
    setVerificationStep({ taskSid: taskSid, step: 3 });
    openVerificationFlow({ taskSid: taskSid, open: true });
  };

  return (
    <div
      style={{ cursor: user.verified ? 'auto' : 'pointer' }}
      onClick={continueVerification}
      className={classList}
      data-testid='user-verified-component'
    >
      {user.verified ? (
        <>
          <Check className={classes.verifiedImage} style={{ color: '#fff' }} />
          <span data-testid='user-verified'>Verified</span>
        </>
      ) : (
        <>
          <ErrorOutline
            className={classes.verifiedImage}
            style={{ color: '#fff' }}
          />
          <span>Unverified</span>
        </>
      )}
    </div>
  );
};

export default connector(UserVerified);
