import React from 'react';
import { FormControl, InputLabel } from '@material-ui/core';

export interface IOwnProps {
  labelText: string;
  name: string;
  hasError?: boolean;
  isRequired?: boolean;
}

const SelectControl: React.FC<IOwnProps> = ({
  labelText,
  name,
  isRequired = false,
  hasError = false,
  children,
}) => {
  const labelId = `${name}-select-label`;

  return (
    <FormControl
      variant='outlined'
      required={isRequired}
      fullWidth
      error={hasError}
    >
      <InputLabel htmlFor={labelId}>{labelText}</InputLabel>
      {children}
    </FormControl>
  );
};

export default SelectControl;
