import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemSelectContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    itemSelectText: {
      fontSize: 16,
      fontWeight: 900,
      color: '#878c96',
      marginRight: 8,
    },
    selectRoot: {
      padding: '3px 24px 0 0',
      color: theme.colorPrimary,
      '&:focus': {
        background: 'none',
      },
    },
    selectIcon: {
      top: 'auto',
      color: theme.colorPrimary,
      fontSize: 26,
    },
  })
);

export default useStyles;
