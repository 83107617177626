import React, { useEffect, useState } from 'react';
import {
  AssetTable,
  IColumn,
  IRow,
} from '../../../../shared/AssetTable/AssetTable/AssetTable';
import { CircularProgress, IconButton } from '@material-ui/core';
import {
  getCardNumber,
  parseConsumption,
} from '../../../../CollectionCards/ChargingSessions/helpers';
import parseSeconds, {
  parseSecondsDigital,
} from '../../../../../utils/parseSeconds';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { ElliTooltip } from '../../../../shared/ElliTooltip/ElliTooltip';
import { IBreadcrumbHandlerProps } from '../../../../../utils/addBreadcrumbHandler';
import { IChargingRecordViewModel } from '../../../../../types/chargingRecords';
import { IRFIDCardModel } from '../../../../../types/subscriber';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import { formatIsoDates } from '../../../../../utils/dates';
import {
  IFieldNames,
  mapFieldsForTable,
} from '../../../../../utils/mapFieldsForTable';
import useStyles from './useStyles';

interface ITimer {
  id: string;
  duration: string;
}

interface IOwnProps {
  chargingSessions: IChargingRecordViewModel[];
  title?: string;
  chargingCards: IRFIDCardModel[];
  addBreadcrumbHandler: (props: IBreadcrumbHandlerProps) => void;
}

export const ChargingSessions: React.FC<IOwnProps> = ({
  chargingSessions = [],
  chargingCards,
  addBreadcrumbHandler,
}) => {
  const classes = useStyles();
  const [timers, setTimers] = useState<ITimer[]>([]);
  const [timerIntervals, setTimerIntervals] = useState<ReturnType<
    typeof setInterval
  > | null>(null);

  useEffect(() => {
    if (timerIntervals) {
      clearInterval(timerIntervals);
    }
    chargingSessions.forEach((session) => {
      if (session.type === 'active') {
        let difInSec = differenceInSeconds(
          new Date(),
          new Date(session.creation_date)
        );
        setTimerIntervals(
          setInterval(() => {
            setTimers((current) => {
              const withoutNew = current.filter(
                (timer) => timer.id !== session.id
              );
              return [
                ...withoutNew,
                {
                  id: session.id,
                  duration: parseSecondsDigital(difInSec),
                },
              ];
            });
            difInSec++;
          }, 1000)
        );
      }
    });
  }, [chargingSessions]);

  const fieldNames: IFieldNames = {
    start_date: {
      name: 'Start Date',
    },
    duration: {
      name: 'Duration',
    },
    consumption: {
      name: 'Consumption',
    },
    card_used: {
      name: 'Card Used',
    },
    actions: {
      name: '',
      width: '36px',
    },
  };

  const getColumns = (): IColumn[] => mapFieldsForTable(fieldNames, []);

  const getTimer = (id: string): string => {
    const timer = timers.find((timer) => timer.id === id);
    if (timer) {
      return timer.duration;
    }

    return '-';
  };

  const getRows = (): IRow[] => {
    if (!chargingSessions || !chargingSessions.length) {
      return [];
    }

    return Object.values(chargingSessions).map((cs) => {
      return {
        data: {
          start_date: {
            content: (
              <div data-testid='start-date-data'>
                {formatIsoDates(cs.creation_date)}
              </div>
            ),
          },
          duration: {
            content: (
              <span data-testid='duration-data'>
                {cs.type === 'active' ? (
                  <span className={classes.valueWithIcon}>
                    <span data-testid='data-timer'>{getTimer(cs.id)}</span>
                    <CircularProgress
                      size={14}
                      classes={{
                        root: classes.circleProgress,
                      }}
                    />
                  </span>
                ) : (
                  parseSeconds(cs.duration)
                )}
              </span>
            ),
          },
          consumption: {
            content: (
              <ElliTooltip title={parseConsumption(cs.consumption)}>
                <span
                  className={`${classes.textOverflow}`}
                  data-testid='consumption'
                >
                  {parseConsumption(cs.consumption)}
                </span>
              </ElliTooltip>
            ),
          },
          card_used: {
            content: getCardNumber(
              cs.card_id,
              cs.rfid_card_serial_number,
              addBreadcrumbHandler,
              chargingCards,
              {
                textOverflow: classes.textOverflow,
              }
            ),
          },
          actions: {
            content:
              cs.type === 'active' ? (
                <IconButton
                  color='primary'
                  data-testid='charging-session-icon'
                  onClick={() =>
                    addBreadcrumbHandler({
                      component: 'ActiveSessionDetails',
                      friendlyText: 'Charging Session',
                      id: cs.id,
                    })
                  }
                >
                  <ChevronRightIcon fontSize='large' />
                </IconButton>
              ) : (
                <IconButton
                  color='primary'
                  data-testid='charging-session-icon'
                  onClick={() =>
                    addBreadcrumbHandler({
                      component: 'ChargingSessionDetails',
                      friendlyText: 'Charging Session Details',
                      id: cs.id,
                    })
                  }
                >
                  <ChevronRightIcon fontSize='large' />
                </IconButton>
              ),
          },
        },
        id: cs.id,
      };
    });
  };

  return (
    <div>
      <AssetTable
        condensed={true}
        data-testid='asset-table'
        bulkActions={[]}
        columns={getColumns()}
        rows={getRows()}
        sortRequestHandler={() => true}
        noDataTitle='No charging sessions available'
        noDataSubTitle='There has been no charging sessions at this station.'
      />
    </div>
  );
};

export default ChargingSessions;
