import React, { useEffect, useState } from 'react';
import MarkunreadMailboxOutlinedIcon from '@material-ui/icons/MarkunreadMailboxOutlined';
import { Typography } from '@material-ui/core';
import {
  AssetTable,
  IColumn,
  IRow,
} from '../../shared/AssetTable/AssetTable/AssetTable';
import {
  IBreadcrumbHandlerProps,
  addBreadcrumbHandler,
} from '../../../utils/addBreadcrumbHandler';
import connector, { IPropsFromState } from '../../Connector/Connector';

import { CardOrderStateModel } from '../../../store/state/cardorders';
import { ElliTooltip } from '../../shared/ElliTooltip/ElliTooltip';
import { ICardOrderModel } from '../../../types/subscriber';
import { formatIsoDates } from '../../../utils/dates';
import {
  IFieldNames,
  mapFieldsForTable,
} from '../../../utils/mapFieldsForTable';
import { parseAddress } from '../../../utils/parseAddress';
import useSharedStyles from '../../shared/useSharedStyles';
import { sortRequestHandler as sortStateHandler } from '../../../utils/sortRequestHander';

import { useCurrentTask } from '../../../custom-hooks/useCurrentTask';
import useStyles from './useStyles';

type TOwnProps = IPropsFromState & {
  crumbId?: string;
};

export interface ISortState {
  sortByColumnKey: string | null;
  sortDesc: boolean;
}

const fieldNames: IFieldNames = {
  item_id: {
    name: 'Item id',
    width: '180px',
  },
  shipping_address: {
    name: 'Shipping Address',
    width: '165px',
  },
  status: {
    name: 'Status',
    width: '100px',
  },
  order_date: {
    name: 'Order Date',
    width: '165px',
  },
  orderer: {
    name: 'Ordered By',
    width: '100px',
  },
  reason: {
    name: 'Order Reason',
    width: '165px',
  },
};

const addClickableLink = (data: string): string => {
  return data === '-' ? '' : 'clickableCell';
};

const getColumns = (): IColumn[] => {
  const sortableColumns: string[] = [
    'item_id',
    'status',
    'order_date',
    'shipping_address',
    'orderer',
    'reason',
  ];

  return mapFieldsForTable(fieldNames, sortableColumns);
};

export const OrdersCollection: React.FC<TOwnProps> = ({
  addBreadcrumb,
  breadcrumbState,
  cardOrdersState,
  chargingCardState,
  sortCardOrders,
  crumbId,
}) => {
  const taskSid = useCurrentTask();
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const cardOrders = cardOrdersState && cardOrdersState[taskSid];
  const taskBreadcrumbState = breadcrumbState && breadcrumbState[taskSid];
  const chargingCards = chargingCardState && chargingCardState[taskSid];

  const [sortState, setSortState] = useState<ISortState>({
    sortByColumnKey: 'order_date',
    sortDesc: false,
  });

  const getReasonText = (reason: ICardOrderModel['reason']) => {
    if (!reason) {
      return '';
    }

    return (
      <ElliTooltip title={reason}>
        <span className={sharedClasses.textOverflow}>{reason}</span>
      </ElliTooltip>
    );
  };

  const sortRequestHandler = (columnkey: string | null): void => {
    sortStateHandler(columnkey, sortState, setSortState);
  };

  const breadcrumbHandler = (props: IBreadcrumbHandlerProps): void =>
    addBreadcrumbHandler({
      ...props,
      addBreadcrumb,
      taskSid,
      crumbId,
      taskBreadcrumbState,
    });

  useEffect(() => {
    sortCardOrders({
      taskSid,
      sortState,
    });
  }, [sortState, taskSid, sortCardOrders]);

  const getCardNumber = (
    iCOM: ICardOrderModel | undefined
  ): string | JSX.Element => {
    if (iCOM) {
      const card =
        chargingCards.data &&
        chargingCards.data.find((cc) => cc.id === iCOM.rfid_card.id);
      if (card) {
        return (
          <div
            className={addClickableLink(card.number)}
            data-testid='card-link'
            onClick={() =>
              breadcrumbHandler({
                component: 'ChargingCardDetails',
                friendlyText: 'Charging Card Details',
                id: card.id,
              })
            }
          >
            {card.number}
          </div>
        );
      }
    }
    return (
      <div data-testid='card-link'>{iCOM ? iCOM.rfid_card.number : '-'}</div>
    );
  };

  const getRows = (orders: CardOrderStateModel): IRow[] => {
    if (!(orders && orders.data && orders.data.length > 0)) {
      return [];
    }

    return orders.data.map((cardOrder) => {
      return {
        data: {
          item_id: {
            content: getCardNumber(cardOrder),
          },
          status: {
            content: cardOrder.status,
          },
          order_date: {
            content: cardOrder.created_at
              ? formatIsoDates(cardOrder.created_at)
              : '-',
          },
          shipping_address: {
            content: cardOrder.shipping_address ? (
              <ElliTooltip title={parseAddress(cardOrder.shipping_address)}>
                <span className={sharedClasses.textOverflow}>
                  {parseAddress(cardOrder.shipping_address)}
                </span>
              </ElliTooltip>
            ) : (
              '-'
            ),
          },
          orderer: {
            content: cardOrder.orderer?.last_name || '',
          },
          reason: {
            content: getReasonText(cardOrder.reason),
          },
        },
        id: cardOrder.id,
      };
    });
  };

  return (
    <div
      className={sharedClasses.cardElement}
      data-testid='orders-collection-ui-component'
    >
      <div className={sharedClasses.sectionWrap}>
        <div className={classes.logoContainer}>
          <MarkunreadMailboxOutlinedIcon
            style={{
              color: '#6633cc',
              fontSize: 24,
            }}
          />
        </div>
        <Typography classes={{ root: sharedClasses.sectionTitle }} variant='h2'>
          Orders
        </Typography>
      </div>
      <section>
        <AssetTable
          bulkActions={[]}
          columns={getColumns()}
          rows={getRows(cardOrders)}
          sortingState={sortState}
          sortRequestHandler={sortRequestHandler}
          noDataTitle='No orders available'
          noDataSubTitle='User has not yet made an order.'
        />
      </section>
    </div>
  );
};

export default connector(OrdersCollection);
