import './animation.css';

import * as Flex from '@twilio/flex-ui';

import React, { useEffect, useState } from 'react';
import {
  checkCrm,
  checkVerifiedCustomer,
  parseURLparameters,
} from '../../utils/parseURLparameters';
import connector, { IPropsFromState } from '../Connector/Connector';

import ActionPanel from './ActionPanel';
import { BreadcrumbModel } from '../../store/state/breadcrumbs';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import CloseIcon from '@material-ui/icons/Close';
import DetailPanels from './DetailPanels';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import StaticPanel from './StaticPanel';
import VerifyCustomer from '../VerifyCustomer/VerifyCustomer';
import WelcomePanel from '../WelcomeScreen';
import decodeToken from '../../utils/decodeToken';
import { isCCManager } from '../../utils/isRole';
import useSharedStyles from '../shared/useSharedStyles';

import throttle from 'lodash/throttle';
import { useBeforeunload } from 'react-beforeunload';
import { useCurrentTask } from '../../custom-hooks/useCurrentTask';
import { useDebounceResizeObserver } from '../../custom-hooks/useDebounceResizeObserver';
import useStyles from './useStyles';

type TProps = IPropsFromState & {
  task?: Flex.ITask;
};

export const CRMPanel: React.FC<TProps> = ({
  task,
  userState,
  urlQuery,
  userSearchState,
  breadcrumbState,
  getUserRequest,
  verifyUser,
  clearAll,
  getUserSearchRequest,
  clearSelectedUser,
  setVerificationStep,
  openVerificationFlow,
  getRFIDCardsRequest,
  getTariffsRequest,
  setCurrentBreadcrumb,
}) => {
  useBeforeunload((event) => event.preventDefault());
  const taskSid = useCurrentTask();
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const isManager = isCCManager();
  const { ref, width } = useDebounceResizeObserver(500);

  const user = userState && userState[taskSid];
  const userSearched = userSearchState && userSearchState[taskSid];
  const taskBreadcrumbState = breadcrumbState && breadcrumbState[taskSid];

  const [userId, setUserId] = useState<string>('');
  const [verifyDisplayState, setVerifyDisplayState] = useState<boolean>(false);
  const [firstRender, setFirstRender] = useState(true);
  const [emailFromURL, setEmailFromURL] = useState('');
  const [isDataLoadingFromAPI, setDataLoadingFromAPI] = useState(false);
  const [programScroll, setProgramScroll] = useState<boolean>(false);
  const [
    currentBreadcrumb,
    setLocalCurrentBreadcrumb,
  ] = useState<BreadcrumbModel | null>(null);

  const urlParams = parseURLparameters(urlQuery);
  const isCrm = checkCrm(urlParams);
  const isCustomerVerified = checkVerifiedCustomer(urlParams);

  const userSearchedData =
    userSearched &&
    userSearched.data &&
    userSearched.data[0] &&
    userSearched.data[0].iam_user.id;

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [ref]);

  useEffect(() => {
    if (taskBreadcrumbState) {
      const current = taskBreadcrumbState.breadcrumbs.find((bc) => bc.current);

      if (current) {
        setLocalCurrentBreadcrumb(current);
      }
    }
  }, [taskBreadcrumbState]);

  useEffect(() => {
    openVerificationFlow({ taskSid, open: false });
    setVerificationStep({ taskSid, step: 1 });
    getRFIDCardsRequest();
    getTariffsRequest({ taskSid });
  }, []);

  useEffect(() => {
    if (isCrm && firstRender) {
      if (isCustomerVerified) {
        getUserSearchRequest({
          taskSid: taskSid,
          query: urlParams.email,
        });
        if (userSearchedData) {
          try {
            setFirstRender(false);
            setUserId(userSearchedData);
            clearSelectedUser({ taskSid: taskSid });
          } catch (error) {
            setUserId('');
            clearSelectedUser({ taskSid: taskSid });
          }
        }
      } else {
        setEmailFromURL(urlParams.email);
        setVerifyDisplayState(true);
      }
    }
  }, [firstRender, userSearchedData, isCrm, isCustomerVerified, taskSid]);

  useEffect(() => {
    if (task) {
      const { email, token } = task.attributes;

      if (token) {
        try {
          setUserId(decodeToken(token).uid);
        } catch (error) {
          setUserId('');
        }
      }

      if (email) {
        setVerifyDisplayState(true);
      }
    } else {
      // this is currently the only solution to make RHL possible as the CRMPanel is re-mounted on each code change
      if (process.env.NODE_ENV === 'production') {
        setUserId('');
        clearAll({ taskSid });
      }
    }
  }, [task, clearAll, taskSid]);

  useEffect(() => {
    if (userId) {
      clearAll({ taskSid });
      setDataLoadingFromAPI(true);
      getUserRequest({ userId, taskSid });
      verifyUser({ verified: true, taskSid });
    }
  }, [taskSid, userId, getUserRequest, verifyUser]);

  useEffect(() => {
    if (currentBreadcrumb && ref.current) {
      const newPosition = currentBreadcrumb.startPoint;

      if (newPosition !== undefined && programScroll) {
        ref.current.scroll({
          top: 0,
          left: newPosition - 12,
          behavior: 'smooth',
        });

        setProgramScroll(false);
      }
    }
  }, [currentBreadcrumb]);

  const scrollHandler = (): void => {
    let newCurrentBreadcrumb = undefined;

    if (taskBreadcrumbState) {
      newCurrentBreadcrumb = taskBreadcrumbState.breadcrumbs.find((bc) => {
        if (bc.startPoint !== undefined && bc.midPoint !== undefined) {
          return (
            (ref.current as HTMLDivElement).scrollLeft > bc.startPoint - 300 &&
            (ref.current as HTMLDivElement).scrollLeft < bc.midPoint
          );
        }

        return false;
      });
    }

    if (
      newCurrentBreadcrumb &&
      currentBreadcrumb &&
      newCurrentBreadcrumb.id !== currentBreadcrumb.id
    ) {
      setCurrentBreadcrumb({
        taskSid,
        id: newCurrentBreadcrumb.id as string,
      });
    }
  };

  const setBreadcrumb = (code: string): void => {
    if (currentBreadcrumb) {
      const currentPos = taskBreadcrumbState.breadcrumbs.findIndex(
        (bc) => bc.id === currentBreadcrumb.id
      );

      const position = code === 'ArrowLeft' ? -1 : 1;

      const newPosition =
        taskBreadcrumbState.breadcrumbs[currentPos + position];
      if (newPosition && newPosition.id) {
        setProgramScroll(true);
        setCurrentBreadcrumb({ taskSid, id: newPosition.id });
      }
    }
  };

  const keyListener = (event: React.KeyboardEvent<HTMLElement>): void => {
    const elementType = (event.target as HTMLElement).tagName || '';
    const handledKeys = ['ArrowLeft', 'ArrowRight'];
    const ignoredElements = ['input', 'textarea'];

    if (
      handledKeys.includes(event.key) &&
      !ignoredElements.includes(elementType.toLowerCase())
    ) {
      event.preventDefault();
      setBreadcrumb(event.key);
    }
  };

  const hasUserData = Boolean(user && user.data);

  return (
    <div
      onWheel={throttle(() => scrollHandler(), 500)}
      onKeyDown={(event) => keyListener(event)}
      className={classes.crmWrapper}
      data-testid='crm-panel'
      ref={ref}
      tabIndex={0}
    >
      {isManager ? (
        isDataLoadingFromAPI ? (
          <LoadingScreen task={task} setDataLoading={setDataLoadingFromAPI} />
        ) : hasUserData ? (
          <>
            <div className={classes.topBar}>
              <VerifyCustomer
                setDataLoading={setDataLoadingFromAPI}
                emailFromURL={emailFromURL}
                startOpen={false}
                width={width}
              />
              <Breadcrumbs
                containerRef={ref}
                setProgramScroll={setProgramScroll}
              />
            </div>
            <div
              className={
                isCrm ? classes.closeIconCRM : classes.closeIconWrapper
              }
            >
              <CloseIcon
                style={{ fill: 'white', fontSize: 14 }}
                onClick={() => clearAll({ taskSid })}
              />
            </div>
            <div className={classes.contentWrapper}>
              <div className={sharedClasses.overview}>
                <StaticPanel />
                <ActionPanel />
              </div>
              <DetailPanels containerRef={ref} />
            </div>
          </>
        ) : (
          <div className={classes.welcomeWrapper}>
            <WelcomePanel
              setDataLoading={setDataLoadingFromAPI}
              verifyDisplayState={verifyDisplayState}
              emailFromURL={emailFromURL}
              width={width}
            />
          </div>
        )
      ) : (
        <div className={classes.welcomeWrapper}>
          <WelcomePanel
            setDataLoading={setDataLoadingFromAPI}
            verifyDisplayState={verifyDisplayState}
            emailFromURL={emailFromURL}
            width={width}
          />
        </div>
      )}
    </div>
  );
};

export default connector(CRMPanel);
