import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      padding: 48,
      minWidth: 350,
    },
    stepper: { fontSize: '2em', width: '100%' },
    stepperButton: {
      borderColor: theme.colorPrimary,
      color: theme.colorPrimary,
      width: '100%',
    },
    warningNote: {
      marginTop: '1em',
      color: theme.colorAlertSecondary,
    },
  })
);

export default useStyles;
