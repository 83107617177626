import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  pdfWrapper: {
    width: '100%',
    height: 800,
  },
});

export default useStyles;
