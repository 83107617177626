import React from 'react';
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  Typography,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import Drawer from '@material-ui/core/Drawer';
import { ElliTooltip } from '../../shared/ElliTooltip/ElliTooltip';
import useStyles from './useStyles';

export interface IFilterOption {
  name: string;
  checked: boolean;
  filterName: string;
  filterFunc: (val: any) => boolean;
}

export interface IFilter {
  filterName: string;
  options: IFilterOption[];
}

export interface IFilters {
  filters: IFilter[];
}

interface IProps {
  filterDrawerState: boolean;
  toggleFiltersDrawer: (
    open: boolean
  ) => (event: React.MouseEvent) => void | undefined;
  handleFilterChange: (option: IFilterOption) => void;
  filterState: IFilters;
  setFilterState: React.Dispatch<React.SetStateAction<IFilters>>;
  selectedFilters: IFilterOption[];
  clearAll: () => void;
}

const TableFilter: React.FC<IProps> = ({
  filterDrawerState,
  toggleFiltersDrawer,
  handleFilterChange,
  filterState,
  selectedFilters,
  clearAll,
}) => {
  const classes = useStyles();

  const list = () => (
    <div
      data-testid='table-filter'
      className={classes.list}
      role='presentation'
    >
      <div className={classes.closeButtonWrapper}>
        <IconButton
          data-testid='close-icon'
          onClick={toggleFiltersDrawer(false)}
          color='primary'
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.topDrawer}>
        <Typography classes={{ root: classes.mainFilterHeading }} variant='h1'>
          Filters ({selectedFilters.length})
        </Typography>
        <Button
          className={classes.clearAllButton}
          color='primary'
          onClick={clearAll}
        >
          Clear all
        </Button>
      </div>
      <Divider />
      <div className={classes.filterSection}>
        <div className={classes.filterOptions}>
          {filterState.filters.map((filter, index) => (
            <React.Fragment key={filter.filterName}>
              <div className={classes.filterOption}>
                <Typography
                  classes={{
                    root: classes.filterTitle,
                  }}
                  variant='h2'
                >
                  {filter.filterName}
                </Typography>
                {filter.options.map((option) => (
                  <FormControlLabel
                    key={option.name}
                    classes={{
                      label: classes.checkboxLabel,
                    }}
                    control={
                      <Checkbox
                        data-testid='filter-checkbox'
                        className={classes.checkBox}
                        checked={option.checked}
                        onChange={() => handleFilterChange(option)}
                        name={option.name}
                        color='primary'
                      />
                    }
                    label={
                      <ElliTooltip title={option.name}>
                        <span
                          className={classes.tooltipOverflow}
                          data-testid='option'
                        >
                          {option.name}
                        </span>
                      </ElliTooltip>
                    }
                  />
                ))}
              </div>
              {index !== filterState.filters.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <Drawer
      data-testid='filter-drawer'
      anchor='right'
      open={filterDrawerState}
      onClose={toggleFiltersDrawer(false)}
    >
      {list()}
    </Drawer>
  );
};

export default TableFilter;
