import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    noMapDataOverlay: {
      zIndex: 10,
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.63)',
    },
    noMapDataTextBox: {
      border: 'solid 2px #878c96',
      backgroundColor: '#f5f6f8',
      padding: '8px 20px',
    },
    noMapDataText: {
      lineHeight: 1.5,
      color: '#878c96',
    },
    mapLoading: {
      visibility: 'hidden',
    },
    refreshButton: {
      width: '40px',
      height: '40px',
      top: '16px',
      cursor: 'pointer',
      right: '16px',
      position: 'absolute',
      backgroundColor: '#ffffff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

export default useStyles;
