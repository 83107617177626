import * as Msal from 'msal';

import axios, { AxiosRequestConfig } from 'axios';
import { msalConfig, scopes } from '../msalConfig';

import { config } from '../config';

interface ILoginRequest {
  scopes: string[];
  state?: string;
}

export default class AuthService {
  myMSALObj: Msal.UserAgentApplication;
  loginRequest: ILoginRequest;
  idToken: string | null;
  bearerToken: string | null;

  constructor() {
    this.myMSALObj = new Msal.UserAgentApplication(msalConfig);
    this.loginRequest = { scopes };
    this.idToken = sessionStorage.getItem('msal.idtoken');
    this.bearerToken = sessionStorage.getItem('bearer');
  }

  async fetchIdToken(): Promise<void> {
    try {
      const response = await this.myMSALObj.acquireTokenSilent({ scopes });
      await this.exchangeToken(
        response.idToken.rawIdToken,
        response.accessToken
      );
    } catch (error) {
      sessionStorage.clear();

      const loginErrors = [
        'user_login_error',
        'consent_required',
        'interaction_required',
        'login_required',
      ];

      if (loginErrors.includes(error.errorCode)) {
        await this.fetchIdTokenInteractive();
      }

      throw error;
    }
  }

  async fetchIdTokenInteractive(): Promise<void> {
    await this.myMSALObj.loginPopup(this.loginRequest);
    // the response of loginPopup contains null as the access_token, the access_token
    // thus requires a call to acquireTokenSilent, reason unknown.
    const response = await this.myMSALObj.acquireTokenSilent(this.loginRequest);
    await this.exchangeToken(response.idToken.rawIdToken, response.accessToken);
  }

  async exchangeToken(
    idToken: string,
    accessToken: string,
    endpoint = 'login'
  ): Promise<void> {
    try {
      const reqConfig: AxiosRequestConfig = {
        url: `${config.elliAuthUrl || config.elliBaseUrl}/identity/${endpoint}`,
        method: 'POST',
        data: {
          token: idToken,
          access_token: accessToken,
          type: 'oidc',
        },
        headers: { 'Content-Type': 'application/json' },
      };
      const res = await axios.request(reqConfig);
      sessionStorage.setItem('bearer', res.data.token);
      return;
    } catch (error) {
      if (endpoint !== 'signup') {
        await this.exchangeToken(idToken, accessToken, 'signup');
        return;
      }

      sessionStorage.clear();
      throw error;
    }
  }

  static logout(): void {
    sessionStorage.removeItem('bearer');
  }
}
