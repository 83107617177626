import React, { useEffect, useState } from 'react';
import connector, { IPropsFromState } from '../../Connector/Connector';

import {
  addBreadcrumbHandler,
  IBreadcrumbHandlerProps,
} from '../../../utils/addBreadcrumbHandler';
import { formatIsoDates } from '../../../utils/dates';
import { lexicographical } from '../../../utils/comparisonFunctions';
import { parseAddress } from '../../../utils/parseAddress';
import { useCurrentTask } from '../../../custom-hooks/useCurrentTask';
import { useTypedTranslation } from '../../../custom-hooks/useTypedTranslation';
import { useStyles } from './useStyles';

import { ActionBar } from '../../shared/ActionBar/ActionBar';
import ActionButtons from './ActionButtons';
import CardElement from '../../shared/CardElement/CardElement';
import ChangeBillingModal from './ChangeBillingModal';
import ChargingReadiness from './ChargingReadiness';
import CopyTextFieldList from '../../ui/CopyTextField/CopyTextFieldList';
import { DialogChargingCard } from '../../ChargingCards/DialogChargingCard';
import { Divider, Grid, Typography } from '@material-ui/core';
import EditIcon from '../../../assets/image/edit.svg';
import {
  getSubscriptionStatus,
  StatusIndicator,
} from '../../CardsUI/StatusIndicator/StatusIndicator';
import { IActionItem } from '../../shared/ActionDropdownContainer';
import InformationItem from '../../shared/InformationItems/InformationItem';
import { IRFIDCardModel, ISubscriptionModel } from '../../../types/subscriber';
import { ITariffConditionModel, ITariffModel } from '../../../types/tariff';
import OfflineBoltOutlinedIcon from '@material-ui/icons/OfflineBoltOutlined';
import { TariffService } from '../../../services/tariffs';
import Tooltip from '../../ui/Tooltip';
import { TOOLTIP_NAME } from '../../DetailCards/Subscription/Status/Status';

type SubscriptionType = 'upcoming' | 'active';
const hasSubscriptionWithType = (
  subscriptions: ISubscriptionModel[],
  type: SubscriptionType
) => {
  return subscriptions.some((sub) => sub.status === type);
};

const findUpcomingSubscription = (subscriptionData: ISubscriptionModel[]) => {
  return subscriptionData.find((sub) => sub.status === 'upcoming');
};

const sortSubscriptionData = (subscriptionData: ISubscriptionModel[]) => {
  subscriptionData.sort((a, b) => {
    const date1 = new Date(a.created_at);
    const date2 = new Date(b.created_at);
    return lexicographical(date1, date2) * -1;
  });
};

const nullSubscription: ISubscriptionModel = {
  id: '-',
  start_date: '-',
  end_date: '-',
  status: 'inactive',
  subscriber_id: '-',
  base_condition_id: '-',
  expiration_reminder_sent_at: '-',
  created_at: '-',
  updated_at: '-',
  automatic_renewal: true,
  notice_period_end_date: '-',
  tariff_id: '-',
  currency: '-',
  monthly_fee_net_amount: 0,
  monthly_fee_vat_amount: 0,
  monthly_fee_vat_percentage: 0,
  monthly_fee: 0,
  sign_up_fee_net_amount: 0,
  sign_up_fee_vat_amount: 0,
  sign_up_fee_vat_percentage: 0,
  sign_up_fee: 0,
};

export interface IChargingCardDialog {
  rfidUid: string;
  number?: string;
  tag?: string;
}

export type TProps = IPropsFromState & {
  crumbId?: string;
};

export const SubscriptionsUI: React.FC<TProps> = ({
  subscriberState,
  subscriptionState,
  chargingCardState,
  cardOrdersState,
  addBreadcrumb,
  chargingSessionsState,
  invoicesState,
  tariffsState,
}) => {
  const classes = useStyles();
  const taskSid = useCurrentTask();
  const { t, terms } = useTypedTranslation();
  const subscriber = subscriberState && subscriberState[taskSid];
  const subscription = subscriptionState && subscriptionState[taskSid];
  const chargingCards = chargingCardState && chargingCardState[taskSid];
  const chargingSessions =
    chargingSessionsState && chargingSessionsState[taskSid];
  const orders = cardOrdersState && cardOrdersState[taskSid];
  const invoices = invoicesState && invoicesState[taskSid];

  const [subscriptionData, setSubscriptionData] = useState<ISubscriptionModel>(
    nullSubscription
  );
  const [
    upcomingTariff,
    setUpcomingTariff,
  ] = useState<ITariffConditionModel | null>(null);
  const [upcomingSubscriptionId, setUpcomingSubscriptionId] = useState<string>(
    ''
  );
  const [
    subscriptionTariff,
    setSubscriptionTariff,
  ] = useState<ITariffConditionModel | null>(null);

  const [
    subscriptionChargingCard,
    setSubscriptionChargingCard,
  ] = useState<IRFIDCardModel | null>(null);

  const [dialogData, setDialogData] = useState<IChargingCardDialog | null>(
    null
  );

  const [tariff, setTariff] = useState<ITariffModel | null>(null);

  useEffect(() => {
    if (subscriptionData) {
      const getTariff = async () => {
        try {
          const subscriptionTariffData = await TariffService.getByConditionId(
            subscriptionData.tariff_id,
            subscriptionData.base_condition_id
          );
          setSubscriptionTariff(subscriptionTariffData);
        } catch (error) {
          setSubscriptionTariff(null);
        }
      };

      const tariffs = tariffsState[taskSid];
      if (tariffs?.data) {
        setTariff(
          tariffs.data.find(({ id }) => id === subscriptionData.tariff_id) ||
            null
        );
      }

      if (
        subscriptionData.tariff_id !== '-' &&
        subscriptionData.base_condition_id !== '-'
      ) {
        getTariff();
      }
    }
  }, [subscriptionData]);

  useEffect(() => {
    if (subscriptionData && chargingCards && chargingCards.data) {
      const currentCard = chargingCards.data.find(
        (cc) => cc.subscription_id === subscriptionData.id
      );
      setSubscriptionChargingCard(currentCard || null);
      if (currentCard) {
        setDialogData({
          rfidUid: currentCard.id,
          number: currentCard.number,
          tag: currentCard.tag,
        });
      }
    }
  }, [subscriptionData, chargingCards]);

  const setAvailableSubscriptionData = (
    subscriptionData: ISubscriptionModel[]
  ) => {
    const hasActiveSubscription = hasSubscriptionWithType(
      subscriptionData,
      'active'
    );
    if (hasActiveSubscription) {
      const latestActiveSubscription = subscriptionData.find(
        (sub) => sub.status === 'active'
      );
      setSubscriptionData(latestActiveSubscription as ISubscriptionModel);
    } else {
      setSubscriptionData(subscriptionData[0]);
    }
  };

  useEffect(() => {
    if (subscription && subscription.data && subscription.data.length >= 1) {
      sortSubscriptionData(subscription.data);
      setAvailableSubscriptionData(subscription.data);
    } else {
      setSubscriptionData(nullSubscription);
    }
  }, [subscriptionState, taskSid]);

  useEffect(() => {
    const onSetUpcomingTariff = async (
      subscriptionData: ISubscriptionModel | undefined
    ) => {
      if (subscriptionData === undefined) {
        setUpcomingTariff(null);
        return;
      }
      try {
        const tariff = await TariffService.getByConditionId(
          subscriptionData.tariff_id,
          subscriptionData.base_condition_id
        );
        setUpcomingTariff(tariff);
      } catch (err) {
        setUpcomingTariff(null);
      }
    };

    if (subscription && subscription.data && subscription.data.length >= 1) {
      const hasUpcomingSubscription = hasSubscriptionWithType(
        subscription?.data,
        'upcoming'
      );
      if (hasUpcomingSubscription) {
        const upcomingSubscription = findUpcomingSubscription(
          subscription?.data
        );
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        // Due to the hasUpcomingSubscription check we can be sure that upcomingSubscription won't be undefined
        setUpcomingSubscriptionId(upcomingSubscription!.id);
        onSetUpcomingTariff(upcomingSubscription);
      } else {
        setUpcomingTariff(null);
      }
    }
  }, [subscription?.data]);

  const breadcrumbHandler = (props: IBreadcrumbHandlerProps): void =>
    addBreadcrumbHandler({
      ...props,
      addBreadcrumb,
      taskSid,
      level: 1,
    });

  const actionItems: IActionItem[] = [
    {
      icon: (
        <OfflineBoltOutlinedIcon
          style={{
            fontSize: 24,
          }}
        />
      ),
      friendlyText: 'Charging Readiness',
      component: <ChargingReadiness />,
    },
    {
      icon: <img src={EditIcon} alt='Edit Billing Address' />,
      friendlyText: 'Edit Billing Address',
      component: <ChangeBillingModal />,
      disabled: !subscriber?.data,
    },
  ];

  const generateTooltipData = (title: string, content?: string) => [
    {
      title,
      text: content || '-',
    },
  ];

  const getItems = () => {
    return [
      {
        id: 'tariff-name',
        headline: (
          <>
            {t(terms.public_charging.tariff_name)}
            <div className={classes.tooltipWrapper}>
              <Tooltip tooltipName='tariff-info'>
                <CopyTextFieldList
                  textList={generateTooltipData('Tariff Code', tariff?.code)}
                />
              </Tooltip>
            </div>
          </>
        ),
        content: (
          <>
            {subscriptionTariff ? (
              <Typography
                classes={{
                  root: classes.textWithLink,
                }}
                data-testid='tariff-name'
                variant='body2'
                onClick={() =>
                  breadcrumbHandler({
                    component: 'SubscriptionDetails',
                    friendlyText: 'Subscription',
                    id: subscriptionData.id,
                  })
                }
              >
                {subscriptionTariff.name}
              </Typography>
            ) : (
              '-'
            )}
          </>
        ),
      },
      {
        id: 'subscription-status-title',
        headline: t(terms.public_charging.status),
        content: (
          <>
            <StatusIndicator
              status={getSubscriptionStatus(subscriptionData)}
              className={classes.activationStatus}
            />
            <Typography className={classes.statusText} variant='body2'>
              {getSubscriptionStatus(subscriptionData)}
            </Typography>
          </>
        ),
      },
      {
        id: 'signup-date-id',
        headline: (
          <>
            {t(terms.public_charging.signup_date)}
            <div className={classes.tooltipWrapper}>
              <Tooltip tooltipName={TOOLTIP_NAME}>
                <CopyTextFieldList
                  textList={generateTooltipData(
                    'Subscription ID',
                    subscriptionData.id
                  )}
                />
              </Tooltip>
            </div>
          </>
        ),
        content: (
          <Typography variant='body2'>
            {formatIsoDates(subscriptionData.start_date)}
          </Typography>
        ),
      },
      {
        id: 'preliminary-end',
        headline: (
          <Typography data-testid='preliminary-end-title' variant='h6'>
            {t(terms.public_charging.preliminary_end)}
          </Typography>
        ),
        content: (
          <Typography data-testid='preliminary-end' variant='body2'>
            {formatIsoDates(subscriptionData.end_date)}
          </Typography>
        ),
      },
      {
        id: 'customer-address',
        headline: (
          <Typography data-testid='customer-address-title' variant='h6'>
            {t(terms.public_charging.billing_address)}
          </Typography>
        ),
        content: (
          <Typography data-testid='customer-address' variant='body2'>
            {(subscriber?.data && parseAddress(subscriber.data.address)) || '-'}
          </Typography>
        ),
      },
      {
        id: 'linked-charging-cards',
        headline: (
          <>
            {t(terms.public_charging.linked_charging_card)}
            {dialogData && (
              <div className={classes.tooltipWrapper}>
                <Tooltip tooltipName='more-info'>
                  <DialogChargingCard
                    rfidUid={dialogData.rfidUid}
                    cardNumber={dialogData.number}
                    tag={dialogData.tag}
                  />
                </Tooltip>
              </div>
            )}
          </>
        ),
        content: (
          <>
            {subscriptionChargingCard ? (
              <Typography variant='body2'>
                <span
                  data-testid='linked-charging-card'
                  className={classes.chargingCardLink}
                  onClick={() =>
                    breadcrumbHandler({
                      component: 'ChargingCardDetails',
                      friendlyText: 'Charging Card Details',
                      id: subscriptionChargingCard.id,
                    })
                  }
                >
                  {subscriptionChargingCard.number || '-'}
                </span>
              </Typography>
            ) : (
              '-'
            )}
          </>
        ),
      },
      {
        id: 'upcoming-tariff',
        headline: t(terms.public_charging.upcoming_tariff),
        content: (
          <>
            {upcomingTariff && subscription.data ? (
              <Typography variant='body2'>
                <span
                  data-testid='upcoming-tariff'
                  className={classes.chargingCardLink}
                  onClick={() =>
                    breadcrumbHandler({
                      component: 'SubscriptionDetails',
                      friendlyText: 'Subscription',
                      id: upcomingSubscriptionId,
                    })
                  }
                >
                  {upcomingTariff.name || '-'}
                </span>
              </Typography>
            ) : (
              '-'
            )}
          </>
        ),
      },
    ];
  };

  return subscriptionData.id !== '-' ? (
    <CardElement
      title={t(terms.public_charging.box_title)}
      withActions={true}
      actionItems={actionItems}
    >
      <Grid container className={classes.detailWrapper}>
        {getItems().map((item) => (
          <Grid item xs={item.id === 'customer-address' ? 12 : 6} key={item.id}>
            <InformationItem item={item} />
          </Grid>
        ))}
      </Grid>

      <Divider className={classes.divider} />
      <ActionBar>
        <ActionButtons
          subscription={subscription}
          chargingCards={chargingCards}
          chargingSessions={chargingSessions}
          orders={orders}
          invoices={invoices}
          breadcrumbHandler={breadcrumbHandler}
        />
      </ActionBar>
    </CardElement>
  ) : null;
};

export default connector(SubscriptionsUI);
