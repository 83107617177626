import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translations from './i18n.json';

const resources = {
  de: {
    translation: translations['de-DE'].elli_customer_care_portal,
  },
  en: {
    translation: translations['en-GB'].elli_customer_care_portal,
  },
};

export const availableLanguages = {
  de: 'Deutsch',
  en: 'English',
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
  });

export { i18n };

export const setLanguage = (
  language: keyof typeof availableLanguages
): void => {
  i18n.changeLanguage(language).catch();
};
export const getLanguage = (): string => i18n.language.substr(0, 2);
