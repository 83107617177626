import React, { useEffect, useState } from 'react';
import {
  IRFIDCardModel,
  RfidCardOrderStatus,
} from '../../../../types/subscriber';
import SimpleTable, {
  TTableHead,
  TTableRows,
} from '../../../shared/Table/SimpleTable';

import { IBreadcrumbHandlerProps } from '../../../../utils/addBreadcrumbHandler';
import { ITariffConditionModel } from '../../../../types/tariff';
import { StatusIndicator } from '../../../CardsUI/StatusIndicator/StatusIndicator';
import { Typography } from '@material-ui/core';
import { formatIsoDates } from '../../../../utils/dates';
import useStyles from './useStyles';

type TOwnProps = {
  chargingCard: IRFIDCardModel;
  tariff: ITariffConditionModel | null;
  addBreadcrumbHandler: (props: IBreadcrumbHandlerProps) => void;
  subscriptionId?: string;
  subscriptionStatus?: string;
};

export const PublicCharging: React.FC<TOwnProps> = ({
  chargingCard,
  tariff,
  addBreadcrumbHandler,
  subscriptionId,
  subscriptionStatus,
}) => {
  const classes = useStyles();
  const [tableHeadings, setTableHeadings] = useState<TTableHead[]>([]);
  const [tableRows, setTableRows] = useState<TTableRows[]>([]);

  const setLink = (tar: string | undefined): string | JSX.Element => {
    if (tar && tar !== '-' && subscriptionId) {
      return (
        <span
          className='clickableCell'
          data-testid='subscription-link'
          onClick={() =>
            addBreadcrumbHandler({
              component: 'SubscriptionDetails',
              friendlyText: 'Subscription',
              id: subscriptionId,
            })
          }
        >
          {tar}
        </span>
      );
    }
    return '-';
  };

  useEffect(() => {
    const columns = [
      {
        name: 'Pairing Date',
      },
      {
        name: 'Tariff Name',
      },
      {
        name: 'Status',
      },
    ];

    setTableHeadings(columns);

    const rows = [
      {
        pairingDate: chargingCard.pairing_date
          ? formatIsoDates(chargingCard.pairing_date)
          : '-',
        subscription: tariff ? (
          setLink(tariff.name)
        ) : (
          <span data-testid='tariff-testid'>-</span>
        ),
        status: subscriptionStatus ? (
          <div className={classes.statusWrapper}>
            <StatusIndicator
              status={subscriptionStatus as RfidCardOrderStatus}
              className={classes.activationStatus}
            />
            <Typography
              className={classes.statusText}
              data-testid='subscription-status'
              variant='body2'
            >
              {subscriptionStatus}
            </Typography>
          </div>
        ) : (
          '-'
        ),
      },
    ];

    setTableRows(rows);
  }, [chargingCard, tariff]);

  return <SimpleTable tableHeadings={tableHeadings} tableRows={tableRows} />;
};

export default PublicCharging;
