import { IAddressModel, IShippingAddressModel } from '../types/subscriber';

import { IHomeChargingAddress } from '../types/user';
import { getCountry } from './getCountryFromCode';

export const parseAddress = (
  address?: IShippingAddressModel | IAddressModel
): string => {
  if (!address) {
    return '-';
  }

  let addressLine = '';

  if (address.address_line_1) {
    addressLine += `${address.address_line_1}, `;
  }

  if (address.address_line_2) {
    addressLine += `${address.address_line_2}, `;
  }

  return `${addressLine}${address.city} ${address.zip}, ${getCountry(
    address.country_code
  )}`;
};

export const parseHomeChargingAddress = (
  address?: IHomeChargingAddress
): string => {
  if (!address) {
    return '-';
  }

  let addressLine = '';

  if (address.street && address.street !== ' ') {
    addressLine += `${address.street.trim()}`;

    if (address.zip || address.city || address.country) {
      addressLine += ', ';
    }
  }

  if (address.zip && address.zip !== ' ') {
    addressLine += `${address.zip.trim()}`;

    if (address.city && address.city !== ' ') {
      addressLine += ' ';
    } else if (address.country && address.country !== ' ') {
      addressLine += ', ';
    }
  }

  if (address.city && address.city !== ' ') {
    addressLine += `${address.city.trim()}`;

    if (address.country && address.country !== ' ') {
      addressLine += `, `;
    }
  }

  if (address.country && address.country !== ' ') {
    addressLine += `${getCountry(address.country.trim())}`;
  }

  return addressLine;
};
