import {
  createStyles,
  fade,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: 24,
      color: theme.colorPrimary,
    },
    open: {
      color: theme.surfaceTertiary,
    },
    error: {
      fill: fade(theme.textPrimary, 0.54),
    },
  })
);
