import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      fontWeight: 'normal',
      fontSize: 16,
      color: theme.colorPrimary,
      borderBottom: '1px solid #f5f6f8',
      padding: '12px 16px',
    },
    menuItemText: {
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: 16,
      fontWeight: 900,
    },
    menuList: {
      padding: 0,
    },
    actionIcon: {
      marginRight: 8,
      lineHeight: 1,
      height: 24,
    },
    actionItemWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    paperRoot: {
      boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.08)',
    },
  })
);

export default useStyles;
