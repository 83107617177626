import Store from '../store';

export const getCurrentTaskSid = (state: any) => {
  const selectedTaskSid = state.flex.view.selectedTaskSid;
  const pathname = state.flex.router.location
    ? state.flex.router.location.pathname
    : '';
  const matchedTaskSid = pathname.substring(pathname.lastIndexOf('/') + 1);
  return selectedTaskSid || matchedTaskSid || '';
};

export const useCurrentTask = (): string => {
  return getCurrentTaskSid(Store.getState());
};
