import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  zip: yup
    .string()
    .required()
    .min(2),
  city: yup.string().required(),
  country_code: yup.string().required(),
  address_line_1: yup.string(),
  address_line_2: yup.string(),
  state: yup.string(),
});
