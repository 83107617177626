import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.textPrimary,
    },
    dialog: {
      padding: 48,
      minWidth: 350,
    },
    terminationConfirmationButton: {
      display: 'flex',
      flexDirection: 'column',
      height: '39px',
      justifyContent: 'center',
      marginTop: '12px',
    },
    terminateButton: {
      borderColor: theme.colorAlertPrimary,
      color: theme.colorAlertPrimary,
    },
    terminationConfirmationVinCheckbox: {
      display: 'flex',
      justifyContent: 'center',
    },
  })
);

export default useStyles;
