import React from 'react';
import { TextField } from '@material-ui/core';

export interface IOwnProps {
  id: string;
  label: string;
  name: string;
  isRequired?: boolean;
  inputRef?: React.Ref<unknown>;
  hasError?: boolean;
}

/**
 * Simple input field to decouple third party library from application
 */
const InputField: React.FC<IOwnProps> = ({
  id,
  label,
  name,
  isRequired = false,
  inputRef,
  hasError = false,
}) => {
  return (
    <TextField
      variant='outlined'
      fullWidth
      id={id}
      label={label}
      name={name}
      placeholder={label}
      required={isRequired}
      inputRef={inputRef}
      error={hasError}
    />
  );
};

export default InputField;
