import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useStyles from './useStyles';

interface IOwnProps {
  status: string;
}

interface IStatusType {
  color: string;
  title: string;
  pulse?: boolean;
}

export const Status: React.FC<IOwnProps> = ({ status }) => {
  const classes = useStyles();
  const theme = useTheme();
  const backgroundColorStatusMapping: { [x: string]: IStatusType } = {
    AVAILABLE: { color: theme.colorAccent, title: 'Available' },
    BLOCKED: { color: theme.colorAlertPrimary, title: 'Blocked' },
    CHARGING: {
      color: theme.colorAlertSecondary,
      title: 'Charging',
      pulse: true,
    },
    INOPERATIVE: { color: theme.colorAlertPrimary, title: 'Inoperative' },
    OUTOFORDER: { color: theme.colorAlertPrimary, title: 'Out of order' },
    RESERVED: { color: theme.colorAlertSecondary, title: 'Reserved' },
    UNKNOWN: { color: '#cad3e0', title: 'Unknown' },
  };
  let connectorStatus = backgroundColorStatusMapping[status.toUpperCase()];
  if (!connectorStatus) {
    connectorStatus = backgroundColorStatusMapping['UNKNOWN'];
  }
  return (
    <div className={classes.typeWrapper}>
      <div
        data-testid='status-indicator'
        className={`${classes.indicator} ${connectorStatus.pulse &&
          classes.statusPulse}`}
        style={{ backgroundColor: connectorStatus.color }}
      />
      <div style={{ marginLeft: 8 }}>{connectorStatus.title}</div>
    </div>
  );
};
