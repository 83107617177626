import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    titleWithExpand: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    expandIcon: {
      fontSize: '24px',
      cursor: 'pointer',
      transition: 'transform .3s cubic-bezier(0.87, 0, 0.13, 1) !important',
    },
    expandIconOpen: {
      transform: 'rotate(0)',
    },
    expandIconClosed: {
      transform: 'rotate(180deg)',
    },
    contentWrapper: {
      paddingTop: '16px',
    },
    iconElement: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '24px',
      marginRight: '10px',
    },
  })
);

export default useStyles;
