import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    loadingScreenWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: 'calc(100vh - 44px)',
    },
    closeWrapper: {
      position: 'absolute',
      top: 0,
      right: 0,
      padding: 16,
    },
    closeButton: {},
    loadingScreenBox: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingTop: 68,
      backgroundColor: 'white',
      boxShadow: '0 16px 32px 0 rgba(0, 0, 0, 0.12)',
      height: 638,
      width: 680,
    },
    loadingTitle: {
      marginTop: '16px',
      marginBottom: '32px',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: 1.5,
    },
    loadingInformationList: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      width: '440px',
    },
    loadingInformationListItem: {
      display: 'flex',
      alignItems: 'center',
      width: '50%',
      paddingLeft: '15px',
      paddingRight: '28px',
      marginBottom: '13px',
    },
  })
);

export default useStyles;
