import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    statusWrapper: {
      display: 'flex',
      alignItems: ' center',
    },
    activationStatus: {
      display: 'inline-block',
      marginRight: '4px',
      verticalAlign: 'baseline',
    },
    statusText: {
      textTransform: 'capitalize', // !important',
    },
    subscriptionStatusInfo: {
      color: theme.palette.grey['500'],
      fontSize: '1.2em',
      marginLeft: '.5em',
      cursor: 'pointer',
    },
  })
);

export default useStyles;
