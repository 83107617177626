import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    successMessageWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      '& > img': {
        margin: 32,
        marginTop: 16,
      },
    },
  })
);

export default useStyles;
