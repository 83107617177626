import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() => {
  return createStyles({
    actionContainer: {
      float: 'right',
    },
  });
});

export default useStyles;
