import * as Flex from '@twilio/flex-ui';

import {
  CardOrderState,
  CardOrderStateModel,
} from '../../store/state/cardorders';
import {
  IChargingCardState as ChargingCardState,
  IChargingCardModel as ChargingCardStateModel,
} from '../../store/state/chargingCards';
import {
  ChargingSessionsState,
  ChargingSessionsStateModel,
} from '../../store/state/chargingSessions';
import {
  HomeStationsState,
  HomeStationsStateModel,
} from '../../store/state/homeStations';
import { InvoiceState, InvoiceStateModel } from '../../store/state/invoices';
import {
  OrganizationState,
  OrganizationStateModel,
} from '../../store/state/organization';
import React, { useEffect, useState } from 'react';
import {
  SubscriberState,
  SubscriberStateModel,
} from '../../store/state/subscriber';
import {
  SubscriptionState,
  SubscriptionStateModel,
} from '../../store/state/subscription';
import { UserState, UserStateModel } from '../../store/state/user';
import connector, { IPropsFromState } from '../Connector/Connector';

import CloseIcon from '@material-ui/icons/Close';
import { DealershipLogo } from '../DealershipLogo/DealershipLogo';
import IconButton from '@material-ui/core/IconButton';
import LoadingState from './LoadingState/LoadingState';
import { Typography } from '@material-ui/core';
import { parseURLparameters } from '../../utils/parseURLparameters';
import { useCurrentTask } from '../../custom-hooks/useCurrentTask';
import useStyles from './useStyles';

export type StoreDataModel =
  | UserStateModel
  | CardOrderStateModel
  | SubscriberStateModel
  | SubscriptionStateModel
  | ChargingCardStateModel
  | ChargingSessionsStateModel
  | OrganizationStateModel
  | HomeStationsStateModel
  | InvoiceStateModel
  | null;

export type StoreData =
  | UserState
  | CardOrderState
  | SubscriberState
  | SubscriptionState
  | ChargingCardState
  | ChargingSessionsState
  | OrganizationState
  | HomeStationsState
  | InvoiceState
  | null;

type TProps = IPropsFromState & {
  setDataLoading: (x: boolean) => void;
  task?: Flex.ITask;
};

export const LoadingScreen: React.FC<TProps> = ({
  task,
  userState,
  subscriberState,
  subscriptionState,
  cardOrdersState,
  chargingCardState,
  chargingSessionsState,
  organizationState,
  homeStationsState,
  userSearchState,
  invoicesState,
  setDataLoading,
  clearSelectedUser,
  clearAll,
}) => {
  const taskSid = useCurrentTask();
  const classes = useStyles();

  const [showClose, setShowClose] = useState<boolean>(false);

  const isDataLoaded = (data: StoreDataModel): boolean => {
    return data && (data.data || data.error) ? true : false;
  };

  const getTaskState = (data: StoreData): StoreDataModel => {
    return data && data[taskSid];
  };

  const user = getTaskState(userState);
  const userLoaded = isDataLoaded(user);

  const subscriber = getTaskState(subscriberState);
  const subscriberLoaded = isDataLoaded(subscriber);

  const subscription = getTaskState(subscriptionState);
  const subscriptionLoaded = isDataLoaded(subscription);

  const cardOrders = getTaskState(cardOrdersState);
  const cardOrdersLoaded = isDataLoaded(cardOrders);

  const chargingCards = getTaskState(chargingCardState);
  const chargingCardsLoaded = isDataLoaded(chargingCards);

  const chargingSessions = getTaskState(chargingSessionsState);
  const chargingSessionsLoaded = isDataLoaded(chargingSessions);

  const organization = getTaskState(organizationState);
  const organizationLoaded = isDataLoaded(organization);

  const homeStations = getTaskState(homeStationsState);
  const homeStationsLoaded = isDataLoaded(homeStations);

  const invoices = getTaskState(invoicesState);
  const invoicesLoaded = isDataLoaded(invoices);

  const selectedUser =
    userSearchState &&
    userSearchState[taskSid] &&
    userSearchState[taskSid].selectedUser;

  const showScreenInfo = () => {
    if (task && !selectedUser) {
      const { name } = task.attributes;
      return `${name}`;
    }

    if (selectedUser) {
      return (
        (selectedUser.first_name &&
          selectedUser.last_name &&
          `${selectedUser.first_name} ${selectedUser.last_name}`) ||
        selectedUser.email
      );
    }

    const params = parseURLparameters(window.location.search);
    return params.email;
  };

  useEffect(() => {
    setTimeout(() => {
      setShowClose(true);
    }, 10000);
  }, []);

  useEffect(() => {
    if (
      userLoaded &&
      subscriberLoaded &&
      subscriptionLoaded &&
      cardOrdersLoaded &&
      chargingCardsLoaded &&
      chargingSessionsLoaded &&
      organizationLoaded &&
      homeStationsLoaded &&
      invoicesLoaded
    ) {
      setTimeout(() => {
        if (selectedUser) {
          clearSelectedUser({ taskSid: taskSid });
        }
        setDataLoading(false);
      }, 1500);
    }
  }, [
    userLoaded,
    subscriberLoaded,
    subscriptionLoaded,
    cardOrdersLoaded,
    chargingCardsLoaded,
    chargingSessionsLoaded,
    organizationLoaded,
    homeStationsLoaded,
    invoicesLoaded,
  ]);

  const resetState = () => {
    clearAll({ taskSid });
    clearSelectedUser({ taskSid });
    setDataLoading(false);
  };

  return (
    <div className={classes.loadingScreenWrapper}>
      <div className={classes.loadingScreenBox}>
        <div className={classes.closeWrapper}>
          {showClose && (
            <IconButton
              data-testid='stop-loading-button'
              aria-label='close'
              onClick={() => resetState()}
            >
              <CloseIcon />
            </IconButton>
          )}
        </div>
        <div style={{ display: 'flex' }}>
          <Typography style={{ marginRight: 8 }} variant='h1'>
            {showScreenInfo()}
          </Typography>
          {selectedUser && (
            <DealershipLogo
              id={selectedUser.tenant_id}
              width='32px'
              height='32px'
            />
          )}
        </div>
        <h4 className={classes.loadingTitle}>
          Loading customer information...
        </h4>
        <div
          data-testid='loading-screen'
          className={classes.loadingInformationList}
        >
          <div className={classes.loadingInformationListItem}>
            <LoadingState data={user} />
            <Typography style={{ marginLeft: 5 }} variant='body2'>
              User Profile
            </Typography>
          </div>
          <div className={classes.loadingInformationListItem}>
            <LoadingState data={chargingSessions} />
            <Typography style={{ marginLeft: 5 }} variant='body2'>
              Charging sessions
            </Typography>
          </div>
          <div className={classes.loadingInformationListItem}>
            <LoadingState data={organization} />
            <Typography style={{ marginLeft: 5 }} variant='body2'>
              Organization Profile
            </Typography>
          </div>
          <div className={classes.loadingInformationListItem}>
            <LoadingState data={invoices} />
            <Typography style={{ marginLeft: 5 }} variant='body2'>
              Invoices
            </Typography>
          </div>
          <div className={classes.loadingInformationListItem}>
            <LoadingState data={subscription} />
            <Typography style={{ marginLeft: 5 }} variant='body2'>
              Subscriptions
            </Typography>
          </div>
          <div className={classes.loadingInformationListItem}>
            <LoadingState data={homeStations} />
            <Typography style={{ marginLeft: 5 }} variant='body2'>
              Charging stations
            </Typography>
          </div>
          <div className={classes.loadingInformationListItem}>
            <LoadingState data={chargingCards} />
            <Typography style={{ marginLeft: 5 }} variant='body2'>
              Charging cards
            </Typography>
          </div>
          <div className={classes.loadingInformationListItem}>
            <LoadingState data={null} />
            <Typography style={{ marginLeft: 5 }} variant='body2'>
              Support tickets
            </Typography>
          </div>
          <div className={classes.loadingInformationListItem}>
            <LoadingState data={cardOrders} />
            <Typography style={{ marginLeft: 5 }} variant='body2'>
              Orders
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connector(LoadingScreen);
