import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    verifiedBox: {
      fontSize: '11px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '23px',
      borderRadius: '16px',
      fontWeight: 'bold',
      padding: '0 10px',
      position: 'absolute',
      right: '16px',
      top: '16px',
    },
    verified: {
      backgroundColor: '#00ce4e',
      color: '#fff',
      boxShadow: '0px 3px 10px 1px #00ce4e4f',
    },
    unverified: {
      background: ' #f00066',
      color: '#fff',
      boxShadow: '0px 3px 10px 1px #f000664f',
      padding: '0 10px 0 5px',
    },
    verifiedImage: {
      marginRight: '3px',
      fontSize: '17px',
    },
  })
);

export default useStyles;
