import update from 'immutability-helper';
import { State } from '..';
import { ActionInterface } from '../actions';

export default (
  state: State,
  action: ActionInterface.GetTariffsSuccess
): State => {
  const { data, taskSid } = action;
  const currentTariffs = state[taskSid];
  const updatedTariffs = update(currentTariffs, {
    data: {
      $set: data,
    },
    loading: {
      $set: false,
    },
  });

  return {
    ...state,
    [taskSid]: updatedTariffs,
  };
};
