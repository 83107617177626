export default (timestamp: number): string => {
  const hours = Math.floor(timestamp / 60 / 60);
  const minutes = Math.floor(timestamp / 60) - hours * 60;
  const seconds = timestamp % 60;

  if (hours > 0 && seconds > 0) {
    return `${hours}h ${minutes}m ${seconds}s`;
  }

  if (hours > 0 && minutes > 0) {
    return `${hours}h ${minutes}m`;
  }

  if (hours > 0) {
    return `${hours}h`;
  }

  if (minutes > 0 && seconds > 0) {
    return `${minutes}m ${seconds}s`;
  }

  if (minutes > 0) {
    return `${minutes}m`;
  }

  return `${seconds}s`;
};

export const parseSecondsDigital = (timestamp: number): string => {
  const hours = Math.floor(timestamp / 60 / 60);
  const minutes = Math.floor(timestamp / 60) - hours * 60;
  const seconds = timestamp % 60;

  const parsedHours = hours < 10 ? `0${hours}` : hours;
  const parsedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const parsedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${parsedHours}:${parsedMinutes}:${parsedSeconds}`;
};
