import React from 'react';
import { Typography } from '@material-ui/core';
import useStyles from './useStyles';

interface IProps {
  title: string;
  content: string;
}
export const BasicInformationItem: React.FC<IProps> = ({ title, content }) => {
  const classes = useStyles();
  return (
    <div className={classes.cardDetail}>
      <Typography variant='h6'>{title}</Typography>
      <div className={classes.cardDetailData}>
        <Typography variant='body2'>{content}</Typography>
      </div>
    </div>
  );
};
