import { Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { IChargingRecordViewModel } from '../../../../types/chargingRecords';
import IconButton from '@material-ui/core/IconButton';
import { PriceDialog } from './PriceDialog';
import decimalToComma from '../../../../utils/decimalToComma';
import { formatIsoDates } from '../../../../utils/dates';
import parseSeconds from '../../../../utils/parseSeconds';
import useSharedStyles from '../../../shared/useSharedStyles';
import useStyles from './useStyles';

interface IOwnProps {
  chargingSession: IChargingRecordViewModel;
}

const Details: React.FC<IOwnProps> = ({ chargingSession }) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const [priceDialogOpen, setPriceDialogOpen] = useState<boolean>(false);
  return (
    <>
      <Grid item xs={12}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h4'>
            <span data-testid='public-details-title'>Details</span>
          </Typography>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h6'>Session Start</Typography>
          <div className={sharedClasses.cardDetailData}>
            <Typography variant='body2'>
              {formatIsoDates(chargingSession.start_date_time)}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h6'>Session End</Typography>
          <div className={sharedClasses.cardDetailData}>
            <Typography variant='body2'>
              {chargingSession.stop_date_time
                ? formatIsoDates(chargingSession.stop_date_time)
                : '-'}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h6'>Session Duration</Typography>
          <div className={sharedClasses.cardDetailData}>
            <Typography variant='body2'>
              {parseSeconds(chargingSession.duration)}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h6'>Energy Consumption</Typography>
          <div className={sharedClasses.cardDetailData}>
            <Typography variant='body2'>
              {chargingSession.consumption
                ? `${decimalToComma(
                    chargingSession.consumption.toFixed(3)
                  )} kWh`
                : '-'}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h6'>
            Price (incl. VAT {chargingSession.vat_percentage * 100}%)
          </Typography>
          <div className={classes.addressElement}>
            <Typography variant='body2' className={classes.headingWithIcon}>
              {chargingSession.price
                ? `${decimalToComma(
                    Number(chargingSession.price).toFixed(2)
                  )} ${chargingSession.currency}`
                : `0 ${chargingSession.currency}`}
              <IconButton
                data-testid='price-info-button'
                onClick={() => setPriceDialogOpen((prevState) => !prevState)}
                className={classes.infoIcon}
                disableRipple
                disableFocusRipple
              >
                <HelpOutlineIcon />
              </IconButton>
            </Typography>
          </div>
          <PriceDialog
            open={priceDialogOpen}
            onClose={() => setPriceDialogOpen(false)}
            chargingSession={chargingSession}
          />
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={sharedClasses.cardDetail}>
          <Typography variant='h6'>Billing Status</Typography>
          <div className={classes.addressElement}>
            <Typography className={classes.billingStatus} variant='body2'>
              {chargingSession.billing_status}
            </Typography>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default Details;
