import { ActionInterface } from '../actions';
import { IInvoice } from '../../../../types/invoice';
import { ISortState } from '../../../../components/shared/AssetTable/AssetTable/AssetTable';
import { State } from '..';
import { lexicographical } from '../../../../utils/comparisonFunctions';
import update from 'immutability-helper';

interface ISortFunctionProps {
  a: IInvoice;
  b: IInvoice;
}
interface ISortFunctions {
  [string: string]: (props: ISortFunctionProps) => number;
}

export const sortFunctions: ISortFunctions = {
  invoice_number: ({ a, b }) => {
    return lexicographical(a.invoice_number, b.invoice_number);
  },
  creation_date: ({ a, b }) => {
    const invoiceDateA = new Date(a.created_at).getTime();
    const invoiceDateB = new Date(b.created_at).getTime();
    return lexicographical(invoiceDateA, invoiceDateB);
  },
  amount: ({ a, b }) => {
    return lexicographical(a.amount, b.amount);
  },
  credit_notes_amount: ({ a, b }) => {
    return lexicographical(a.credit_notes, b.credit_notes);
  },
};

export const generateSortFunction = (sortState: ISortState) => (
  a: IInvoice,
  b: IInvoice
) => {
  const sortKey = sortState.sortByColumnKey as AdminOrderSortKey;
  if (sortKey) {
    const sortFunction = sortFunctions[sortKey];
    const ascendingSortResult = sortFunction({ a, b });

    return sortState.sortDesc ? ascendingSortResult * -1 : ascendingSortResult;
  } else {
    return 0;
  }
};

type AdminOrderSortKey = keyof typeof sortFunctions;

export default (state: State, action: ActionInterface.SortInvoices): State => {
  const { sortState, taskSid } = action;
  const currentInvoicesState = state[taskSid];

  if (currentInvoicesState && currentInvoicesState.data) {
    const newInvoicesData: IInvoice[] = currentInvoicesState.data.slice();
    newInvoicesData.sort(generateSortFunction(sortState));

    const updatedInvoicesState = update(currentInvoicesState, {
      data: {
        $set: newInvoicesData,
      },
      loading: {
        $set: false,
      },
    });

    return {
      ...state,
      [action.taskSid]: updatedInvoicesState,
    };
  }

  return {
    ...state,
  };
};
