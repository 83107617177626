/* eslint-disable @typescript-eslint/naming-convention */
export interface UIStateModel {
  isVerificationOpen: boolean;
  verificationStep: number;
}

export interface UIState {
  [id: string]: UIStateModel;
}

const state: UIState = {};

export type State = typeof state;
export default state;
