import { Typography } from '@material-ui/core';
import { format } from 'date-fns';
import React from 'react';

import DeleteIcon from '../../../../../assets/image/delete.svg';
import { useTypedTranslation } from '../../../../../custom-hooks/useTypedTranslation';
import useInvoiceDetailsStyles from '../../Details/useStyles';
import {
  ILineItem,
  LineItemReimbursementTypes,
  LineItemTypes,
} from './NewLineItem';
import useStyles from './useStyles';

interface IOwnProps {
  lineItems: IContainerLineItem[];
  currency: string;
  handleRemove: (lineItemId: string) => void;
}

export interface IContainerLineItem extends ILineItem {
  id: string;
}

const LineItemsContainer: React.FC<IOwnProps> = ({
  lineItems,
  currency,
  handleRemove,
}) => {
  const classes = useStyles();
  const detailsClasses = useInvoiceDetailsStyles();
  const { t, terms } = useTypedTranslation();
  const { fields, line_items_headline, short_version } = terms.refunds.form;

  const createLineItemRow = (lineItem: IContainerLineItem) => {
    const lineItemTypeValue = LineItemTypes[lineItem.type];
    const lineItemReimbursementTypeValue =
      LineItemReimbursementTypes[lineItem.reimbursementType];

    const externalCdrId = lineItem.externalCdrId || '';
    const externalCdrIdDisplay = externalCdrId
      ? `${externalCdrId.substring(0, 8)}…`
      : '-';

    const dateValue = lineItem.incurredAt
      ? format(new Date(lineItem.incurredAt), 'dd.MM.yy')
      : '-';

    return (
      <tr key={lineItem.id}>
        <td title={lineItemReimbursementTypeValue.text}>
          {lineItemReimbursementTypeValue.icon}
        </td>
        <td title={lineItemTypeValue}>{lineItemTypeValue}</td>
        <td>{dateValue}</td>
        <td title={externalCdrId}>{externalCdrIdDisplay}</td>
        <td className={classes.amount}>
          {lineItem.amount} {currency}
        </td>
        <td>
          <div className={classes.deleteButton}>
            <img
              src={DeleteIcon}
              className={classes.deleteIcon}
              onClick={() => handleRemove(lineItem.id)}
              data-testid='delete-line-item'
            />
          </div>
        </td>
      </tr>
    );
  };

  const getTotalAmountRow = () => {
    const totalAmount = (lineItems || []).reduce(
      (acc, { amount }) => acc + amount,
      0
    );

    return (
      <tr className={classes.totalAmountRow}>
        <td colSpan={4}>
          <strong>{t(terms.refunds.form.total_amount)}</strong>
        </td>
        <td className={classes.amount}>
          <strong>
            {totalAmount} {currency}
          </strong>
        </td>
        <td />
      </tr>
    );
  };

  return (
    <>
      <Typography variant='h4' className={classes.headline}>
        {t(line_items_headline)}
      </Typography>
      <table className={classes.lineItemTable}>
        <thead>
          <tr>
            <td className={classes.iconColumn} />
            <td className={detailsClasses.itemTitle}>{t(fields.type)}</td>
            <td className={detailsClasses.itemTitle}>
              {t(short_version.incurred_at)}
            </td>
            <td className={detailsClasses.itemTitle}>
              {t(short_version.external_cdr_id)}
            </td>
            <td
              className={[detailsClasses.itemTitle, classes.amount].join(' ')}
            >
              {t(fields.amount)}
            </td>
            <td className={classes.deleteColumn} />
          </tr>
        </thead>
        <tbody>
          {lineItems.map(createLineItemRow)}
          {getTotalAmountRow()}
        </tbody>
      </table>
    </>
  );
};

export default LineItemsContainer;
