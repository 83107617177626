import update from 'immutability-helper';
import { omit } from 'lodash';
import { State } from '..';
import { ActionInterface } from '../actions';
import { CardOrderStateModel } from '..';

export default (
  state: State,
  action: ActionInterface.GetCardOrderRequest
): State => {
  const cardOrder = state[action.taskSid] || {
    loading: true,
    data: null,
    error: null,
    request: null,
  };

  const updatedCardOrder: CardOrderStateModel = update(cardOrder, {
    error: {
      $set: null,
    },
    loading: {
      $set: true,
    },
    request: {
      $set: omit(action, ['type', 'taskSid']),
    },
  });

  return {
    ...state,
    [action.taskSid]: updatedCardOrder,
  };
};
