import update from 'immutability-helper';

import { State, TariffsStateModel } from '..';
import { ActionInterface } from '../actions';

export default (
  state: State,
  action: ActionInterface.GetTariffsRequest
): State => {
  const tariffs = state[action.taskSid] || {
    loading: true,
    data: null,
    error: null,
  };

  const updatedSubscription: TariffsStateModel = update(tariffs, {
    error: {
      $set: null,
    },
    loading: {
      $set: true,
    },
  });

  return {
    ...state,
    [action.taskSid]: updatedSubscription,
  };
};
