export interface IConfig {
  useDevtools: boolean;
  twilioAccountSid: string;
  elliBaseUrl: string;
  elliAuthUrl?: string;
  aadRedirectUrl: string;
  aadClientAuthority: string;
  aadClientId: string;
  XApiKey: string;
  googleMapApiKey: string;
  env: string;
  launchDarklyClientId: string;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const config: IConfig = window.config;

export { config };
