import { IChargingStationModel, State } from '..';

import { ActionInterface } from '../actions';
import { omit } from 'lodash';
import update from 'immutability-helper';

export default (
  state: State,
  action: ActionInterface.GetNearbyLocationsRequest
): State => {
  const chargingStationState = state[action.taskSid] || {
    loading: false,
    data: null,
    nearbyLocations: null,
    nearbyLocationsLoading: true,
    error: null,
    request: null,
  };

  const updatedChargingStationState: IChargingStationModel = update(
    chargingStationState,
    {
      error: {
        $set: null,
      },
      nearbyLocationsLoading: {
        $set: true,
      },
      request: {
        $set: omit(action, ['type', 'taskSid']),
      },
    }
  );

  return {
    ...state,
    [action.taskSid]: updatedChargingStationState,
  };
};
