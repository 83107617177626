import update from 'immutability-helper';
import { State } from '..';
import { ActionInterface } from '../actions';

export default (
  state: State,
  action: ActionInterface.TerminateSubscriptionSuccess
): State => {
  const currentSubscriptionState = state[action.taskSid];

  // we can be sure that this is not undefined as this saga would only be actionable
  // when an upcoming subscription is available
  const index = currentSubscriptionState.data?.findIndex(
    (sub) => sub.status === 'upcoming'
  );
  const updatedState = update(currentSubscriptionState, {
    data: {
      $splice: [[index!, 1]],
    },
  });

  return {
    ...state,
    [action.taskSid]: updatedState,
  };
};
