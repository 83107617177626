import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useTypedTranslation } from '../../../custom-hooks/useTypedTranslation';

export type OwnProps = {
  step?: number;
};

export const StepDescription: React.FC<OwnProps> = ({ step }) => {
  const { t, terms } = useTypedTranslation();
  if (step === 1) {
    return (
      <Typography color='textPrimary' variant='subtitle1' paragraph>
        <strong>
          2. {t(terms.subscription_termination.result_dialog_step_2_title)}
        </strong>
      </Typography>
    );
  }
  return (
    <Typography color='textPrimary' variant='subtitle1' paragraph>
      <strong>
        {step !== undefined ? '1. ' : ''}
        {t(terms.subscription_termination.result_dialog_step_1_title)}
      </strong>
    </Typography>
  );
};
