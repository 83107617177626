import activeSessions from './activeSessions/reducers';
import breadcrumbs from './breadcrumbs/reducers';
import cardorders from './cardorders/reducers';
import chargingCards from './chargingCards/reducers';
import chargingSessions from './chargingSessions/reducers';
import chargingStations from './chargingStations/reducers';
import { combineReducers } from 'redux';
import homeStations from './homeStations/reducers';
import invoices from './invoices/reducers';
import organization from './organization/reducers';
import other from './other/reducers';
import subscriber from './subscriber/reducers';
import subscription from './subscription/reducers';
import ui from './ui/reducers';
import user from './user/reducers';
import userSearch from './userSearch/reducers';
import tariffs from './tariffs/reducers';

export default combineReducers({
  other,
  breadcrumbs,
  user,
  subscriber,
  subscription,
  cardorders,
  userSearch,
  ui,
  chargingCards,
  chargingSessions,
  chargingStations,
  organization,
  homeStations,
  activeSessions,
  invoices,
  tariffs,
});
