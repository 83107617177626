import { HomeStationsState, State } from '..';

import { ActionInterface } from '../actions';
import update from 'immutability-helper';

export default (
  state: State,
  action: ActionInterface.ClearHomeStations
): State => {
  const updatedState: HomeStationsState = update(state, {
    $unset: [action.taskSid],
  });

  return {
    ...updatedState,
  };
};
