import { AppDispatch } from '../../../../store';
import { ActionCreator } from '../../../../store/state/actions';
import { ActionInterface } from '../../../../store/state/other/actions';
import { Dispatcher } from '../index';

interface IDispatchers {
  clearAll: Dispatcher<ActionInterface.ClearAll>;
  getRFIDCardsRequest: () => ActionInterface.GetRFIDCardsRequest;
}

export const createDispatchers = (dispatch: AppDispatch): IDispatchers => ({
  clearAll: (payload) => dispatch(ActionCreator.other.ClearAll(payload)),

  getRFIDCardsRequest: () =>
    dispatch(ActionCreator.other.GetRFIDCardsRequest()),
});
