import { AxiosResponse } from 'axios';

import { TLineItemType } from '../components/DetailCards/Invoice/Refunds/AddRefundForm/NewLineItem';
import ApiService from './api';
import { IRefund } from '../components/DetailCards/Invoice/Refunds/AddRefundForm';
import { ICreditNote } from '../types/creditNote';

export interface ILineItemRequest {
  type: TLineItemType;
  amount: number;
  external_cdr_id?: string;
  incurred_at?: string;
  reimbursement_type: string;
}

export interface IRefundRequest {
  currency: string;
  country_code: string;
  line_items: ILineItemRequest[];
}

const mapRefundForRequest = (refund: IRefund): IRefundRequest => ({
  country_code: refund.countryCode,
  currency: refund.currency,
  line_items: refund.lineItems.map((lineItem) => ({
    type: lineItem.type,
    amount: lineItem.amount,
    external_cdr_id: lineItem.externalCdrId,
    incurred_at: lineItem.incurredAt
      ? new Date(lineItem.incurredAt).toISOString()
      : undefined,
    reimbursement_type: lineItem.reimbursementType.toLowerCase(),
  })),
});

const addRefund = async (
  invoiceId: string,
  refundData: IRefundRequest
): Promise<ICreditNote> => {
  const apiService = new ApiService(
    `/mgmt/customer/v1/invoices/${invoiceId}/refund`,
    {
      method: 'POST',
      data: refundData,
    }
  );

  const response = await apiService.query();

  return response.data;
};

const getPdfForCreditNote = async (
  creditNoteId: string
): Promise<AxiosResponse<Blob>> => {
  const apiService = new ApiService(
    `/mgmt/customer/v1/creditnotes/${creditNoteId}/pdf`,
    {
      responseType: 'blob',
    }
  );
  return apiService.query();
};

export const RefundService = {
  mapRefundForRequest,
  addRefund,
  getPdfForCreditNote,
};
