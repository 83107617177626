import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    rowTitleCell: {
      maxWidth: '130px',
      padding: '8px 0',
      borderRight: '1px solid #CCC',
    },
  })
);

export default useStyles;
