import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    indicator: {
      borderRadius: '5px',
      height: 8,
      width: 8,
      marginRight: 4,
    },
  })
);

export default useStyles;
