import React from 'react';
import { Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

import useStyles from './useStyles';
import { ISelectOption } from './index';

interface IOwnProps {
  labelId: string;
  labelText: string;
  name: string;
  value?: string;
  handleChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
  options: ISelectOption[];
}

const SelectComponent: React.FC<IOwnProps> = ({
  labelId,
  labelText,
  name,
  value,
  handleChange,
  options,
}) => {
  const classes = useStyles();
  return (
    <Select
      labelId={labelId}
      value={value}
      label={labelText}
      onChange={handleChange}
      data-testid={`${name}-select`}
      classes={{
        icon: classes.selectIcon,
      }}
      inputProps={{
        name,
      }}
    >
      {options.map(({ key, value: option, content, isDisabled }) => (
        <MenuItem key={key} value={option} disabled={isDisabled}>
          {content}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectComponent;
