import ApiService from './api';
import { ILocationModel } from '../types/chargingsession';
import { config as appConfig } from '../config';

export interface ILocationParams {
  lat: number;
  long: number;
  radius: number;
}

const getById = async (evseId: string): Promise<ILocationModel[]> => {
  const apiService = new ApiService('/searchandfind/v1/locations', {
    data: {
      evse_id: evseId,
    },
    headers: {
      'X-apikey': appConfig.XApiKey,
    },
  });
  const response = await apiService.query();
  return response.data;
};

const getByLocationId = async (
  locationId: string
): Promise<ILocationModel[]> => {
  const apiService = new ApiService(
    `/searchandfind/v1/locations/${locationId}`,
    {
      headers: {
        'X-apikey': appConfig.XApiKey,
      },
    }
  );
  const response = await apiService.query();
  return response.data;
};

const getNearbyLocations = async (
  location: ILocationParams
): Promise<ILocationModel[]> => {
  const apiService = new ApiService('/searchandfind/v1/nearbylocations', {
    data: { ...location, limit: 100 },
    headers: {
      'X-apikey': appConfig.XApiKey,
    },
  });
  const response = await apiService.query();
  return response.data;
};

export const LocationService = {
  getById,
  getNearbyLocations,
  getByLocationId,
};
