import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    activationStatus: {
      display: 'inline-block',
      marginRight: '4px',
      verticalAlign: 'baseline',
    },

    logoContainer: {
      marginRight: '16px',
      height: '24px',
    },

    statusWrapper: {
      display: 'flex',
      alignItems: 'center',
    },

    statusText: {
      textTransform: 'capitalize', // !important',
    },
  })
);

export default useStyles;
