import React, { useState } from 'react';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import IconButton from '@material-ui/core/IconButton';

import connector, { IPropsFromState } from '../Connector/Connector';
import CardList from './CardList/CardList';
import { useCurrentTask } from '../../custom-hooks/useCurrentTask';
import { useToggle } from '../../custom-hooks/useToggle';
import { useTypedTranslation } from '../../custom-hooks/useTypedTranslation';
import AddRfidCardIcon from '../../assets/image/addcard.svg';
import useStyles from './useStyles';
import DialogContainer from '../shared/Dialogs/DialogContainer/DialogContainer';
import OrderRfidCard from '../shared/Dialogs/OrderRfidCard';

type TProps = IPropsFromState;

export const ChargingCards: React.FC<TProps> = ({
  addBreadcrumb,
  chargingCardState,
}) => {
  const taskSid = useCurrentTask();
  const classes = useStyles();
  const [isOrderCardDialogOpen, setIsOrderCardDialogOpen] = useState<boolean>(
    false
  );
  const chargingCards = chargingCardState && chargingCardState[taskSid];
  const [isExpanded, toggleExpand] = useToggle(false);
  const { getScopedTranslation } = useTypedTranslation('action_cards');

  const handleOrderCardDialogClose = () => {
    setIsOrderCardDialogOpen(false);
  };

  const openDetailsPanel = (component: string, friendlyText: string) => {
    const data = {
      component,
      friendlyText,
      level: 1,
    };

    addBreadcrumb({ taskSid, data });
  };

  const openOrderCardPopup = () => {
    setIsOrderCardDialogOpen(true);
  };

  const createShowAllHandler = () => (
    <div className={classes.panelAction}>
      <span
        style={{ color: '#6633cc' }}
        onClick={() => openDetailsPanel('CardsUI', 'Charging Cards')}
        data-testid='show-all-cards'
      >
        {getScopedTranslation('general.show_all')}
      </span>
      <DoubleArrowIcon style={{ marginLeft: 5, color: '#6633cc' }} />
    </div>
  );

  const createShowMoreHandler = () => (
    <div className={classes.panelAction}>
      <UnfoldMoreIcon color='primary' />
      <span
        style={{
          marginLeft: 5,
        }}
        data-testid='show-all-more'
        onClick={toggleExpand}
      >
        {isExpanded
          ? getScopedTranslation('general.show_less')
          : getScopedTranslation('general.show_more')}
      </span>
    </div>
  );

  const getPanelAction = () => {
    const chargingCardsCount = chargingCards?.data?.length || 0;

    if (chargingCardsCount < 2) {
      return null;
    } else if (chargingCardsCount > 3) {
      return createShowAllHandler();
    } else {
      return createShowMoreHandler();
    }
  };

  const getOrderCardButton = () => (
    <IconButton
      aria-label={getScopedTranslation('rfid_cards.order.title')}
      onClick={openOrderCardPopup}
      classes={{ root: classes.orderRfidCardButton }}
      disableRipple
    >
      <img
        src={AddRfidCardIcon}
        alt={getScopedTranslation('rfid_cards.order.title')}
        width='36'
        height='36'
      />
    </IconButton>
  );

  return (
    <div className={classes.chargingCardWrapper} data-testid='charging-cards'>
      <div className={classes.chargingCardInnerWrapper}>
        <CardList expand={isExpanded} />
        {getPanelAction()}
      </div>
      {getOrderCardButton()}
      <DialogContainer
        isOpen={isOrderCardDialogOpen}
        onClose={handleOrderCardDialogClose}
      >
        <OrderRfidCard />
      </DialogContainer>
    </div>
  );
};

export default connector(ChargingCards);
