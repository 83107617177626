import { CardMedia } from '@material-ui/core';
import React from 'react';
import elliLogo from '../../assets/image/logos/elli.png';
import seatLogo from '../../assets/image/logos/seat.png';
import skodaLogo from '../../assets/image/logos/skoda.png';
import vwLogo from '../../assets/image/logos/vw.png';
import wechargeLogo from '../../assets/image/logos/wecharge.png';

interface IDealershipLogos {
  [id: string]: string;
}

const dealershipLogos: IDealershipLogos = {
  Ee4qUdsuePsfg3wj1NuL: vwLogo,
  FUt6cGL7Muvu9aVRZDLL: elliLogo,
  BMJ7MQCdw1ChOg5tFmEJ: elliLogo,
  LgWQZjLJPb7lNaIwnPKQ: seatLogo,
  JMJn0x1539PRL3Biefz3: seatLogo,
  eSMMKx84NriRMEpCapIu: wechargeLogo,
  pkEp7dW3rzepQWc40Tg0: wechargeLogo,
  ursWWtYQQ0QQahA0DFNv: vwLogo,
  '6vAHOeO0oWltnhNKD1c2': skodaLogo,
  waZpfLWcTLPVMDg3WlwP: skodaLogo,
};

interface IOwnProps {
  id: string | undefined;
  width: string | undefined;
  height: string | undefined;
}

export const DealershipLogo: React.FC<IOwnProps> = ({ id, width, height }) => {
  if (!id) return null;

  return (
    <CardMedia
      data-testid='dealership-logo'
      component='img'
      image={dealershipLogos[id]}
      title='Logo'
      alt='Logo'
      style={{
        height: width ? width : '30px',
        width: height ? height : '30px',
      }}
    />
  );
};
