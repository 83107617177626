import React, { useEffect, useReducer } from 'react';

import PaginatedCollection from '../../shared/Collection/PaginatedCollection';
import connector, { IPropsFromState } from '../../Connector/Connector';
import { useCurrentTask } from '../../../custom-hooks/useCurrentTask';
import { getColumns, getRows } from './fieldsConfig';
import reducer, { ACTION_TYPES } from './reducer';
import { ISortState } from '../../shared/AssetTable/AssetTable/AssetTable';
import { useTypedTranslation } from '../../../custom-hooks/useTypedTranslation';
import useSharedStyles from '../../shared/useSharedStyles';

export type TOwnProps = IPropsFromState & {
  invoiceId?: string;
  crumbId?: string;
};

const defaultColumnsSort: ISortState = {
  sortByColumnKey: 'credit_note_number',
  sortDesc: true,
};

export const CreditNotesCollection: React.FC<TOwnProps> = ({
  addBreadcrumb,
  breadcrumbState,
  crumbId,
  invoiceId,
  invoicesState,
}) => {
  const taskSid = useCurrentTask();
  const classes = useSharedStyles();
  const { t, terms } = useTypedTranslation();
  const taskBreadcrumbState = breadcrumbState && breadcrumbState[taskSid];
  const [{ sortState, sortedCreditNotes: creditNotes }, dispatch] = useReducer(
    reducer,
    {
      sortState: defaultColumnsSort,
      sortedCreditNotes: [],
    }
  );

  const setSortState = (columnKey: string) => {
    dispatch({
      type: ACTION_TYPES.SET_SORTING,
      payload: columnKey,
    });
  };

  useEffect(() => {
    const { data: invoices } = invoicesState[taskSid];
    if (!invoices) {
      return;
    }

    const currentInvoice = invoices.find(({ id }) => id === invoiceId);
    const invoiceCreditNotes = currentInvoice?.credit_notes
      ? [...currentInvoice?.credit_notes]
      : [];

    dispatch({
      type: ACTION_TYPES.SET_CREDIT_NOTES,
      payload: invoiceCreditNotes,
    });
  }, [invoicesState, invoiceId, taskSid]);

  const headline = (
    <h1 className={classes.sectionTitle}>
      {t(terms.refunds.overview.headline)}
    </h1>
  );

  return (
    <PaginatedCollection
      headline={headline}
      columns={getColumns()}
      rows={getRows(
        creditNotes,
        taskSid,
        addBreadcrumb,
        taskBreadcrumbState,
        crumbId
      )}
      sortHandler={setSortState}
      sortState={sortState}
      rowTypePlural={'Credit Notes'}
    />
  );
};

export default connector(CreditNotesCollection);
