import React, { useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { TransitionProps } from '@material-ui/core/transitions';
import {
  Button,
  Dialog,
  Fade,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { checkCrm, parseURLparameters } from '../../utils/parseURLparameters';
import connector, { IPropsFromState } from '../Connector/Connector';

import Close from '@material-ui/icons/Close';
import { UserSearch } from './UserSearch/UserSearch';
import { LogicLayer } from './LogicLayer';
import { IUserModel } from '../../types/user';
import VerifyQuestions from './VerifyQuestions/VerifyQuestions';
import { useCurrentTask } from '../../custom-hooks/useCurrentTask';
import useStyles from './useStyles';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Fade timeout={100} ref={ref} {...props} />;
});

type TVerifyProps = IPropsFromState & {
  width?: number | undefined;
  startOpen: boolean;
  emailFromURL?: string;
  fullSizeButton?: boolean;
  setDataLoading: (x: boolean) => void;
};

export const VerifyCustomer: React.FC<TVerifyProps> = ({
  width,
  startOpen,
  userSearchState,
  getUserRequest,
  clearSelectedUser,
  verifyUser,
  emailFromURL,
  getSubscriberTempRequest,
  subscriberState,
  clearSubscriberTemp,
  setVerificationStep,
  openVerificationFlow,
  uiState,
  fullSizeButton = false,
  setDataLoading,
  clearAll,
}) => {
  const classes = useStyles();
  const [progress, setProgress] = useState<number>(0);
  const [dialogWidth, setDialogWidth] = useState<number>(900);
  const taskSid = useCurrentTask();
  const urlParams = parseURLparameters(window.location.search);
  const isCrm = checkCrm(urlParams);

  const user = userSearchState && userSearchState[taskSid];
  const subscriber = subscriberState && subscriberState[taskSid];
  const ui = uiState && uiState[taskSid];
  const isUser = user && user.selectedUser;
  const isOpen = ui && ui.isVerificationOpen;

  const onVerify = (userVerified = true) => {
    if (isUser) {
      openVerificationFlow({ taskSid: taskSid, open: false });
      clearSubscriberTemp({ taskSid: taskSid });
      clearAll({ taskSid: taskSid });
      setDataLoading(true);
      getUserRequest({
        userId: (user.selectedUser as IUserModel).id,
        taskSid: taskSid,
      });
      verifyUser({ taskSid, verified: userVerified });
    }
  };

  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        onVerify();
      }, 400);
    }
  }, [progress]);

  useEffect(() => {
    if (width) {
      setDialogWidth(width + 13);
      openVerificationFlow({ taskSid: taskSid, open: startOpen });
    }
  }, [width, startOpen]);

  const handleClickOpen = () => {
    openVerificationFlow({ taskSid: taskSid, open: true });
    setVerificationStep({ taskSid: taskSid, step: 1 });
  };

  const handleClose = () => {
    openVerificationFlow({ taskSid: taskSid, open: false });

    if (user) {
      clearSelectedUser({ taskSid: taskSid });
    }

    if (subscriber && subscriber.searchData) {
      clearSubscriberTemp({ taskSid: taskSid });
    }
  };

  const goNext = () => {
    setVerificationStep({ taskSid: taskSid, step: 2 });
    if (isUser) {
      getSubscriberTempRequest({
        userId: (user.selectedUser as IUserModel).id,
        taskSid: taskSid,
        organizationId: '',
      });
    }
  };

  const setStep = (data: number) => {
    setVerificationStep({ taskSid: taskSid, step: data });
  };

  const renderData = (step: number) => {
    switch (step) {
      case 1:
        return (
          <UserSearch
            emailFromURL={emailFromURL}
            isUser={Boolean(isUser)}
            setStep={goNext}
            setProgress={setProgress}
          />
        );
      case 2:
        return (
          <LogicLayer
            setStep={setStep}
            userSearch={userSearchState}
            subscriberData={subscriberState}
            onVerify={onVerify}
          />
        );
      case 3:
        return <VerifyQuestions setStep={setStep} setProgress={setProgress} />;
      default:
        return null;
    }
  };

  return (
    <div data-testid='verify-customer-component'>
      {fullSizeButton ? (
        <Button
          onClick={handleClickOpen}
          className={classes.selectProfileButton}
          variant='contained'
          color='primary'
        >
          Select Customer Profile
        </Button>
      ) : (
        <div className={classes.searchIconWrapper}>
          <SearchIcon
            data-testid='search-icon'
            style={{ fill: 'white', fontSize: 14 }}
            onClick={handleClickOpen}
          />
        </div>
      )}
      <div data-testid='verify-customer' className={classes.dialogWrapper}>
        <Dialog
          classes={{
            root: isCrm ? classes.crmRoot : classes.root,
          }}
          fullScreen
          open={isOpen}
          onClose={handleClose}
          onEnter={(e) => {
            e.style.width = `${dialogWidth}px`;
          }}
          TransitionComponent={Transition}
          BackdropProps={{
            classes: {
              root: isCrm ? classes.crmBackdrop : classes.backDrop,
            },
          }}
          PaperProps={{ classes: { root: classes.dialogPaper } }}
        >
          {isOpen && (
            <Grid
              container
              className={classes.modalContainer}
              direction='column'
              justify='flex-start'
              alignItems='center'
            >
              <IconButton
                className={classes.closeButton}
                autoFocus
                color='inherit'
                data-testid='close-modal-button'
                onClick={handleClose}
                aria-label='close'
              >
                <Close />
              </IconButton>
              {renderData(ui.verificationStep)}
              <Grid
                item={true}
                classes={{
                  root: classes.progressBottom,
                }}
              >
                <LinearProgress
                  data-testid='progress-bar'
                  variant='determinate'
                  color='primary'
                  className={classes.progressBar}
                  value={progress}
                />
              </Grid>
              {ui && ui.verificationStep === 3 && (
                <Grid
                  item={true}
                  classes={{
                    root: classes.noVerification,
                  }}
                  data-testid='without-verification'
                  onClick={() => onVerify(false)}
                >
                  <Typography
                    classes={{
                      root: classes.textDecoration,
                    }}
                    variant='h5'
                  >
                    Continue without verification
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}
        </Dialog>
      </div>
    </div>
  );
};

export default connector(VerifyCustomer);
