import React from 'react';
import { IconButton } from '@material-ui/core';
import AcceptIcon from '../../../../assets/image/acceptIcon.svg';
import ArrowRight from '../../../../assets/image/arrowRight.svg';
import DeclineIcon from '../../../../assets/image/declineIcon.svg';
import { ElliTooltip } from '../../ElliTooltip/ElliTooltip';
import useStyles from './useStyles';

const iconMapping = {
  approve: AcceptIcon,
  open: ArrowRight,
  reject: DeclineIcon,
};

interface IOwnProps {
  action: keyof typeof iconMapping;
  onClick?: () => void;
  style?: { [s: string]: string };
  title: string;
}

export const ActionButton: React.FC<IOwnProps> = ({
  title,
  action,
  onClick,
  style,
}) => {
  const classes = useStyles();

  return (
    <ElliTooltip title={title} alwaysShowOnHover={true}>
      <IconButton
        onClick={(e) => {
          if (onClick) {
            e.stopPropagation();
            onClick();
          }
        }}
        size={'small'}
        style={style}
        className={`${classes.actionButton}`}
        classes={{ label: classes.actionButtonLabel }}
      >
        <img src={iconMapping[action]} alt='action' />
      </IconButton>
    </ElliTooltip>
  );
};
