import { InfoWindow, Marker } from '@react-google-maps/api';
import React, { useState } from 'react';

import { Typography } from '@material-ui/core';
import mainMarker from '../../../assets/image/map/mainMarker.svg';
import nearbyMarker from '../../../assets/image/map/nearbyLocation.svg';
import nearbyMarkerHover from '../../../assets/image/map/nearbyLocationHover.svg';

interface IMarkerProps {
  id: string;
  lat: number;
  lng: number;
  evse_id?: string;
  type: string;
  onClick?: () => void;
}

export const CustomMarker: React.FC<IMarkerProps> = ({
  id,
  lat,
  lng,
  evse_id,
  type,
  onClick,
}) => {
  const [isMarkerHovered, setIsMarkerHovered] = useState<boolean>(false);

  return type === 'main' ? (
    <Marker
      data-testid='main-marker'
      clickable={false}
      key={id}
      icon={mainMarker}
      position={{ lat, lng }}
    />
  ) : (
    <Marker
      data-testid='default-marker'
      clickable={true}
      onMouseOver={() => setIsMarkerHovered(true)}
      onMouseOut={() => setIsMarkerHovered(false)}
      onClick={onClick}
      key={id}
      icon={isMarkerHovered ? nearbyMarkerHover : nearbyMarker}
      position={{
        lat,
        lng,
      }}
    >
      {isMarkerHovered && (
        <InfoWindow
          position={{
            lat,
            lng,
          }}
        >
          <Typography style={{ color: '#000' }} variant='h5'>
            {evse_id}
          </Typography>
        </InfoWindow>
      )}
    </Marker>
  );
};
