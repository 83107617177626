import { ActionInterface, ActionType } from '../actions';
import initialState, { IOtherState, State } from '..';

import getRFIDCardsError from './getRFIDCardsError';
import getRFIDCardsRequest from './getRFIDCardsRequest';
import getRFIDCardsSuccess from './getRFIDCardsSuccess';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = (state: State = initialState, action: any): IOtherState => {
  switch (action.type) {
    case ActionType.GetRFIDCardsRequest:
      return getRFIDCardsRequest(
        state,
        action as ActionInterface.GetRFIDCardsRequest
      );
    case ActionType.GetRFIDCardsSuccess:
      return getRFIDCardsSuccess(
        state,
        action as ActionInterface.GetRFIDCardsSuccess
      );
    case ActionType.GetRFIDCardsError:
      return getRFIDCardsError(
        state,
        action as ActionInterface.GetRFIDCardsError
      );
    default:
      return state;
  }
};

export default reducer;
