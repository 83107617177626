import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    paginationContainer: {
      margin: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  })
);

export default useStyles;
