import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accountsCardHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 16,
    },
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    titleIcon: {
      color: theme.colorPrimary,
      fontSize: 24,
      marginRight: 16,
    },
    panelTitle: {
      fontSize: 20,
      fontWeight: 900,
    },
  })
);

export default useStyles;
