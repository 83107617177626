import { makeStyles, createStyles, Theme } from '@material-ui/core';

const arrowGenerator = (color: string) => {
  return {
    '&[x-placement*="bottom"] $arrow': {
      '&::before': {
        borderColor: `transparent transparent ${color} transparent`,
        borderWidth: '0 1em 1em 1em',
      },
      height: '1em',
      left: 0,
      marginTop: '-0.95em',
      top: 0,
      width: '2em',
      border: 'solid 1px #cad3e0',
    },
    '&[x-placement*="left"] $arrow': {
      '&::before': {
        borderColor: `transparent transparent transparent ${color}`,
        borderWidth: '1em 0 1em 1em',
      },
      height: '2em',
      marginRight: '-0.95em',
      right: 0,
      width: '1em',
    },
    '&[x-placement*="right"] $arrow': {
      '&::before': {
        borderColor: `transparent ${color} transparent transparent`,
        borderWidth: '1em 1em 1em 0',
      },
      height: '2em',
      left: 0,
      marginLeft: '-0.95em',
      width: '1em',
    },
    '&[x-placement*="top"] $arrow': {
      '&::before': {
        borderColor: `${color} transparent transparent transparent`,
        borderWidth: '1em 1em 0 1em',
      },
      bottom: 0,
      height: '1em',
      left: 0,
      marginBottom: '-0.95em',
      width: '2em',
    },
  };
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    arrow: {
      '&::before': {
        borderStyle: 'solid',
        content: '""',
        display: 'block',
        height: 0,
        margin: 'auto',
        width: 0,
      },
      fontSize: 6,
      position: 'absolute',
    },
    contentDiv: {
      color: '#000000',
      fontSize: theme.typography.caption.fontSize,
      fontWeight: 'normal',
      lineHeight: 1.33,
    },
    popper: arrowGenerator('#cad3e0'),
    tooltipBox: {
      backgroundColor: '#fff',
      padding: '8px',
      position: 'relative',
      boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.08)',
      border: 'solid 1px #cad3e0',
    },
  })
);

export default useStyles;
