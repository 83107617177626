import { ActionInterface } from '../actions';

import {
  IRFIDCardModel,
  ISubscriptionModel,
} from '../../../../types/subscriber';
import { ITariffModel } from '../../../../types/tariff';
import { ISortState } from '../../../../components/shared/AssetTable/AssetTable/AssetTable';
import { State } from '..';
import { lexicographical } from '../../../../utils/comparisonFunctions';
import update from 'immutability-helper';

interface ISortFunctionProps {
  a: ISubscriptionModel;
  b: ISubscriptionModel;
  chargingCards?: IRFIDCardModel[];
  tariffs?: ITariffModel[];
}

interface ISortFunctions {
  [string: string]: (props: ISortFunctionProps) => number;
}

export const getCardNumber = (
  id?: string,
  chargingCards: IRFIDCardModel[] = []
): string => {
  if (id) {
    const card = chargingCards.find((cc) => cc.subscription_id === id);
    if (card) {
      return card.number;
    }
  }
  return '';
};

export const getTariff = (
  tariffId?: string,
  tariffs: ITariffModel[] = []
): string => {
  if (tariffs.length < 1) {
    return '';
  }
  if (tariffId) {
    const tariff = tariffs.find((item) => item.id === tariffId);

    if (tariff) {
      return tariff.code;
    }
  }

  return '';
};

export const sortFunctions: ISortFunctions = {
  tariff_name: ({ a, b, tariffs }) => {
    const subA = getTariff(a.tariff_id, tariffs);
    const subB = getTariff(b.tariff_id, tariffs);
    return lexicographical(subA, subB);
  },
  status: ({ a, b }) => {
    return lexicographical(a.status, b.status);
  },
  start_date: ({ a, b }) => {
    const createdAtA = new Date(a.start_date);
    const createdAtB = new Date(b.start_date);
    return lexicographical(createdAtA, createdAtB);
  },
  preliminary_end: ({ a, b }) => {
    const createdAtA = new Date(a.end_date);
    const createdAtB = new Date(b.end_date);
    return lexicographical(createdAtA, createdAtB);
  },
  linked_charging_card: ({ a, b, chargingCards }) => {
    const cardA = getCardNumber(a.id, chargingCards);
    const cardB = getCardNumber(b.id, chargingCards);
    return lexicographical(cardA, cardB);
  },
};

export const generateSortFunction = (
  sortState: ISortState,
  chargingCards: IRFIDCardModel[],
  tariffs: ITariffModel[]
) => (a: ISubscriptionModel, b: ISubscriptionModel): number => {
  const sortKey = sortState.sortByColumnKey as AdminOrderSortKey;
  if (sortKey) {
    const sortFunction = sortFunctions[sortKey];
    const ascendingSortResult = sortFunction({ a, b, chargingCards, tariffs });

    return sortState.sortDesc ? ascendingSortResult * -1 : ascendingSortResult;
  } else {
    return 0;
  }
};

type AdminOrderSortKey = keyof typeof sortFunctions;

export default (
  state: State,
  action: ActionInterface.SortSubscriptions
): State => {
  const { sortState, taskSid, chargingCards, tariffs } = action;
  const currentSubsriptionsState = state[taskSid];

  if (currentSubsriptionsState && currentSubsriptionsState.data) {
    const sortedSubscriptions: ISubscriptionModel[] = currentSubsriptionsState.data.slice();
    sortedSubscriptions.sort(
      generateSortFunction(sortState, chargingCards, tariffs)
    );

    const updatedSubscriptions = update(currentSubsriptionsState, {
      data: {
        $set: sortedSubscriptions,
      },
      loading: {
        $set: false,
      },
    });

    return {
      ...state,
      [action.taskSid]: updatedSubscriptions,
    };
  }

  return {
    ...state,
  };
};
