import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    indicator: {
      borderRadius: 50,
      height: 8,
      width: 8,
      backgroundColor: '#cad4e0',
      margin: '0 auto',
    },
    statusGreen: {
      backgroundColor: theme.colorAccent,
    },
  })
);

export default useStyles;
