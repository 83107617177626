import { Grid, Typography } from '@material-ui/core';
import {
  IConnectorDetails,
  getConnectorDetails,
} from '../../../utils/getConnectorDetails';
import React, { useEffect, useState } from 'react';
import connector, { IPropsFromState } from '../../Connector/Connector';

import ChargingLocation from './ChargingLocation/ChargingLocation';
import ChargingSessionLogo from '../../../assets/image/chargingSession.svg';
import FaultedSession from './FaultedSession/FaultedSession';
import { HomeChargingService } from '../../../services/stations';
import { IChargingRecordViewModel } from '../../../types/chargingRecords';
import { IHomeChargingStation } from '../../../types/user';
import { ILocationModel } from '../../../types/chargingsession';
import { LocationService } from '../../../services/locations';
import PrivateDetails from './Private/Details';
import PublicDetails from './Public/Details';
import { formatIsoDates } from '../../../utils/dates';
import useSharedStyles from '../../shared/useSharedStyles';
import { useCurrentTask } from '../../../custom-hooks/useCurrentTask';
import useStyles from './useStyles';

type TOwnProps = IPropsFromState & {
  resourceId?: string;
  crumbId?: string;
};

export const ChargingSessionDetails: React.FC<TOwnProps> = ({
  chargingCardState,
  chargingSessionsState,
  resourceId,
  addBreadcrumb,
  breadcrumbState,
  crumbId,
  userState,
}) => {
  const taskSid = useCurrentTask();
  const sharedClasses = useSharedStyles();
  const classes = useStyles();

  const [
    chargingSession,
    setChargingSession,
  ] = useState<IChargingRecordViewModel | null>(null);

  const [recordType, setRecordType] = useState<string>('');

  const [
    currentStation,
    setCurrentStation,
  ] = useState<IHomeChargingStation | null>(null);

  const [currentLocation, setCurrentLocation] = useState<ILocationModel | null>(
    null
  );

  const [
    connectorDetails,
    setConnectorDetails,
  ] = useState<IConnectorDetails | null>(null);

  useEffect(() => {
    const details = getConnectorDetails({
      currentStation,
      currentSession: chargingSession,
    });

    if (details) {
      setConnectorDetails(details);
    }
  }, [chargingSession, currentStation]);

  useEffect(() => {
    if (!resourceId) {
      return;
    }

    const chargingSessions =
      chargingSessionsState[taskSid] && chargingSessionsState[taskSid].data;

    if (chargingSessions) {
      const currentSession = chargingSessions.find(
        (cc) => cc.id === resourceId
      );

      if (currentSession) {
        setChargingSession(currentSession);
        setRecordType(currentSession.type);
      }
    }
  }, [resourceId, chargingSessionsState]);

  useEffect(() => {
    if (chargingSession) {
      const getCurrentStation = async () => {
        try {
          const station = await HomeChargingService.getStationById(
            chargingSession.location_id
          );

          setCurrentStation(station);
        } catch (error) {
          setCurrentStation(null);
        }
      };

      const getCurrentLocationById = async () => {
        try {
          const location = await LocationService.getByLocationId(
            chargingSession.location_id
          );

          if (location && location.length) {
            setCurrentLocation(location[0]);
          }
        } catch (error) {
          setCurrentLocation(null);
        }
      };

      const getCurrentLocation = async () => {
        try {
          const location = await LocationService.getById(
            chargingSession.location_evse_id
          );

          if (location && location.length) {
            setCurrentLocation(location[0]);
          }
        } catch (error) {
          setCurrentLocation(null);
        }
      };

      if (chargingSession.location_id) {
        getCurrentStation();
        getCurrentLocationById();
      } else if (chargingSession.location_evse_id) {
        getCurrentLocation();
      }
    }
  }, [chargingSession]);

  return (
    chargingSession && (
      <div
        className={sharedClasses.cardElement}
        data-testid='charging-session-details-component'
      >
        <div className={classes.chargingCardDetailsHeader}>
          <div className={classes.titleWrapper}>
            <div className={classes.logoContainer}>
              <img src={ChargingSessionLogo} alt='charging session logo' />
            </div>
            <Typography
              variant='h1'
              classes={{
                root: classes.panelTitle,
              }}
            >
              Charging Record -{' '}
              {recordType === 'private' || recordType === 'business' ? (
                <span data-testid='id-header'>{chargingSession.id}</span>
              ) : (
                <span data-testid='date-header'>
                  {formatIsoDates(chargingSession.start_date_time)}
                </span>
              )}
            </Typography>
          </div>
          {chargingSession.session_faulted && <FaultedSession />}
        </div>

        <Grid
          container
          justify='space-between'
          alignItems='flex-start'
          direction='row'
        >
          {recordType === 'public' ? (
            <PublicDetails chargingSession={chargingSession} />
          ) : (
            <PrivateDetails
              chargingSession={chargingSession}
              connectorDetails={connectorDetails}
            />
          )}
          <ChargingLocation
            chargingCardState={chargingCardState}
            addBreadcrumb={addBreadcrumb}
            breadcrumbState={breadcrumbState}
            crumbId={crumbId}
            chargingSession={chargingSession}
            userState={userState}
            currentStation={currentStation}
            currentLocation={currentLocation}
          />
        </Grid>
      </div>
    )
  );
};

export default connector(ChargingSessionDetails);
