import React from 'react';
import MaterialTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import useStyles from './useStyles';

export type TTableData = {
  [name: string]: JSX.Element | string | number;
};

export type TTableHead = {
  name: string;
};

export type TTableRows = TTableData;

interface IOwnProps {
  tableHeadings: TTableHead[];
  tableRows: TTableRows[];
}

export const SimpleTable: React.FC<IOwnProps> = ({
  tableHeadings,
  tableRows,
}) => {
  const classes = useStyles();

  return (
    <MaterialTable
      className={classes.table}
      size='small'
      aria-label='a dense table'
      data-testid='simple-table'
    >
      <TableHead>
        <TableRow data-testid='table-head-row'>
          {tableHeadings.map((heading) => (
            <TableCell
              data-testid='table-head-cell'
              key={heading.name}
              classes={{
                paddingNone: classes.cellPaddingNone,
                root: classes.tableCellRoot,
                head: classes.tableHead,
              }}
              padding='none'
              align='left'
            >
              {heading.name}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {tableRows.map((row, index) => (
          <TableRow key={index} data-testid='table-body-row'>
            {Object.keys(row).map((data: string, dataIndex: number) => (
              <TableCell
                data-testid='table-body-cell'
                key={dataIndex}
                classes={{
                  paddingNone: classes.cellPaddingNone,
                  root: classes.tableCellRoot,
                }}
                padding='none'
                scope='row'
              >
                {row[data]}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </MaterialTable>
  );
};

export default SimpleTable;
