import update from 'immutability-helper';
import { State } from '..';
import { ActionInterface } from '../actions';

export default (
  state: State,
  action: ActionInterface.SetSelectedUser
): State => {
  const { user, taskSid } = action;
  const currentUser = state[taskSid];

  const updatedUser = update(currentUser, {
    selectedUser: {
      $set: user,
    },
  });

  return {
    ...state,
    [action.taskSid]: updatedUser,
  };
};
