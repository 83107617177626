import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  textWrapper: {
    display: 'flex',
    flexFlow: 'column nowrap',
    marginBottom: 20,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  textBox: {
    height: 30,
    padding: 0,
    paddingLeft: 10,
    color: 'black',
    fontWeight: 'normal',
    borderRadius: 3,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 249,
    boxSizing: 'border-box',
  },
  inputRoot: {
    borderRadius: 3,
    width: 280,
    background: '#E6E6E6',
    paddingRight: 8,
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23) !important',
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: 12,
    marginBottom: 4,
  },
  copyIcon: {
    cursor: 'pointer',
    fontSize: 16,
    '&:hover': {
      background: 'none !important',
    },
  },
  inputFocused: {
    outline: 'none',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderWidth: 1,
    '& fieldset': {
      borderWidth: '1px !important',
      borderColor: 'rgba(0, 0, 0, 0.23) !important',
    },
  },
  text: {
    color: 'black',
    lineHeight: 1,
    maxWidth: 220,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'block',
    height: 'auto',
  },
  icon: {},
});
