import { ActionInterface } from '../actions';
import { State } from '..';
import update from 'immutability-helper';

export default (
  state: State,
  action: ActionInterface.SetCurrentBreadcrumb
): State => {
  if (!state[action.taskSid]) {
    return {
      ...state,
    };
  }

  const breadcrumbState = state[action.taskSid];

  const currentCrumb = breadcrumbState.breadcrumbs.findIndex(
    (bc) => bc.id === action.id
  );

  const oldCurrent = breadcrumbState.breadcrumbs.findIndex((bc) => bc.current);

  let updatedBreadcrumbs = update(breadcrumbState, {
    breadcrumbs: {
      [currentCrumb]: {
        $merge: {
          current: true,
        },
      },
    },
  });

  if (oldCurrent > -1 && oldCurrent !== currentCrumb) {
    updatedBreadcrumbs = update(updatedBreadcrumbs, {
      breadcrumbs: {
        [oldCurrent]: {
          $merge: {
            current: false,
          },
        },
      },
    });
  }

  return {
    ...state,
    [action.taskSid]: updatedBreadcrumbs,
  };
};
