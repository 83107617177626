import React, { useRef } from 'react';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import { useStyles } from './useStyles';

export interface IOwnProps {
  title: string;
  text?: string;
  dataTestId?: string;
}

const copyText = (ref: React.RefObject<HTMLInputElement>): void => {
  (ref.current as HTMLInputElement).select();
  document.execCommand('copy');
};

const CopyTextField: React.FC<IOwnProps> = ({ title, text, dataTestId }) => {
  const classes = useStyles();
  const ref = useRef<HTMLInputElement>(null);
  const handleButtonClick: () => void = () => copyText(ref);
  const elementDataTestId = dataTestId || title.toLowerCase().replace(' ', '-');

  return (
    <div className={classes.textWrapper}>
      <span className={classes.title}>{title}</span>
      <TextField
        InputProps={{
          classes: {
            input: classes.textBox,
            root: classes.inputRoot,
            focused: classes.inputFocused,
          },
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                onClick={handleButtonClick}
                className={classes.copyIcon}
                data-testid={`${elementDataTestId}-copy-button`}
                disableFocusRipple
                disableRipple
              >
                <FileCopyIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        inputProps={{
          ref,
          readOnly: true,
          'data-testid': elementDataTestId,
        }}
        defaultValue={text || '-'}
        variant='outlined'
        size='small'
      />
    </div>
  );
};

export default CopyTextField;
