import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outerItemWrapper: {
      display: 'flex',
      alignItems: 'flex-start',
      flexFlow: 'row wrap',
    },
    sectionTitle: {
      marginBottom: 16,
      fontSize: 16,
      fontWeight: 900,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    itemWrapper: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      marginBottom: 24,
      marginRight: 24,
    },
    itemTitle: {
      fontSize: 12,
      color: theme.textSecondary,
      marginBottom: 10,
      lineHeight: 1.33,
    },
    itemValue: {
      fontSize: 14,
      display: 'flex',
      alignItems: 'center',
    },
    itemLink: {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  })
);

export default useStyles;
