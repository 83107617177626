import { IOwnProps as IInputFieldProps } from '../../Forms/InputField';
import { IOwnProps as IControlledSelect } from '../../Forms/SelectField/ControlledSelect';
import { countryInformationMapper } from '../../../../utils/currencies';
import { ISubscriberModel } from '../../../../types/subscriber';
import { IUserModel } from '../../../../types/user';

type SelectField = Omit<IControlledSelect, 'control'>;
type TranslationFunc = (key: string) => string;
type DefaultValues = Omit<IRfidCardOrderFields, 'zip' | 'other_reason'>;

export interface IRfidCardOrderFields {
  zip: string;
  city: string;
  country: string;
  reason: string;
  address_line_1?: string;
  address_line_2?: string;
  name?: string;
  state?: string;
  other_reason?: string;
}

export interface ITranslatedFields {
  textFields: { [key: string]: IInputFieldProps };
  selectFields: { [key: string]: SelectField };
}

export const DEFAULT_VALUES: DefaultValues = {
  city: '',
  country: '',
  reason: '',
  address_line_1: '',
  address_line_2: '',
  name: '',
  state: '',
};

const countryCodeOptions = Object.keys(countryInformationMapper).map((key) => ({
  key,
  value: key,
  content: key,
}));

export const getTranslatedTextFields = (
  translate: TranslationFunc
): ITranslatedFields['textFields'] => ({
  name: {
    id: 'name',
    name: 'name',
    label: translate('name'),
  },

  addressLine1: {
    id: 'address_line_1',
    name: 'address_line_1',
    label: translate('address_line_1'),
  },

  addressLine2: {
    id: 'address_line_2',
    name: 'address_line_2',
    label: translate('address_line_2'),
  },

  zip: {
    id: 'zip',
    name: 'zip',
    label: translate('zip'),
    isRequired: true,
  },

  city: {
    id: 'city',
    name: 'city',
    label: translate('city'),
    isRequired: true,
  },

  state: {
    id: 'state',
    name: 'state',
    label: translate('state'),
  },

  otherReason: {
    id: 'other_reason',
    name: 'other_reason',
    label: translate('other_reason'),
    isRequired: true,
  },
});

const getTranslatedSelectFields = (
  translate: TranslationFunc
): ITranslatedFields['selectFields'] => {
  const orderReasonOptions: IControlledSelect['options'] = [
    {
      key: 'lost',
      content: translate('reason_options.lost'),
      value: 'lost',
    },
    {
      key: 'defective',
      content: translate('reason_options.defective'),
      value: 'defective',
    },
    {
      key: 'not-arrived',
      content: translate('reason_options.not_arrived'),
      value: 'not-arrived',
    },
    {
      key: 'other',
      content: translate('reason_options.other'),
      value: 'other',
    },
  ];

  return {
    country: {
      labelText: translate('country'),
      label: translate('country'),
      name: 'country',
      labelId: 'country',
      options: countryCodeOptions,
      isRequired: true,
    },
    reason: {
      labelText: translate('reason'),
      label: translate('reason'),
      name: 'reason',
      labelId: 'reason',
      options: orderReasonOptions,
      isRequired: true,
    },
  };
};

/**
 * Creates all fields for the form to order a rfid card with translations
 */
export const getTranslatedFields = (
  translate: TranslationFunc
): ITranslatedFields => {
  const getPrefixedString = (field: string) => translate(`fields.${field}`);

  return {
    textFields: getTranslatedTextFields(getPrefixedString),
    selectFields: getTranslatedSelectFields(getPrefixedString),
  };
};

export const getDefaultValues = (
  subscriber: ISubscriberModel,
  user: IUserModel | null
): Partial<IRfidCardOrderFields> => {
  const { address } = subscriber;
  const { first_name, last_name } = user || {};
  const name =
    first_name || last_name
      ? `${first_name} ${last_name}`
      : DEFAULT_VALUES.name;

  if (!address) {
    return { ...DEFAULT_VALUES, name };
  }

  return {
    zip: address.zip,
    city: address.city || DEFAULT_VALUES.city,
    country: address.country_code || DEFAULT_VALUES.country,
    reason: DEFAULT_VALUES.reason,
    address_line_1: address.address_line_1 || DEFAULT_VALUES.address_line_1,
    address_line_2: address.address_line_2 || DEFAULT_VALUES.address_line_2,
    name,
    state: address.state || DEFAULT_VALUES.state,
  };
};
