import { IHomeChargingStation, IHomeStation } from '../../../types/user';

/* eslint-disable @typescript-eslint/naming-convention */
import { ErrorObject } from 'serialize-error';

export interface GetHomeStationsRequest {
  user_id: string;
  taskSid: string;
}

export interface HomeStationsStateModel {
  loading: boolean;
  data: IHomeChargingStation | null;
  stations: IHomeStation[] | [];
  error: ErrorObject | null;
  request: { user_id: string } | null;
}

export interface HomeStationsState {
  [id: string]: HomeStationsStateModel;
}

const state: HomeStationsState = {};

export type State = typeof state;
export default state;
