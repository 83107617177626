import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    filterTag: {
      background: '#f5f6f8',
      fontSize: '14px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '6px 10px',
      marginRight: '8px',

      '&:last-child': {
        marginRight: 0,
      },
    },
    leftIcon: {
      marginRight: '4px !important',
      fontSize: '13px !important',
      color: '#878c96 !important',
    },
    rightIcon: {
      marginLeft: '4px !important',
      fontSize: '20px !important',

      '&:hover': {
        color: 'rgba(0, 0, 0, 0.4) !important',
        cursor: 'pointer',
      },
    },
    text: {
      lineHeight: 1,
    },
  })
);

export default useStyles;
