import {
  IChargingSpotModel,
  ILocationAddress,
  ILocationModel,
} from '../types/chargingsession';
import {
  IHomeChargingAddress,
  IHomeChargingStation,
  IHomeEvse,
} from '../types/user';
import {
  TConnectorType,
  TPowerType,
  connectorTypeMap,
  getPowerType,
} from '../components/DetailCards/ChargeRecord/PowerConnector/PowerConnector';
import { getAuthorisationMode, getStationModel } from '../utils/stationUtils';

import { LocationConnectorTypeMap } from '../components/DetailCards/ChargingStation/TypeIndicator/TypeIndicator';
import { formatIsoDates } from '../utils/dates';
import { parseHomeChargingAddress } from '../utils/parseAddress';

interface IOwnModel {
  station: IHomeChargingStation | null;
  location: ILocationModel | null;
}

export interface IConnectorViewModel {
  evse_id: string;
  type_name: string;
  power_type: string;
  max_power?: number;
  max_power_type: string;
  status: string;
  connector_type: string | number;
  price?: string;
}

interface IGeoLocation {
  lat: number;
  long: number;
}

export interface IStationViewModel {
  type: string;
  name: string;
  station_serial_number: string;
  model: string;
  authorization_mode: string;
  lifecycle_state: string;
  last_contact: string;
  onboarding_date: string;
  address: string;
  connectors: IConnectorViewModel[];
  locationName: string;
  locationDescription: string;
  iam_id: string;
  station_id: string;
  auth_name: string;
  device_twin_id: string;
  pairing_code: string;
  authentication_modes: string[];
  geo_location?: IGeoLocation;
  operator: string;
  connection_state: string;
  station_owner_type: string;
  station_owner: string;
  setStationOwner: (name: string) => void;
}

export class StationViewModel implements IStationViewModel {
  type: string;
  name: string;
  station_serial_number: string;
  model: string;
  authorization_mode: string;
  lifecycle_state: string;
  last_contact: string;
  onboarding_date: string;
  address: string;
  connectors: IConnectorViewModel[];
  locationName: string;
  locationDescription: string;
  iam_id: string;
  station_id: string;
  auth_name: string;
  device_twin_id: string;
  pairing_code: string;
  authentication_modes: string[];
  geo_location?: IGeoLocation;
  operator: string;
  connection_state: string;
  station_owner_type: string;
  station_owner: string;

  constructor({ station, location }: IOwnModel) {
    this.type = '-';
    this.operator = '-';
    this.name = '-';
    this.station_serial_number = '-';
    this.model = '-';
    this.authorization_mode = '-';
    this.lifecycle_state = '-';
    this.last_contact = '-';
    this.onboarding_date = '-';
    this.address = '-';
    this.connectors = [];
    this.locationName = '-';
    this.locationDescription = '-';
    this.iam_id = '-';
    this.station_id = '-';
    this.auth_name = '-';
    this.device_twin_id = '-';
    this.pairing_code = '-';
    this.authentication_modes = [];
    this.geo_location = undefined;
    this.connection_state = '-';
    this.station_owner_type = '-';
    this.station_owner = '-';

    if (station) {
      this.type = 'private_business';
      this.setStationProps(station);
    }

    if (location) {
      this.type = 'public';
      this.setLocationProps(location);
    }
  }

  setStationProps(data: IHomeChargingStation): void {
    this.name = data.name || '-';
    this.station_serial_number = data.station_serial_number || '-';
    this.model = getStationModel(data.model);
    this.authorization_mode = getAuthorisationMode(data.authorization_mode);
    this.lifecycle_state = data.lifecycle_state;
    this.last_contact = data.last_contact
      ? formatIsoDates(data.last_contact)
      : '-';
    this.onboarding_date = data.onboarding_date
      ? formatIsoDates(data.onboarding_date)
      : '-';
    this.address =
      data.location && data.location.address
        ? this.parseAddress(data.location.address)
        : '-';
    this.connectors = this.parseStationEvses(data.evses);
    this.locationName = (data.location && data.location.name) || '-';
    this.locationDescription =
      (data.location && data.location.description) || '-';
    this.iam_id = (data.owner && data.owner.iam_id) || '-';
    this.station_owner_type = (data.owner && data.owner.type) || '-';
    this.station_id = data.id || '-';
    this.auth_name = data.auth_name || '-';
    this.device_twin_id = data.device_twin_id || '-';
    this.pairing_code = data.pairing_code || '-';
    this.connection_state = data.connection_state || '-';
    if (data.location && data.location.geo_location) {
      this.geo_location = {
        lat: +(data.location.geo_location.lat as string),
        long: +(data.location.geo_location.long as string),
      };
    }
  }

  setLocationProps(data: ILocationModel): void {
    this.operator = (data.operator && data.operator.name) || '-';
    this.address =
      data.address && typeof data.address !== 'string'
        ? this.parseAddress(data.address)
        : '-';
    this.connectors = this.parseLocationConnectors(data.charging_spots);
    if (data.geo_location) {
      this.geo_location = {
        lat: data.geo_location.lat,
        long: data.geo_location.long,
      };
    }
  }

  parseLocationConnectors(data: IChargingSpotModel[]): IConnectorViewModel[] {
    const connectors: IConnectorViewModel[] = [];

    data.forEach((evse) => {
      if (!evse.connectors) {
        evse.connectors = [];
      }

      evse.connectors.forEach((connector) => {
        const parsedConnector = {
          evse_id: evse.evse_id,
          connector_type: connector.connector_type_id || 'UNKNOWN',
          type_name:
            typeof connector.connector_type_id === 'number'
              ? LocationConnectorTypeMap[connector.connector_type_id].title
              : '',
          power_type: connector.current_type || '-',
          max_power: connector.max_electric_power,
          max_power_type: 'w',
          status: evse.status || '',
          price: '--',
        };

        connectors.push(parsedConnector);
      });
    });

    return connectors;
  }

  parseStationEvses(evses: IHomeEvse[]): IConnectorViewModel[] {
    const connectors: IConnectorViewModel[] = [];

    evses.forEach((evse) => {
      evse.connectors.forEach((connector) => {
        const parsedConnector = {
          evse_id: this.parseConnectorEvse(
            evse.public_evse_id,
            evse.id,
            connector.id
          ),
          connector_type: connector.type || 'UNKNOWN',
          type_name:
            connectorTypeMap[(connector.type || 'UNKNOWN') as TConnectorType]
              .name,
          power_type: getPowerType(
            connector.power_type as TPowerType | undefined
          ),
          max_power: connector.max_electric_power_kw,
          max_power_type: 'kw',
          status: connector.status,
          price: '--',
        };

        connectors.push(parsedConnector);
      });
    });

    return connectors;
  }

  parseAddress(address: IHomeChargingAddress | ILocationAddress): string {
    return parseHomeChargingAddress(address as IHomeChargingAddress);
  }

  parseConnectorEvse(
    public_evse_id?: string,
    evse_id?: number,
    connector_id?: number
  ): string {
    if (public_evse_id) {
      return public_evse_id;
    }

    return `Connector ${connector_id} (EVSE ${evse_id})`;
  }

  setStationOwner(name: string): void {
    this.station_owner = name;
  }
}
