import { ActionInterface } from '../actions';
import { State } from '..';
import update from 'immutability-helper';

export default (
  state: State,
  action: ActionInterface.UpdateBreadcrumb
): State => {
  if (!state[action.taskSid]) {
    return {
      ...state,
    };
  }

  const breadcrumbState = state[action.taskSid];

  const currentCrumb = breadcrumbState.breadcrumbs.findIndex(
    (bc) => bc.id === action.data.id
  );

  const updatedBreadcrumbs = update(breadcrumbState, {
    breadcrumbs: {
      [currentCrumb]: {
        $merge: {
          ...action.data,
        },
      },
    },
  });

  return {
    ...state,
    [action.taskSid]: updatedBreadcrumbs,
  };
};
