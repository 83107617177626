import { IUserSearchModel } from './../../../types/user';
import { ActionCreator, ActionInterface, ActionType } from './actions';
import { all, call, cancelled, debounce, put } from 'redux-saga/effects';

import { UsersService } from '../../../services/users';

export function* userSaga() {
  function* getUser(action: ActionInterface.GetUserSearchRequest) {
    try {
      const users: IUserSearchModel[] = yield call(UsersService.findUser, {
        query: action.query,
      });

      yield put<ActionInterface.GetUserSearchSuccess>(
        ActionCreator.GetUserSearchSuccess({
          data: users,
          taskSid: action.taskSid,
        })
      );
    } catch (error) {
      yield put<ActionInterface.GetUserSearchError>(
        ActionCreator.GetUserSearchError({
          error,
          taskSid: action.taskSid,
        })
      );

      yield cancelled();
    }
  }

  yield all([debounce(1000, ActionType.GetUserSearchRequest, getUser)]);
}

export default userSaga;
