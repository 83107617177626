import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.textPrimary,
    },
    errorDialog: {
      padding: 32,
      minWidth: 346,
      color: theme.colorAlertPrimary,
    },
  })
);

export default useStyles;
