import {
  IHomeChargingStationModel,
  IOrganizationModel,
} from '../../../types/user';

import { IAccounts } from '../../../types/organization';
import { ISortState } from '../../../components/shared/AssetTable/AssetTable/AssetTable';
/* eslint-disable @typescript-eslint/naming-convention */
import { actionCreator } from '../../type-utils';

export const ActionType = {
  GetOrganizationRequest: 'organization/get-organization-request',
  GetOrganizationSuccess: 'organization/get-organization-success',
  GetOrganizationError: 'organization/get-organization-error',
  ClearOrganization: 'organization/clear-organization',
  SortOrganizationStations: 'organization/sort-organization-stations',
  SortAccounts: 'organization/sort-accounts',
};

export declare namespace ActionInterface {
  export interface GetOrganizationRequest {
    type: string;
    taskSid: string;
    organizationId: string;
  }

  export interface GetOrganizationSuccess {
    type: string;
    taskSid: string;
    stations: IHomeChargingStationModel;
    data: IOrganizationModel;
    accounts: IAccounts;
  }

  export interface GetOrganizationError {
    type: string;
    taskSid: string;
    error: Error;
  }

  export interface ClearOrganization {
    type: string;
    taskSid: string;
  }

  export interface SortOrganizationStations {
    type: string;
    taskSid: string;
    sortState: ISortState;
  }

  export interface SortAccounts {
    type: string;
    taskSid: string;
    sortState: ISortState;
  }
}

export const ActionCreator = {
  GetOrganizationRequest: actionCreator<ActionInterface.GetOrganizationRequest>(
    ActionType.GetOrganizationRequest
  ),
  GetOrganizationSuccess: actionCreator<ActionInterface.GetOrganizationSuccess>(
    ActionType.GetOrganizationSuccess
  ),
  GetOrganizationError: actionCreator<ActionInterface.GetOrganizationError>(
    ActionType.GetOrganizationError
  ),
  ClearOrganization: actionCreator<ActionInterface.ClearOrganization>(
    ActionType.ClearOrganization
  ),
  SortOrganizationStations: actionCreator<
    ActionInterface.SortOrganizationStations
  >(ActionType.SortOrganizationStations),
  SortAccounts: actionCreator<ActionInterface.SortAccounts>(
    ActionType.SortAccounts
  ),
};
