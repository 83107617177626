import './App.css';

import * as Flex from '@twilio/flex-ui';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { mainTheme } from '../theme';

type MainProps = {
  manager: Flex.Manager;
};

function App(props: MainProps) {
  const { manager } = props;

  if (!manager) {
    return null;
  }

  return (
    <Flex.ContextProvider manager={manager}>
      <MuiThemeProvider theme={mainTheme}>
        <CssBaseline />
        <Flex.RootContainer />
      </MuiThemeProvider>
    </Flex.ContextProvider>
  );
}

export default App;
