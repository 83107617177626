import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  activationStatus: {
    display: 'inline-block',
    marginRight: 4,
    verticalAlign: 'baseline',
  },

  statusWrapper: {
    display: 'flex',
    alignItems: 'center',
  },

  statusText: {
    textTransform: 'capitalize',
  },

  chargingCardIcon: {
    marginTop: -3,
  },
}));

export default useStyles;
