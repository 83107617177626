import { Button, Typography } from '@material-ui/core';
import Connector, { IPropsFromState } from '../Connector/Connector';

import React from 'react';
import Subject from '@material-ui/icons/Subject';
import { SubscriptionTable } from '../SubscriberView/SubscriptionTable/SubscriptionTable';
import useSharedStyles from '../shared/useSharedStyles';
import { useCurrentTask } from '../../custom-hooks/useCurrentTask';

type TOwnProps = IPropsFromState & {
  crumbId?: string;
};

export const ContractsUI: React.FC<TOwnProps> = ({
  subscriptionState,
  addBreadcrumb,
}) => {
  const taskSid = useCurrentTask();
  const sharedClasses = useSharedStyles();
  const subscription = subscriptionState && subscriptionState[taskSid];

  const addPanelHandler = (component: string, friendlyText: string): void => {
    const data = {
      component,
      friendlyText,
      level: 2,
    };

    addBreadcrumb({ taskSid, data });
  };

  return (
    <div
      className={sharedClasses.cardElement}
      data-testid='contracts-ui-component'
    >
      <div className={sharedClasses.sectionWrap}>
        <Subject
          color='primary'
          style={{ fontSize: '20px', marginRight: '5px' }}
        />
        <Typography variant='h4' color='primary'>
          Contracts
        </Typography>
      </div>
      {subscription && subscription.data ? (
        <SubscriptionTable subscriptionData={subscription.data} />
      ) : (
        <Typography data-testid='contracts-ui' variant='body1'>
          No data available
        </Typography>
      )}
      <Button
        variant='outlined'
        color='primary'
        onClick={() => addPanelHandler('UserUI', 'User Profile')}
      >
        Add Another Panel
      </Button>
    </div>
  );
};

export default Connector(ContractsUI);
