import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  zip: yup
    .string()
    .required()
    .min(2),
  city: yup.string().required(),
  country: yup.string().required(),
  reason: yup.string().required(),
  other_reason: yup.string().when('reason', {
    is: (reason: string) => reason === 'other',
    then: yup
      .string()
      .min(3)
      .required(),
  }),
});
