import React, { ButtonHTMLAttributes } from 'react';
import { Button, CircularProgress } from '@material-ui/core';

import useStyles from './useStyles';

interface IOwnProps {
  mainText: string | React.ReactElement;
  handleClick?: () => void;
  isDisabled?: boolean;
  smallText?: string;
  buttonType?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  inProgress?: boolean;
  className?: string;
  actionType?: 'primary' | 'secondary';
}

const MultilineButton: React.FC<IOwnProps> = ({
  handleClick,
  mainText,
  isDisabled,
  smallText,
  buttonType = 'submit',
  inProgress,
  className,
  actionType = 'primary',
}) => {
  const classes = useStyles();

  const mainTextStyles = smallText
    ? [classes.withSmallButtonText, classes.mainButtonText].join(' ')
    : classes.mainButtonText;

  const isPrimaryType = actionType === 'primary';

  return (
    <Button
      color={isPrimaryType ? 'secondary' : 'primary'}
      variant={isPrimaryType ? 'contained' : 'outlined'}
      classes={{
        root: classes.buttonStyling,
      }}
      onClick={handleClick}
      disabled={isDisabled}
      className={className}
      type={buttonType}
    >
      {inProgress ? (
        <CircularProgress
          aria-label='loadingSpinner'
          className={classes.circularProgress}
          size='16px'
          color='inherit'
        />
      ) : (
        undefined
      )}

      <div className={inProgress ? classes.textContainerHidden : ''}>
        <div className={mainTextStyles}>{mainText}</div>
        {smallText && <p className={classes.smallButtonText}>{smallText}</p>}
      </div>
    </Button>
  );
};

export default MultilineButton;
