import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    unknownSubscriber: {
      color: theme.textSecondary,
    },
  })
);

export default useStyles;
