import { AppDispatch } from '../../../../store';
import { ActionCreator } from '../../../../store/state/actions';
import { ActionInterface } from '../../../../store/state/subscription/actions';
import { Dispatcher } from '../index';

interface IDispatchers {
  sortSubscriptions: Dispatcher<ActionInterface.SortSubscriptions>;
  getSubscriptions: Dispatcher<ActionInterface.GetSubscriptionRequest>;
  terminateSubscription: Dispatcher<
    ActionInterface.TerminateSubscriptionRequest
  >;
}

export const createDispatchers = (dispatch: AppDispatch): IDispatchers => ({
  sortSubscriptions: (payload) =>
    dispatch(ActionCreator.subscription.SortSubscriptions(payload)),
  getSubscriptions: (payload) =>
    dispatch(ActionCreator.subscription.GetSubscriptionRequest(payload)),
  terminateSubscription: (payload) =>
    dispatch(ActionCreator.subscription.TerminateSubscriptionRequest(payload)),
});
