import {
  IChargingRecord,
  IChargingRecordViewModel,
} from '../../../types/chargingRecords';

/* eslint-disable @typescript-eslint/naming-convention */
import { ErrorObject } from 'serialize-error';
import { IChargingSessionModel } from '../../../types/chargingsession';

export interface GetChargingSessionsRequest {
  subscriber_id: string;
  taskSid: string;
}

export interface ChargingSessionsStateModel {
  loading: boolean;
  data: IChargingRecordViewModel[] | null;
  error: ErrorObject | null;
  request: { subscriber_id: string } | null;
  public: IChargingSessionModel[] | null;
  private: IChargingRecord[] | null;
  organization: IChargingRecord[] | null;
}

export interface ChargingSessionsState {
  [id: string]: ChargingSessionsStateModel;
}

const state: ChargingSessionsState = {};

export type State = typeof state;
export default state;
