import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    activationStatus: {
      display: 'inline-block',
      marginRight: '7px',
      verticalAlign: 'baseline',
    },
  })
);

export default useStyles;
