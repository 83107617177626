import { IOtherState, State } from '..';

import { ActionInterface } from '../actions';
import update from 'immutability-helper';

export default (
  state: State,
  action: ActionInterface.GetRFIDCardsSuccess
): State => {
  const { data } = action;

  const updatedState: IOtherState = update(state, {
    rfidCards: {
      data: {
        $set: data,
      },
      loading: {
        $set: false,
      },
    },
  });

  return {
    ...updatedState,
  };
};
