/* eslint-disable @typescript-eslint/naming-convention */

export interface BreadcrumbModel {
  defaultFilters?: string[];
  component: string;
  large?: boolean;
  withSidebar?: boolean;
  friendlyText: string;
  level: number;
  componentId?: string;
  id?: string;
  midPoint?: number;
  startPoint?: number;
  current: boolean;
  org?: boolean;
  location_evse_id?: string;
  station_id?: string;
}

export interface BreadcrumbStateModel {
  breadcrumbs: BreadcrumbModel[];
}

export interface BreadcrumbState {
  [id: string]: BreadcrumbStateModel;
}

const state: BreadcrumbState = {};

export type State = typeof state;
export default state;
