import React, { ChangeEvent } from 'react';
import autoSuggestMatch from 'autosuggest-highlight/match';
import autoSuggestParse from 'autosuggest-highlight/parse';
import { matchSorter } from 'match-sorter';

import { Grid, TextField } from '@material-ui/core';
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  FilterOptionsState,
} from '@material-ui/lab';

import { useCurrentTask } from '../../custom-hooks/useCurrentTask';
import { useTypedTranslation } from '../../custom-hooks/useTypedTranslation';
import { IUserModel, IUserSearchModel } from '../../types/user';
import connector, { IPropsFromState } from '../Connector/Connector';
import { DealershipLogo } from '../DealershipLogo/DealershipLogo';
import useStyles from './useStyles';

export const renderSelectedUser = (user: IUserSearchModel) =>
  user ? `${user.iam_user.email} (${user.iam_user.id})` : '';

const renderMatchParts = (parts: ReturnType<typeof autoSuggestParse>) => {
  return parts.map(
    (part: { highlight: boolean; text: string }, index: number) => (
      <span key={index} style={{ fontWeight: part.highlight ? 800 : 400 }}>
        {part.text}
      </span>
    )
  );
};

const renderOptionToSuggestion = (suggestion: string, inputValue: string) => {
  const matches = autoSuggestMatch(suggestion, inputValue);
  return renderMatchParts(autoSuggestParse(suggestion, matches));
};

const renderCardNumberOptionToSuggestion = (
  suggestion: string,
  inputValue: string
) => {
  let textToHighlight = suggestion;
  let matches = autoSuggestMatch(textToHighlight, inputValue);
  if (matches.length === 0) {
    textToHighlight = textToHighlight.replace(/-/g, '');
    matches = autoSuggestMatch(textToHighlight, inputValue);
  }
  return renderMatchParts(autoSuggestParse(textToHighlight, matches));
};

type TOwnProps = IPropsFromState & {
  emailFromURL?: string;
};

export const Search: React.FC<TOwnProps> = ({
  userSearchState,
  getUserSearchRequest,
  setSelectedUser,
}) => {
  const taskSid = useCurrentTask();
  const userSearch = userSearchState && userSearchState[taskSid];

  const classes = useStyles();
  const isLoading = userSearch && userSearch.loading;
  const { t, terms } = useTypedTranslation();

  const inputChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    reason: AutocompleteInputChangeReason
  ): void => {
    if (e && reason !== 'reset') {
      const input = e.target.value;
      if (input && input.length >= 3) {
        getUserSearchRequest({
          taskSid: taskSid,
          query: input.trim(),
        });
      }
    }
  };

  const selectChangeHandler = (option: IUserSearchModel | null): void => {
    if (option?.iam_user.id) {
      setSelectedUser({
        user: option.iam_user,
        taskSid: taskSid,
      });
    }
  };

  const getName = (user: IUserSearchModel): string => {
    if (user.iam_user.first_name && user.iam_user.last_name) {
      return `${user.iam_user.first_name} ${user.iam_user.last_name}`;
    }

    return 'Name unknown';
  };

  const getSearchValues = (): Array<IUserSearchModel> => {
    if (userSearch && userSearch.data) {
      return userSearch.data;
    }

    return [];
  };

  const getCurrentValue = (): IUserSearchModel | undefined => {
    if (userSearch && userSearch.data && userSearch.selectedUser) {
      return userSearch.data.find(
        (user: IUserSearchModel) =>
          user.iam_user.id === (userSearch.selectedUser as IUserModel).id
      );
    }

    return undefined;
  };

  const getCardNumber = ({ rfid_card }: IUserSearchModel) =>
    rfid_card?.number || '';

  const getCustomerNumber = ({ subscriber }: IUserSearchModel) =>
    subscriber?.customerNumber || '';

  const getGridForRfidCardNumber = (
    option: IUserSearchModel,
    inputValue: string
  ) => {
    if (!option.hits.includes('rfid_card.number')) {
      return null;
    }
    return (
      <Grid item={true}>
        {renderCardNumberOptionToSuggestion(
          `${getCardNumber(option)}${t(
            terms.customer_verification.user_search.match_label_rfid_card_number
          )}`,
          inputValue
        )}
      </Grid>
    );
  };

  const getGridForCustomerNumber = (
    option: IUserSearchModel,
    inputValue: string
  ) => {
    if (!option.hits.includes('subscriber.customerNumber')) {
      return null;
    }
    return (
      <Grid item={true}>
        {renderOptionToSuggestion(
          `${getCustomerNumber(option)}${t(
            terms.customer_verification.user_search.match_label_customer_number
          )}`,
          inputValue
        )}
      </Grid>
    );
  };

  const filterOptions = (
    options: IUserSearchModel[],
    { inputValue }: FilterOptionsState<IUserSearchModel>
  ): IUserSearchModel[] =>
    matchSorter(options, inputValue.trim(), {
      // The keys listed here will be searched and highlighted by the Autocomplete component
      keys: [
        (s) => s.iam_user.email,
        (s) => s.iam_user.id,
        (s) => getCardNumber(s),
        (s) => getCardNumber(s).replace(/-/g, ''),
        (s) => getCustomerNumber(s),
      ],
    });

  return (
    <div>
      <Autocomplete
        className={classes.userIdInput}
        getOptionSelected={(option, value) =>
          option.iam_user.id === value.iam_user.id
        }
        getOptionLabel={renderSelectedUser}
        options={getSearchValues()}
        value={getCurrentValue()}
        onInputChange={(event, _option, reason) =>
          inputChangeHandler(
            event as React.ChangeEvent<HTMLInputElement>,
            reason
          )
        }
        onChange={(
          _event: ChangeEvent<unknown>,
          option: IUserSearchModel | null
        ) => selectChangeHandler(option)}
        loading={isLoading}
        noOptionsText={t(terms.customer_verification.user_search.no_users)}
        autoComplete={true}
        clearOnEscape={true}
        freeSolo={true}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t(terms.customer_verification.user_search.search_label)}
            variant='outlined'
            className={classes.inputRoot}
            autoFocus={true}
            inputProps={{
              ...params.inputProps,
              'data-testid': 'user-search',
            }}
          />
        )}
        renderOption={(option, { inputValue }) => (
          <Grid
            data-testid='search-options'
            container
            justify='space-between'
            alignItems='center'
          >
            <Grid data-testid='search-result-item'>
              <Grid item={true}>
                {renderOptionToSuggestion(getName(option), inputValue)}
              </Grid>
              <Grid item={true}>
                {renderOptionToSuggestion(option.iam_user.email, inputValue)}
              </Grid>
              {getGridForRfidCardNumber(option, inputValue)}
              {getGridForCustomerNumber(option, inputValue)}
            </Grid>
            <Grid item={true}>
              <DealershipLogo
                id={option.iam_user.tenant_id}
                width='30px'
                height='30px'
              />
            </Grid>
          </Grid>
        )}
        filterOptions={filterOptions}
      />
    </div>
  );
};

export default connector(Search);
