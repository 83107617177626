import { IUserModel } from './user';

export type ISubscriberModel = {
  billing_info_completed?: boolean;
  address?: IAddressModel;
  connection_layer_id: string;
  connection_layer_id_type: string;
  created_at: string;
  fleet_id: string;
  iam_id: string;
  id: string;
  payment_account_no: string;
  status: string;
  tenant_id: string;
  updated_at: string;
  customer_number?: string;
};

export interface IShippingAddressModel {
  address_line_1?: string;
  address_line_2: string;
  city: string;
  country_code: string;
  house_number: string;
  street: string;
  zip: string;
}

export interface IAddressModel {
  id: string;
  city: string;
  country_code: string;
  zip: string;
  created_at: string;
  updated_at: string;
  connection_layer_id: string;
  address_line_2?: string;
  address_line_1?: string;
  state?: string;
}

export interface IChargeRecordModel {
  billing_status: string;
  cpo_price: number;
  created_at: string;
  currency: string;
  elli_connector_id: string;
  elli_evse_uid: string;
  elli_location_id: string;
  end_date_time: string;
  error: string | null;
  external_cdr_id: string;
  id: string;
  location_address: string;
  location_city: string;
  location_connector_power_type: string;
  location_connector_standard: string;
  location_coordinates_latitude: number;
  location_coordinates_longitude: number;
  location_country: string;
  location_evse_id: string;
  location_name: string;
  location_operator_name: string;
  location_postal_code: number;
  ocpi_version: string;
  rfid_card_id: string;
  start_date_time: string;
  subscription_id: string;
  token_id: string;
  token_type: string;
  total_energy: number;
  total_price_excl_tax: number;
  total_price: number;
  total_time: number;
  updated_at: string;
  vat_amount: number;
  vat_percentage: number;
}

export type RfidCardOrderStatus =
  | 'pending'
  | 'approved'
  | 'rejected'
  | 'printed'
  | 'shipped'
  | 'completed';

export type RfidCardStatus =
  | 'active'
  | 'inactive'
  | 'ordered'
  | 'readytopair'
  | 'archived';

export interface IRFIDCardModel {
  htb_white_list?: string;
  id: string;
  number: string;
  plasticard_brand_id: number;
  plasticard_design_template: number;
  status: RfidCardStatus;
  subscription_id: string;
  subscriber_id: string;
  tenant_id: string;
  tenant_name: string;
  tag: string;
  updated_at?: string;
  public_charging?: boolean;
  created_at: string;
  pairing_date?: string;
  label?: string;
}

export const isActivatableCard = (card: IRFIDCardModel) =>
  (['archived', 'inactive'] as RfidCardStatus[]).includes(card.status);

export const isArchivableCard = (card: IRFIDCardModel) =>
  (['active', 'inactive'] as RfidCardStatus[]).includes(card.status);

export const isDeactivatableCard = (card: IRFIDCardModel) =>
  (['active'] as RfidCardStatus[]).includes(card.status);

export interface ICardOrderRequestModel {
  created_at: string;
  first_name: string;
  id: string;
  language_code: string;
  last_name: string;
  rfid_card: Omit<IRFIDCardModel, 'created_at'>;
  shipping_address: IShippingAddressModel;
  status: RfidCardOrderStatus;
  subscriber_id: string;
  updated_at: string;
  plasticard_salutation: number;
  reason: string;
  subscription_id?: string;
  ordered_by?: string;
}

export interface ICardOrderModel extends ICardOrderRequestModel {
  orderer?: IUserModel;
}

export type ISubscriptionModel = {
  automatic_renewal: boolean;
  base_condition_id: string;
  created_at: string;
  currency: string;
  end_date: string;
  expiration_reminder_sent_at: string;
  id: string;
  inactivation_reason?: string;
  monthly_fee_net_amount: number;
  monthly_fee_vat_amount: number;
  monthly_fee_vat_percentage: number;
  monthly_fee: number;
  notice_period_end_date: string;
  sign_up_fee_net_amount: number;
  sign_up_fee_vat_amount: number;
  sign_up_fee_vat_percentage: number;
  sign_up_fee: number;
  start_date: string;
  status: string;
  subscriber_id: string;
  tariff_id: string;
  updated_at: string;
  voucher_details?: {
    total_credit: number;
    remaining_credit: number;
    last_used_at?: string;
    currency: string;
  };
};

export interface ITariffModel {
  id: string;
  code: string;
  tenant_id: string;
  created_at: string;
  updated_at: string;
}
