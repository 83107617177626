import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    detailWrapper: {
      marginBottom: '16px',
      padding: '8px 16px',
    },
    statusWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    activationStatus: {
      display: 'inline-block',
      marginRight: '4px',
      verticalAlign: 'baseline',
    },
    statusText: {
      textTransform: 'capitalize', // !important',
    },
    divider: {
      width: '100%',
      backgroundColor: '#f5f6f8 !important',
    },
    infoIcon: {
      marginLeft: '2px !important',
      cursor: 'pointer',
      fontSize: '12px !important',
    },
  })
);

export default useStyles;
