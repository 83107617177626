/* eslint-disable @typescript-eslint/naming-convention */
import { createMuiTheme, fade } from '@material-ui/core/styles';
import { ExpandMore } from '@material-ui/icons';

enum Colors {
  Accent700 = '#00ce4e',
  Black = '#000000',
  White = '#ffffff',
  ElectricLime = '#00ff99',
  ImpellentPurple = '#6633cc',
  PopularPink = '#f00066',
  SolarOrange = '#ff8c00',
  OsloGrey = '#878c96',
  AthensGrey = '#f5f6f8',
  GeyserGrey = '#cad3e0',
  DarkImpellentPurple = '#3d20b6',
  defaultMUIFrameColor = 'rgba(0,0,0,0.23)',
  iconHoverColor = 'rgba(0, 0, 0, 0.08)',
}
declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    colorPrimary: string;
    colorPrimaryDark: string;
    colorAccent: string;
    colorConfirmation: string;
    materialFrameColor: string;
    surfacePrimary: string;
    surfaceSecondary: string;
    surfaceTertiary: string;
    textPrimary: string;
    textSecondary: string;
    colorAlertPrimary: string;
    colorAlertSecondary: string;
    portalPadding: string;
  }
  interface ThemeOptions {
    colorPrimary?: string;
    colorPrimaryDark?: string;
    colorAccent?: string;
    colorConfirmation?: string;
    materialFrameColor?: string;
    surfacePrimary?: string;
    surfaceSecondary?: string;
    surfaceTertiary?: string;
    textPrimary?: string;
    textSecondary?: string;
    colorAlertPrimary?: string;
    colorAlertSecondary?: string;
    portalPadding?: string;
  }
}

export const mainTheme = createMuiTheme({
  colorAccent: Colors.ElectricLime,
  colorAlertPrimary: Colors.PopularPink,
  colorAlertSecondary: Colors.SolarOrange,
  colorConfirmation: Colors.Accent700,
  colorPrimary: Colors.ImpellentPurple,
  colorPrimaryDark: Colors.DarkImpellentPurple,
  materialFrameColor: Colors.defaultMUIFrameColor,

  overrides: {
    MuiButton: {
      containedSecondary: {
        '&:hover': {
          backgroundColor: Colors.ElectricLime,
          boxShadow: `0 8px 32px 0 ${fade(Colors.ElectricLime, 0.6)}`,
        },
        borderRadius: 0,
        boxShadow: `0 4px 12px 0 ${fade(Colors.ElectricLime, 0.3)}`,
        letterSpacing: 'normal',
      },
      outlinedPrimary: {
        '&&': {
          borderWidth: '2px',
        },
        borderColor: Colors.ImpellentPurple,
      },
      textPrimary: {
        '&&': { background: 'transparent' },
        '&:focus': { textDecoration: 'underline' },
        '&:hover': { textDecoration: 'underline' },
        color: Colors.ImpellentPurple,
        minWidth: '0',
        padding: '0',
        textDecoration: 'none',
        whiteSpace: 'nowrap',
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '16px',
      },
    },
    MuiIconButton: {
      root: {
        padding: '0',
        '&:hover': {
          backgroundColor: Colors.iconHoverColor,
        },
      },
      colorPrimary: {
        '&:hover': {
          backgroundColor: Colors.iconHoverColor,
        },
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: undefined,
      },
    },
    MuiTableRow: {
      hover: {
        '&$hover:hover': {
          backgroundColor: Colors.AthensGrey,
        },
      },
    },
    MuiInputLabel: {
      root: {
        '&.MuiInputLabel-outlined': {
          background: '#fff',
          paddingRight: 4,
        },
      },
    },
  },
  palette: {
    error: {
      contrastText: Colors.Black,
      main: Colors.PopularPink,
    },
    primary: {
      contrastText: Colors.White,
      main: Colors.ImpellentPurple,
    },
    secondary: {
      contrastText: Colors.Black,
      main: Colors.ElectricLime,
    },
  },
  portalPadding: '36px',
  props: {
    MuiSelect: {
      IconComponent: ExpandMore,
    },
  },
  surfacePrimary: Colors.White,
  surfaceSecondary: Colors.AthensGrey,
  surfaceTertiary: Colors.GeyserGrey,
  textPrimary: Colors.Black,
  textSecondary: Colors.OsloGrey,

  typography: {
    // useNextVariants: true,
    body1: {
      // Using this for menu / list items
      fontSize: '14px',
      fontWeight: 600,
      letterSpacing: '0.14px',
    },
    body2: {
      // Note that Material UI uses body2 as the default
      color: Colors.Black,
      fontSize: '14px',
      lineHeight: '1.43',
      fontWeight: 400,
      letterSpacing: '0.14px',
    },
    button: {
      fontSize: '16px',
      fontWeight: 700,
      textTransform: 'none',
    },
    caption: {
      fontSize: '12px',
      letterSpacing: '0px',
      lineHeight: '1.33',
    },
    fontFamily: 'Inter, sans-serif',
    fontWeightBold: 800,
    fontWeightLight: 300,
    fontWeightMedium: 700,
    fontWeightRegular: 400,
    h1: {
      // Title1 (Zeplin)
      fontSize: '28px',
      fontWeight: 900,
      lineHeight: '1.21',
    },
    h2: {
      // Title2
      fontSize: '24px',
      fontWeight: 800,
      lineHeight: '1.33',
    },
    h3:
      // Title 3
      {
        fontSize: '18px',
        fontWeight: 400,
        lineHeight: '1.78',
      },
    h4: {
      // Headline
      fontSize: '16px',
      fontWeight: 900,
      lineHeight: '1.38',
    },
    h5: {
      color: Colors.ImpellentPurple,
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '1.33',
      letterSpacing: 0,
    },
    h6: {
      color: Colors.OsloGrey,
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '1.33',
      letterSpacing: '0.4px',
    },
  },
});
export type MainTheme = typeof mainTheme;

// do this automatically
mainTheme.palette.text.primary = mainTheme.textPrimary;
mainTheme.palette.text.secondary = mainTheme.textSecondary;
mainTheme.shape.borderRadius = 0; // remove rounded borders on text fields
