import update from 'immutability-helper';
import serializeError from 'serialize-error';

import { State } from '..';
import { ActionInterface } from '../actions';

export default (
  state: State,
  action: ActionInterface.GetTariffsError
): State => {
  const { error, taskSid } = action;

  const tariffs = state[taskSid] || {
    loading: true,
    data: null,
    error: null,
  };

  const updatedTariffs = update(tariffs, {
    error: {
      $set: serializeError(error),
    },
    loading: {
      $set: false,
    },
    data: {
      $set: null,
    },
  });

  return {
    ...state,
    [action.taskSid]: updatedTariffs,
  };
};
