import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import {
  IRFIDCardModel,
  ISubscriptionModel,
  RfidCardStatus,
} from '../../../../types/subscriber';
import connector, { IPropsFromState } from '../../../Connector/Connector';

import { Circle } from './Circle/Circle';
import { useCurrentTask } from '../../../../custom-hooks/useCurrentTask';
import useStyles from './useStyles';

interface ICircleState {
  circleState: 'happy' | 'sad' | 'neutral';
  happyText: string;
  sadText: string;
}

type TOwnProps = IPropsFromState;

export const ChargingReadiness: React.FC<TOwnProps> = ({
  cardOrdersState,
  subscriptionState,
  chargingCardState,
}) => {
  const classes = useStyles();
  const taskSid = useCurrentTask();

  const subscription = subscriptionState && subscriptionState[taskSid];
  const hasActiveSubscription =
    subscription &&
    Array.isArray(subscription.data) &&
    subscription.data.length;

  const cardOrders = cardOrdersState && cardOrdersState[taskSid];
  const hasCardOrders =
    cardOrders && Array.isArray(cardOrders.data) && cardOrders.data.length;

  const chargingCards = chargingCardState && chargingCardState[taskSid];
  const hasChargingCards =
    chargingCards &&
    Array.isArray(chargingCards.data) &&
    chargingCards.data.length;

  const [orderState, setOrderState] = useState<ICircleState>({
    circleState: 'neutral',
    happyText: '',
    sadText: 'No card ordered',
  });
  const [addedState, setAddedState] = useState<ICircleState>({
    circleState: 'sad',
    happyText: '',
    sadText: 'No card added to Card Wallet',
  });
  const [signedUpState, setSignedUpState] = useState<ICircleState>({
    circleState: 'sad',
    happyText: '',
    sadText: 'No active subscription',
  });
  const [linkedState, setLinkedState] = useState<ICircleState>({
    circleState: 'sad',
    happyText: '',
    sadText: 'No card paired with active subscription',
  });
  const [blockedState, setBlockedState] = useState<ICircleState>({
    circleState: 'neutral',
    happyText: 'No card',
    sadText: '',
  });
  const [readyState, setReadyState] = useState<ICircleState>({
    circleState: 'sad',
    happyText: '',
    sadText: 'Not ready for charging',
  });

  useEffect(() => {
    if (
      addedState.happyText &&
      signedUpState.happyText &&
      linkedState.happyText &&
      blockedState.happyText
    ) {
      setReadyState({
        circleState: 'happy',
        happyText: 'Ready for charging',
        sadText: '',
      });
    } else {
      setReadyState({
        circleState: 'sad',
        happyText: '',
        sadText: 'Not ready for charging',
      });
    }
  }, [orderState, addedState, signedUpState, linkedState, blockedState]);

  useEffect(() => {
    if (hasActiveSubscription) {
      setSignedUpState({
        circleState: 'happy',
        happyText: 'Signed up for subscription',
        sadText: '',
      });
    }

    if (hasChargingCards) {
      setAddedState({
        circleState: 'happy',
        happyText: 'Card added to Card Wallet',
        sadText: '',
      });
    }

    if (hasCardOrders) {
      setOrderState({
        circleState: 'happy',
        happyText: 'Card ordered',
        sadText: '',
      });
    }

    if (hasChargingCards && hasActiveSubscription) {
      const currentCard = (chargingCards.data as IRFIDCardModel[]).find(
        (card) =>
          card.subscription_id ===
          (subscription.data as ISubscriptionModel[])[0].id
      );

      if (currentCard) {
        setLinkedState({
          circleState: 'happy',
          happyText: 'Card linked to subscription',
          sadText: '',
        });

        const sadStates: RfidCardStatus[] = ['inactive', 'archived'];
        if (sadStates.includes(currentCard.status)) {
          setBlockedState({
            circleState: 'sad',
            happyText: '',
            sadText: `Card ${currentCard.status}`,
          });
        }

        if (currentCard.status === 'active') {
          setBlockedState({
            circleState: 'happy',
            happyText: 'Card active',
            sadText: '',
          });
        }
      }
    }
  }, [hasActiveSubscription, hasChargingCards, hasCardOrders]);

  return (
    <div className={classes.chargingReadinessWrapper}>
      <div className={classes.closeIcon}></div>
      <div className={classes.headerWrapper}>
        <Typography
          variant='h1'
          classes={{
            root: classes.dialogTitle,
          }}
        >
          Readiness for Public Charging
        </Typography>
        <Typography
          variant='subtitle1'
          classes={{
            root: classes.dialogSubtitle,
          }}
        >
          Preconditions that need to be met before charging in public
        </Typography>
      </div>
      <div className={classes.gridContainer}>
        <div className={classes.area1}>
          <Circle
            circleState={orderState.circleState}
            happyText={orderState.happyText}
            sadText={orderState.sadText}
            linePosition='center'
          />
        </div>
        <div className={classes.area2}>
          <Circle
            circleState={addedState.circleState}
            happyText={addedState.happyText}
            sadText={addedState.sadText}
            linePosition='bottom'
          />
        </div>
        <div className={classes.area3}>
          <Circle
            circleState={signedUpState.circleState}
            happyText={signedUpState.happyText}
            sadText={signedUpState.sadText}
            linePosition='top'
          />
        </div>
        <div className={classes.area4}>
          <div className={classes.area41}>
            <Circle
              circleState={linkedState.circleState}
              sadText={linkedState.sadText}
              happyText={linkedState.happyText}
              linePosition='bottom'
            />
          </div>
          <div className={classes.area42}>
            <Circle
              circleState={blockedState.circleState}
              sadText={blockedState.sadText}
              happyText={blockedState.happyText}
              linePosition='top'
            />
          </div>
        </div>
        <div className={classes.area5}>
          <Circle
            circleState={readyState.circleState}
            sadText={readyState.sadText}
            happyText={readyState.happyText}
          />
        </div>
      </div>
    </div>
  );
};

export default connector(ChargingReadiness);
