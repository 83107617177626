/* eslint-disable @typescript-eslint/naming-convention */
import { IRFIDCardModel, ISubscriptionModel } from '../../../types/subscriber';

import { IChargingRecordViewModel } from '../../../types/chargingRecords';
import { ISortState } from '../../../components/shared/AssetTable/AssetTable/AssetTable';
import { ITariffModel } from '../../../types/tariff';
import { actionCreator } from '../../type-utils';

export const ActionType = {
  GetChargingCardRequest: 'charging-card/get-charging-card-request',
  GetChargingCardSuccess: 'charging-card/get-charging-card-success',
  GetChargingCardError: 'charging-card/get-charging-card-error',
  SetCurrentCard: 'charging-card/set-current-card',
  SortChargingCards: 'charging-card/sort-chargingcards',
};

export declare namespace ActionInterface {
  export interface GetChargingCardRequest {
    type: string;
    subscriberId: string;
    taskSid: string;
  }

  export interface GetChargingCardSuccess {
    type: string;
    data: IRFIDCardModel[];
    taskSid: string;
  }

  export interface GetChargingCardError {
    type: string;
    error: Error;
    taskSid: string;
  }

  export interface SetCurrentCard {
    type: string;
    taskSid: string;
    card: IRFIDCardModel;
  }

  export interface SortChargingCards {
    type: string;
    taskSid: string;
    sortState: ISortState;
    sessions: IChargingRecordViewModel[];
    subscriptions: ISubscriptionModel[];
    tariffs: ITariffModel[];
  }
}

export const ActionCreator = {
  GetChargingCardRequest: actionCreator<ActionInterface.GetChargingCardRequest>(
    ActionType.GetChargingCardRequest
  ),
  GetChargingCardSuccess: actionCreator<ActionInterface.GetChargingCardSuccess>(
    ActionType.GetChargingCardSuccess
  ),
  GetChargingCardError: actionCreator<ActionInterface.GetChargingCardError>(
    ActionType.GetChargingCardError
  ),
  SetCurrentCard: actionCreator<ActionInterface.SetCurrentCard>(
    ActionType.SetCurrentCard
  ),
  SortChargingCards: actionCreator<ActionInterface.SortChargingCards>(
    ActionType.SortChargingCards
  ),
};
