import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Dialog } from '@material-ui/core';
import useStyles from './useStyles';
import { useToggle } from '../../../custom-hooks/useToggle';

interface IOwnProps {
  tooltipName: string;
  additionalText?: string;
  className?: string;
}

/**
 * Custom tooltip with info-button and dialog popup
 * @param children content for the dialog
 * @param tooltipName tooltip name
 * @param additionalText text to be part of the clickable tooltip
 * @param className css class to override defaults
 */
const Tooltip: React.FC<IOwnProps> = ({
  children,
  tooltipName,
  additionalText,
  className,
}) => {
  const [isOpen, toggleIsOpen] = useToggle(false);
  const classes = useStyles();
  const paperProps = {
    classes: {
      root: classes.dialog,
    },
  };

  return (
    <>
      <span onClick={toggleIsOpen} className={classes.buttonWrapper}>
        <IconButton
          data-testid={`${tooltipName}-button`}
          className={className || classes.infoIcon}
          disableRipple
          disableFocusRipple
        >
          <InfoOutlinedIcon />
        </IconButton>
        {additionalText}
      </span>

      <Dialog
        onClose={toggleIsOpen}
        open={isOpen}
        data-testid={`${tooltipName}-dialog`}
        PaperProps={paperProps}
      >
        {children}
      </Dialog>
    </>
  );
};

export default Tooltip;
