import {
  IBreadcrumbHandlerProps,
  addBreadcrumbHandler,
} from '../../../utils/addBreadcrumbHandler';
import {
  IConnectorDetails,
  getConnectorDetails,
} from '../../../utils/getConnectorDetails';
import React, { useEffect, useState } from 'react';
import connector, { IPropsFromState } from '../../Connector/Connector';

import CircularProgress from '@material-ui/core/CircularProgress';
import { ElliTooltip } from '../../shared/ElliTooltip/ElliTooltip';
import { HomeChargingService } from '../../../services/stations';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import { IActiveSession } from '../../../types/activeSessions';
import { IHomeChargingStation } from '../../../types/user';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PowerConnector from '../../DetailCards/ChargeRecord/PowerConnector/PowerConnector';
import { SessionState } from '../../shared/Dialogs/SessionState/SessionState';
import { StatusIndicator } from '../../DetailCards/ActiveSession/StatusIndicator/StatusIndicator';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import { formatIsoDates } from '../../../utils/dates';
import { getSessionState } from '../../../utils/getSessionState';
import { getStationName } from '../../DetailCards/ActiveSession';
import { parseSecondsDigital } from '../../../utils/parseSeconds';
import { useCurrentTask } from '../../../custom-hooks/useCurrentTask';
import useStyles from './useStyles';

type TOwnProps = IPropsFromState & {
  session: IActiveSession;
  loading: boolean;
  userId: string;
  crumbId?: string;
};

export const ActiveSession: React.FC<TOwnProps> = ({
  session,
  loading,
  userId,
  crumbId,
  breadcrumbState,
  addBreadcrumb,
  getActiveSessionsRequest,
}) => {
  const classes = useStyles();
  const taskSid = useCurrentTask();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [
    currentStation,
    setCurrentStation,
  ] = useState<IHomeChargingStation | null>(null);
  const [stationName, setStationName] = useState<string>('');
  const [timer, setTimer] = useState<string>('');
  const taskBreadcrumbState = breadcrumbState && breadcrumbState[taskSid];
  const [
    connectorDetails,
    setConnectorDetails,
  ] = useState<IConnectorDetails | null>(null);

  const refresh = () => {
    getActiveSessionsRequest({
      taskSid,
      userId,
    });
  };

  let loadingClass = `${classes.itemWrapper}`;

  if (loading) {
    loadingClass += ` ${classes.loading}`;
  }

  const breadcrumbHandler = (props: IBreadcrumbHandlerProps): void =>
    addBreadcrumbHandler({
      ...props,
      addBreadcrumb,
      taskSid,
      crumbId,
      taskBreadcrumbState,
    });

  useEffect(() => {
    const details = getConnectorDetails({
      currentStation,
      currentSession: session,
    });

    if (details) {
      setConnectorDetails(details);
    }
  }, [session, currentStation]);

  useEffect(() => {
    const getCurrentStation = async () => {
      try {
        const station = await HomeChargingService.getStationById(
          session.station_id
        );

        setCurrentStation(station);
      } catch (error) {
        setCurrentStation(null);
      }
    };

    getCurrentStation();
  }, [session]);

  useEffect(() => {
    let difInSec = differenceInSeconds(
      new Date(),
      new Date(session.start_date_time)
    );
    const timerInterval = setInterval(() => {
      setTimer(parseSecondsDigital(difInSec));
      difInSec++;
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [session]);

  useEffect(() => {
    if (currentStation) {
      setStationName(
        getStationName({
          session,
          station: currentStation,
        })
      );
    }
  }, [currentStation]);

  return (
    <div
      className={classes.activeSessionWrapper}
      data-testid='active-session-action-component'
    >
      <div className={classes.sessionDetailsWrapper}>
        <h1
          className={classes.sectionTitle}
          data-testid='section-title-details'
        >
          Session Details
        </h1>
        <div className={classes.startDurationWrapper}>
          <div className={classes.itemWrapper}>
            <span className={classes.itemTitle}>Session Start</span>
            <span className={classes.itemValue} data-testid='data-start-time'>
              {formatIsoDates(session.start_date_time)}
            </span>
          </div>
          <div data-testid='loading' className={loadingClass}>
            <span className={classes.itemTitle}>Duration</span>
            <span className={classes.valueWithIcon}>
              <span data-testid='data-timer'>{timer}</span>
              {!loading && (
                <CircularProgress
                  size={10}
                  classes={{
                    root: classes.circleProgress,
                  }}
                />
              )}
            </span>
          </div>
        </div>
        <div className={classes.itemWrapper}>
          <span className={classes.itemTitle}>Charging Card Id</span>
          <span className={classes.itemValue} data-testid='data-rfid-card'>
            {session.rfid_card_id || '-'}
          </span>
        </div>
        <div data-testid='loading' className={loadingClass}>
          <span className={classes.itemTitleWithIcon}>
            <span>Session State</span>
            <IconButton
              data-testid='more-info-button'
              onClick={() => setDialogOpen((prevState) => !prevState)}
              className={classes.infoIcon}
              disableRipple
              disableFocusRipple
            >
              <InfoOutlinedIcon />
            </IconButton>
          </span>
          <span className={classes.itemValue}>
            <span
              style={{
                marginRight: 10,
              }}
            >
              <StatusIndicator status={session.charging_state} />
            </span>
            <span data-testid='data-charge-state'>
              {getSessionState(session.charging_state)}
            </span>
          </span>
        </div>
      </div>
      <div className={classes.connectorDetailsWrapper}>
        <h1 className={classes.sectionTitle}>Connector Details</h1>
        <div className={classes.itemWrapper}>
          <span className={classes.itemTitle}>Station Id</span>
          <span className={classes.itemValue}>
            {stationName ? (
              <div
                className={classes.valueWithIcon}
                onClick={() =>
                  breadcrumbHandler({
                    component: 'ChargingStationDetails',
                    friendlyText: 'Charging Station',
                    id: (currentStation as IHomeChargingStation).id,
                    config: {
                      station_id: (currentStation as IHomeChargingStation).id,
                    },
                  })
                }
              >
                <HomeIcon
                  data-testid='HomeIcon'
                  style={{
                    marginRight: 4,
                    fontSize: 16,
                  }}
                />
                <span className={classes.itemLink}>
                  <ElliTooltip title={stationName}>
                    <span
                      className={classes.tooltipOverflow}
                      data-testid='data-station-id'
                    >
                      {stationName}
                    </span>
                  </ElliTooltip>
                </span>
              </div>
            ) : (
              '-'
            )}
          </span>
        </div>
        <div className={classes.itemWrapper}>
          <span className={classes.itemTitle}>Connector Info</span>
          <span className={classes.itemValue} data-testid='data-connector'>
            <PowerConnector
              connectorDetails={connectorDetails}
              styles={{
                icon: {
                  minWidth: 16,
                  width: 16,
                },
                text: {
                  marginLeft: 4,
                },
              }}
            />
          </span>
        </div>
      </div>
      <div className={classes.actionButtonsWrapper}>
        <span
          role='button'
          className={classes.actionButton}
          onClick={() => refresh()}
          data-testid='button-refresh'
        >
          Refresh
        </span>
        <span
          role='button'
          className={classes.actionButton}
          data-testid='button-open-session'
          onClick={() =>
            breadcrumbHandler({
              component: 'ActiveSessionDetails',
              friendlyText: 'Charging Session',
              id: session.id,
            })
          }
        >
          Open Session
        </span>
      </div>
      <SessionState open={dialogOpen} onClose={() => setDialogOpen(false)} />
    </div>
  );
};

export default connector(ActiveSession);
