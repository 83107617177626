import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    dialogTitle: {
      marginBottom: '20px',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    dialogInitials: {
      marginLeft: '10px',
      display: 'flex',
    },
    subTitle: {
      textAlign: 'center',
    },
    backContainer: {
      marginTop: '20px',
      marginBottom: '20px',
    },
    buttonStyling: {
      padding: '5px 65px',
    },
  })
);

export default useStyles;
