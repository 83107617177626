import { makeStyles } from '@material-ui/core/styles';
import { mainTheme } from '../../../theme';

export const useStyles = makeStyles({
  textWithLink: {
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      color: mainTheme.colorPrimary,
    },
  },
  successMessageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& > img': {
      margin: 32,
      marginTop: 16,
    },
  },
  detailWrapper: {
    padding: '0 16px 16px',
  },
  chargingCardLink: {
    cursor: 'pointer',
    textDecoration: 'underline',

    '&:hover': {
      color: '#6633cc',
    },
  },
  divider: {
    width: '100%',
    backgroundColor: '#f5f6f8 !important',
  },
  informationRow: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 0,
    flexFlow: 'row wrap',
  },
  statusWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  activationStatus: {
    display: 'inline-block',
    marginRight: '4px',
    verticalAlign: 'baseline',
  },
  statusText: {
    textTransform: 'capitalize', // !important',
  },
  headingWithIcon: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: 1, // !important,
  },
  infoIcon: {
    marginLeft: '2px !important',
    cursor: 'pointer',
    fontSize: '12px !important',
  },
  tooltipWrapper: {
    position: 'absolute',
    right: 0,
    top: 0,
    transform: 'translateX(100%)',
  },
});
