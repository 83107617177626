import {
  IBreadcrumbHandlerProps,
  addBreadcrumbHandler,
} from '../../../utils/addBreadcrumbHandler';
import React, { useEffect, useState } from 'react';
import connector, { IPropsFromState } from '../../Connector/Connector';

import { ChargingStationsTable } from './ChargingStationsTable';
import ChargingStationsIcon from '../../../assets/image/charging_station.svg';
import { IHomeStation } from '../../../types/user';
import { ISortState } from '../../shared/AssetTable/AssetTable/AssetTable';
import Pagination from '../../shared/Pagination/Pagination';
import { Typography } from '@material-ui/core';
import { paginateArray } from '../../../utils/paginateArray';
import useSharedStyles from '../../shared/useSharedStyles';
import { sortRequestHandler as sortStateHandler } from '../../../utils/sortRequestHander';

import { useCurrentTask } from '../../../custom-hooks/useCurrentTask';
import useStyles from './useStyles';

type TOwnProps = IPropsFromState & {
  crumbId?: string;
  isOrg?: boolean;
};

export const ChargingStationsCollection: React.FC<TOwnProps> = ({
  homeStationsState,
  organizationState,
  isOrg,
  sortHomeStations,
  sortOrganizationStations,
  addBreadcrumb,
  crumbId,
  breadcrumbState,
}) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const taskSid = useCurrentTask();
  const [chargingStations, setChargingStations] = useState<IHomeStation[]>([]);
  const [sortState, setSortState] = useState<ISortState>({
    sortByColumnKey: 'name',
    sortDesc: true,
  });

  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pagesLength, setPagesLength] = useState<number>(0);
  const [paginatedStations, setPaginatedStations] = useState<IHomeStation[][]>([
    [],
  ]);
  const taskBreadcrumbState = breadcrumbState && breadcrumbState[taskSid];

  const paginateStations = (stations: IHomeStation[]): void => {
    const ps = paginateArray(stations, itemsPerPage);
    setPaginatedStations(ps);
    setPagesLength(ps.length);
  };

  const sortRequestHandler = (columnkey: string | null): void => {
    sortStateHandler(columnkey, sortState, setSortState);
  };

  const breadcrumbHandler = (props: IBreadcrumbHandlerProps): void =>
    addBreadcrumbHandler({
      ...props,
      addBreadcrumb,
      taskSid,
      crumbId,
      taskBreadcrumbState,
    });

  useEffect(() => {
    setChargingStations(paginatedStations[currentPage - 1]);
  }, [paginatedStations, currentPage]);

  useEffect(() => {
    const stations = homeStationsState[taskSid].stations;
    const orgStations = organizationState[taskSid].stations;
    if (isOrg) {
      if (orgStations && orgStations.stations.length) {
        paginateStations(orgStations.stations);
      }
    } else {
      if (stations.length) {
        paginateStations(stations);
      }
    }
  }, [homeStationsState, organizationState, itemsPerPage, isOrg]);

  useEffect(() => {
    if (isOrg) {
      sortOrganizationStations({ taskSid, sortState });
    } else {
      sortHomeStations({ taskSid, sortState });
    }
  }, [sortState, taskSid, sortHomeStations]);

  return (
    <div
      className={sharedClasses.cardElement}
      data-testid='charging-stations-collection'
    >
      <div className={classes.chargingCardDetailsHeader}>
        <div className={classes.titleWrapper}>
          <img
            src={ChargingStationsIcon}
            alt='Charging Stations'
            className={classes.titleIcon}
          />
          <Typography
            variant='h1'
            classes={{
              root: classes.panelTitle,
            }}
          >
            Stations
          </Typography>
        </div>
      </div>
      <div className={classes.chargingSessionsTableWrapper}>
        <ChargingStationsTable
          chargingStations={chargingStations}
          sortState={sortState}
          sortRequestHandler={sortRequestHandler}
          addBreadcrumbHandler={breadcrumbHandler}
        />
        <Pagination
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pagesLength={pagesLength}
          itemSelectText='Stations per page'
        />
      </div>
    </div>
  );
};

export default connector(ChargingStationsCollection);
