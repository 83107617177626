import { ActionInterface } from '../actions';
import { State } from '..';
import serializeError from 'serialize-error';
import update from 'immutability-helper';

export default (
  state: State,
  action: ActionInterface.GetChargingStationError
): State => {
  const { error, taskSid } = action;

  const currentChargingStationState = state[taskSid] || {
    loading: true,
    nearbyLocationsLoading: false,
    data: null,
    nearbyLocations: null,
    error: null,
    request: null,
  };

  const updatedChargingCardState = update(currentChargingStationState, {
    error: {
      $set: serializeError(error),
    },
    loading: {
      $set: false,
    },
    data: {
      $set: null,
    },
  });

  return {
    ...state,
    [action.taskSid]: updatedChargingCardState,
  };
};
