import React from 'react';
import { ListItem, Typography } from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircle';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import useStyles from './useStyles';

interface IOwnProps {
  data?: any;
  selectField: (n: number) => void;
}

export const VerificationQuestionsItem: React.FC<IOwnProps> = ({
  data,
  selectField,
}) => {
  const classes = useStyles();

  return (
    <ListItem
      className={data.isSelected ? classes.selected : classes.root}
      onClick={() => selectField(data.id)}
      data-testid='flex-data'
    >
      <div className={classes.flex}>
        <Typography variant='h4'>{data.q}</Typography>
        <Typography variant='body2'>{data.a}</Typography>
      </div>
      {data.isSelected ? (
        <CheckCircle
          data-testid='selected-item'
          className={classes.checkCircle}
        />
      ) : (
        <CheckCircleOutline className={classes.checkCircleOutline} />
      )}
    </ListItem>
  );
};
