import { CSSTransition, TransitionGroup } from 'react-transition-group';
import connector, { IPropsFromState } from '../Connector/Connector';

import { BreadcrumbModel } from '../../store/state/breadcrumbs';
import DetailPanel from '../DetailPanel/DetailPanel';
import React from 'react';
import { useCurrentTask } from '../../custom-hooks/useCurrentTask';

type IOwnProps = IPropsFromState & {
  containerRef: React.RefObject<HTMLDivElement>;
};

export const DetailPanels: React.FC<IOwnProps> = ({
  breadcrumbState,
  containerRef,
}) => {
  const taskSid = useCurrentTask();
  const breadcrumbsList = breadcrumbState && breadcrumbState[taskSid];

  return (
    <>
      {breadcrumbsList && (
        <TransitionGroup component={null}>
          {breadcrumbsList.breadcrumbs.map(
            (crumb: BreadcrumbModel, index: number) => {
              if (index !== 0) {
                return (
                  <CSSTransition
                    timeout={{
                      appear: 200,
                      enter: 0,
                      exit: 400,
                    }}
                    key={index}
                    classNames='detail-panel'
                  >
                    <DetailPanel crumb={crumb} containerRef={containerRef} />
                  </CSSTransition>
                );
              }

              return null;
            }
          )}
        </TransitionGroup>
      )}
    </>
  );
};

export default connector(DetailPanels);
