import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    typeWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    connectorLogo: {
      minWidth: '24px',
    },
  })
);

export default useStyles;
