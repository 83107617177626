import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    debug: {
      marginTop: '50px',
      color: '#ddd',
    },
    crmWrapper: {
      height: '100%',
      boxSizing: 'border-box',
      position: 'relative',
      background: '#f5f6f8',
      overflowX: 'scroll',
      paddingLeft: '12px',
      outline: 'none',
      fontFamily: 'Inter, sans-serif',
    },
    contentWrapper: {
      display: 'flex',
      width: '100%',
      marginTop: '44px',
    },
    topBar: {
      padding: '12px',
      display: 'flex',
      alignItems: 'center',
      position: 'fixed',
      background: '#f5f6f8',
      width: '100%',
      marginLeft: '-12px',
      zIndex: 10,
    },
    loading: {
      display: 'flex',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    root: {
      height: '100%',
    },
    actionButton: {
      marginBottom: '10px !important',
      '&:lastChild': {
        marginBottom: '0', // !important,
      },
    },
    welcomeWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    logoContainer: {
      height: '103px',
      img: {
        height: '100%',
      },
    },
    welcomeImageContainer: {
      width: '320px',
      margin: '10px auto 30px',
      img: {
        width: '100%',
      },
    },
    closeIconWrapper: {
      top: '61px',
      position: 'fixed',
      right: '0',
      background: '#ff8c00',
      borderRadius: '4px',
      width: '20px',
      height: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      marginRight: '16px',
      boxShadow: '0px 3px 10px 1px #ff8c0042',
      zIndex: 11,
    },
    closeIconCRM: {
      top: '20px',
      position: 'fixed',
      right: '0',
      background: '#ff8c00',
      borderRadius: '4px',
      width: '20px',
      height: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      marginRight: '16px',
      boxShadow: '0px 3px 10px 1px #ff8c0042',
      zIndex: 11,
    },
    invalidRoleBox: {
      border: '2px solid #f00066',
      padding: '12px 36px',
    },

    invalidTitle: {
      textAlign: 'center',
      fontSize: '16px',
      fontWeight: 900,
      lineHeight: 15,
      color: '#f00066',
    },
  })
);

export default useStyles;
