import React, { useEffect, useState } from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  IBreadcrumbHandlerProps,
  addBreadcrumbHandler,
} from '../../../utils/addBreadcrumbHandler';
import connector, { IPropsFromState } from '../../Connector/Connector';
import {
  getAuthorisationMode,
  getStationModel,
} from '../../../utils/stationUtils';

import { AccessModeDialog } from './AccessModeDialog/AccessModeDialog';
import CardElement from '../../shared/CardElement/CardElement';
import { IHomeChargingStation } from '../../../types/user';
import { RfidCardOrderStatus } from '../../../types/subscriber';
import { StatusIndicator } from '../../CardsUI/StatusIndicator/StatusIndicator';
import { formatIsoDates } from '../../../utils/dates';
import { parseHomeChargingAddress } from '../../../utils/parseAddress';
import useSharedClasses from '../../shared/useSharedStyles';
import { useCurrentTask } from '../../../custom-hooks/useCurrentTask';
import ActionButtons from './ActionButtons';
import { ActionBar } from '../../shared/ActionBar/ActionBar';
import useStyles from './useStyles';

type TProps = IPropsFromState & {
  crumbId?: string;
  startCollapsed?: boolean;
};

const nullHomeStation: IHomeChargingStation = {
  id: '-',
  auth_name: '-',
  authorization_mode: '-',
  connection_state: '-',
  lifecycle_state: '-',
  created_at: '-',
  last_contact: '-',
  model: '-',
  name: '-',
  onboarding_date: '-',
  evses: [
    {
      connectors: [
        {
          format: 'test',
          id: 0,
          max_amperage: 0,
          max_electric_power_kw: 11,
          max_voltage: 400,
          power_type: '-',
          status: 'unreported',
          type: '-',
        },
      ],
      id: 0,
      status: 'unreported',
    },
  ],
  location: {
    address: {},
    id: '-',
  },
};

export const HomeChargingUI: React.FC<TProps> = ({
  homeStationsState,
  breadcrumbState,
  chargingSessionsState,
  cardOrdersState,
  chargingCardState,
  startCollapsed = false,
  addBreadcrumb,
}) => {
  const sharedClasses = useSharedClasses();
  const classes = useStyles();
  const taskSid = useCurrentTask();
  const homeStations = homeStationsState && homeStationsState[taskSid];
  const taskBreadcrumbState = breadcrumbState && breadcrumbState[taskSid];
  const orders = cardOrdersState && cardOrdersState[taskSid];
  const chargingSessions =
    chargingSessionsState && chargingSessionsState[taskSid];
  const chargingCards = chargingCardState && chargingCardState[taskSid];

  const [homeStationData, setHomeStationData] = useState<IHomeChargingStation>(
    nullHomeStation
  );

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (homeStations && homeStations.data) {
      setHomeStationData({ ...nullHomeStation, ...homeStations.data });
    } else {
      setHomeStationData(nullHomeStation);
    }
  }, [homeStationsState, taskSid]);

  const breadcrumbHandler = (props: IBreadcrumbHandlerProps): void =>
    addBreadcrumbHandler({
      ...props,
      addBreadcrumb,
      taskSid,
      taskBreadcrumbState,
      level: 1,
    });

  return homeStationData.id !== '-' ? (
    <CardElement title='Home Charging' startCollapsed={startCollapsed}>
      <Grid
        data-testid='home-charging-ui-component'
        classes={{
          root: classes.detailWrapper,
        }}
        container
        justify='space-between'
        alignItems='center'
        direction='row'
      >
        <Grid item xs={6}>
          <div className={sharedClasses.cardDetail}>
            <Typography variant='h6'>Station Name</Typography>
            <div className={sharedClasses.cardDetailData}>
              <Typography data-testid='home-station-name' variant='body2'>
                {homeStationData.name}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={sharedClasses.cardDetail}>
            <Typography variant='h6'>Station Model</Typography>
            <div className={sharedClasses.cardDetailData}>
              <Typography data-testid='home-station-model' variant='body2'>
                {getStationModel(homeStationData.model)}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={sharedClasses.cardDetail}>
            <Typography variant='h6'>
              Access Mode
              <IconButton
                data-testid='more-info-button'
                onClick={() => setDialogOpen((prevState) => !prevState)}
                className={classes.infoIcon}
                disableRipple
                disableFocusRipple
              >
                <InfoOutlinedIcon />
              </IconButton>
            </Typography>
            <AccessModeDialog
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
            />
            <div className={sharedClasses.cardDetailData}>
              <Typography variant='body2'>
                {getAuthorisationMode(homeStationData.authorization_mode)}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={sharedClasses.cardDetail}>
            <Typography variant='h6'>Connector</Typography>
            <div className={sharedClasses.cardDetailData}>
              {homeStationData.evses[0] ? (
                <div className={classes.statusWrapper}>
                  <StatusIndicator
                    status={
                      homeStationData.evses[0].connectors[0]
                        .status as RfidCardOrderStatus
                    }
                    className={classes.activationStatus}
                  />
                  <Typography className={classes.statusText} variant='body2'>
                    {homeStationData.evses[0].connectors[0].status}
                  </Typography>
                </div>
              ) : (
                '-'
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={sharedClasses.cardDetail}>
            <Typography variant='h6'>Last Contact</Typography>
            <div className={sharedClasses.cardDetailData}>
              <Typography
                data-testid='home-station-last-contact'
                variant='body2'
              >
                {homeStationData.last_contact
                  ? formatIsoDates(homeStationData.last_contact)
                  : '-'}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={sharedClasses.cardDetail}>
            <Typography variant='h6'>Internet Connection</Typography>
            <div className={sharedClasses.cardDetailData}>
              <span>
                {homeStationData.connection_state ? (
                  <div className={classes.statusWrapper}>
                    <StatusIndicator
                      status={
                        homeStationData.connection_state as RfidCardOrderStatus
                      }
                      className={classes.activationStatus}
                    />
                    <Typography className={classes.statusText} variant='body2'>
                      {homeStationData.connection_state}
                    </Typography>
                  </div>
                ) : (
                  '-'
                )}
              </span>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6'>Address</Typography>
          <div className={sharedClasses.cardDetailData}>
            <Typography variant='body2'>
              {(homeStationData.location &&
                parseHomeChargingAddress(homeStationData.location.address)) ||
                '-'}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <ActionBar>
        <ActionButtons
          breadcrumbHandler={breadcrumbHandler}
          chargingCards={chargingCards}
          chargingSessions={chargingSessions}
          homeStations={homeStations}
          orders={orders}
        />
      </ActionBar>
    </CardElement>
  ) : null;
};

export default connector(HomeChargingUI);
