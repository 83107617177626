import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogWrapper: {
      position: 'relative',
    },
    progressBar: {
      backgroundColor: theme.surfaceTertiary,
      borderRadius: '2px',
      height: '4px',
      marginBottom: '4px',
      width: '240px',
    },
    closeButton: {
      cursor: 'pointer',
      position: 'absolute',
      top: 16,
      right: 16,
      color: theme.textSecondary,
    },
    noVerification: {
      cursor: 'pointer',
      position: 'absolute',
      bottom: 16,
      right: 16,
    },
    textDecoration: {
      textDecoration: 'underline',
    },
    root: {
      right: '0 !important',
      left: 'auto !important',
      top: 'auto !important',
      bottom: '0 !important',
      height: 'calc(100% - 44px) !important',
    },
    crmRoot: {
      right: '0 !important',
      left: 'auto !important',
      top: 'auto !important',
      bottom: '0 !important',
      height: '100% !important',
    },
    backDrop: {
      width: 'calc(100%)',
      top: 'auto !important',
      right: 'auto !important',
      left: 'auto !important',
      bottom: 0, // !important,
      '@media (max-width: 1280px)': {
        display: 'none !important',
      },
    },
    crmBackdrop: {
      width: 'calc(100%)',
      top: 'auto !important',
      right: 'auto !important',
      left: 'auto !important',
      bottom: 0, // !important,
      height: 'calc(100% - 43px) !important',
      '@media (max-width: 1280px)': {
        display: 'none !important',
      },
    },
    dialogPaper: {
      width: '85% !important',
      height: '85% !important',
      maxWidth: '650px !important',
      maxHeight: '750px !important',
      '@media (max-width: 1280px)': {
        width: '100% !important',
        height: '100% !important',
        maxWidth: 'none !important',
        maxHeight: 'none !important',
      },
    },
    modalContainer: {
      height: '100% !important',
      justifyContent: 'flex-start !important',
      paddingTop: '64px !important',

      '@media (max-width: 1280px)': {
        paddingTop: '0 !important',
        justifyContent: 'center !important',
      },
    },
    progressBottom: {
      position: 'absolute',
      left: '50%',
      bottom: '50px',
      transform: 'translate(-50%, -50%)',
      margin: '0 auto',

      '@media (max-width: 1440px) and (min-width: 1280px)': {
        bottom: '30px',
      },
    },
    searchIconWrapper: {
      background: '#6633cc',
      borderRadius: '4px',
      width: '20px',
      height: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      marginRight: '8px',
      boxShadow: '0px 3px 10px 1px #6633cc42',
    },
    selectProfileButton: {
      boxShadow: '0 4px 12px 0 rgba(102, 51, 204, 0.3) !important',
      width: '222px',
      height: '39px',
    },
  })
);

export default useStyles;
