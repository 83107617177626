import { ErrorObject } from 'serialize-error';
import { ILocationModel } from './../../../types/chargingsession';
import { ILocationParams } from './../../../services/locations';

interface ILocation {
  [id: string]: ILocationModel;
}

export interface ILocations {
  [id: string]: ILocationModel[];
}

export interface IChargingStationModel {
  loading: boolean;
  nearbyLocationsLoading: boolean;
  data: ILocation | null;
  nearbyLocations: ILocations | null;
  error: ErrorObject | null;
  request: { evse_id: string } | { location: ILocationParams } | null;
}

export interface IChargingStationState {
  [id: string]: IChargingStationModel;
}

const state: IChargingStationState = {};

export type State = typeof state;
export default state;
