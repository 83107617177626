import { Dialog } from '@material-ui/core';
import { IChargingRecordViewModel } from '../../../../types/chargingRecords';
import React from 'react';
import decimalToComma from '../../../../utils/decimalToComma';
import useStyles from './useStyles';
export interface IOwnProps {
  open: boolean;
  onClose: (value: string) => void;
  chargingSession: IChargingRecordViewModel;
}

export const PriceDialog: React.FC<IOwnProps> = ({
  open,
  onClose,
  chargingSession,
}) => {
  const classes = useStyles();

  const getSellingPrice = (): string => {
    const totalPrice = chargingSession.price || 0;
    const discount = chargingSession.discount || 0;
    const vatAmount = chargingSession.vat_amount || 0;

    const sellingPrice = totalPrice + discount - vatAmount;

    return decimalToComma(sellingPrice);
  };

  const getPercentFromDecimal = (vatDecimal: number): number => {
    return vatDecimal * 100;
  };

  const getNegativeDiscount = (discount = 0): string => {
    return decimalToComma(discount * -1);
  };

  const getRate = (price = 0, priceExchanged = 1): string => {
    const rate = (price / priceExchanged).toFixed(2);
    return decimalToComma(rate);
  };

  const hasBlockingFee = (cdr: IChargingRecordViewModel) => {
    return (
      cdr.blocking_fee !== undefined &&
      cdr.blocking_fee_duration_in_minutes !== undefined
    );
  };

  const getChosenCpoPriceWithCurrency = () => {
    const isGuaranteedPrice =
      chargingSession.cpo_pricing_source === 'guaranteed_cpo_price';
    const chosenCpoPrice = decimalToComma(
      isGuaranteedPrice
        ? chargingSession.guaranteed_cpo_price!
        : chargingSession.cpo_price
    );

    const currency = isGuaranteedPrice
      ? chargingSession.guaranteed_cpo_price_currency
      : chargingSession.cpo_price_currency;

    return `${chosenCpoPrice} ${currency}`;
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      data-testid='price-info-dialog'
      PaperProps={{
        classes: {
          root: classes.dialog,
        },
      }}
    >
      <div className={classes.customerPriceWrapper}>
        <span className={classes.title}>Customer Price</span>
        <div className={classes.itemWrapper}>
          <span className={classes.itemProperty}>Selling Price</span>
          <span className={classes.itemValue} data-testid='selling-price'>
            {getSellingPrice()} {chargingSession.currency}
          </span>
        </div>
        <div className={classes.itemWrapper}>
          <span className={classes.itemProperty}>
            Taxation {getPercentFromDecimal(chargingSession.vat_percentage)}%
          </span>
          <span className={classes.itemValue} data-testid='taxation'>
            {decimalToComma(
              chargingSession.vat_amount ? chargingSession.vat_amount : '0.00'
            )}{' '}
            {chargingSession.currency}
          </span>
        </div>
        <div
          data-testid='discount-wrapper'
          className={`${classes.discountWrapper} ${
            !chargingSession.discount ? classes.greyedOut : ''
          }`}
        >
          <span className={classes.itemProperty}>Discount</span>
          <span className={classes.itemValue} data-testid='discount'>
            {getNegativeDiscount(chargingSession.discount || 0)}{' '}
            {chargingSession.currency}
          </span>
        </div>
        <div className={classes.totalWrapper}>
          <span className={classes.itemValue} data-testid='total-price'>
            {decimalToComma(
              chargingSession.price ? chargingSession.price : '0.00'
            )}{' '}
            {chargingSession.currency}
          </span>
        </div>
      </div>
      <div className={classes.priceIncludesWrapper}>
        <span className={classes.title}>Includes</span>
        <div
          data-testid='blocking-fee-wrapper'
          className={`${classes.itemWrapper} ${
            hasBlockingFee(chargingSession) ? '' : classes.greyedOut
          }`}
        >
          <span className={classes.itemProperty}>
            Blocking Fee
            {chargingSession.blocking_fee_duration_in_minutes !== undefined
              ? ` (${chargingSession.blocking_fee_duration_in_minutes} min)`
              : ''}
          </span>
          <span className={classes.itemValue} data-testid='blocking-fee'>
            {decimalToComma(
              chargingSession.blocking_fee !== undefined
                ? chargingSession.blocking_fee
                : '0'
            )}{' '}
            {chargingSession.currency}
          </span>
        </div>
      </div>
      <div className={classes.buyingPriceWrapper}>
        <span className={classes.title}>Buying Price</span>
        <div className={classes.itemWrapper}>
          <span className={classes.itemProperty}>Price in local currency</span>
          <span className={classes.itemValue} data-testid='chosen-cpo-price'>
            {getChosenCpoPriceWithCurrency()}
          </span>
        </div>
        {!chargingSession.guaranteed_cpo_price &&
          chargingSession.cpo_price_currency !== chargingSession.currency && (
            <>
              <div className={classes.itemWrapper}>
                <span className={classes.itemProperty}>
                  Price in target currency
                </span>
                <span
                  className={classes.itemValue}
                  data-testid='exchange-price'
                >
                  {decimalToComma(
                    chargingSession.cpo_price_exchanged
                      ? chargingSession.cpo_price_exchanged.toFixed(2)
                      : '0.00'
                  )}{' '}
                  {chargingSession.currency}
                </span>
              </div>
              <div className={classes.itemWrapper}>
                <span className={classes.itemProperty}>
                  Exchange rate target currency
                </span>
                <span className={classes.itemValue} data-testid='exchange-rate'>
                  {getRate(
                    chargingSession.cpo_price,
                    chargingSession.cpo_price_exchanged || 1
                  )}{' '}
                  {chargingSession.currency}
                </span>
              </div>
            </>
          )}
      </div>
    </Dialog>
  );
};
