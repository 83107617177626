import React from 'react';

import CopyTextFieldList from '../../ui/CopyTextField/CopyTextFieldList';

export interface IOwnProps {
  userId: string;
  subscriberId: string;
  customerNumber?: string;
}

export const UserIds: React.FC<IOwnProps> = ({
  userId,
  subscriberId,
  customerNumber,
}) => {
  const getTextList = () => {
    const textList = [];

    if (customerNumber) {
      textList.push({
        title: 'Customer Number',
        text: customerNumber,
      });
    }

    textList.push({
      title: 'User Id (IAM Id)',
      dataTestId: 'user-id',
      text: userId,
    });

    textList.push({
      title: 'Subscriber Id',
      text: subscriberId,
    });

    return textList;
  };

  return <CopyTextFieldList textList={getTextList()} />;
};
