import { ChargingSessionsState, State } from '..';

import { ActionInterface } from '../actions';
import update from 'immutability-helper';

export default (
  state: State,
  action: ActionInterface.ClearChargingSessions
): State => {
  const updatedState: ChargingSessionsState = update(state, {
    $unset: [action.taskSid],
  });

  return {
    ...updatedState,
  };
};
