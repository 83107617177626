import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';

import {
  DialogContextState,
  IDialogContextState,
} from '../context/ActionDialogContext';
import useStyles from './useStyles';

export interface IOwnProps {
  isOpen: boolean;
  onClose: (input?: unknown) => unknown;
}

/**
 * Presentational component for popup dialog. Logic is provided by wrapping component container
 */
const DialogContainer: React.FC<IOwnProps> = ({
  isOpen,
  onClose,
  children,
}) => {
  const classes = useStyles();
  const context: IDialogContextState = {
    handleCloseDialog: onClose,
  };

  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      PaperProps={{
        classes: {
          root: classes.dialog,
        },
      }}
    >
      <>
        <IconButton
          classes={{
            root: classes.closeButton,
          }}
          data-testid='dialog-container-close'
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <DialogContextState.Provider value={context}>
          {children}
        </DialogContextState.Provider>
      </>
    </Dialog>
  );
};

export default DialogContainer;
