import { IHomeChargingStation, IHomeChargingStationModel } from '../types/user';

import ApiService from './api';

interface IRequestParams {
  userId?: string;
  organizationId?: string;
}

const getStations = async ({
  userId,
  organizationId,
}: IRequestParams): Promise<IHomeChargingStationModel> => {
  const data: { user_id?: string; organization_id?: string; limit: number } = {
    limit: 50,
  };

  if (userId) {
    data.user_id = userId;
  }

  if (organizationId) {
    data.organization_id = organizationId;
  }

  const apiService = new ApiService('/mgmt/csms/v1/stations', {
    data: {
      ...data,
    },
  });
  const firstResponse = await apiService.query();
  const responseData = firstResponse.data;

  const totalCount = responseData.total_count;

  for (let i = 50; i <= totalCount; i = i + 50) {
    const newApiService = new ApiService('/mgmt/csms/v1/stations', {
      data: {
        ...data,
        offset: i,
      },
    });
    const newResponse = await newApiService.query();
    responseData.stations.push(...newResponse.data.stations);
  }

  return responseData;
};

const getStationById = async (
  stationId: string
): Promise<IHomeChargingStation> => {
  const apiService = new ApiService(`/mgmt/csms/v1/stations/${stationId}`);
  const response = await apiService.query();
  return response.data;
};

export const HomeChargingService = { getStations, getStationById };
