import React, { ChangeEvent } from 'react';
import { TextField } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import useStyles from './useStyles';
import StatusIcon, { Status } from '../../Icons/StatusIcon';

export interface IOwnProps {
  title: string;
  status?: Status;
  isDisabled?: boolean;
  message?: string;
  value?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const InputFieldWithStatus: React.FC<IOwnProps> = ({
  title,
  isDisabled = false,
  status = Status.OPEN,
  message,
  value = '',
  onChange,
}) => {
  const classes = useStyles();

  const getErrorMessage = () => {
    if (!message || status !== Status.ERROR) {
      return null;
    }

    return (
      <p className={classes.error} data-testid='status-error-message'>
        <ErrorOutlineIcon className={classes.errorIcon} /> {message}
      </p>
    );
  };

  return (
    <div className={classes.inputFieldWrapper}>
      <TextField
        label={title}
        className={`${classes.inputRoot} ${classes.inputField}`}
        variant='outlined'
        disabled={isDisabled}
        onChange={onChange}
        value={value}
        InputLabelProps={{
          classes: {
            root: classes.label,
          },
        }}
      />
      <div className={classes.statusIcon}>
        <StatusIcon status={status} />
      </div>
      {getErrorMessage()}
    </div>
  );
};

export default InputFieldWithStatus;
