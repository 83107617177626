import React, { useEffect, useRef, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableRow,
} from '@material-ui/core';

import { AssetTableHead } from '../AssetTableHead/AssetTableHead';
import Magnifier from '../../../../assets/image/magnifyingGlass.svg';
import useStyles from './useStyles';

export interface ISortState {
  sortByColumnKey: string | null;
  sortDesc: boolean;
}
export interface IColumn {
  header: string;
  key: string;
  isSortable: boolean;
  isStatus?: boolean;
  width?: string;
  noPadding?: boolean;
  noBorder?: boolean;
  align?: TableCellProps['align'];
}

export interface IBulkActionWithCallback {
  icon: JSX.Element;
  title: string;
  callback: (ids: string[]) => void;
}
export interface IBulkActionWithMenu {
  icon: JSX.Element;
  title: string;
  menu: (ids: string[], closeCallback: () => void) => JSX.Element;
  key: string;
}
export type BulkAction = IBulkActionWithCallback | IBulkActionWithMenu;

export interface ICell {
  content: JSX.Element | string | number;
}

export interface IRow {
  id: string;
  data: {
    [key: string]: ICell;
  };
}

interface IOwnProps {
  bulkActions: BulkAction[];
  columns: IColumn[];
  filterState?: any; // FilterState
  onRowClick?: (row: IRow) => void;
  rows: IRow[];
  sortRequestHandler: (property: string) => void;
  sortingState?: ISortState;
  noDataTitle?: string;
  noDataSubTitle?: string;
  condensed?: boolean;
}

const addBulkEditingInfo = (content: ICell['content']): ICell['content'] => {
  if (typeof content === 'string' || typeof content === 'number') {
    return content;
  } else {
    return React.cloneElement(content);
  }
};

export const AssetTable: React.FC<IOwnProps> = ({
  bulkActions,
  columns,
  filterState,
  onRowClick,
  rows,
  sortRequestHandler,
  sortingState = { sortByColumnKey: null, sortDesc: false },
  noDataTitle,
  noDataSubTitle,
  condensed = false,
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState<string[]>([]);
  const sentinelTop = useRef<HTMLDivElement>(null);

  const toggleSelection = (id: string) => {
    if (bulkActions.length === 0) {
      return;
    }
    if (selected.includes(id)) {
      setSelected(selected.filter((value) => value !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const toggleSelectAll = () => {
    if (bulkActions.length === 0) {
      return;
    }
    if (selected.length === 0) {
      setSelected(rows.map((row) => row.id));
    } else {
      setSelected([]);
    }
  };

  useEffect(() => {
    setSelected([]);
  }, [filterState]);

  const getTableCellClasses = (column: IColumn): string => {
    const classList = [];

    if (column.noPadding) {
      classList.push(classes.noPadding);
    } else {
      classList.push(classes.tableCell);
    }

    if (column.noBorder) {
      classList.push(classes.noBorder);
    }

    return classList.join(' ');
  };

  return (
    <React.Fragment>
      <div style={{ height: '1px' }} ref={sentinelTop} />
      <Table className={classes.table}>
        <AssetTableHead
          condensed={condensed}
          bulkActions={bulkActions}
          columns={columns}
          isStuck={false}
          nRows={rows.length}
          selected={selected}
          sortingState={sortingState}
          sortRequestHandler={sortRequestHandler}
          toggleSelectAll={toggleSelectAll}
        />

        <TableBody>
          {rows.length > 0 ? (
            rows.map((row) => {
              const rowSelected = selected.includes(row.id);
              const checkboxCallback = () => {
                toggleSelection(row.id);

                // Unfocus the checkbox so that the row does not remain grey
                const focusedElement = document.activeElement as HTMLElement;
                focusedElement.blur();
              };

              const onClickRowEvent = () => {
                if (onRowClick) {
                  onRowClick(row);
                }
                return selected.length > 0 ? checkboxCallback : undefined;
              };

              return (
                <TableRow
                  data-testid='table-row'
                  key={Math.random()}
                  className={`${classes.tableRow} ${
                    rowSelected ? classes.tableRowSelected : ''
                  } ${selected.length > 0 ? classes.tableRowAnySelected : ''} ${
                    onRowClick ? classes.tableRowClickable : ''
                  }`}
                  onClick={onClickRowEvent}
                  style={{ height: condensed ? '48px' : '56px' }}
                >
                  {columns.map((column) => (
                    <TableCell
                      className={getTableCellClasses(column)}
                      key={`${row.id}-${column.key}`}
                      align={column.align || 'inherit'}
                    >
                      {addBulkEditingInfo(row.data[column.key].content)}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell
                className={classes.noContentRow}
                colSpan={columns.length}
                align='center'
              >
                <div className={classes.noDataWrapper}>
                  <img src={Magnifier} alt='magnifier' />
                  <p className={classes.noDataTitle}>{noDataTitle}</p>
                  <p className={classes.noDataSubTitle}>{noDataSubTitle}</p>
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};
