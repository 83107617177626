import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  dialog: {
    padding: 30,
  },
  infoIcon: {
    marginLeft: '2px !important',
    cursor: 'pointer',
    fontSize: '12px !important',
  },

  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default useStyles;
