import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';

import { ElliTooltip } from '../../../shared/ElliTooltip/ElliTooltip';
import React from 'react';
import useStyles from './useStyles';

interface IActionItem {
  icon: JSX.Element;
  friendlyText: string;
  url: string;
}

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dropdownRef: React.RefObject<HTMLButtonElement>;
  actionItems: IActionItem[];
}

export const ActionDropdown: React.FC<IProps> = ({
  open,
  setOpen,
  dropdownRef,
  actionItems,
}) => {
  const classes = useStyles();

  const handleItem = (
    _event: React.MouseEvent<EventTarget>,
    item: IActionItem
  ): void => {
    if (item.url) {
      window.open(item.url);
    }
    setOpen(false);
  };

  const handleDialog = (_event: React.MouseEvent<EventTarget>): void => {
    setOpen(false);
  };

  return (
    <>
      <Popper
        open={open}
        anchorEl={dropdownRef.current}
        role={undefined}
        transition
        disablePortal
        placement='bottom-start'
        data-testid='action-dropdown-popper'
        style={{ zIndex: 50 }}
      >
        {({ TransitionProps }): JSX.Element => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'left top',
            }}
          >
            <Paper
              classes={{
                root: classes.paperRoot,
              }}
            >
              <ClickAwayListener onClickAway={(event) => handleDialog(event)}>
                <MenuList
                  classes={{
                    root: classes.menuList,
                  }}
                  autoFocusItem={open}
                  id='menu-list-grow'
                  data-testid='action-dropdown-list'
                >
                  {actionItems.map((item) => (
                    <MenuItem
                      classes={{
                        root: classes.menuItem,
                      }}
                      data-testid='action-dropdown-item'
                      key={item.friendlyText}
                      onClick={(event) => handleItem(event, item)}
                    >
                      <ElliTooltip title={item.friendlyText}>
                        <div className={classes.actionItemWrapper}>
                          <span className={classes.actionIcon}>
                            {item.icon}
                          </span>
                          <span className={classes.menuItemText}>
                            {item.friendlyText}
                          </span>
                        </div>
                      </ElliTooltip>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default ActionDropdown;
