import React, { useState } from 'react';
import { CircularProgress, Divider } from '@material-ui/core';
import shortid from 'shortid';

import { useTypedTranslation } from '../../../../../custom-hooks/useTypedTranslation';
import useStyles from './useStyles';
import MultilineButton from '../../../../shared/Buttons/MultilineButton';
import LineItemsContainer, { IContainerLineItem } from './LineItemsContainer';
import NewLineItem, { TLineItemFormData } from './NewLineItem';
import { countryInformationMapper } from '../../../../../utils/currencies';
import { RefundService } from '../../../../../services/refunds';
import Message, { MessageTypes, IMessage } from '../../../../shared/Message';
import { useCurrentTask } from '../../../../../custom-hooks/useCurrentTask';
import connector, { IPropsFromState } from '../../../../Connector/Connector';
import { AxiosError } from 'axios';

export interface IRefund {
  invoiceId: string;
  countryCode: string;
  currency: string;
  lineItems: IContainerLineItem[];
}

export type TOwnProps = IPropsFromState & {
  invoiceId: string;
};

export const AddRefundForm: React.FC<TOwnProps> = ({
  invoiceId,
  subscriberState,
  getInvoices,
}) => {
  const taskSid = useCurrentTask();
  const taskSubscriberState = subscriberState && subscriberState[taskSid];
  const { t, terms } = useTypedTranslation();
  const classes = useStyles();
  const [currency, setCurrency] = useState<string>();
  const [countryCode, setCountryCode] = useState<string>();
  const [message, setMessage] = useState<IMessage>();
  const [lineItems, setLineItems] = useState<IContainerLineItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleLineItemRemove = (lineItemId: string) => {
    const newLineItems = lineItems.filter(({ id }) => id !== lineItemId);
    setLineItems(newLineItems);
  };

  const addLineItem = (
    lineItemData: TLineItemFormData,
    newCountryCode: string
  ) => {
    const newLineItem = {
      id: shortid.generate(),
      ...lineItemData,
    };
    setLineItems((current) =>
      current?.length ? [...current, newLineItem] : [newLineItem]
    );
    setCurrency(countryInformationMapper[newCountryCode].currency);
    setCountryCode(newCountryCode);
  };

  const resetRefund = () => {
    setLineItems([]);
  };

  const submitRefund = async () => {
    if (invoiceId && countryCode && currency && lineItems) {
      setIsLoading(true);
      const mappedRefund = RefundService.mapRefundForRequest({
        invoiceId,
        countryCode,
        currency,
        lineItems,
      });

      try {
        await RefundService.addRefund(invoiceId, mappedRefund);
        resetRefund();
        setMessage({
          type: MessageTypes.SUCCESS,
          text: t(terms.refunds.form.success),
        });

        if (taskSubscriberState.data) {
          getInvoices({
            taskSid,
            subscriberId: taskSubscriberState.data.id,
          });
        }
      } catch (e) {
        let text = t(terms.refunds.form.error);

        if (e?.response) {
          const axiosError = e as AxiosError<{
            errors: { code: string; message: string }[];
          }>;
          const responseErrors = axiosError.response?.data.errors;

          if (responseErrors?.length && responseErrors[0].message) {
            text = responseErrors[0].message;
          }
        }

        setMessage({
          type: MessageTypes.ERROR,
          text,
        });
      }

      setIsLoading(false);
    }
  };

  const submitButtonContent = isLoading ? (
    <CircularProgress size={20} classes={{ root: classes.loadingSpinner }} />
  ) : (
    t(terms.refunds.form.submit)
  );

  return (
    <div className={classes.container}>
      <NewLineItem handleSubmit={addLineItem} />
      <Divider className={classes.divider} />
      {!!lineItems?.length && currency && (
        <LineItemsContainer
          lineItems={lineItems}
          currency={currency}
          handleRemove={handleLineItemRemove}
        />
      )}
      <div className={`${classes.centered} ${classes.asRows}`}>
        <MultilineButton
          mainText={submitButtonContent}
          handleClick={submitRefund}
          isDisabled={!lineItems?.length}
        />
        {message && <Message message={message} />}
      </div>
    </div>
  );
};

export default connector(AddRefundForm);
