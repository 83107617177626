import { ActionCreator, ActionInterface, ActionType } from './actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import { IRFIDCardModel } from '../../../types/subscriber';
import { SubscribersService } from '../../../services/subscribers';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';

const sortLastUsed = (a: IRFIDCardModel, b: IRFIDCardModel): number => {
  if (a.updated_at && b.updated_at) {
    const aLastUsed = new Date(a.updated_at);
    const bLastUsed = new Date(b.updated_at);

    if (isAfter(aLastUsed, bLastUsed)) {
      return -1;
    } else if (isBefore(aLastUsed, bLastUsed)) {
      return 1;
    }
  }
  return 0;
};

const sortCardsFn = (a: IRFIDCardModel, b: IRFIDCardModel): number => {
  if (a.status === 'active' && b.status === 'active') {
    return sortLastUsed(a, b);
  } else if (a.status === 'active') {
    return -1;
  } else if (b.status === 'active') {
    return 1;
  } else if (a.subscription_id && b.subscription_id) {
    return sortLastUsed(a, b);
  } else if (a.subscription_id) {
    return -1;
  } else if (b.subscription_id) {
    return 1;
  } else {
    return sortLastUsed(a, b);
  }
};

const sortCards = (cards: IRFIDCardModel[]): IRFIDCardModel[] => {
  return cards.sort(sortCardsFn);
};

export function* chargingCardSaga() {
  function* getChargingCards(action: ActionInterface.GetChargingCardRequest) {
    try {
      const chargingCards: IRFIDCardModel[] = yield call(
        SubscribersService.getRFIDCards,
        {
          subscriberId: action.subscriberId,
        }
      );

      const sortedCards: IRFIDCardModel[] = yield call(
        sortCards,
        chargingCards
      );

      yield put<ActionInterface.GetChargingCardSuccess>(
        ActionCreator.GetChargingCardSuccess({
          data: sortedCards,
          taskSid: action.taskSid,
        })
      );
    } catch (error) {
      yield put<ActionInterface.GetChargingCardError>(
        ActionCreator.GetChargingCardError({
          error,
          taskSid: action.taskSid,
        })
      );
    }
  }

  yield all([takeEvery(ActionType.GetChargingCardRequest, getChargingCards)]);
}

export default chargingCardSaga;
