import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    welcomePanel: {
      height: 678,
      width: 638,
      background: '#fff',
      boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.08)',
      borderRadius: 4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexFlow: 'column nowrap',
    },
    invalidRoleWelcomePanel: {
      height: 811,
      width: 534,
      background: '#fff',
      boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.08)',
      borderRadius: 4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexFlow: 'column nowrap',
    },
    logoutLink: {
      fontSize: 12,
      color: theme.textSecondary,
      marginTop: 40,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    loggedOutBox: {
      border: `2px solid ${theme.colorPrimary}`,
      padding: '12px 36px',
    },
    invalidTitle: {
      textAlign: 'center',
      fontSize: 16,
      fontWeight: 900,
      lineHeight: 1.5,
      color: theme.colorPrimary,
    },
    loginButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 36,
      background: theme.colorPrimary,
      color: '#fff',
      fontWeight: 900,
      fontSize: 16,
      boxShadow: '0 4px 12px 0 rgba(102, 51, 204, 0.3)',
      height: 40,
      border: 'none',
      minWidth: 193,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgb(71, 35, 142)',
      },
    },
    buttonIcon: {
      marginRight: 8,
    },
    insufficentPermissionsText: {
      color: theme.colorAlertPrimary,
      fontSize: 13,
      fontWeight: 'normal',
      textAlign: 'center',
      marginBottom: 23,
    },
    invalidRoleContextBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexFlow: 'column nowrap',
      maxWidth: 350,
    },
    accountEmail: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      flexFlow: 'column nowrap',
      marginBottom: 24,
    },
    emailTitle: {
      fontSize: 12,
      fontWeight: 900,
      marginBottom: 10,
    },
    emailText: {
      fontSize: 13,
    },
    textWrapper: {
      display: 'flex',
      flexFlow: 'column nowrap',
      marginBottom: 20,
      width: '100%',
    },
    textBox: {
      height: 30,
      padding: 0,
      paddingLeft: 10,
      color: 'black',
      fontWeight: 'normal',
      borderRadius: 3,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: 249,
      boxSizing: 'border-box',
    },
    inputRoot: {
      borderRadius: 3,
      width: '100%',
      background: '#E6E6E6',
      paddingRight: 8,
      display: 'flex',
      justifyContent: 'space-between',
      '&:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23) !important',
      },
    },
    title: {
      fontWeight: 'bold',
      fontSize: 12,
      marginBottom: 4,
    },
    copyIcon: {
      cursor: 'pointer',
      fontSize: 16,
      '&:hover': {
        background: 'none !important',
      },
    },
    inputFocused: {
      outline: 'none',
      borderColor: 'rgba(0, 0, 0, 0.23)',
      borderWidth: 1,
      '& fieldset': {
        borderWidth: '1px !important',
        borderColor: 'rgba(0, 0, 0, 0.23) !important',
      },
    },
    divider: {
      width: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      marginBottom: 24,
    },
    topText: { marginBottom: 8 },
    bottomText: {},
    userDetailsWrapper: {
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      width: '100%',
    },
    mailtoLink: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  })
);

export default useStyles;
