import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    statusWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    activationStatus: {
      display: 'inline-block',
      marginRight: 4,
      verticalAlign: 'baseline',
    },
    statusText: {
      textTransform: 'capitalize',
    },
  })
);

export default useStyles;
